.fj_account {
    #wrapper #main {
        font-family: $neuzeit;
        margin-top: 1rem;
        @include respond(small) {
            margin-top: 1rem;
        }

        .content-asset .account-asset {
            margin: 65px 0 30px;
            font-size: 0.75rem;
            color: $black;
            line-height: 1.14;
            letter-spacing: -0.12px;
            @include respond(largeUp) {
                margin: 28px 0 30px;
            }
        }

        .contact-asset,
        #secondary .contact-asset {
            p,
            h3,
            a,
            .content-asset {
                font-size: 14px;
                color: $black;
                text-transform: none;
            }
            h3 {
                margin-bottom: 0; 
            }
            a.msg-us {
                font-weight: 700;
                text-decoration: underline;
            }
            p {
                margin: 0;
            }
        }

        .content-asset .account-asset h3,
        #secondary .account-nav-asset h3,
        .contact-asset h3 {
            font-family: $neuzeit;
            color: $black;
            font-weight: bold;
            text-transform: none;
            letter-spacing: -0.22px;
            line-height: 1.14;
        }
        #secondary {
            padding-top: 0;
            .content-asset .account-asset {
                width: 170px;
            }
        }
        .mobile-visible {
            display: none;
            @include respond(small) {
				padding-bottom: 20px;
                clear: both;
            }
        }
		.account-nav-asset {
			@include respond(small) {
				p {
					color: #000;
				}
				h3 {
					padding-top: 0;
    				margin-top: 0;
					padding-bottom: 0;
    				margin-bottom: 0;                    
					& + p {
						padding-bottom: 0;
						br {
							display: none;
						}
					}
				}
				& ~ h3 {
					padding-top: 0;
					margin-top: 14px;
					padding-bottom: 0;
    				margin-bottom: 0;
				}
			}
		}
    }
    #wrapper.order-unregistered #main {
        padding-top: 100px;
        @include respond(largeUp) {
            padding-top: 65px; 
        }
        h1 {
            margin-top: 0;
        }
    }
}
