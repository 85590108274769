
///////////////////////
/// Styles for Checkout Header
///////////////////////
#wrapper.pt_order-confirmation,
#wrapper.pt_checkout{
	.top-banner{
		padding: 0 1.15em;
		h1.primary-logo{
			@include respond(small) {
				float: left;
			}
		}
		.innerWidth {
			@include respond(largeUp) {
				position: relative;
			}
		}
		.menu-utility-user{
			border: 0;
			margin: 0;
			width: 300px;
			@include respond(small) {
				float: left;
				position: relative;
				top: 0;
				padding-top: 0;
				width: 89%;
			}
			li{
				border: 0;
				max-height: 0;
				@include respond(small) {
					&:hover{
						background: none;
					}
				}
				.content-asset{
					max-height: 0;
				}
				&.information{
					padding-left: 20px;
					padding-top: 26px;
					@include respond(small) {
						position: absolute;
						top: 0;
						padding-top: 15px;
						width: 100%;
						text-align: left;
						padding-left: 40px;
						box-sizing: border-box;
					}
					span{
						font-size: 12px;
						font-weight: bold;
						display: block;
						line-height: 120%;
						@include respond(small) {
							font-size: 11px;
							float: left;
						}
						span{
							color: $gray-warm;
							font-weight: normal;
							float: none;
						}
					}
				}
				&#mini-cart{
					margin-top: 27px;
					@include respond(small) {
						position: relative;
						float: right;
						margin-top: 0;
						width: auto;
						right: 0;
					}
					a.mini-cart-link{
						background: #fff url("../images/cart-sprite.png") no-repeat scroll 14px 8px;
						color: $gray-dark;
						padding-right: 0;
						padding-left: 40px;
						@include respond(small) {
							height: auto;
						    padding-right: 0;
						    padding-left: 28px;
						    width: 50px;
						    background-position: 7px 2px;
						    line-height: 27px;
						    padding-bottom: 5px;
						}
					}
					.mini-cart-content{
						top: 55px;
						@include respond(small) {
							display: none!important;
						}
					}
				}
			}
		}
	}
	///////////////////////
	/// Styles for Checkout Footer
	///////////////////////
	footer{
		padding-bottom: 0;
		.footer-container{
			.innerWidth{
				@include respond(small) {
					text-align: center;
				}
			}
			#footerLogo{
				margin: 8px 0;
				@include respond(small) {
					margin: 20px auto 17px;
				}

			}
			.footer-bottom{

				.left-checkout-footer{
					float: left;
					margin: 20px 0 0 24px;
					@include respond(small) {
						margin: 4px 0 24px;
						width: 100%;
					}
					.content-asset{
						ul{
							padding: 0;
							margin: 0;
							@include respond(small) {
								float: left;
								width: 100%;
							}
							li{
								list-style: none;
								display: inline-block;
								padding: 0 0 0 35px;
								@include respond(large) {
									padding-left: 5px;
								}
								@include respond(small) {
									padding: 0;
									width: 38%;
									float: left;
									text-align: center;
									&:first-child{
										text-align: left;
										width: 31%;
									}
									&:last-child{
										text-align: right;
										width: 31%;
									}
								}
								a{
									font-size: 11px;
									color: $gray-warm;
									text-transform: uppercase;
									@include respond(small) {
										font-size: 10px;
									}
								}
							}
						}
					}
				}
				.banner-security{
					float: right;
					margin: 15px 0 0 0;
					width: 50%;
					text-align: center;
					@include respond(small) {
						margin-bottom: 15px;
						width: 100%;
					}
					& > span{
						font-weight: bold;
						text-transform: uppercase;
						color: $gray-dark;
						width: 33%;
						display: inline-block;
						vertical-align: middle;
						padding-left: 29px;
						line-height: 120%;
						text-align:left;
						float: left;
						box-sizing: border-box;
						background: url('../images/security-icons.gif') no-repeat left 2px;
						@include respond(small) {
							width: 33%;
							padding:30px 0 0;
							float: left;
							font-size: 11px;
							text-align: center;
						}
						@include respond(large) {
							padding-left: 28px;
						}
						&.secure{
							@include respond(small) {
								background: url('../images/secure-tr-icon.gif') no-repeat center top;
							}
						}
						&.returns{
							background-position: left center;
							line-height: 30px;
							@include respond(small) {
								background: url('../images/return-center-icon.gif') no-repeat center top;
								line-height: 120%;
								padding-left: 10px;
								padding-right: 10px;
							}
						}
						&.phone{
							background-position: left -55px;
							span{
								color: $gray-warm;
								font-size: 12px;
								text-transform: none;
								font-weight: normal;
							}
							@include respond(small) {
								line-height: 120%;
								background-position: center -57px;
								span{
									padding: 0;
									width: 100%;
									float: none;
									font-weight: normal;
									color: $gray-warm;
								}
							}
						}
					}
				}
			}
		}
	}
	.footer-copy{
		@include respond(small) {
			line-height: 15px;
			min-height: 0;
		}
		.innerWidth{
			@include respond(small) {
				padding: 12px 0;
			}
			.content-asset{
				text-align: center;
				a{
					display: none;
				}
				.copyright{
					float: none;
				}
			}
		}
	}
}
#wrapper.pt_order-confirmation {
	.top-banner{
		h1.primary-logo{
			@include respond(small) {
				float: none;
			}
		}
	}
}
#wrapper.pt_order-confirmation{
	.top-banner{
		div.primary-logo{
			@include respond(small) {
				float: none;
			}
		}
	}
}
#wrapper.pt_checkout{
	.top-banner{
		div.primary-logo{
			@include respond(small) {
				float: left;
			}
		}
		#mini-cart {
            @include respond(small) {
                .mini-cart-link {
                    padding-bottom: 5px;
                }
			}
		}
	}
}