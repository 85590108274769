
// Help Menu

#wrapper.pt_checkout .top-banner {
	.menu-utility-user {
		background-color: transparent;
		float: right;
		position: absolute;
			right: 0;

		li {
			float: left;
			padding-top: 0;
		}

		li#mini-cart {
			a.mini-cart-link {
				padding-left: 0;
				padding-top: 5px;
			}
			.minicart-quantity {
				margin-left: 0;
				margin-top:-5px;
			}
		}
	}
}

.menu-help {
	font-size: 14px;
	letter-spacing: 1px;
	list-style: none;
	margin: 0;
	padding: 0;
	float: right;
	overflow: hidden;
	line-height: 26px;
	text-transform: uppercase;
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	height: 26px;
	.help-menu-trigger {
		border-left: 1px solid #ececec;
		padding: 0;
		position: relative;
		@include breakpoint( $small-breakpoint ) {
			padding:10px 0;
			margin-right:17px;
		}

		&::after {
			content: "X";
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;
			opacity: 0;
			color: #9b9b9b;
			font-weight: 700;
			line-height: 26px;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&.active {

		.help-menu-trigger {
			span {
				opacity: 0;
			}
			&::after {
				opacity: 1;
			}
		}

		.help-links {
			display: block;
		}

	}

	.help-links {
		display: none;
		a,
		h3 {
			display: inline-block;
			font-family: $din-condensed;
			font-size: 14px;
			font-weight: 400;
			margin: 0;
			padding: 0 10px;
			vertical-align: top;
		}
	}

	.help-panel {
		float: left;
	}

	.content-asset {
		a, h3 {
			color: $black;
			text-transform: uppercase;
			span {
				padding:5px 15px;
			}
		}
	}
}

.menu-utility-user {
	background-color: $blue-medium;
	min-height: 48px;
	margin: 0;
    padding: 0;

	@include respond(largeUp) {
        width: 155px;
    }

	li {
		list-style: none;

		&#liveChat{
			@include respond(large){
				display: block;
			}
			@include respond(small){
				display: none;
			}
		}

		&.user-info {
			display: block;
			height: 100%;
			position: relative;
			@include respond(small){
				position:inherit;
			}
				&.is-active .user-panel {
					opacity: 1;
					display: block;
					@include respond(small){
						left: 0px;
						height: 82%;
						position: fixed;
						top: 74px;
						overflow-y: auto;
					}
				}

				& > a {
					background-image: url('../images/icons/icon-account.svg');
					background-position: 50%;
					background-repeat: no-repeat;
					background-size: 23px 27px;
					color: #FFF;
					display: block;
					font-size: 18px;
					height: 100%;
					line-height: 20px;
					min-height: 48px;
					padding: 0;
					text-transform: uppercase;
					width: 48px;

                    @include respond(largeUp){
						background-position: 20px 40%;
						padding: 24px 10px 16px 47px;
						width: 100%;
						letter-spacing:1px;
					}

					&.loggedin {
						&::after {
							background-color: #7ED321;
							border-radius: 50%;
							content: "";
							display: block;
							height: 12px;
							position: absolute;
							top: 15px;
							left: 31px;
							width: 12px;
							@include respond(small){
								position:relative;
							}
						}
					}

					span {
						display: none;

						@include respond(largeUp){
							display: block;
						}
                    }
				}
		}

		// User Info Dropdown
		.user-panel {
			background-color: #f8f8f8;
			border-top: 5px solid #4d738a;
			box-shadow: 0 2px 6px 0 rgba(0,0,0,.5);
			opacity: 0;
			position: absolute;
			top: 48px;
			right: -48px;
			transition: all .3s ease;
			width: 100vw;
			display:none;
			font-family: neuzeit-grotesk,sans-serif,serif;
			.is-active & {
				opacity: 1;
			}

			@include respond(largeUp) {
				max-width: 375px;
				position: absolute;
				top: 100%;
				right: 0;
				left: auto;
				transform: none;
				z-index: 20;
			}

			&__top {
				padding: 20px 25px;

				h3 {
					color: #000;
					font-family: neuzeit-grotesk,sans-serif,serif;
					font-weight: bold;
					font-size: 24px;
					letter-spacing: -0.12px;
					margin: 0;
					text-transform: capitalize;
				}
			}

			&__greeting {
				font-family: neuzeit-grotesk,sans-serif,serif;
				color: $grey-label;
				font-size: 14px;
				letter-spacing: -0.28px;
			}

			.user-links a {
				font-family: neuzeit-grotesk,sans-serif,serif;
				background-color: $gray-pale;
				border-top: 1px solid $gray-line;
				color: $black;
				font-weight: bold;
				display: block;
				font-size: 14px;
				letter-spacing: 0.35px;
				padding: 18px 20px 16px;
				position: relative;
				text-transform: uppercase;
				text-indent: 53px;
				background-repeat: no-repeat;
				background-position: 30px center;

				&:hover {
					background-color: $gray-cirlce;
				}

				&::after {
					content: "";
					position: absolute;
					width: 16px;
					height: 16px;
					top: 50%;
					right: 24px;
					border-top: 2px solid $blue-medium2;
					border-right: 2px solid $blue-medium2;
					transform: translateY(-50%) rotate(45deg);
					transform-origin: 50% 50%;
					transition: transform .5s;
				}

				&.user-insider {
					background-image: url('../images/icons/icon-insider.png');
				}

				&.user-preferences {
					background-image: url('../images/icons/icon-preferences.png');
				}

				&.user-orders {
					background-image: url('../images/icons/icon-orders.png');
				}

				&.user-profile {
					background-image: url('../images/icons/icon-my_info.png');
				}

				&.user-wishlist {
					background-image: url('../images/icons/icon-wishlist.png');
				}

				&.user-logout {
					text-indent: 10px;
					&::after {
						border: none;
					}
				}
			}

			.account-no-toggle-register a {
				background-color: #000;
				border-radius: 0;
				color: #FFF;
				font-family: neuzeit-grotesk,sans-serif,serif;
				font-size: 15px;
				font-weight: bold;
				width: 100%;
				height: 60px;
				line-height: 60px;
				text-align: center;
				display: block;
				margin-top: 15px;
				&:hover {
					text-decoration: none;
				}
			}

			// Non Logged-in User
			.account-toggle-wrapper {
				padding: 30px;

				+ .account-toggle-wrapper {
					border-top: 1px solid $gray-mediumer;
				}

				&.active {
					.account-toggle-content {
						display: block;
					}
				}

				input:not([type=checkbox]) {
					border: 1px solid $gray-border;
					border-radius: 0;
					@include font-small;
					font-size: 16px;
					letter-spacing: -0.32px;
					font-weight: 400;
					padding: 4px 18px;
					text-transform: none;

					+ input,
					+ span:empty + input,
					+ button {
						margin-top: 15px;
					}
				}

				label,
				.password-link {
					font-size: 14px;
					font-weight: normal;
					text-transform: capitalize;
					padding: 28px 0;
					background: none;
					letter-spacing: normal;

					a {
						text-decoration: underline;
						color: $black;
						font-weight: bold;
						padding-left: 9px;

						&.privacy-policy {
							font-weight: normal;
							padding-left: 0;
						}
					}

					span {
						text-transform: none;
						&.signup-lable-text {
							display: inline-block;
							width: calc(100% - 35px);
						}
					}
				}

				.password-link {
					font-size: 12px;
					padding: 13px 0;
				}

				button {
					background-color: #000;
					border-radius: 0;
					color: #FFF;
					font-family: neuzeit-grotesk,sans-serif,serif;
					font-size: 15px;
					font-weight: bold;
					width: 100%;
					height:60px;
				}

				input[type=checkbox]+label>.styled-checkbox {
					position: relative;
					display: inline-block;
					width: 18px;
					height: 18px;
					margin-right: 15px;
					float: left;
					background: $white;
					border: 1px solid $black;
					vertical-align: top;

					&::after,
					&::before {
						display: none;
					}
				}

				input[type=checkbox] {
					display: none;
				}

				input[type=checkbox]:checked+label>.styled-checkbox {
					background-color: $black;

					&::before,
					&::after {
						content: "";
						position: absolute;
						height: 3px;
						background: #fff;
						display: block;
					}

					&::before {
						width: 8px;
						bottom: 5px;
						transform: rotate(45deg);
					}

					&::after {
						width: 14px;
						right: -1px;
						bottom: 7px;
						transform: rotate(-45deg);
					}
				}
				&.active {
					.account-toggle-trigger {
						&::after {
							content: "-";
						}
					}
				}
				.account-toggle-trigger {
					color: #000;
					font-weight: bold;
					font-size: 24px;
					line-height: 36px;
					position: relative;
					display: block;
					text-transform: capitalize;

					&::after {
						font-size: 30px;
						content: "+";
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
					}
				}

				.account-toggle-content {
					display: none;
					padding-top: 30px;
					span.error, div.error, div.error-message {
						font-family: $font-small;
					}
				}
			}
		}

		div.content-asset {
			max-height: 28px;
			@include respond('small') {
				max-height: initial;
			}
		}
    }
}

@media only screen and (max-width: $standard-breakpoint) {
	.menu-utility-user {
		width: 60px;
		.user-info {
			a.user-account {
				span {
					display: none;
				}
			}
		}
	}
}


.user-panel.en_AU .user-orders,
.user-panel.en_NZ .user-orders,
.user-panel.en_AU .user-wishlist,
.user-panel.en_NZ .user-wishlist {
	display: none !important;
}

@media only screen and (max-width: $small-breakpoint) {
	.menu-utility-user {
		width:49px;
		li.user-info > a {
			background-position: 55% 40%;
		}
	}
	#mini-cart {
		width:49px;
		.mini-cart-total {
			width:49px;
		}
	}
}

.user-panel.en_AU .user-orders,
.user-panel.en_NZ .user-orders,
.user-panel.en_AU .user-wishlist,
.user-panel.en_NZ .user-wishlist {
	display: none !important;
}
