.mini-cart-buttons {
    .mini-cart-button {
        font-family: $neuzeit;
        font-size: 16px;
        &:hover {
            text-decoration: none;
        }
    }
    .button--secondary {
        border-radius: 4px;
        background-color: $darkgrey;
        border-color: $darkgrey;
    }
    .button--primary {
        border-radius: 4px;
        background-color: $white;
        border-color: $black;
    }
}

.mini-cart-attributes {
	.label {
		text-transform: none;
	}
}

.mini-cart-content {
    border-color: $black;
    font-family: $neuzeit;
}

.mini-cart-pricing.emb-options .mini-cart-price {
    margin-bottom: 15px;
}
