///////////////////////
/// Styles for Shopping Cart page
///////////////////////

#wrapper.pt_cart{
	#main{
		#primary{
			.error-form {
				padding-left: 0;
				padding-right: 0;
				margin: 0;
				box-sizing: border-box;
				font-family: $neuzeit;
				font-size: 16px;
				letter-spacing: .025rem;
				color: $red;
				@include respond(largeUp) {
					margin: .5em 0;
				}
			}
			.bonus-item-promo {
				.bonus-item-details {
					margin-top: 20px;
				}
				.bonus-products {
					a {
						height: 44px;
						font-size: 20px;
						line-height: 44px;
					}
				}
			}
			#cart-items-form {
				#cart-table {
					tr {
						td {
							&.item-image {
								img {
									width: 126px;
									margin-right: 0;
									@include respond(largeUp) {
										width: 142px;
										margin-right: 48px;
									}
								}
							}
							&.item-details {
								.product-list-item {
									font-size: 16px;
									font-weight: normal;
									letter-spacing: normal;
									.name {
										a {
											font-weight: 900;
										}
									}
									.sku {
										span {
											font-size: 14px;
										}
									}
									.gc-to,
									.gc-from,
									.gc-message {
										@include respond(small) {
											margin: 8px 0;
										}
									}
									.gc-to {
										&.gc-inline {
											@include respond(small) {
												margin-bottom: 0;
											}
											@include respond(largeUp) {
												display: inline-block;
												margin-right: 48px;
											}
											+ .gc-to-email {
												display: inline-block;
												@include respond(small) {
													margin: 0;
												}
												.label {
													display: none;
												}
											}
										}
									}
									.label,
									.value {
										font-size: 16px;
										font-weight: normal;
										text-transform: none;
										color: $gray-dark;
									}
									.capitalize {
										text-transform: capitalize;
									}

								}
								.item-edit-details {
									@include respond(largeUp) {
										padding: 20px 0 16px;
									}
								}
							}
							&.item-price {
								.price-promotion {
									display: inline-flex;
									> span:first-child {
										&:first-child {
											display: inline-block;
											width: auto;
										}
										display: inline-block;
										width: auto;
									}
									.price-sales {
										display: inline-block;
										padding: 9px 0 0 9px;
										width: auto;
										@include respond(small) {
											padding: 0 0 0 9px;
										}
									}
								}
							}
						}
					}
					.item-image {
						@include respond(largeUp) {
							width: 194px;
						}
					}
					.item-details {
						@include respond(largeUp) {
							width: calc(61% - 194px);
						}
					}
					.item-quantity {
						@include respond(largeUp) {
							width: 21%;
						}
					}
					.item-price,
					.item-total {
						@include respond(largeUp) {
							width: 11.5%;
						}
					}

				}
			}
			.name-container {
				.cart-actions-top {
					button {
						&.go-checkout {
							font-size:20px;
						}
						&.dw-apple-pay-button {
							border-radius:0!important;
						}
					}
					a {
						&.cart-action-paypal {
							&.button {
								span {
									font-size:20px;
								}
							}
						}
					}
				}
			}
			.cart-actions {
				&.cart-actions-top {
					.go-checkout {
						@include respond(large) {
							width: 220px;
						}
					}
					.cart-action-paypal {
						@include respond(large) {
							width: 220px;
						}
						img {
							@include respond(large) {
								height: 100%;
							}
						}
					}
				}
				.button {
					font-weight: 400;
				}
				a {
					&.cart-action-paypal {
						span {
							display: inline-block;
							font-size:20px;
							font-weight: 400;
							line-height: 40px;
							vertical-align: top;
						}
						img {
							display: inline-block;
							height: 100%;
							vertical-align: top;
						}
					}
				}
			}
		}
	}
}


