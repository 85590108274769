.refinement-header-wrapper {
    padding-top: 0;
    @include respond(small) {
        padding-top: 0;
        .breadcrumb {
            margin-bottom: 8px;
        }
    }
}

.refinement.color .color-selection,
.refinement.refinementColor .color-selection {
    .color-selection-value {
        width: 21px;
        height: 21px;
        margin-right: 3px;
        margin-bottom: 3px;
        .refinement-link {
            padding: 0;
            display: block;
            height: 17px;
            width: 17px;
        }
        &.swatch-lightblue a {
            background-color: #add8e6;
        }
    }
    .selected-indicator {
        display: none;
    }
}

.refinement ul.color-selection {
    width: 91%;
    &.scrollable {
        height: auto;
    }
}

.refinements .color .color-selection {
    .selected {
        width: 21px;
        height: 21px;
        margin-right: 3px;
        margin-bottom: 3px;
        .refinement-link {
            padding: 0;
            display: block;
            height: 17px;
            width: 17px;
        }
        &.swatch-lightblue a {
            background-color: #add8e6;
        }
    }
}

#cookiebanner {
    z-index: 100 !important;
}
