body {
	background: $body-bg-color; //$white;
    color: $primary-color; //$black;
    @include font-regular(12px);
	//font-family: $titillium;
	//font-size: 12px;
    letter-spacing: $globalLetterSpacing; //0.05em;
}

h1,h2,h3,h4,h5{
	font-family: $oswald;
	letter-spacing: 0.1em;
}

h1 {
	font-size: 1.5rem;
	font-weight: 400;
	margin: .625rem 0;
	@include respond(largeUp) {
		font-size: 1.75em;
		margin: 0 0 14px;
	}
}

h2 {
		font-size: 1.3em;
		text-transform: uppercase;
	}

h3 {
		font-size: 12px;
		color: #333;
}

a {
	color: $red;
	overflow: hidden;
	text-decoration: none;

	&:not(.UsableNetAssistive) {
		outline: none;
	}
}

fieldset {
	border: 0 none;
	margin: 0;
	padding: 0;
}

table {
	width: 100%;
}

th {
	text-align: left;
}

td {
	padding: 1em;
	vertical-align: top;
}

#main{
	margin-bottom: 1em;
}

//the global page-width wrapper
.innerWidth{
	margin: 0 auto;
	max-width: $standard-breakpoint;
	clear: both;

	@include respond(large) {
		max-width: $small-breakpoint;
	}
	@include respond(small) {
		max-width: $small-breakpoint;
	}

	&:after{
		content: '';
		clear: both;
		float: none;
		display: block;
	}
}
form{
	form-row.error{
		.error,
		.error-message{
			color: $error-red;
		}
	}
}
#wrapper{
	.error-form{
		border: 0;
		float: left;
		width: 100%;
	}
}
