.tooltip {
	cursor: help;
	position: relative;
	&.product-tile {
		cursor: pointer;
	}
}

.tooltip-content {
	display: none;
}

.ui-tooltip.ui-widget-content {
  	background: $black;
  	border-radius: 3px;
  	box-shadow: 2px 3px 3px 0 $gray-warm;
  	color: $white;
}

.ui-tooltip-content {
	padding: 0.3em;
	.recommendation-tooltip-header {
		font-size: 1.2em;
		font-weight: bold;
		padding: 0.5em;
		.product-price {
			font-weight: 400;
			.price-standard {
				color: $gray-warm;
				text-decoration: line-through;
			}
		}
	}
	.recommendation-tooltip-description,
	.recommendation-tooltip-attributes {
		padding: 0.8em;
	}
	.shipping-method-cost {
		padding-bottom: 0.3em;
	}
	.shippingtotal {
		margin-top: 0.3em;
		.value {
			border-top: 1px solid $gray-dark;
		}
	}
	.shipping-method-cost .value,
	.surcharge-product .value,
	.promo .value,
	.shippingtotal .value {
		margin-left: 3px;
		float: right;
	}
	.close-tooltip {
		display: none;
		@include respond(small){
			color: $white;
			display: block;
			float: right;
			height: 20px;
			line-height: inherit;
			width: 20px;
		}
	}
}
