.customer-service-mobile-drawer {
	@include respond(small){
		&.active {
			.content {
				display: block;
			}
		}
	}
}

#wrapper.pt_customer-service #primary {
	.form-horizontal,
	.contactus-form {
		label,
		.field-wrapper {
			float: none;
			display: block;
			padding-left: 0;
		}
		.required-indicator {
			float: none;
		}
		.form-caption,
		.form-row button {
			margin-left: 0;
		}
		label {
			padding-bottom: 5px;
			span:nth-child(2) {
				float: left;
			}
		}
	}
}
