@import "nav";
@import "menu_utility";
//@import "country_selector";


// Header Banner - Promo and Help Menu

.header-banner {
	align-items: center;
	background-color: $gray-pale;
	color: $gray-mediumer;
	display: flex;
	justify-content: space-between;
	line-height: 26px;
	height: 26px;
	overflow: hidden;
	padding-left: 20px;
	z-index: 52;
	position: fixed;
	width: 100vw;
	top:0;
	box-sizing:border-box;
    @include respond(largeUp) {
        justify-content: center;
        padding-left: 0;
        margin-left: -90px;
    }
	// Header Promo
	.header-slider {
		height: 100%;
		text-align: left;

		.header-promotion {
			font-size: 14px;
			letter-spacing: 1px;
			line-height: 26px;
			position: absolute;
			top: 100%;

			a {
				color: $gray-mediumer;
			}
		}
	}
}

.top-banner {
	border-bottom: 2px solid #e6e6e6;
	position: relative;

	.primary-logo {
		padding: 0;
		margin: 18px auto;
		z-index: 1;
		float: left;
	}
}

// Header Main - Logo, Search, My Account, Mini-cart

.header-main {
	box-shadow: 0 0 20px 0 rgba(0,0,0,.5);
	position: fixed;
	z-index: 51;
	width: 100%;
	background-color: white;
	top: 26px;

	@include respond(small){
		text-align: left;
		height:48px;
	}

	@include respond(largeUp){
		height: 60px;

	}

	&__inner {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: space-between;
		background-color: #fff;
		position:relative;
		box-shadow: 0 0 20px 0 rgba(0,0,0,.5);
		@include respond(small){
			height:48px;
			z-index:2;
		}
	}

	.home & {
		//hide the border on the homepage
		border: 0;
	}
	* {
		box-sizing: border-box;
	}
}


.logo-container {
	display: flex;

	@include respond(largeUp){
		padding-left: 20px;
	}
}

.primary-logo {

	a {
		@include respond(small){
			display: block;
			padding: 10px;
			background-image: none;
			background-repeat: no-repeat;
		}

		@include respond(large){ //tablet view
			padding: 0;
			background-image: none;
		}

		img {
			display: block;
			width:40px;
			height:auto;
			@include respond(small){
				width:28px;
				height:auto;
			}
		}
	}
}

// Search/Account/Cart Container

.search-account-cart-container {
	display: flex;
	height: 100%;
}

#mobileSearchShowBtn {
	background-color: $blue-dark;
	background-image: url('../images/icons/icon-search-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	color: $gray-dark;
	height: 48px;
	width: 48px;

	@include respond(largeUp) {
		display: none;
	}
}

.header-search {
	color: $gray-dark;
	background-color: $blue-dark;
	display: none;
	padding: 0;
	transition: width .5s;

	@include respond(largeUp) {
		display: block;
		width: 200px;
	}
	@include respond(suggestbreak) {
		position: relative;
	}

	fieldset {
		display: block;

		/*@include respond(largeUp) {
			display: block;
		}*/
	}

	input[type="text"] {
		background-color: $blue-dark;
		border: none;
		border-radius: 0;
		color: hsla(0,0%,100%,.5);
		font-family: $din-condensed; 
		letter-spacing: 1.64px;
		font-size: 18px;
		height: 100%;
		line-height: 1;
		opacity: 1;
		padding: 12px 13px 10px;

		@include respond(largeUp) {
			/*opacity: 1;*/
			padding: 22px 50px 12px 20px;
		}

		.open {
			opacity: 1;
		}
		&:focus {
			&::placeholder {
				color:transparent;
			}
		}

		&::-ms-clear {
		    display: none;
		}
		&::placeholder {
			color: hsla(0,0%,100%,.5);
			font-family: $font-small;
		}
	}

	// Search Button
	button[type="submit"] {
		background-image: url('../images/icons/icon-search-white.svg');
		background-color: $blue-dark;
		background-position: 50%;
		background-repeat: no-repeat;
		border: none;
		height: 20px;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 50%;
		right: 145px;
		z-index: 8;
		transform: translateY(-50%);
		width: 20px;
		transition: none;
		@include respond(small){
			display: none;
		}
		@include respond(suggestbreak) {
			right: 15px;
		}

		&:hover {
			background-color: transparent;
			color: initial;
			background-image: url(../images/icons/icon-search-orange.svg);
		}
	}

	span#closeSearch{
		display: none;
		width: 16px;
		height: 16px;
		position: relative;
		float: right;
		margin: -27px 0 0 0;
		cursor: pointer;

		@include respond(small){
			display: block;
		}
		&:before,&:after{
			content:'';
			position:absolute;
			width:18px;
			height:2px;
			background-color: #ccc;
			border-radius:2px;
			top: 7px;
		}

		&:before{
			-webkit-transform:rotate(45deg);
			-moz-transform:rotate(45deg);
			transform:rotate(45deg);
			left:-2px;
		}
		&:after{
			-webkit-transform:rotate(-45deg);
			-moz-transform:rotate(-45deg);
			transform:rotate(-45deg);
			right:0px;
		}
	}
}
.header-search[style*="display"] {
	@include respond(largeUp) {
		display: block !important;
	}
}


//--------------------------------------------------------------
// Sitewide Header and Nav Styles
//--------------------------------------------------------------


body.mobile-menu-active {
	overflow: hidden;
}

.header-subnav {
	position: relative;
	display: block;
	height: $nav-height-mobile;
	top: 0;
	right: 0;
	left: 0;
	background: $white;
	z-index: 50;
	transition: transform .5s;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ( 0 );

	@include breakpoint( $small-breakpoint ) {
		height: $nav-height-desktop;
		position: fixed;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	.header-pretext {
		position: relative;
		display: none;
		height: 26px;
		background: $gray-pale;
		font-family: $font-default;
		line-height: 26px;
		text-transform: initial;
		z-index: 10;

		@include breakpoint( $small-breakpoint ) {
			display: block;
		}

		.content-wrapper {
			max-width: none;
			height: 100%;
			padding: 0;
		}

		.help-menu {
			float: right;
			overflow: hidden;
			height: 100%;
			line-height: 26px;
			text-transform: uppercase;
			display: none;

			@include breakpoint( $small-breakpoint ) {
				display: block;
			}

			.help-menu-trigger {
				font-size:14px;
				position: relative;
				display: block;
				float: right;
				padding: 0 15px 0 15px;
				border-left: solid 1px $gray-light;
				letter-spacing: 1px;
				color: $gray-mediumer;

				span {
					transition: all 0.5s;
					transition-delay: 0.2s;
				}

				&::after {
					content: "X";
					position: absolute;
					display: block;
					top: 50%;
					left: 50%;
					opacity: 0;
					color: $gray-mediumer;
					font-weight: 700;
					line-height: 26px;
					transform: translateX( -50% ) translateY( -50% );
					transition: all 0.5s;
					transition-delay: 0s;
				}
			}

			.help-menu-content {
				float: left;

				a {
					font-size:14px;
					letter-spacing: 1px;
					color: $gray-mediumer;
					display: inline-block;
					padding: 0 10px;
					transform: translateY( 100% );
					transition: transform 0.5s;

					&:nth-child( 2 ) {
						transition-delay: 0.1s;
					}

					&:nth-child( 3 ) {
						transition-delay: 0.2s;
					}

					&:nth-child( 4 ) {
						transition-delay: 0.3s;
					}

					&:nth-child( 5 ) {
						transition-delay: 0.4s;
					}

					&:nth-child( 6 ) {
						transition-delay: 0.5s;
					}

					&:nth-child( 7 ) {
						transition-delay: 0.6s;
					}

					&:nth-child( 8 ) {
						transition-delay: 0.7s;
					}

					&:nth-child( 9 ) {
						transition-delay: 0.8s;
					}

					&:nth-child( 10 ) {
						transition-delay: 0.9s;
					}
				}
			}

			&.active {
				.help-menu-trigger {
					span {
						opacity: 0;
						transition-delay: 0s;
					}

					&::after {
						opacity: 1;
						transition-delay: 0.2s;
					}
				}

				.help-menu-content a {
					transform: translateY( 0 );
				}
			}
		}

		.content-marquee {
			position: relative;
			overflow: hidden;
			height: 100%;
			padding-left: 20px;

			.content-marquee-item {
				font-size:14px;

				position: absolute;
				top: 100%;
				color: $gray-mediumer;
				line-height: 26px;
				letter-spacing: 1px;
			}

			span {
				display: inline-block;
				padding-left: 20px;
			}
		}
	}

	.header-pretext-mobile {
		position: relative;
		display: block;
		height: 26px;
		background: $gray-pale;
		font-family: $font-default;
		line-height: 26px;
		text-transform: initial;
		z-index: 10;

		@include breakpoint( $small-breakpoint ) {
			display: none;
		}

		.content-wrapper-mobile {
			max-width: none;
			height: 100%;
			padding: 0;
		}

		.content-marquee-mobile {
			position: relative;
			overflow: hidden;
			height: 100%;
			padding-left: 20px;

			.content-marquee-item-mobile {
				font-size:14px;

				position: absolute;
				top: 100%;
				color: $gray-mediumer;
				line-height: 26px;
				letter-spacing: 1px;
			}

			span {
				display: inline-block;
				padding-left: 20px;
			}
		}
	}

	.header-mobile-close {
		opacity: 0;
		display: block;
		position: absolute;
		top: 0px;
		right: 0px;
		width: 48px;
		height: 48px;
		overflow: hidden;
		@include breakpoint( $small-breakpoint ) {
			top: 5px;
			right: 7px;
		}
	}

	.close-left{
		top: 14px;
		transform: rotate( 45deg );
		position: absolute;
		height: 2px;
		width: 28px;
		left: 14px;
		background: #ffffff;
		transition: all .3s;
		transform-origin: 0 50%;

		@include breakpoint( $small-breakpoint ) {
			top: 16px;
		}
	}

	.close-right{
		top: 34px;
		transform: rotate( -45deg );
		position: absolute;
		height: 2px;
		left: 14px;
		width: 28px;
		background: #ffffff;
		transition: all .3s;
		transform-origin: 0 50%;

		@include breakpoint( $small-breakpoint ) {
			top: 36px;
		}
	}

	.nav-mobile-img{
		opacity: 1;
		margin: 1px 0 -3px;
	}

	.header-main {
		position: relative;
		height: 48px;
		box-shadow: 0 4px 15px 0 rgba( $black, 0.15 );
		z-index: 8;
		background: $white;

		@include breakpoint( $small-breakpoint ) {
			height: 60px;
			box-shadow: 0 0 20px 0 rgba( $black, 0.5 );
		}

		.content-wrapper {
			@include clearfix;

			max-width: none;
			height: 100%;
			padding: 0;

			@include respond ( small ) {
				display: flex;
			}
		}

		.header-main-left {
			float: left;
			height: 100%;

			@include respond ( small ) {
				padding: 10px 0 0 20px;
				float: none;
			}

			.mobile-hamburger-toggle {
				position: relative;
				display: block;
				float: left;
				width: 48px;
				height: 48px;
				border-right: solid 1px $gray-light;
				overflow: hidden;

				@include respond ( small ) {
					display: none;
				}

				span {
					position: absolute;
					height: 2px;
					left: 10px;
					background: $black;
					transition: all 0.3s;
					transform-origin: 0 50%;

					&.top {
						width: 28px;
						top: 15px;
					}

					&.middle {
						width: 20px;
						top: 23px;
					}

					&.bottom {
						width: 28px;
						top: 31px;
					}
				}

				&.active {
					span {
						left: 14px;

						&.top {
							top: 14px;
							transform: rotate( 45deg );
						}

						&.middle {
							width: 0;
							top: 24px;
						}

						&.bottom {
							top: 34px;
							transform: rotate( -45deg );
						}
					}
				}
			}

			.nav-logo {
				display: block;
				float: left;
				overflow: hidden;
				height: 48px;
				padding: 9px 0 9px 10px;

				@include breakpoint( $small-breakpoint ) {
					height: 40px;
					padding: 0;
				}

				img {
					height: 100%;
				}
			}
		}

		.header-main-right {
			float: right;
			height: 100%;
			overflow: hidden;

			@include respond ( small ) {
				float: none;
				flex: 1;
			}

			.flex-width {
				display: flex;
			}

			.main-nav {
				display: none;

				@include respond ( small ) {
					display: block;
					flex-shrink: 0;
					flex-grow: 1;
					text-align: center;
				}
			}

			ul.nav-items {
				display: none;
				list-style-type: none;
				padding: 0;
				margin: 0;

				@include breakpoint( $small-breakpoint ) {
					display: inline-block;
				}

				li {
					display: block;
					list-style-type: none;
					float: left;

					& > a {
						display: block;
						padding: 5px 2vw 3px;
						line-height: 52px;

						@include breakpoint( 1270px ) {
							padding: 5px 2vw 3px;
						}

						img {
							position: relative;
							height: 20px;
							margin-bottom: 18px;
							vertical-align: bottom;
						}

						&.active,
						&:hover {
							padding-bottom: 0;
							border-bottom: solid 5px $black;
						}
					}
				}
			}

			.main-right-auxillary {
				flex-shrink: 0;
				flex-grow: 0;
				float: right;
				height: 60px;
				@include respond(small) {
					height:50px;
				}
			}

			.search {
				cursor: pointer;
				position: relative;
				overflow: hidden;
				float: left;
				width: 48px;
				height: 100%;
				background: $blue-dark;
				transition: width 0.5s;

				@include breakpoint( $small-breakpoint ) {
					width: auto !important;
				}

				input[type="text"] {
					font-size:18px;
					@include placeholder-color( $white );

					width: 100%;
					padding: 12px 75px 10px 13px;
					border: none;
					opacity: 0;
					background: transparent;
					color: $white;
					line-height: 26px;
					letter-spacing: 1.64px;

					@include breakpoint( $small-breakpoint ) {
						width: 170px;
						padding: 20px 50px 14px 20px;
						opacity: 1;
					}

					@include breakpoint( $standard-breakpoint ) {
						width: 200px;
					}
				}

				*[type="submit"] {
					position: absolute;
					width: 20px;
					height: 20px;
					top: 50%;
					right: 15px;
					padding: 0;
					margin: 0;
					border: none;
					text-transform: uppercase;
					transform: translateY( -50% );
					background: transparent url( "../img/icon-search-white.svg" ) center center no-repeat;

					&::after {
						display: none;
					}
				}

				.expand-search {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 20;

					@include breakpoint( $small-breakpoint ) {
						display: none;
					}
				}

				.close-search {
					position: absolute;
					overflow: hidden;
					opacity: 0;
					width: 22px;
					height: 22px;
					top: 50%;
					right: 45px;
					transform: translateY( -50% );
					transition: opacity 0.5s;
					z-index: 15;

					@include breakpoint( $small-breakpoint ) {
						display: none;
					}

					&::before,
					&::after {
						content: "";
						position: absolute;
						display: block;
						width: 120%;
						height: 2px;
						left: 1px;
						background: rgba( $white, 0.65 );
					}

					&::before {
						top: 1px;
						transform-origin: 0 0;
						transform: rotate( 45deg );
					}

					&::after {
						bottom: 1px;
						transform-origin: 0 100%;
						transform: rotate( -45deg );
					}
				}

				&.open {
					.expand-search {
						display: none;
					}

					.close-search {
						opacity: 1;
					}

					input[type="text"] {
						opacity: 1;
					}
				}
			}

			.account {
				font-size:18px;

				position: relative;
				display: block;
				float: left;
				height: 100%;
				padding: 9px 13px;
				color: $white;
				background: $blue-medium;
				line-height: 20px;
				text-align: center;
				text-decoration: none;
				transition: 0.5s ease;

				@include breakpoint( $small-breakpoint ) {
					padding: 14px 20px 16px;
				}

				@include breakpoint( $standard-breakpoint ) {
					width: 155px;
					padding: 14px 0 16px;
				}

				&::before {
					content: "";
					position: absolute;
					display: none;
					width: 12px;
					height: 12px;
					top: 26%;
					left: 53%;
					border-radius: 6px;
					background-color: $green;
				}

				&.logged-in:before {
					display: block;
				}

				.account-title {
					display: none;
					letter-spacing: 1px;

					@include breakpoint( $standard-breakpoint ) {
						display: inline;
					}
				}
			}

			.cart {
				font-size:18px;

				position: relative;
				display: block;
				float: left;
				height: 100%;
				padding: 14px;
				background: $blue-light;
				color: $white;
				line-height: 20px;
				text-align: center;
				text-decoration: none;
				vertical-align: middle;

				@include breakpoint( $small-breakpoint ) {
					padding: 20px;
				}

				@include breakpoint( $standard-breakpoint ) {
					width: 100px;
					padding: 20px 0;
				}

				img {
					vertical-align: middle;

					@include breakpoint( $standard-breakpoint ) {
						margin-right: 5px;
					}
				}

				.cart-count {
					font-size:14px;

					position: absolute;
					display: block;
					width: 20px;
					height: 20px;
					top: 8%;
					right: 9%;
					border-radius: 20px;
					background: $black;
					text-align: center;
					letter-spacing: 1px;
					line-height: 1.5;

					@include breakpoint( $small-breakpoint ) {
						top: 15%;
						right: 15%;
					}

					@include breakpoint( $standard-breakpoint ) {
						letter-spacing: 1px;
					}

					&::before,
					&::after {
						display: none;
						position: relative;
						top: -2px;
					}

					&::before {
						content: "[";
					}

					&::after {
						content: "]";
					}

					@include breakpoint( $standard-breakpoint ) {
						position: relative;
						display: inline;
						top: auto;
						right: auto;
						border-radius: 0;
						background: transparent;
						font-size: inherit;

						&::before,
						&::after {
							display: inline;
						}
					}
				}
			}

			@media screen and ( max-width: 1200px ) {
				.account,
				.cart {

					.nav-mobile-img {
						opacity: 1;
						transition: opacity .3s;
					}

					.header-mobile-close {
						opacity: 0;
					}

					&.active {
						.nav-mobile-img {
							opacity: 0;
						}

						.header-mobile-close {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	&.second-nav {
		height: 40px;
		position:relative;
		display:none;

		@include respond ( small ) {
			display:block;
			background:none;
			z-index:1;
		}
		&.nav-mobile-only {
			a::after {
				display:none;
			}
		}
		.mobile-menu {
			.block-promo {
				padding: 20px;
				float:left;
				width:100%;

				&::after {
					display:none;
				}

				.mobile-menu-promo-title {
					padding: 10px 0;
				}
				.mobile-menu-promo-text {
					font-size: 16px;
					font-family: $font-small;
					text-transform: none;
					color: $gray-strong;
					letter-spacing:normal;
				}
			}
		}
	}
	.mobile-secondary-nav  {
		position: relative;
		display: block;
		z-index: 5;
		transition: transform 0.5s;

		@include respond ( largeup ) {
			display: none;
		}

		ul {
			display: flex;
			justify-content: space-between;
			list-style: none;
			padding: 0 25px;
			margin: 0;
			&.nav-items {
				background:$white;
				transition: transform 1s;
				&.hidden-subnav {
					transform: translateY(-60px);
					transition: transform 1s;
				}
			}
		}

		li {
			padding: 0;
			margin: 0;

			a {
				font-size:16px;
				display: block;
				padding: 13px 0;
				color:black;
				text-transform: uppercase;
				&.active {
					background: $gray-pale;
					padding: 13px 25px;
				}
			}
		}
	}

	&.secondary-hidden {
		height: 48px;
		top: 154px;
		transform: translateY(-26px);

		.mobile-secondary-nav {
			transform: translateY( -42px );
		}

		.header-pretext-mobile {
			transform: translateY( -42px );
		}
	}

	&.secondary-hidden-mobile {
		height: 0;
		top: 112px;
		transform: translateY(-29px);
		z-index:-1;
		.header-pretext-mobile {
			transform: translateY( -42px );
		}
	}

	.header-dropdown {
		position: absolute;
		display: none;
		overflow-y: auto;
		width: 100%;
		top: 48px;
		right: 0;
		left: 0;
		box-shadow: 0 2px 6px 0 rgba( $black, 0.5 );
		background: $gray-pale;
		z-index: 10;
		max-height: 95vh;

		@include breakpoint( $small-breakpoint ) {
			position: absolute;
			top: 100%;
			bottom: auto;
			left: auto;
		}

		&.active {
			display: block;
		}

		.dropdown-greeting {
			margin-bottom: 5px;
		}

		&.search {
			border-top: solid 5px $blue-dark;

			@include breakpoint( 940px ) {
				max-width: 800px;
				right: 130px;
			}

			@include breakpoint( $standard-breakpoint ) {
				right: 255px;
			}

			.dropdown-greeting {
				color: $blue-dark;
			}
		}

		&.account {
			border-top: solid 5px $blue-medium;

			@include breakpoint( $small-breakpoint ) {
				max-width: 375px;
			}

			&.logged-in {
				@include breakpoint( $small-breakpoint ) {
					right: 67px;
				}

				@include breakpoint( $standard-breakpoint ) {
					right: 101px;
				}
			}

			&.logged-out {
				@include breakpoint( $small-breakpoint ) {
					right: 67px;
				}

				@include breakpoint( $standard-breakpoint ) {
					right: 10px;
				}
			}

			.dropdown-greeting {
				color: $blue-medium;
			}

			&.active {

				.fj-nav-link {
					font-size:18px;
				}
			}
		}

		&.cart {
			border-top: solid 5px $blue-light;

			@include breakpoint( $small-breakpoint ) {
				max-width: 375px;
			}

			.dropdown-greeting {
				color: $blue-light;
			}
		}

		.search-right {
			position: relative;
			padding: 20px;
			background: $gray-cirlce;
			color: $gray-mediumer;
			font-family: $font-small;
			text-transform: initial;

			@include breakpoint( $small-breakpoint ) {
				position: absolute;
				width: 25%;
				top: 0;
				right: 0;
				bottom: 0;
			}

			.search-for {
				font-size:11px;

				padding-bottom: 25px;
				letter-spacing: 0.3px;

				a {

					&:hover:after,
					&.active:after {
						display: none;
					}
				}
			}

			ul,
			li {
				font-size:11px;

				list-style: none;
				padding: 0;
				margin: 0;
				line-height: 30px;
			}

			a {
				font-size:11px;

				position: relative;
				color: inherit;
				letter-spacing: 0.3px;

				&::after {
					content: "";
					position: absolute;
					display: none;
					height: 1px;
					right: 0;
					bottom: -5px;
					left: 0;
					background: $black;
				}

				&:hover:after,
				&.active:after {
					display: block;
				}
			}

			span {
				color: $gray-dark;
			}
		}

		.search-left {
			padding: 25px;
			background: $gray-pale;

			@include breakpoint( $small-breakpoint ) {
				margin-right: 25%;
			}

			.dropdown-title {
				font-size:24px;

				letter-spacing: 1px;
			}

			.search-suggestions {
				overflow: hidden;

				.search-suggestion {
					display: flex;
					float: none;
					width: auto;
					padding: 25px 10px 0 0;

					@include breakpoint( $small-breakpoint ) {
						float: left;
						width: 50%;
					}
				}

				.search-image {
					margin-right: 20px;
				}

				.search-info {
					align-self: center;
				}

				.title {
					font-size:14px;

					padding-bottom: 5px;
					letter-spacing: 1px;
				}

				.subtext {
					font-size:11px;

					font-family: $font-small;
					color: $gray-medium;
					text-transform: initial;
					letter-spacing: 0.3px;
				}
			}
		}

		.dropdown-top {
			padding: 20px 25px;

			.dropdown-greeting {
				font-size:11px;

				font-family: $font-small;
				text-transform: initial;
				letter-spacing: 0.75px;
			}

			.dropdown-title {
				font-size:30px;

				letter-spacing: 2.5px;
			}
		}

		.account-toggle-wrapper {
			padding: 30px;

			& + .account-toggle-wrapper {
				border-top: solid 1px $gray-mediumer;
			}

			.account-toggle-trigger {
				font-size:24px;

				position: relative;
				display: block;

				&::after {
					font-size:30px;

					content: "+";
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY( -50% );
				}
			}

			.account-toggle-content {
				display: none;
				padding-top: 30px;
			}

			input:not( [type="checkbox"] ),
			button {
				font-size:18px;

				width: 100%;
				padding: 15px;
				border: solid 2px rgba( $blue-medium, 0.45 );
				text-transform: uppercase;

				& + input,
				& + button {
					margin-top: 15px;
				}
			}

			input:not([type="checkbox"]) {
				border: solid 2px rgba( $blue-medium, 0.45 );

				&:focus {
					border-color: $blue-dark;
				}
			}

			button {
				font-size:24px;

				background: $black;
			}

			input[type="checkbox"] {
				display: none;

				& + label {
					font-size:11px;

					display: block;
					padding: 20px 0;
					cursor: pointer;
					line-height: 18px;
					font-family: $font-small;
					text-transform: initial;

					& > .styled-checkbox {
						position: relative;
						display: inline-block;
						width: 18px;
						height: 18px;
						margin-right: 5px;
						background: $blue-medium;
						border: solid 1px $blue-dark;
						vertical-align: top;
					}
				}

				&:checked + label > .styled-checkbox {
					background: $blue-dark;

					&::before,
					&::after {
						content: "";
						position: absolute;
						height: 3px;
						background: $white;
					}

					&::before {
						width: 8px;
						bottom: 5px;
						transform: rotate( 45deg );
					}

					&::after {
						width: 14px;
						right: -1px;
						bottom: 7px;
						transform: rotate( -45deg );
					}
				}
			}

			.password-link {
				font-size:11px;

				padding: 20px 0 0 0;
				font-family: $font-small;
				text-transform: initial;

				a {
					text-decoration: underline;
				}
			}

			&.active {
				.account-toggle-trigger:after {
					content: "–";
				}

				.account-toggle-content {
					display: block;
				}
			}
		}

		.cart-items {
			.cart-item {
				overflow: hidden;
				padding: 10px 20px;
				border-bottom: solid 2px $gray-light;

				.cart-item-image {
					float: left;
					margin-right: 20px;
				}

				.cart-item-info {
				}

				.info-row {
					display: flex;
					justify-content: space-between;

					& + .info-row {
						padding-top: 5px;
					}
				}

				.cart-item-title {
					font-size:20px;
				}

				.cart-item-quantity {
					font-size:16px;

					padding-left: 15px;
					font-family: $font-small;
					text-transform: lowercase;
				}

				.cart-item-attributes {
					font-size:12px;

					color: $gray-medium;
					font-family: $font-small;
					line-height: 16px;
					text-transform: initial;
				}

				.cart-item-price {
					font-size:16px;

					align-self: flex-end;
					padding-left: 15px;
					font-family: $font-small;
				}
			}
		}

		.cart-summary {
			padding: 25px 20px;

			.summary-subtotal {
				display: flex;
				justify-content: space-between;
				padding-bottom: 25px;
				line-height: 28px;

				.subtotal-title {
					font-size:24px;
				}

				.subtotal-amount {
					font-size:16px;

					font-family: $font-small;
				}
			}

			.summary-buttons {
				overflow: hidden;
				margin: 0 -8px;
				text-align: center;

				div {
					float: left;
					width: 55%;
					padding: 0 8px;

					&:first-child {
						width: 45%;
					}
				}

				a {
					display: block;
					width: 100%;
					padding: 10px;
				}
			}
		}
	}

	.header-drawer-desktop {
		position: relative;
		display: none;
		overflow: hidden;
		height: 0;
		z-index: 1;
		box-shadow: 0 2px 6px 0 rgba( $black, 0.5 );
		background: $white;

		@include breakpoint( $small-breakpoint ) {
			display: block;
		}

		.desktop-menu {
			display: none;
			width: 100%;
			max-width: $max-menu-width;
			padding: 50px 20px;
			margin: 0 auto;
			overflow: hidden;

			&.active {
				display: block;
			}

			&.shoes {
				.link-group {
					max-width: 156px;
				}
			}
		}

		.desktop-menu-link-groups {
			overflow: hidden;

			.link-group {
				float: left;
				width: 50%;
				max-width: 185px;
				padding-right: 25px;
				margin-bottom: 35px;

				&:first-child {
					margin-bottom: 40px;
				}

				* {
					display: block;
					line-height: 25px;
				}

				.link-group-head {
					font-size:24px;

					padding-bottom: 15px;
				}

				.link-group-link {
					font-size:16px;
					letter-spacing: 1.2px;

					&:hover {
						text-decoration: underline;
					}
				}

				.link-group-divider {
					width: 80px;
					margin: 8px 0 15px 0;
					border-top: solid 2px rgba( $black, 0.3 );
				}

				.link-group-spacer {
					height: 25px;
				}
			}
		}

		.desktop-menu-promos {
			display: flex;
			float: right;
			overflow: hidden;
			max-width: 50%;
			margin-left: 50px;

			@include breakpoint( $standard-breakpoint ) {
				max-width: 75%
			}

			.desktop-menu-promo {
				display: block;
				float: left;
				max-width: 275px;

				&:hover > .desktop-menu-promo-title {
					text-decoration: underline;
				}
				&:hover > .desktop-menu-promo-text {
					text-decoration:none;
				}
				& + .desktop-menu-promo {
					margin-left: 25px;
				}

				img {
					width: 100%;
				}

				.desktop-menu-promo-title {
					font-size:20px;

					padding: 5px 0;
				}

				.desktop-menu-promo-text {
					font-size:16px;
					Line-height: 16px;
					Letter-spacing: .3px;
					Font-weight: 200;
					color: $gray-medium;
					font-family: $font-small;
					text-transform: initial;

				}
			}
		}

		.desktop-menu-1857 {
			overflow: hidden;
			text-align: center;

			a {
				font-size:20px;
				display: inline-block;
				padding-top: 30px;
				padding-bottom: 2px;
				text-align: center;
				border-bottom: solid 3px $black;

				img {
					width: 80px;
					display: block;
					padding-bottom: 50px;
					margin: 0 auto;
				}
			}
		}
	}

	.header-drawer-mobile {
		position: relative;
		display: none;
		overflow-y: auto;
		width: 100vw;
		top: 0;
		left: 0;
		background: $gray-lighter;
		overscroll-behavior: contain;
    	height: 85vh;
    	padding-bottom:20px;

		a {
			font-size:18px;
		}

		.special-1857 {
			font-size:30px;
			@include respond(small){
				&.desktop-only {
					display:none;
				}
			}
		}

		&.primary {
			padding-top: 74px;
			z-index: 6;
		}

		&.secondary {
			z-index: 4;

			@include breakpoint( $small-breakpoint ) {
				padding-top: 38px;
			}
		}

		&.active {
			display: block;

		}

		.mobile-menu {
			display: none;

			&.active {
				display: block;
			}
		}
	}
}

.header-spacer {
	height: $nav-height-mobile;

	@include breakpoint( $small-breakpoint ) {
		display: none;
	}
}

.desktop-menu-container{
	display: flex;
	flex-direction: row;
}

.desktop-menu-promos--margin{

	@include breakpoint( $small-breakpoint ) {
		margin-left: 455px !important;
	}

	@include breakpoint( $standard-breakpoint ) {
		margin-left: 440px !important;
	}
}

.overflow {
	overflow: hidden;
}

#needle_headerNT a {
	display:none!important; /* live chat appears and breaks "myaccount" button. */
}

.skip-nav {
    position: absolute;
    left: -10000px;
}

/*END Header Styles */



