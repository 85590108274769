.fj_account #wrapper.pt_wish-list {
	#main {
        font-family: $neuzeit;
        margin: 18px auto;
        width: 96%;
        @include respond(largeUp) {
            margin-top: 33px;
            width: 90%;
        }
        #primary {
            @include respond(small) {
                width: 97%;
                margin: 0 auto;
            }
            h2.mobile-visible {
                font-family: $neuzeit;
                font-size: 1.5rem;
                font-weight: 700;
                letter-spacing: normal;
                text-transform: none;
                margin-bottom: 6px;
            }
            .item-list {
                position: relative;
                tr {
                    font-family: $neuzeit;
                    .product-list-item {
                        font-size: 0.75rem;
                        letter-spacing: 0.3px;
                        color: $black;
                        .name {
                            font-size: 1rem;
                            letter-spacing: -0.08px;
                        }
                        .price span.value {
                            font-size: 0.75rem;
                            letter-spacing: 0.3px;
                            color: $black;
                            font-weight: normal;
                            margin-top: 28px;
                        }
                    }
                    td.item-details .product-list-item .price span.value {
                        font-size: rem(12px);
                    }

                    .item-dashboard,
                    .product-availability-list li.is-in-stock {
                        @include respond(largeUp) {
                            text-align: right;
                        }
                    }
                    .item-details {
                        display: flex;
                        .order-image {
                            width: 88px;
                            margin-right: 22px;
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .order-details-wrap {
                        display: flex;
                    }
                    .item-dashboard,
                    .item-details {
                        padding-bottom: 50px;
                        @include respond(small) {
                            width: auto;
                            display: block;
                            float: none;
                        }

                        .product-availability-list {
                            li.is-in-stock {
                                font-size: 0.75rem;
                                font-weight: 700;
                                color: $black;
                                margin-bottom: 10px;
                            }
                            .on-order,
                            .notavailable {
                                margin-bottom: 10px;
                            }
                        }

                        .option-date-added .field-wrapper .value {
                            font-size: 0.75rem;
                            letter-spacing: 0.3px;
                            color: $black;
                            margin-bottom: 20px;
                            span {
                                display: inline-block;
                            }
                        }
                        .option-add-to-cart button {
                            border-radius: 3px;
                            height: 44px;
                            line-height: 44px;
                            font-size: rem(14px);
                            @include respond(small) {
                                float: left;
                                margin-top: 0;
                            }
                        }
                        .option-add-to-cart label {
                            font-size: 0.75rem;
                            font-weight: 700;
                            color: $black;
                            float: none;
                            text-align: left;
                            text-transform: none;
                            margin-bottom: 4px;
                        }
                        .option-add-to-cart button,
                        .option-update button {
                            font-family: $neuzeit;
                            font-weight: 700;
                        }
                        .cart-actions {                            
                            clear: both;
                            @include respond(largeUp) {
                                float: right;
                            }
                        }

                        .option-update {
                            display: inline;
                            .delete-item {
                                font-size: rem(14px);
                            }
                        }

                        .option-add-to-cart {
                            padding-bottom: 15px;
                            .input-text {
                                height: rem(44px);
                                float: left;
                            }
                        }

                        .edit-link,
                        .option-update button {
                            color: $black;
                            display: inline-block;
                            font-weight: 700;
                            letter-spacing: 0.35px;
                            text-transform: uppercase;
                            text-decoration: underline;
                            height: auto;
                            border: none;
                            line-height: normal;

                            &:hover {
                                color: $black;
                            }
                        }
                        .edit-link {
                            position: relative;
                            top: 3px;
                        }

                        .pipe {
                            color: $black;
                            padding: 10px 5px 0 10px;
                        }

                    }
                }
            }
        }
        #primary,
        #secondary {
            @include respond(largeUp) {
                float: left;
            }
        }
        #primary {
            @include respond(largeUp) {
                padding-left: 0;
                width: 63%;
                margin: 0 5%;
                float: left;
            }
        }
        #secondary {
            @include respond(small) {
                display: none;
            }
        }
        .mobile-visible {
            @include respond(small) {
                display: block;
            }
        }
    }
}
