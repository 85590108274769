#wrapper.pt_order {
    #main {
        width: 100%;
        @include respond(small) {
            margin-top: rem(85px);
        }
        #primary {
            .order-header,
            .order-content,
            .order-content li {
                display: flex;
                position: relative;
            }
            .order-content li {
                &:hover {
                    .tooltiphover-content {
                        display: block;
                    }
                }
            }
            .order-header {
                @include respond(small) {
                    display: block;
                }
            }
            .order-tlt {
                flex: 1;
            }
            .order-tlt,
            .order-search {
                @include respond(small) {
                    margin: 0 10px;
                }
            }
            .guest-lookup {
                .order-tlt {
                    @include respond(small) {
                        margin: 0;
                    }
                }
            }
            .order-content-wrap {
                padding-bottom: 20px;
            }
            .order-cancel-error {
                font-size: 16px;
                font-weight: 700;
                color: $red;
            }
            .order-content {
                position: relative;
                align-self: center;
                clear: both;
                padding: 0;
                &.order-content {
                    &.cancel-tlt {
                        align-self: flex-end;
                        @include respond(small) {
                            margin: -20px 0 -15px;
                        }
                    }
                }
                .order-details-cancel {
                    position: absolute;
                    top: 0;
                    right: 0;
                    @include respond(small) {
                        top: -73px;
                    }
                    .button {
                        white-space: nowrap;
                        height: auto;
                        font-size: 14px;
                    }
                }
                li {
                    margin-left: 25px;
                    align-items: center;
                    &:first-child {
                        margin-left: 0;
                    }
                    img {
                        height: 18px;                            
                        margin-right: -26px;

                        &.icon-msg {
                            height: 22px;
                        }
                    }
                    a {
                        padding-left: 30px;
                        cursor: pointer;
                    }
                }
            }
            .cancel-asset {
                align-items: flex-end;
                @include respond(largeUp) {
                    margin-bottom: 20px;
                }
            }
            .orders-tooltip {
                display: none;
                background-color: #e8e8e8;
                color: $black;
                color: #000;
                position: absolute;
                width: 217px;
                top: 29px;
                left: -64px;
                padding: 0 20px 18px;
                font-weight: 400;
                z-index: 99;
                @include respond(small) {
                    left: 0;
                }
                .orders-tooltip-content {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        border-left: solid 10px transparent; 
                        border-right: solid 10px transparent; 
                        border-bottom: solid 10px #e8e8e8; 
                        height: 0; 
                        width: 0;
                        top: -26px;
                        left: 102px;
                        @include respond(small) {
                            left: 36px;
                        }
                    }
                    .content-asset p {
                        line-height: normal;
                    }
                }
            }
            h2 {
                font-family: $neuzeit;
                font-size: rem(24px);
                letter-spacing: normal;
                text-transform: none;
                line-height: normal;
                @include respond(small) {
                    margin-bottom: -3px;
                }
            }

            .no-hits,
            .alert {
                color: $red;
            }
            .no-hits {
                @include respond(small) {
                    margin-bottom: 30px;
                }
            }
            .order-search {
                @include respond(largeUp) {
                    width: 300px;
                    float: right;
                }
                .row {
                    margin: -13px 0 30px;
                }                    
                .legacy-box {                        
                    position: relative;
                    label {
                        padding-bottom: 4px;
                        letter-spacing: normal;
                        font-size: 14px;
                        font-weight: 400;
                    }
                    .order-search-btn {
                        position: absolute;
                        top: 30px;
                        right: -12px;
                        background: transparent;
                        border: none;
                        @include respond(largeUp) {                            
                            right: -19px;
                        }
                        img {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }

            .order-tracking-message {
                line-height: 1.5;
                letter-spacing: -0.32px;
            }
            .search-result-options {
                &:nth-of-type(2) {
                    border-top: solid 2px $gray-lighter;
                    margin-top: 30px;
                    .pagination .mobile-pagination {
                        right: -15px;
                        &:first-child {
                            left: -15px;
                        }
                    }
                }
                .pagination {
                    .mobile-pagination {
                        right: -15px;
                        &:first-child {
                            left: -15px;
                        }
                        .first-last a.page-previous {
                            margin-top: -20px;
                        }
                    }
                    .results-hits {                           
                        letter-spacing: -0.4px;
                        color: $black;
                        line-height: 58px;
                        @include respond(largeUp) {
                            line-height: 2.2;
                        }
                    }
                    li {
                        padding: 20px 10px;
                        &.current-page,
                        a {
                            color: $black;
                        }
                        &.current-page {
                            line-height: 17px;
                        }
                        &.first-last,
                        &.last-first {
                            @include respond(largeUp) {
                                padding: 20px 0 20px 5px;
                            }
                        }
                    }
                }
                @include respond(largeUp) {
                    margin-bottom: 8px;
                }
            } 

            .order-shipments {
                position: relative;
                @include respond(largeUp) {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    align-items: center;
                }
                padding: 10px 20px;
                background-color: $gray-pale;                    
                div {
                    @include respond(largeUp) {
                        flex: 1;
                        text-align: center;
                    }
                    &.details-btn {                       
                        button {
                            float: right;
                            border-width: 1px;
                            font-size: 14px;
                            line-height: 40px;
                            height: auto;
                        }
                        @include respond(small) {
                            position: absolute;
                            top: 15px;
                            right: 15px;
                        }
                        @include respond(extra-small) {
                            top: 37px;
                        }

                    }
                    &.border-right {
                        @include respond(largeUp) {
                            border-right: solid 1px #e8e8e8;
                        }
                    }
                    &.neg-left {
                        @include respond(largeUp) {
                            margin-left: -20px;
                        }
                    }
                }                   
            }

            .order-image {                
                position: relative;
                padding: 10px 10px 6px;
                @include respond(small) {
                    margin-right: 13px;
                    padding-bottom: 180px;
                }
                &.som-list-image {
                    @include respond(small) {
                        padding-bottom: 50px;
                    }                       
                }
                .lineitem-count {
                    position: absolute;
                    right: 7px;
                    top: 50px;
                }
            }

            .order-image,
            .order-products {
                @include respond(small) {
                    float: left;
                }
            }

            .order-products {
                padding: 20px;
            }

            .order-items {
                .order-wrap {
                    border-top: solid 1px #e8e8e8;
                    display: none;
                    &:nth-child(1) {
                        display: flex;
                    }
                    .myjoy-image {
                        max-width: 195px;
                        padding: 30px 0;
                    }
                }
            }

            .order-wrapper {
                border: solid 1px #e8e8e8;
                margin-bottom: 30px;
                .label {
                    font-weight: 700;
                }
                .order-items {
                    position: relative;
                } 
            }

            .order-products {
                @include respond(standard) {
                    min-width: 150px;
                }
                @include respond(extra-small) {
                    max-width: 201px;
                }
                padding-bottom: 15px;
                a {
                    color: $black;
                }
            }

            .order-total {
                margin-top: 10px;
            }

            .order-counter {
                font-size: 12px;
                padding: 0 10px 5px;
            }

            .mobile-visible{
                display: none;
                @include respond(small) {
                    display: block;
                }
            } 
            &.account-content-full {
                .order-detail-options {
                    border-top: none;
                }
                padding: 0 0 60px 0;
                .details-history {
                    border: none;
                    .order-shipment-table {                            
                        border: solid 1px #e8e8e8;
                        @include respond(largeUp) {
                            margin-top: 45px;
                        }
                    }
                    .order-totals-table tr td {
                        &:first-child {
                            text-align: left;
                        }
                    }
                }

            }
            .order-block-interface {
                display: flex;
                justify-content: space-between;
                margin: -10px 0 20px;

                .order-block-numbering {
                    @include respond(largeUp) {
                        padding-left: 2rem;
                    }
                }
                .order-block-buttons {
                    display: flex;
                    cursor: pointer;
                    margin-top: 10px;
                    @include respond(largeUp) {
                        padding-right: 2rem;
                    }
                    .order-block-right {
                        &:before {
                            content: '';                          
                            display: block;
                            top: 50%;
                            left: calc(50% - 4px);
                            width: 13px;
                            height:13px;
                            border-top: 3px solid #000;
                            border-right: 3px solid #000;
                            transform: translateX(-50%) translateY(-50%) rotate(45deg);
                            box-sizing: border-box;                     
                        }                   
                    }  
                    .order-block-left{
                        &:before {                            
                            content: '';                           
                            display: block;
                            top: 50%;
                            left: calc(50% - 4px);
                            width: 13px;
                            height:13px;
                            border-top: 3px solid #000;
                            border-right: 3px solid #000;
                            transform: translateX(-50%) translateY(-50%) rotate(225deg);
                            box-sizing: border-box;
                            margin-right: 1rem;                           
                        }                   
                    }              
                }
            }
        }
        .mobile-visible {
            @include respond(small) {
                display: block;
            }
        }
        #secondary {
            @include respond(small) {
                display: none;
            }   
        }
        .order-cancel {
            .order-confirmation-details {
                margin-top: 0;
            }
            .shiptrack-header {
                display: none;
            }
        } 
        .order-confirmation-details table.order-shipment-table tr td.line-item-details .item-image img {
            max-width: 195px;
            width: auto;
        }
    }
}
.order-subtlt,
.order-title {
    font-size: 14px;
    font-weight: 700;
    color: $black;
}
.subtlt-margin {
    margin-top: 20px;
}
.order-title {
    font-size: 20px;
}
.order-cancel {
    .order-shipment-table {
        &.orderlevel {
            td, th {
                &:nth-child(2),
                &:nth-child(3) {
                    display: none;
                }
            }
            .order-cancel-actions {
                @include respond(small) {
                    &.cancel-buttons {
                        margin-top: 0;
                    }
                }
            }
        }
        td.total-item-price {
            width: 17%;
        }
    }
    .order-details-box {
        @include respond(largeUp) {
            margin-top: 20px;
        }
        @include respond(small) {
            display: none;
        }
    }
    .order-cancel-actions {
        display: flex;            
        width: 100%;            
        border: solid 1px #e8e8e8;
        padding: 0 20px;
        @include respond(largeUp) {
            width: 380px;
            float: right;
        }
        &.cancel-totals {
            background-color: #f7f7f7;
            h2 {
                flex: 1;
                font-size: 20px;
                &:last-child {
                    text-align: right;
                }
            }
            @include respond(small) {
                display: none;
            }
        }
        &.cancel-buttons {
            clear: both;                
            margin: 30px 0;
            padding: 0;
            border: none;
            a {
                text-align: center;
                background-color: #bcbcbc !important;
                padding: 0 15px;
                flex: 1;
                border: none !important;
                &:hover {
                    color: $white;
                }
                &.mob-override {
                    font-size: 16px !important;
                    line-height: 48px !important;
                }
            }
            button {
                margin-left: 25px;
                flex: 1;
            }
            @include respond(largeUp) {
                width: 420px;
            }
        }
        .contact-asset {
            padding: 30px 10px 0;
        }
    }
}
.mobile-full {
    @include respond(small) {
        width: 100%;
    }
}

.guest-orderdets {
    position: absolute;
    top: 0;
    left: 0;
    p.tracker {
        font-size: 14px;
    }
    @include respond(largeUp) {
        right: 0;
        text-align: right;
        top: 10px !important;
        white-space: nowrap;
        left: auto;
    }
}
.guest-helpasset {
    align-self: flex-end;
}
.order-content-guest {
    @include respond(small) {
        margin: 8px 0 20px 10px !important;
    }
    @include respond(largeUp) {
        .order-details-cancel {
            top: -60px !important;
        }
    }
}

.cancel-link {
    margin-top: 4px;
}
.order-items-shipp {
    .product-list-item {
        @include respond(small) {
            padding-left: 0;
        }  
    }    
}
.order-unregistered {
    .order-header {
        @include respond(largeUp) {
            margin-top: 50px;
        }
    }
}
.product-list-item {
    .promo {
        color: $red;
    }
}


.guest-lookup {
    @include respond(largeUp) {
        margin-top: 60px;
    }
    .order-header {
        .content-asset {
            @include respond(largeUp) {
                max-width: 795px;
            }
        }
    }
    .order-content {
        @include respond(small) {
            margin-bottom: 0;
        }
    }
    .order-block {
        p.tracker {
            @include respond(largeUp) {
                margin: -7px 0 17px;
            }
        }
        @include respond(small) {
            margin-bottom: 25px;
        }
    }
    .form-row {
        &.required {
            label {
                font-weight: 700;
                &::after {
                    content: " *";
                    color: $gray-dark;
                }
                .required-indicator {
                    display: none;
                }
            }
        }
    }
    .login-order-track {
        @include respond(largeUp) {
            max-width: 560px;
        }
    }
    .mobile-visible.contact-asset {
        display: none;
    }
}

#wrapper.pt_order #primary .order-confirmation-details table.order-shipment-table td:nth-child(3) {
    @include respond(large) {
        padding: 30px 20px !important;
    }
}
