.svg-account-dims {
	height: 24px;
	width: 23.3px;
}

.svg-arrow-down-dims {
	width: 134px;
	height: 83.4px;
}

.svg-arrow-left-dims {
	width: 83.4px;
	height: 134px;
}

.svg-arrow-right-dims {
	width: 83.4px;
	height: 134px;
}

.svg-arrow-up-dims {
	width: 134px;
	height: 83.4px;
}

.svg-bag-dims {
	width: 46px;
	height: 35.5px;
}

.svg-cafe-dims {
	width: 20px;
	height: 20px;
}

.svg-clickcollect-g-dims {
	width: 46.3px;
	height: 35.3px;
}

.svg-clickcollect-r-dims {
	width: 46.4px;
	height: 35.1px;
}

.svg-close-icon-dims {
	width: 12px;
	height: 11px;
}

.svg-concession-dims {
	width: 20px;
	height: 20px;
}

.svg-delivery-int-g-dims {
	width: 50.91px;
	height: 35.63px;
}

.svg-delivery-int-r-dims {
	width: 51.05px;
	height: 35.46px;
}

.svg-delivery-uk-g-dims {
	width: 46.47px;
	height: 35.26px;
}

.svg-delivery-uk-r-dims {
	width: 46.7px;
	height: 35.9px;
}

.svg-facebook-dims {
	width: 8.3px;
	height: 17.1px;
}

.svg-flag-uk-dims {
	width: 20px;
	height: 13px;
}

.svg-googleplus-dims {
	width: 18px;
	height: 17.1px;
}

.svg-grid-view-dims {
	width: 19px;
	height: 19px;
}

.svg-info-dims {
	width: 20px;
	height: 20px;
}

.svg-instagram-dims {
	width: 17px;
	height: 17.1px;
}

.svg-listview-dims {
	width: 19px;
	height: 19px;
}

.svg-locations-dims {
	width: 20.7px;
	height: 20.3px;
}

.svg-logo-dims {
	width: 113.4px;
	height: 37.8px;
}

.svg-logo-m-retina-dims {
	width: 214.5px;
	height: 50px;
}

.svg-logo-mobile-dims {
	width: 214.5px;
	height: 50px;
}

.svg-logo-retina-dims {
	width: 113.4px;
	height: 38.4px;
}

.svg-mail-dims {
	width: 16px;
	height: 11px;
}

.svg-mail-g-dims {
	width: 42.7px;
	height: 34.1px;
}

.svg-mail-r-dims {
	width: 42.7px;
	height: 34.1px;
}

.svg-menu-icon-dims {
	width: 20px;
	height: 20px;
}

.svg-minus-dims {
	width: 20px;
	height: 20px;
}

.svg-pinterest-dims {
	width: 14.4px;
	height: 17.6px;
}

.svg-plus-dims {
	width: 20px;
	height: 20px;
}

.svg-print-dims {
	width: 20px;
	height: 20px;
}

.svg-progress-dims {
	width: 21.3px;
	height: 22.2px;
}

.svg-restaurant-dims {
	width: 20px;
	height: 20px;
}

.svg-search-dims {
	width: 37.4px;
	height: 38.3px;
}

.svg-soc-dims {
	width: 20px;
	height: 20px;
}

.svg-twitter-dims {
	width: 20.9px;
	height: 17px;
}

.svg-window-plus-dims {
	width: 20px;
	height: 20px;
}

