#dialog-container {
    #PasswordResetForm {
        span.error {
            margin-left: 0;
        }
        .form-row.recaptchaError {
            margin-left: 12%;
            width: 55%;
            margin-top: 0;
        }
        .form-row.required {
            margin-bottom: 0;
        }
        button {
            float: none;
        }
    }
}

#PasswordResetForm {
	fieldset {
		position: relative;
	}
	button {
		float: right;
		top: 0px;
		left: 63%;
	}
}


h1 .account-logout {
	font-size: 0.6em;
	float: none;
}

.registration-selection {
	input, label {
		display: inline;
		float: none;
	}
}

.address-list {
	padding: 0;
	li {
		float: left;
		list-style: none outside none;
		margin: 0;
		min-height: 135px;
		padding: 1em 0;
		width: 30%;
		&.default {
			border-bottom: 1px solid $gray-white;
			float: none;
			width: auto;
		}
	}
	.mini-address-title {
		font-style: italic;
	}
}
.payment-list {
	overflow: hidden;
	padding: 0;
	li {
		float: left;
		list-style: none outside none;
		margin: 0;
		min-height: 110px;
		width: 33%;
	}
}


.order-history-header {
	background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top $gray-white;
	overflow: hidden;
	padding: 10px;
	.label {
		font-weight: 700;
	}
	button {
		float: right;
	}
	.order-date, .order-status, .order-number {
		float: left;
		margin: 2px 0;
		width: 60%;
	}
}

.order-history-items {
	margin: 20px 0;
}

.order-history-table {
	th, td {
		padding: 5px 10px;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		list-style: none outside none;
	}
	.order-shipped-to,
	.order-items,
	.order-total-price {
		width: 33%;
	}
	.toggle {
		cursor: pointer;
		display: block;
	}
}

.js .order-history-table .hidden {
	display: none;
}

#wrapper.pt_order{
	#main.page-content.innerWidth{
		@include respond(large) {
			padding: 0 1em;
			box-sizing: border-box;
		}
	}
}
#wrapper.pt_account{
	#NewPasswordForm{
		min-height: 740px;
		@include respond(large) {
			min-height: 0;
		}
		@include respond(small) {
			min-height: 0;
		}
		label{
			text-align: left;
			width: 190px!important;
			margin-top: 0;
			float: left;
			@include respond(small) {
				width: 100%!important;
				padding-left: 0;
				margin-bottom: 8px;
			}

		}
		.form-caption{
			margin-left: 190px;
			@include respond(small) {
				margin-left: 0;
			}
		}
		.field-wrapper{
			width: 344px;
			padding-left: 5px;
			float: left;
			@include respond(small) {
				width: 100%;
				padding-left: 0;
			}
		}
	}
}
#wrapper.pt_wish-list,
#wrapper.pt_account{
	/////////////////////
	//////Styles for Login page
	/////////////////////
	#main{
		@include respond(large) {
			padding: 0 1em;
			box-sizing: border-box;
		}
		@include respond(small) {
			padding: 0 1em;
			box-sizing: border-box;
		}
		@include respond(small) {
			#secondary{
				.secondary-navigation{
					.content-asset{
						h2{

							& + ul{
								display: none;
							}
						}
					}
				}
			}
		}
	}
	#primary{

		.performance-fitting {
			h3 {
				padding: 14px 0 0;
			}
		}
		h1{
			font-size: 30px;
			color: $gray-dark;
			padding: 42px 0 16px;
			border-bottom: 2px solid $clp-border;
			text-transform: uppercase;
			font-weight: bold;
			line-height: 120%;
			@include respond(small) {
				font-size: 20px;
				margin: 0;
				padding: 30px 0 17px;
			}
		}
		h2{
			font-size: 20px;
			color: $gray-dark;
			line-height: 120%;
			margin: 10px 0;
			@include respond(small) {
				font-size: 16px;
				margin: 13px 0 12px;
			}
		}
		p{
			font-size: 14px;
			color: $gray-warm;
			line-height: 20px;
			padding: 0 0 20px 0;
			margin: 0;
			width: 80%;
			@include respond(small) {
				font-size: 12px;
				width: 100%;
			}
			&.reg-info{
				padding: 10px 0 12px;
				@include respond(small) {
					padding: 13px 0 0;
				}
			}
		}
		h3{
			font-size: 16px;
			color: $gray-dark;
			line-height: 120%;
			text-transform: uppercase;
			padding: 14px 0 24px;
		}
		h4{
			font-size: 14px;
			color: $gray-dark;
			line-height: 120%;
			text-transform: uppercase;
			margin: 0;
		}
		a{
			font-size: 14px;
			color: $gray-dark;
			line-height: 120%;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
		.footwear {
			.product-listing-1x4 {
				a.name-link {
					text-decoration: none;
				}
			}
		}

		.open-close{
			line-height: 18px;
			margin-bottom: 0;
			a{
				font-size: 10px;
				color: $gray-dark;
				line-height: 18px;
				text-transform: uppercase;
			}
		}
		#NewPasswordForm{
			.form-row-button{
				margin-bottom: 20px;
			}
			.form-row{
				@include respond(large) {
					label{
						width: 50%;
					}
				}
			}
		}
		.form-row{
			label{
				float: left;
				width: 21.5%;
				font-size: 12px;
				text-transform: uppercase;
				line-height: 34px;
				margin-bottom: 0;
				@include respond(small) {
					width: 100%;
					line-height: 120%;
					margin-bottom: 5px;
				}
				.required-indicator{
					display: none;
				}
				&::after{
					content: " *";
					color: $gray-dark;
				}
			}
		}
		.content-asset{
			p{
				padding-bottom: 23px;
			}
		}
		.col-1{
			@include respond(small) {
				width: 100%;
				border-bottom: 2px solid $clp-border;
				padding-bottom: 10px;
			}
			.login-box-content{
				form{
					.form-row{
						margin: 6px 0 20px;
						@include respond(small) {
							margin: 0 0 20px 0;
						}
						.field-wrapper{
							width: 78%;
							float: left;
							margin-bottom: 0;
							@include respond(small) {
								width: 100%;
							}
							input[type="text"],
							input[type="tel"],
							input[type="password"],
							input[type="email"]{
								max-width: 340px;
								height: 34px;
								line-height: 30px;
								background: transparent;
							    outline: none;
							    -webkit-appearance: none;
							    -moz-appearance: none;
							}
							.error{
								display: block;
							}
						}
						&.label-inline{
							float: left;
							width: 78%;
							margin-left: 21.5%;
							margin-top: 9px;
							@include respond(small) {
								margin-left: 0;
								margin-bottom: 15px;
							}
							label{
								width: auto;
								text-transform: none;
								color: $gray-warm;
								font-size: 12px;
								line-height: 120%;
								padding-left: 23px;

								&::after{
									content: "";
								}
							}

						}
						&.form-row-button{
							button{
								margin-left: 21.5%;
								@include respond(small) {
									margin-left: 0;
								}
							}
						}
					}
					.reset-pass{
						box-sizing: border-box;
						width: 100%;
						padding-left: 21.5%;
						max-width: 461px;
						float: left;
						margin-top: -13px;
						a#password-reset{
							font-size: 12px;
							float: right;
						}
					}
				}
			}
		}
		.col-2{
			width: 43.4%;
			min-height: 775px;
			@include respond(large) {
				min-height: 0;
			}
			@include respond(small) {
				width: 100%;
				min-height: 0;
			}
			h2{
				@include respond(small) {
					margin: 10px 0;
				}
			}
			h3{
				@include respond(small) {
					font-size: 14px;
					padding: 0 0 23px 0;
				}
			}
			h4{
				@include respond(small) {
					font-size: 12px;
				}
			}
			.content-asset{
				p{
					@include respond(small) {
						padding-bottom: 16px;
					}
				}
			}
			.login-box-content{
				form{
					.form-row-button{
						margin: 28px 0;
						@include respond(small) {
							margin: 18px 0 36px;
						}
					}
				}
			}
		}
	}
	/////////////////////
	//////END Styles for Login page
	/////////////////////

	/////////////////////
	//////Styles for Register page
	/////////////////////
	#RegistrationForm{
		min-height: 740px;
		@include respond(large) {
			min-height: 0;
		}
		@include respond(small) {
			min-height: 0;
		}
		span.required{
			text-align: right;
			font-size: 11px;
			color: $gray-warm;
			display: block;
			width: 344px;
			padding-left: 142px;
			@include respond(small) {
				width: 100%;
				padding-left: 0;
			}
		}
		.form-row{
			margin: 6px 0 12px;
			@include respond(small) {
				margin: 2px 0 8px;
			}
			&.long-label {
			    .input-checkbox {
			        width: auto;
				}
			}
			label{
				text-align: left;
				width: 142px;
				margin-top: 0;
				&.long-label{
					span{
						width: 200px;
					}
				}
				@include respond(small) {
					width: 100%;
					padding-left: 0;
					margin-bottom: 8px;
				}

			}
			.field-wrapper{
				width: 344px;
				padding-left: 5px;
				@include respond(small) {
					width: 100%;
					padding-left: 0;
				}
			}
			.form-caption{
				margin-left: 144px;
				color: $gray-warm;
				@include respond(small) {
					margin-left: 0;
					line-height: 14px;
				}
			}
			&.label-inline{
				margin-top: 15px;
				@include respond(small) {
					 margin-bottom: 0;
   					margin-top: 10px;
				}
				label{
					margin-left: 144px;
					width: 344px;
					color: $gray-warm;
					font-size: 12px;
					text-transform: none;
					line-height: 20px;
					padding-left: 22px;
					@include respond(small) {
						margin-left: 0;
						width: 100%;
						box-sizing: border-box;
					}
					&::after{
						content: "";
					}

					a{
						color: $gray-dark;
						text-decoration: underline;
						text-transform: none;
						font-size: 12px;
						@include respond(small) {
							display: inline-block;
						}
						&:hover{
							text-decoration: none;
						}
					}
				}
				input[type="checkbox"] + label{
					background-position: left 5px;
				}
				input[type="checkbox"]:checked + label{
					background-position: left 5px;
				}
			}
			&.form-row-button{
				margin: 24px 0;
				@include respond(small) {
					margin: 5px 0 40px;
				}

				button{
					margin-left: 144px;
					@include respond(small) {
						margin-left: 0;
					}
				}
			}
		}
	}
}
/////////////////////
//////Styles for Left nav menu
/////////////////////
#wrapper{
	#main{
		#secondary{
			padding-top: 31px;
			@include respond(small) {
				padding: 0 1em;
				box-sizing: border-box;
			}

			nav{
				padding: 0;
				.secondary-navigation{
					@include respond(small) {
						position: relative;
						padding-bottom: 10px;
						border-bottom: 2px solid $clp-border;
					}
					.log-out{
						display: none;
						@include respond(small) {
							display: block;
							color: $gray-dark;
							text-decoration: none;
							position: absolute;
							right: 0;
							top: 5px;
							text-transform: none;
							span{
								text-decoration: underline;
								&:hover{
									text-decoration: none;
								}
							}
						}
					}
					span.toggle,
					h2{
						font-size: 20px;
						line-height: 51px;
						padding: 0;
						margin: 0;
						border-bottom: 2px solid $clp-border;
						@include respond(small) {
							color: $white;
							font-size: 12px;
							font-family: $titillium;
							background:  $black;
							width: 55%;
							line-height: 34px;
							border-radius: 17px;
							padding: 0 20px;
							border: 0;
							margin: 33px 0 10px;
							cursor: pointer;
							&:after {
								content: ">";
								float: right;
								font-size: 24px;
								font-weight: normal;
								line-height: 30px;
							}
							&.open:after {
								-ms-transform: rotate(90deg); /* IE 9 */
							    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
							    transform: rotate(90deg);
							    -webkit-transition-duration: 0.5s;
								-moz-transition-duration: 0.5s;
								-o-transition-duration: 0.5s;
								 transition-duration: 0.5s;
								 line-height: 36px;
						    }
						}
					}
					ul{
						padding: 0;
						margin: 0;
						li{
							border-bottom: 2px solid $clp-border;
							a{
								font-size: 14px;
								color: $gray-warm;
								text-transform: uppercase;
								padding: 4px 0;
								line-height: 157%;
								@include respond(small) {
									display: block;
								}

								&:hover{
									color: $gray-dark;
								}
							}
							@include respond(small) {
								display: block;
								&.hide{
									display: none;
								}
							}
							&.mobile{
								display: none;
								@include respond(small) {
									display: block;
									border-bottom: 0;
									&.account-nav-asset{
										padding-top: 10px;
									}
								}
							}
						}
					}
					.content-asset{
						.content-asset{
							@include respond(small) {
								display: none;
							}
						}
						span.toggle{
							font-family: $oswald;
							font-weight: bold;
							@include respond(small) {
								font-family: $titillium;
							}
						}
					}
				}
			}
			.account-nav-asset{
				padding-top: 35px;
				h3{
					text-transform: uppercase;
					font-size: 14px;
					padding: 0;
					margin: 0;
					font-family: $titillium;
				}
				p{
					font-size: 12px;
					color: $gray-warm;
					line-height: 18px;
					margin: 0;
					span{
						color: $gray-dark;
					}
				}
			}
			&>.account-nav-asset{
				@include respond(small) {
			    	display: none;
			    }
			}
		}
		#primary.account-content{
			box-sizing: border-box;
		    padding-left: 230px;
		    width: 100%;
			min-height: 50vh;
		    @include respond(small) {
		    	padding: 0 1em;
		    }
		}
	}
}
#wrapper.pt_wish-list,
#wrapper.pt_account,
#wrapper.pt_order{
	#primary{
		h1{
			font-size: 30px;
			color: $gray-dark;
			padding: 42px 0 10px;
			text-transform: uppercase;
			font-weight: bold;
			line-height: 120%;
			margin-bottom: 0;
			border-bottom: 0;
			@include respond(small) {
				font-size: 20px;
				margin: 0;
				padding: 18px 0 10px;
			}
			&.border-bottom{
				border-bottom: 2px solid $clp-border;
				margin-bottom: 13px;
    			padding-bottom: 16px;
    			@include respond(small) {
    				padding-top: 30px;
    			}
			}
		}
		span.log-out{
			display: block;
			width: 100%;
			font-size: 14px;
			color: $gray-warm;
			border-bottom: 2px solid $clp-border;
			padding-bottom: 15px;
			a{
				color: $gray-dark;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
			@include respond(small) {
				display: none;
			}
		}
	}
}
/////////////////////
//////Styles for Account Order page
/////////////////////
#wrapper.pt_order{
	#primary{
		.search-result-options{
			margin: 20px 0 24px;
			@include respond(small) {
				margin: 0;
				padding-bottom: 15px;
			}
			.pagination{
				float: left;
				font-size: 11px;
				width: 100%;
				position: relative;
				@include respond(small) {
					padding: 0;
					line-height: 28px;
					.results-hits{
						width: 100%;
						font-size: 12px;
					}
				}
				@include respond(small) {
					.mobile-pagination {
						float: left;
						position: absolute;
						width: auto;
						right: 0;
						&:first-child {
							right: auto;
							left: 0;
						}
					}
				}
				.desktop-pagination{
					li{
						line-height: 150%;
						vertical-align: top;
						height: 17px;
						a{
							height: 17px;
							line-height: 17px;
							margin-top: 0;
						}
					}
				}
			}
		}
		.search-result-items{
			left: 0;
			width: 100%;
			border: 0;
			@include respond(small) {
				margin: 0;
			}
			li{
				.order-history-header{
					background: $gray-white;
					padding: 10px 14px;

					.left-order-info{
						float: left;
						@include respond(large) {
							width: 70%;
						}
					}
					button{
						height: 34px;
						line-height: 30px;
						font-size: 11px;
						padding: 0 18px;
						margin-top: 12px;
						@include respond(small) {
							float: left;
							margin: 16px 0 3px 3px;
						}
					}
					div{
						margin: 0;
						@include respond(small) {
							width: 100%;
						}
						span{
							font-size: 12px;
							text-transform: uppercase;
							color: $gray-dark;
						}
						.label{
							font-weight: bold;
						}
						.value{
							color: $gray-warm;
						}
					}
				}
				.order-history-items{
					@include respond(small) {
						margin: 20px 0 10px;
					}
					.order-history-table{
						.mobile-visible{
							display: none;
							@include respond(small) {
								display: block;
								font-size: 12px;
								text-transform: uppercase;
								color: $gray-dark;
								font-weight: bold;
								margin-bottom: 5px;
							}
						}
						tr{
							th{
								font-size: 12px;
								text-transform: uppercase;
								padding: 5px 15px 3px;
								&.order-shipped-to{
									width: 40%;
								}
								@include respond(small) {
									display: none;
								}
							}
							td{
								padding: 0 15px;
								font-size: 12px;
								color: $gray-warm;
								text-transform: uppercase;
								line-height: 100%;
								@include respond(small) {
									float: left;
									width: 100%;
									box-sizing: border-box;
									padding-bottom: 27px;
								}
								&.total-order{
									@include respond(small) {
										text-align: left;

										span.name{
											display: block;
											margin-bottom: 7px;
										}
										span.value{
											font-size: 12px;
											color: $gray-warm;
											text-transform: uppercase;
										}
									}
								}
								&.order-total{
									@include respond(small) {
										display: none;
									}
								}
								&.order-items{
									ul{
										li{
											line-height: 120%;
    										padding-bottom: 6px;
											text-transform: none;
										}
									}
									a{
										font-size: 12px;
										color: $gray-dark;
										text-transform: none;
										text-decoration: underline;
										margin-top: 5px;
										&:hover{
											text-decoration: none;
										}

									}
								}
							}
							th:last-child,
							td:last-child{
								text-align: right;
								@include respond(small) {
									text-align: left;
									border-bottom: 1px solid $clp-border;
								}
							}
						}
					}
				}
			}
		}
		/////////////////////
		//////Styles for Account Order History Detail page
		/////////////////////
		h1{
			@include respond(small) {
				margin: 0!important;
			}
		}
		.order-information{
			padding: 20px 0 30px;
			@include respond(small) {
				padding: 0 0 10px 0!important;
			}
		}
		.order-confirmation-details{
			.order-hide{
				display: none;
			}
			table.item-list{
				margin-top: 10px;
			}
			table.order-shipment-table{
				@include respond(large) {
					th.header-divider,
					td.header-divider{
						width: 5px;
						padding: 0;
					}
					td.line-item-details{
						width: 30% !important;
						@include respond(large) {
							width: 50% !important;
							.item-image{
								float: left;
							}
						}
					}
					th:nth-child(3),
					td:nth-child(3){
						padding: 15px 20px !important;
					}
					td.order-shipment-details{
						padding-right: 10px !important;
					}
				}
			}
		}
		.order-detail-options{
			padding-bottom: 60px;
			@include respond(small) {
				padding-bottom: 20px;
			}
			@include respond(large) {
				margin-right: 10px;
			}
			a{
				font-size: 14px;
				color: $gray-dark;
				text-decoration: underline;
				margin: 0;
				@include respond(small) {
					font-size: 12px;
				}
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}
/////////////////////
//////Styles for Account Payment page
/////////////////////
#wrapper.pt_account{
	#main{
		@include respond(small) {
			#secondary,
			#primary{
				padding: 0;
			}
		}
	}
	#primary{
		a.button.address-create,
		a.button.add-card{
			height: 34px;
			line-height: 30px;
			color: $gray-dark;
			font-weight: bold;
			font-size: 11px;
			text-transform: uppercase;
			padding: 0 17px;
			text-decoration: none;
			&.section-header-note{
				margin-top: 20px;
				@include respond(small) {
					margin-top: 6px;
				}
			}
			&:hover{
				color: $white;
			}
		}
		ul.payment-list{
			margin: 20px 0;

			li{
				width: 26.4%;
				font-size: 14px;
				color: $gray-warm;
				line-height: 20px;
				margin: 10px 0;
				&:nth-child(4n){
					width: 20%;
					@include respond(small) {
						width: 100%;
					}
				}
				@include respond(small) {
					font-size: 12px;
					line-height: 18px;
				}
				@include respond(small) {
					width: 100%;
					border-bottom: 1px solid $clp-border;
					margin-bottom: 5px;
    				padding-bottom: 19px;

					&:last-child{
						border-bottom: 0;
					}
				}
				form{
					button{
						font-size: 12px;
						color: $gray-dark;
						text-decoration: underline;
						text-transform: none;
						height: auto;
						line-height: 150%;
						margin-top: 10px;
						&:hover{
							text-decoration: none;
						}
					}
				}
			}
		}
	}
	/////////////////////
	//////Styles for Landing page
	/////////////////////
	#primary{
		h1.mobile-hidden{
			@include respond(small) {
				display: none;
			}
		}
		.main-box{
			float: left;
			width: 64%;
			margin-right: 15%;
			margin-bottom: 100px;
			@include respond(small) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 0;
			}
			ul.account-options{
				li{
					padding: 26px 0 25px 50px;
					margin: 0;
					width: 100%;
					box-sizing: border-box;
					height: auto;
					@include respond(small) {
						padding: 19px 0 17px 50px;
					}
					&.my-Information{
						background: url("../images/my-Information-icon.png") no-repeat left center;
					}
					&.addresses{
						background: url("../images/addresses-icon.png") no-repeat left center;
					}
					&.performance-fit{
						background: url("../images/logo-icon.png") no-repeat left center;
					}
					&.payments{
						background: url("../images/payments-icon.png") no-repeat left center;
					}
					&.orders{
						background: url("../images/orders-icon.png") no-repeat left center;
					}
					&.wishlist{
						background: url("../images/wishlist-icon.png") no-repeat left center;
					}

					a{
						font-size: 14px;
						text-transform: uppercase;
						font-weight: bold;
						padding-left: 0;
						text-decoration: none;
						margin-bottom: 2px;
						@include respond(small) {
							font-size: 12px;
							line-height: 16px;
							margin-bottom: 4px;
						}
					}
					p{
						padding-bottom: 0;
						display: block;
						line-height: 20px;
						@include respond(small) {
							line-height: 16px;
						}
					}
				}
			}
		}
		.secondary-box{
			float: left;
			width: 21%;
			@include respond(small) {
				width: 100%;
				margin-bottom: 20px;
			}
			h3{
				font-size: 14px;
				padding: 12px 0 0;
			}

			p{
				font-size: 12px;
				line-height: 16px;
				width: 100%;
				padding-bottom: 16px;
			}

			ul{
				margin: 0;
				padding: 0 10px;
				color: $gray-dark;

				li{
					font-size: 9px;
					color: $gray-dark;
					list-style: square;
					line-height: 16px;
					padding-bottom: 16px;

					span{
						font-size: 12px;
						color: $gray-warm;
						line-height: 100%;
					}
				}
			}
		}
		.community-join{
			a{
				line-height: 30px;
				height: 34px;
				text-decoration: none;
				box-sizing: border-box;
				font-size: 12px;
				padding: 0 18px;

				&:hover{
					color: $white;
				}
			}
		}
	}
}
/////////////////////
//////Styles for Addresses page
/////////////////////
#wrapper.pt_account{
	#main{
		#secondary{
			& + #primary{
				.account-slot-banner{
					display: none;
				}
			}
		}
		#primary{
			h1{
				@include respond(small) {
					border-bottom: 2px solid $clp-border;
					padding: 30px 0 17px;
				}
			}
			.account-slot-banner{
				display: inline-block;
				width: 100%;
				padding: 30px 0 0;

				p{
					padding: 0;
					margin: 0;
					width: 100%;
				}
				img{
					max-width: 100%;
				}
			}
			ul.address-list{
				li{
					padding: 25px 0 40px;
					color: $gray-warm;
					width: 25%;
					@include respond(large) {
						width: 33.33%;
					}
					@include respond(small) {
						width: 100%;
						padding: 15px 0 28px;
						border-bottom: 1px solid $clp-border;
						&:last-child{
							border-bottom: 0;
						}
					}

					&.default{
						padding: 20px 0 40px;
						border-width: 2px;
						width: 100%;
						@include respond(small) {
							border-width: 1px;
							padding: 10px 0 30px;
						}
						a.address-edit{
							margin-left: 0;
						}
					}
					h3{
						font-size: 14px;
						font-family: $titillium;
						padding: 0 0 10px 0;
						margin: 0;
						@include respond(small) {
							font-size: 12px;
						}
					}
					div{
						font-size: 14px;
						line-height: 20px;
						font-style: normal;
						color: $gray-warm;
						@include respond(small) {
							line-height: 18px;
							font-size: 12px;
						}
						&.mini-address-title{
							color: $gray-dark;
							font-weight: bold;
						}
						&.mini-address-location{
							padding-bottom: 10px;
							@include respond(small) {
								padding-bottom: 10px;
							}
							address{
								font-style: normal!important;
							}
						}
					}
					a{
						font-size: 12px;
						color: $gray-dark;
						margin: 0 5px;
						@include respond(large) {
							margin: 0 2px;
						}
						&.address-make-default{
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
/////////////////////
//////Styles for Account UI dialog
/////////////////////
.ui-dialog{
	@include respond(small) {
		width: 310px!important;
		padding: 0 5px!important;
	}
	.ui-widget-header{
		.ui-button{
			position: relative;
			z-index: 100;
			.ui-button-icon-primary{
				display: block;
			    height: 24px;
			    margin-left: 0 !important;
			    width: 23px;
			}
		}
	}
	#dialog-container{
		@include respond(small) {
			padding: 10px!important;
		}
		h1{
			font-size: 20px;
			color: $gray-dark;
			text-transform: uppercase;
			font-weight: bold;
			padding: 10px 0 14px;
			border-bottom: 2px solid $clp-border;
			margin-bottom: 0;
		}
		p{
			font-size: 14px;
			color: $gray-warm;

			a{
				font-size: 14px;
				color: $gray-dark;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
				@include respond(small) {
					word-wrap: break-word;
				}
			}
		}
		.dialog-required{
			float: right;
			font-size: 11px;
			color: $gray-warm;
			padding: 5px 0;
			width: 100%;
			text-align: right;
			&::before{
				content: "*";
			}
		}
		#edit-address-form{
			.form-row-button{
				.delete-button{
					display: none;
				}
				input[type="text"],
				input[type="tel"],
				input[type="password"],
				input[type="email"]{
					height: 34px;
					line-height: 30px;
				}
			}
		}
		#edit-address-form,
		#CreditCardForm {
			float: left;
			width: 100%;
			.form-field-tooltip {
				margin-left: 0;
				width: 100%;
				@include respond(largeUp) {
					margin-left: 34%;
					width: 66%;
				}
			}
			.form-label-text,
			.form-row label{
				float: left;
				width: 32%;
				font-size: 12px;
				text-transform: uppercase;
				line-height: 34px;
				margin: 0;
				text-align: left;
				@include respond(small) {
					width: 100%;
					line-height: 120%;
					margin-bottom: 5px;
				}
				.required-indicator{
					display: none;
				}
			}
			.form-label-text{
				padding-top: 0;
			}
			.form-row.required label{
				&::after{
					content: " *";
					color: $gray-dark;
				}
			}
			.field-wrapper{
				width: 68%;
				margin-bottom: 4px;
				@include respond(small) {
					width: 100%;
				}
				select{
					height: 34px;
					line-height: 30px;
					background-color: #f7f7f7;
				}
			}
			.form-row{
				.form-caption{
					margin-left: 34%;
					font-size: 12px;
					color: $gray-warm;
					@include respond(small) {
						margin-left: 0;
					}
				}
				&.year,
				&.month{

					@include respond(small) {
						margin-left: 0;
						float: left;
						width: 48%;
						overflow: visible;
						label{
							display: none;
						}
					}
					.field-wrapper{
						padding-left: 0;
						width: 100%;
						position: relative;
						select{
							width: 158px;
							&.error {
								margin-bottom: 21px;
							}
							@include respond(small) {
								width: 100%;
							}
						}
						span.error {
							position: absolute;
							top: 33px;
							left: 0;
							width: 150%;
						}
					}
				}
				&.year{
					@include respond(small) {
						margin-left: 10px;
					}
				}
				&.form-row-button{
					margin-left: 34%;
					@include respond(small) {
						margin-left: 0;
						text-align: center;
					}
					.apply-button,
					#applyBtn{
						background: $white;
						color: $gray-dark;
						font-size: 14px;
						&:hover{
							background: $gray-dark;
							color: $white;
						}
					}
					button.cancel{
						font-size: 14px;
						line-height: 120%;
						text-transform: none;
						font-weight: none;
						text-decoration: underline;
						color: $gray-dark;
						background: none;
						padding: 0;
						border: 0;
						height: auto;
						font-weight: normal;
						&:hover{
							text-decoration: none;
						}
					}
				}
				&.firstname {
					clear: both;
				}
			}
		}
	}
}
#wrapper.pt_wish-list{
	#main{
		#secondary{
			@include respond(small) {
				padding: 0;
			}
		}
		#primary{
			@include respond(small) {
				padding: 0;
			}
			table.item-list{
				tr{
					&:last-child{
						border-bottom: 0;
					}
					td{
						&.item-image{
							width: 135px;
							padding-right: 20px;
							padding-left: 0;
							img{
								width: 135px;
							}
							@include respond(small) {
								float: left;
								width: 100px;
								img{
									width: 100px;
								}
							}
						}
						&.item-details{
							padding: 15px 0 25px 20px;
							@include respond(small) {
								float: right;
								width: 59%;
								padding: 10px 0 0 0;
							}
							.product-list-item{
								.name,
								.name a{
										font-style: normal;
										font-weight: bold;
										text-decoration: none;
								}
								.sku{
									padding-bottom: 5px;
									span{
										font-size: 11px;
										color: $gray-warm;
										line-height: 20px;
										font-weight: normal;
									}
								}
								.price{
									padding-bottom: 11px;
	    							padding-top: 8px;

									span.label{
										display: none;
									}
									span.value{
										font-size: 11px;
										line-height: 18px;
									}
								}
								.attribute{
									padding-bottom: 0;

									span{
										font-size: 11px;
										text-transform: uppercase;
										line-height: 18px;
										font-weight: bold;
										color: $gray-dark;

										&.value{
											color: $gray-warm;
											font-weight: normal;
										}
									}
								}
								&.myjoy-item{
									.attribute{
										padding-bottom: 10px;
										display: block;

										.label,
										.value{
											color: $gray-warm;
											font-weight: normal;

										}
										&.name{
											padding-bottom: 0;
											span.value{
												font-weight: bold;
												color: $gray-dark;
												padding-bottom: 0;
												display: block;
											}
										}
									}
									.price{
										padding-top: 0;
									}
								}
							}
							& > a,
							.option-update button{
								padding: 0;
								text-transform: none;
								margin-bottom: 5px;
								text-decoration: underline;
								color: $gray-dark;
								font-size: 12px;
								font-weight: normal;
								line-height: 18px;
								height: auto;
								margin: 4px 0;
								&:hover{
									text-decoration: none;
								}
							}
						}
						&.item-dashboard{
							padding-right: 0;
							text-align: right;
							padding-top: 15px;
							@include respond(small) {
								float: right;
								width: 59%;
								padding: 0;
								text-align: left;
							}
							.product-availability-list{
								display: block;
								font-size: 12px;
								color: $gray-dark;
								padding: 0;
								margin: 0;
								@include respond(small) {
									padding: 10px 0 0;
								}
								li{
									&.is-in-stock{
										color: $gray-dark;
									}
									&.notavailable{
										color: $red;
									}
								}
							}
							.option-date-added{
								.field-wrapper{
									.value{
										font-size: 12px;
										color: $gray-warm;
										text-transform: none;
										width: auto;
										line-height: 140%;
										float: none;
										margin-top: 0;
										&::after{
											content: "";
										}
										@include respond(small) {
											span{
												display: block;
												line-height: 120%;
											}
										}
									}
								}
							}
							.option-add-to-cart{
								float: right;
								@include respond(small) {
									width: 100%;
								}
								input{
									width: 70px;
									&.read-only {
										background-color: $white;
										border: 0;
									}
								}
								button{
									padding-top: 0;
									padding-bottom: 0;
									background: $black;
									color: $white;
									font-size: 11px;
									line-height: 30px;
									height: 34px;
									@include respond(small) {
										display: block;
										margin: 20px 0 30px;
									}
									&:hover{
										background: $white;
										color: $black;
									}
								}
							}
						}
					}
				}
			}
			.col-1{
				h2{
					@include respond(small) {
						margin: 2px 0 12px;
					}
				}
			}
			.col-2{
				.login-box.login-create-account{
					.login-box-content{
						@include respond(small) {
							margin-bottom: 35px;
						}
					}
					.createbenefits{
						ul{
							padding: 0;
							margin: 0;
							list-style: none;
							margin-left: 0!important;
							li{
								span{
									display: block;
									color: $gray-dark;
									text-transform: uppercase;
									@include respond(small) {
										padding-bottom: 0;
										line-height: 150%;
									}
								}
								p{
									padding-bottom: 21px;
									@include respond(small) {
										padding-bottom: 18px;
										line-height: 18px;
									}
								}
							}
						}
					}
					form{
						.form-row-button{
							margin: 4px 0;
							@include respond(small) {
								margin: 0;
							}
							button{
								padding: 0 27px;
								@include respond(small) {
									padding: 0 17px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.performance-fitting {
	.html-slot-container {
		font-size: 14px;
	}
	.results {
		ul {
			list-style: none;
			margin: 0;
			padding-left: 0px;
			li {
				font-weight: bold;
				text-transform: uppercase;
				span {
					text-transform: capitalize;
					font-weight: normal;
				}
			}
		}
	}
}

.performance-video {
	video {
		max-width: 100%;
		margin-top: 2em;
		border: 2px #000 solid;
	}
}
.my-general-info{
	.password {
		div.successful-password-change {
			 @include respond(small) {
			 	clear:both;
			 }
			span {
				font-size: .75rem;
			    margin-left: 10px;
			    position: relative;
    			top: -4px;
			    @include respond(small) {
				   font-size: .7rem;
				   margin-left: 0;
			    }
				&::before {
					content:url("../images/GreenCheck.png");
					position: relative;
				    top: 11px;
				    margin-right: 10px;

				}
			}
		}
	}
}
