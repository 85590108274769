#mini-cart {
	background-color: $blue-light;
	z-index: 20;
	width: 48px;

	@include respond(largeUp){
		width: 100px;
	}
	@include respond(small){
		.cart-close-shield {
			display:none;
			width:70px;
			height:70px;
			position:fixed;
			top:0;
			right:0;
			opacity:1;
			height:74px;
		}
	}
}
.header-mobile-close.js-header-mobile-close{
	display:none;
	position: absolute;
	top: 0;
	right: 0;
	width: 48px;
	height: 48px;
	overflow: hidden;
	@include respond(small){
		&.close-cart-button{
			display:block!important;
			background-color:$blue-light;
		}
	}
	.close-left {
		top: 14px;
		transform: rotate(45deg);
		position: absolute;
		height: 2px;
		width: 28px;
		left: 14px;
		background: #fff;
		transition: all .3s;
		transform-origin: 0 50%;
	}
	.close-right {
		top: 34px;
		transform: rotate(-45deg);
		position: absolute;
		height: 2px;
		left: 14px;
		width: 28px;
		background: #fff;
		transition: all .3s;
		transform-origin: 0 50%;
	}
}
#wrapper.pt_order-confirmation .top-banner .menu-utility-user li#mini-cart,
#wrapper.pt_checkout .top-banner .menu-utility-user li#mini-cart {
	.mini-cart-total {
		height: 20px;
	}
	a.mini-cart-link {
		padding-left:0;
	}
}

#wrapper.pt_checkout .header-mobile-close.js-header-mobile-close.close-cart-button {
	display:none!important;
}
.mini-cart-total {
	position: relative;
	color: $white;
    height: 100%;
	.mini-cart-link {
		background-image: url(../images/icons/icon-cart.svg);
		background-position: 50%;
		background-repeat: no-repeat;
        color: $white;
        display: block;
        height: 100%;
		text-decoration: none;
		position: fixed;
		height: 49px;
		top: 26px;
		width: 49px;
		@include respond(largeUp){
			background-position: 23% 54%;
			align-items: center;
			display: flex;
			justify-content: center;
			padding: 0;
			position:inherit;
			height:inherit;
			top:inherit;
			width:inherit;
		}
    }
    .cartLabel {
        @include visually-hidden;
    }
}
.pt_checkout {
	.minicart-quantity {
		@include respond(largeUp){
			background-color: transparent;
		}
	}
}
.minicart-quantity {
	background-color: $black;
	border-radius: 20px;
	display: block;
	font-size: 14px;
	height: 20px;
	letter-spacing: 1px;
	line-height: 1.5;
	margin: 1px 0 -3px;
	position: absolute;
	top: 8%;
	right: 9%;
	text-align: center;
    width: 20px;
	@include respond(large){
	    background-color: $black;
	}
	@include respond(standard){
		background-color: transparent;
		border-radius: 0;
		font-size: 18px;
		height: auto;
		margin-left: 32px;
		position: relative;
		top: auto;
		right: auto;
		text-align: left;
		width: auto;

		&::before {
			content: "[";
			display: inline;
			margin-right: 2px;
		}

		&::after {
			content: "]";
			display: inline;
			margin-left: 2px;
		}
	}
}

.mini-cart-content {
	background-color: $gray-pale;
	border-top: 5px solid $blue-light;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,.5);
	color: $black;
	display: none;
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 2;
    &.cart-show {
    	display:block!important;
    }
    @include respond(largeUp){
    	width: 375px;
	}
	/* turned off for new JS controls #mini-cart:hover & {
		@include respond(small) {
			display: none;
		}
		display: block;
	} */
}

.enable-slideout-cart #mini-cart {
	.mini-cart-content {
		display: block;
		position: fixed;
		height: 100%;
		transition: all .65s;
		z-index: 999;
		transform: translateX(102%);
		@include respond(small) {
			top: 74px !important;
			width: 100%;
		}
		@include respond(largeUp) {
			top: 86px;
		}
		.mini-cart-close {
			height: 25px;
			width: 25px;
			background: url("../images/icons/close-minicart.svg") no-repeat;
			cursor: pointer;
			position: absolute;
			top: 23px;
			right: 18px;
			@include respond(largeUp) {
				display: none;
			}
		}
		.mini-cart-products {
			max-height: calc(100% - 307px);
		}
		.mini-cart-pricing {
			@include respond(largeUp){
				width: 30%;
			}
		}
		.mini-cart-attributes {
			position: relative;
			bottom: auto;
			white-space: nowrap;
		}
		.mini-cart-product-details {
			min-width: 47%;
		}
		.mini-cart-totals {
			position: fixed;
			bottom: 0;
			width: 100%;
			padding: 20px 0;
			background-color: #f8f8f8;
			border-top: 1px solid #ECECEC;
			height: 232px;
			@include respond(largeUp) {
				width: 375px;
			}
			.label,
			.value {
				padding: 0 20px;
			}
			.mini-cart-buttons {
				padding-bottom: 20px;
			}
		}
		&.cart-show {
			transform: translateX(0%);
		}
	}
}

.pt_checkout {
	.mini-cart-content {
	    &.cart-show {
	    	display:none!important;
	    }
	}
}

@include respond(small){
	#mini-cart {
		.mini-cart-content {
			&.cart-show {
				display:block;
				top:48px;
				left: 0;
			}
		}
		.mini-cart-buttons {
			a {
				font-size:20px;
			}
		}
	}
}

.mini-cart-header {
	color: $black;
	font-family: $din-condensed;
	font-size: 30px;
	font-weight: 400;
	letter-spacing: 2.5px;
	padding: 20px 25px;
	text-transform: uppercase;
}

.mini-cart-products {
	max-height: 353px;
		@include respond(small){
			max-height: 48vh;
		}
	overflow: auto;
}

.mini-cart-product {
	border-bottom: 1px solid #ECECEC;
	display: flex;
	overflow: hidden;
	padding: 10px 20px;
	position: relative;

	&.collapsed {
		.mini-cart-name,
		.mini-cart-attributes,
		.mini-cart-pricing,
		.product-availability-list {
			width: 90%;
		}
		.mini-cart-image {
			display: none;
		}
	}
	.mini-cart-toggle {
		cursor: pointer;
		font-size: 1.5em;
		position: absolute;
		left: 0;
		top: 0;
	}
}

.mini-cart-image {
	flex-shrink: 0;
	margin-right: 20px;
	height: 80px;
	width: 80px;
}

.mini-cart-product-details {
	display: flex;
	flex-direction: column;
	position: relative;
}

.mini-cart-name {
	font-size: 20px;
	line-height: 1;

	.mini-cart-name-value {
		color: $black;
		float: left;
		font-size: 20px;
		text-transform: uppercase;

		&.mini-cart-name-value-myjoys {
			margin: 6px 0 0 0;
			font-size: 14px !important;
			line-height: 150% !important;
			margin-bottom: 8px;
			margin-top: 3px;
			text-transform: none;
			color: #999;
		}
	}
}

.mini-cart-cid-value-myjoys {
	margin-bottom: 3px;
}

.mini-cart-attributes {
	font-size: 12px;
	@include font-small;
	text-transform:none;
	.label {
		text-transform: uppercase;
	}
	.value {
		font-weight: normal;
		color: #999;
	}
}

.product-availability-list {
	display: none;
}

.mini-cart-pricing {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	width: 60%;
	color: #999;
	@include font-small;
	text-transform:none;
	@include respond(largeUp){
		width: 30%;
	}

	.label {
		text-transform: uppercase;
		color: $gray-strong;
	}
	.value {
		color: #000;
		margin-left: -4px;
	}
	.mini-cart-price {
		font-weight: bold;
		color: $gray-strong;
		position: absolute;
		bottom: 10px;
		right: 20px;
		font-weight:300;
	}
	.price-promotion {
		&.price-standard {
			text-decoration: line-through;
			bottom:40px;
		}
		&.mini-cart-price {
			&.price-sales {
				color: $red;
			}
		}
	}
}

.mini-cart-totals {
	padding: 25px 20px;
	text-align: center;

	.mini-cart-slot{
		.minicartslot{
			@include font-small;
			text-transform:none;
			p{
				a{
					 @include respond(large){
				    	margin-left: 0!important;
				    }
				}
			}
		}
	}
}

.mini-cart-subtotals {


	.label {
		float: left;
		font-size: 24px;
		text-transform: uppercase;
	}
	.value {
		float: right;
		font-size: 16px;
		@include font-small;
		text-transform:none;
		color:$gray-strong;
		padding-top:5px;
	}
	&.mini-cart-original-subtotal {
		.value {
			text-decoration: line-through;
		}
	}
	&.mini-cart-estimated-subtotal {
		clear: both;
		.value {
			color: $red;
		}
	}
}

.mini-cart-slot {
	background: none;
	border: none;
	clear: both;
    font-size: 0.9em;
    font-style: normal;
    margin: 2em 1em;
    min-height: 0;
    padding-top: 1px;
}

.mini-cart-link-checkout {
	clear: both;
	display: block !important;
	margin: 1em 0;
	text-align: center;
	color: black !important;
	font-size: .78rem !important;
}

.mini-cart-buttons {
	display: inline-flex;
	justify-content: space-between;

	.mini-cart-button {
		font-size: 24px;
		letter-spacing: 1.82px;
		padding: 10px 20px;
		text-transform: uppercase;

		&+ .mini-cart-button {
			margin-left: 16px;
		}
	}

	.button--primary {
		border: 1px solid #000;
		color: #000;
		display: block;

		&:hover {
			background-color: #000;
			color: #FFF;
		}
	}

	.button--secondary {
		background-color: $blue-light;
		border: 1px solid #68A1B3;
		color: #FFF;
		display: block;

		&:hover {
			background-color: #000;
			color: #FFF;
		}
	}
}

@media only screen and (max-width: $standard-breakpoint) {
	#mini-cart {
		width: 70px;
	}
	.mini-cart-total {
		.mini-cart-link {
			background-position: 50%;
		}
		.cartLabel {
			display: none;
		}
	}
	.minicart-quantity {
		border-radius: 20px;
		display: block;
		font-size: 14px;
		height: 20px;
		letter-spacing: 1px;
		line-height: 1.5;
		margin: 1px 0 -3px;
		position: absolute;
		top: 8%;
		right: 9%;
		text-align: center;
		width: 20px;
		&:before,
		&:after {
			content: '';
		}
	}
}
@media only screen and (max-width: $small-breakpoint) {
	#mini-cart {
		width:49px;
		.mini-cart-total {
			width:49px;
		}
	}
	.header {
		.header-main {
			.main-right-auxillary {
				.header-main-right {
					.search {
						height:49px;
					}
				}
			}
		}
	}
}