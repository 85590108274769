.fj_account {
    #wrapper.pt_order {
        #main {
            margin: 18px auto;
            width: 96%;
            @include respond(largeUp) {
                margin-top: 33px;
                width: 90%;
            }
            #primary {
                h2 {
                    font-family: $neuzeit;
                    font-size: rem(24px);
                    letter-spacing: normal;
                    text-transform: none;
                    line-height: normal;
                    @include respond(small) {
                        margin-bottom: -3px;
                    }
                }

                .order-tracking-message {
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: -0.32px;
                    @include respond(largeUp) {
                        padding-top: 12px;
                    }
                }
                .search-result-options {
                    &:nth-of-type(2) {
                        border-top: solid 2px $gray-lighter;
                        margin-top: 30px;
                        .pagination .mobile-pagination {
                            right: -15px;
                            &:first-child {
                                left: -15px;
                            }
                        }
                    }
                    .pagination {
                        .mobile-pagination {
                            right: -15px;
                            &:first-child {
                                left: -15px;
                            }
                            .first-last a.page-previous {
                                margin-top: -20px;
                            }
                        }
                        .results-hits {
                            font-size: 1.25rem;                            
                            letter-spacing: -0.4px;
                            color: $black;
                            line-height: 58px;
                            @include respond(largeUp) {
                                line-height: 2.2;
                            }
                        }
                        li {
                            padding: 20px 10px;
                            &.current-page,
                            a {
                                font-size: 16px;
                                color: $black;
                            }
                            &.current-page {
                                line-height: 17px;
                            }
                            &.first-last,
                            &.last-first {
                                @include respond(largeUp) {
                                    padding: 20px 0 20px 5px;
                                }
                            }
                        }
                    }
                    @include respond(largeUp) {
                        margin-bottom: 8px;
                    }
                } 
                table.order-history-item-header {
                    @include respond(small) {
                        margin-bottom: 0;
                    }
                    tr {
                        &.header-row {
                            background-color: $gray-lighter;
                        }
                    }

                    th {
                        vertical-align: top;
                    }

                    .orders-row td {
                        padding-top: 20px;
                    }
        
                    .order-number,
                    .order-date,
                    .order-status-th,
                    .order-shipped-to,
                    .order-items,
                    .order-total-price,
                    .order-shipping-info,
                    .order-total {
                        padding: 0 22px;
    
                        .label,
                        .value {
                            display: block;
                            font-size: 1rem;
                            line-height: 1;
                            letter-spacing: -0.32px;
                        }
                        .value {
                            font-weight: normal;
                            margin-top: 5px;
                        }
                        ul {
                            padding: 0;
                            li {
                                font-weight: normal;
                            }
                        }
                    }

                    .order-image {
                        margin-right: 22px;
                        position: relative;
                        @include respond(small) {
                            margin-right: 13px;
                            padding-bottom: 180px;
                        }
                        .lineitem-count {
                            position: absolute;
                            right: 7px;
                            top: 50px;
                        }
                    }

                    .order-image,
                    .order-image img {
                        width: 73px;
                        @include respond(largeUp) {
                            width: 90px;
                        }
                    }

                    .order-image,
                    .order-products {
                        @include respond(small) {
                            float: left;
                        }
                    }

                    .order-wrap {
                        @include respond(largeUp) {
                            display: flex;
                        }
                    }

                    .order-products {
                        @include respond(standard) {
                            min-width: 150px;
                        }
                        @include respond(extra-small) {
                            max-width: 201px;
                        }
                        padding-bottom: 15px;
                    }
    
                    .order-items,
                    .order-date,
                    .order-status-th,
                    .order-shipping-info,
                    .order-total {
                        font-size: 1rem;
                        line-height: 1.5;
                        letter-spacing: -0.32px;
                        font-weight: 700;
                    }

                    .order-number,
                    .order-date,
                    .order-status-th {
                        @include respond(small) {
                            padding: 10px 0 10px 10px;
                        }
                    }

                    td.order-items {
                        @include respond(largeUp) {
                            width: 35%;
                        }
                    }

                    .order-number {
                        @include respond(small) {
                            padding-left: 14px;
                        }
                    }

                    .order-status-th {
                        @include respond(small) {
                            padding-right: 14px;
                        }
                    }                  

                    td.order-total {
                        @include respond(small) {
                            float: left;
                            padding-left: 0
                        }
                        padding-top: 20px;
                    }

                    .order-items,
                    .order-shipping-info,
                    .order-total {
                        @include respond(small) {
                            display: block;
                        }
                    }

                    .order-shipping-info {
                        .lbl-shipping {
                            @include respond(small) {
                                margin-top: 55px;
                            }
                        }
                    }

                    .order-total {
                        .lbl-total {
                            @include respond(small) {
                                margin-top: 20px;
                                padding-bottom: 0;
                            }
                        }
                    }

                    .order-number,
                    .order-date,
                    .order-status-th,
                    .view-details {
                        @include respond(small) {
                            display: inline-block;
                        }
                    }
    
                    .view-details {
                        padding: 0 14px 14px;
                        @include respond(largeUp) {
                            padding: 5px 0;
                            text-align: right;
                        }  
                        @include respond(small) {
                            padding-left: 14px;
                            width: 100%;
                        }
                    }
                    .view-details,
                    .empty {                        
                        button {
                            font-family: $neuzeit;
                            font-size: 14px;
                            margin-right: 6px;
                            border-radius: 4px;
                            border-width: 1px;
                            height: auto;
                            padding: 6px 16px;
                            white-space: nowrap;
                            line-height: 30px;
                        }
                    }
                    .order-date,
                    .order-status-th,
                    .order-number {
                        @media only screen and (min-width: 560px) and (max-width: 880px) {
                            width: 25%;
                        }
                        @include respond(extra-small) {
                            width: 31.5%;
                        }
                        @include respond(largeUp) {
                            .label,
                            .order-status {
                                margin-top: 8px;
                            }
                        }                    
                    }

                    .order-status-th {
                        @include respond(extra-small) {
                            width: 20%;
                        }                       
                    }

                    @media only screen and (min-width: 560px) and (max-width: 1199px) {
                        .order-status .value {
                            padding-bottom: 6px;
                        }
                    }

                    .order-date,
                    .order-number {
                        .label {
                            white-space: nowrap;
                            @media only screen and (max-width: 360px) {
                                white-space: normal;
                            }
                        }
                    }

                    .order-date {
                        .value {
                            text-transform: capitalize;
                        }
                    }

                    td, th {
                        padding: 0;
                    }
                    .mobile-visible{
                        display: none;
                        @include respond(small) {
                            display: block;
                        }
                    }
                }
            }
            .mobile-visible {
                @include respond(small) {
                    display: block;
                }
            }
            #secondary {
                @include respond(small) {
                    display: none;
                }   
            }
        }
    }
}
