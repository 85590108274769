#wrapper.pt_account{
	#primary{
		.login-order-track{
			p{
				width: 100%;
				padding: 10px 0 25px;
			}
			.login-box-content{
				.form-row{
					margin: 10px 0 12px;
					@include respond(large) {
						margin-bottom: 0;
					}
					@include respond(small) {
						margin-bottom: 0;
					}
					label{
						width: 18%;
						@include respond(small) {
							width: 100%;
							line-height: 200%;
						}
						@include respond(large) {
							width: 100%;
							line-height: 200%;
						}
					}
					.field-wrapper{
						width: 340px;
						float: left;
						@include respond(large) {
							margin-bottom: 0;
						}
						@include respond(small) {
							margin-bottom: 0;
							width: 100%;
						}
					}
					
					&.form-row-button{
						margin-top: 10px;
						@include respond(small) {
							margin-bottom: 20px;
							margin-top: 20px;
						}
						button{
							@include respond(small) {
								margin-left: 0;
							}
							@include respond(large) {
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
}
#dialog-container{
	#PasswordResetForm{
		.form-row{
			float: left;
			width: 70%;
			@include respond(small) {
				width: 100%;
				margin-bottom: 10px;
			}
			label{
				float: left;
				font-size: 12px;
				text-transform: uppercase;
				line-height: 34px;
				margin-right: 15px;
				&::after{
					content: " *";
					color: $gray-dark;
				}
				.required-indicator{
					display: none;
				}
			}
			.field-wrapper{
				width: 78%;
				float: left;
				@include respond(small) {
					width: 100%;
				}
				input{
					width: 100%;
				}
			}
			.form-caption.error-message{
				width: 100%;
			}
		}
		button{
			background: $white;
			color: $gray-dark;
			font-size: 11px;
			height: 34px;
			line-height: 30px;
			padding: 0 18px;
			margin-right: 0!important;
			margin-top: 0.5rem;
			&:hover{
				background: $gray-dark;
				color: $white;
			}
			@include respond(small) {
				font-size: 10px;
				padding: 0 5px;
				width: 37%;
				float: left;
				margin-top: 0;
			}
		}
	}
}