#product-nav-container {
	overflow: hidden;
	position: absolute;
	right: 0;
	width: 10rem;
	display: none;
	div {
		float: left;
		text-align: center;
		width: 77px;
	}
	img {
		max-height: 100%;
		max-width: 80%;
	}
	span {
		display: block;
		height: 65px;
	}
	.divided span {
		border-right: 1px solid $gray-light;
		padding-right: 5px;
	}
}

#wrapper.pt_product-details {
	#main.full-width {
		padding: 0 15px;
		box-sizing: border-box;
	}
}

.pdp-main {
	@extend %clearfix;
	font-family: $titillium;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.hidden {
		display: none !important;
	}
	label {
		padding: 0;
		text-align: left;
		text-transform: uppercase;
		width: auto;
	}
	.product-col-1 {
		float: left;
		width: 60%;
		@include respond(largeUp) {
			width: 62%;
		}
		@include respond(large) {
			width: 55%;
		}
		@include respond(small) {
			width: 100%;
			margin-top: 15px;
		}
	}
	.product-primary-image {
		@include respond(small) {
			display: none;
		}
	}
	.product-primary-image,
	.product-primary-image-mobile {
		max-width: 80%;
		text-align: center;
		float: right;
		position: relative;
		@include respond(small) {
			width: 92%;
			float: none;
			margin: auto;
		}

		.prev-image-button,
		.next-image-button {
			height: 30px;
			width: 18px;
			position: absolute;
			top: 50%;
			cursor: pointer;

			@include respond(largeUp) {
				display: none;
			}
		}

		.prev-image-button {
			background-image: url("../images/lCarouselLite.svg");
			left: -8.5%;
		}

		.next-image-button {
			background-image: url("../images/rCarouselLite.svg");
			right: -8.5%;
		}

		.plus-image-link {
			@include respond(small) {
				display: block;
				width: 34px;
				height: 34px;
				background-image: url("../images/plus-image-link.png");
				position: absolute;
				top: 0;
				left: -8.5%;
				z-index: 1;
			}
			@include respond(large) {
				display: block;
				width: 34px;
				height: 34px;
				background-image: url("../images/plus-image-link.png");
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}
		}
		.zoomImg {
			@include respond(large) {
				display: none !important;
			}
		}
	}
	.product-primary-image-mobile {
		@include respond(small) {
			width: 100%;
			max-width: 100%;
		}
	}
	.product-info {
		clear: both;
		margin-top: -5px;
		float: left;
		width: 100%;
		@include respond(small) {
			margin-top: 25px;
		}
		ul {
			font-size: .8rem;
			list-style: square;
			padding: 0 5em;
			@include respond(large) {
				width: 100%;
			}
			@include respond(small) {
				padding: 0em;
			}
			@include respond(largeUp) {
				padding-bottom: 1em;
				padding-top: 1em;
			}
		}
	}
	.product-category {
		color: #999;
		font-size: 16px;
		font-weight: 600;
		font-family: "Oswald", sans-serif;
	}
}

.main-image {
	display: block;
}
.primary-image {
	max-width: 100%;
}

// MOBILE SLIDER
.product-primary-image-mobile {
	max-width: 100%;

	.pdp-mobile-slider {
		background: url('../images/slider-loader.gif') center center no-repeat;
		img {
			max-width: 100%;
		}
		a {
			display: inline-block;
		}
	}
	.slick-dots {
		padding-top: 15px;
	}
	.slick-dots li button {
		width: 12px;
		height: 12px;
		border-radius: 12px;
		border-width: 1px;
	}
	//smart zoom wrapper
	.mobile-zoom-wrapper {
		position: absolute !important;
		top: 0 !important;
		left: 0 !important;
	}

	//360 view button
	.pdp-mobile-slider-360view-btn {
		position: relative;
		z-index: 1;
		span {
			position: absolute;
			left: 0;
			&.start-360-view {
				display: block;
				width: 40px;
				height: 40px;
				background: url("../images/icons/360-icon.png") no-repeat center;
				background-size: contain;
				position: relative;
			}
		}
	}
	//zoom button
	.pdp-mobile-slider-zoom-btn {
		position: relative;
		height: 0;

		span {
			position: absolute;
			right: 0;
			bottom: 100%;
			padding: .2em .5em;
			font-size: 14px;
			text-transform: uppercase;
			color: $black;
			cursor: pointer;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
	.smart-zoom-close-btn-wrapper {
		position: absolute;
		top: 0;
		right: 0;
	}
	.smart-zoom-close-btn {
		display: none;
		position: relative;
		width: 24px;
		height: 24px;
		z-index: 1;
		cursor: pointer;
		&.active {
			display: block;
		}

		.line-1,
		.line-2 {
			display: block;
			width: 100%;
			height: 2px;
			position: absolute;
			top: 0;
			margin-top: 50%;
			border-radius: 3px;
			background-color: $black;
		}
		.line-1 {
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg);
		}
		.line-2 {
			-webkit-transform: rotate(-135deg);
			transform: rotate(-135deg);
		}
	}
	// preloads
	.mobile-slider-loader {
		display: block;
		padding: 10px 0;
		text-align: center;
		clear: both;
	}
	.hide-on-load {
		display: none;
	}
}


.product-thumbnails {
	width: 65px;
	float: left;
	position: relative;
	text-align: center;
	@include respond(small) {
		display: none;
	}
	ul {
		@extend %clearfix;
	}
	// thumbnail
	.thumb.slick-slide {
		border: 2px solid $gray-light;
		float: left;
		margin-bottom: 10px;
		margin-right: 0;
		a {
			float: left;
			display: block;
			height: 100%;
		}
		&.thumb-360 {
			position: relative;
			img {
				opacity: .3;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				background-color: $gray-light;
				pointer-events: none;
			}
			&:after {
				content: '';
				display: block;
				width: 80%;
				height: 80%;
				top: 10%;
				left: 10%;
				position: absolute;
				background: url("../images/icons/360-icon.png") no-repeat center;
				background-size: contain;
				pointer-events: none;
			}
		}
		&.selected {
			border-color: $gray-dark;
		}
	}
	.product-video.slick-slide {
		margin-bottom: 10px;
	}
	// thumbnail image
	img {
		width: 61px;
		height: 55px;
	}
	h2 {
		margin: 1rem 0 .3rem;
	}

	.carousel {
		margin-bottom: 15px;
		margin-top: 1px;
		height: 477px;
		overflow: hidden;
		@include respond(large) {
			height: 340px;
		}

	}

	.slick-prev,
	.slick-next {
		background-image: url("../images/lCarouselLite.svg");
		height: 30px;
		width: 17px;
		position: static;
	}

	.slick-prev {
		transform: rotate(90deg);
	}

	.slick-next {
		transform: rotate(270deg);
	}

}

.pdp-main {
	.product-col-2 {
		float: right;
		width: 39.0%;
		@include respond(largeUp) {
			width: 37.0%;
		}
		@include respond(large) {
			width: 44.0%;
		}
		@include respond(small) {
			width: 100%;
			padding-left: 0;
			float: none;
		}
		.pdpForm.bundle {
			.product-set-list {
				.product-set-item {
					.product-set-details {
						.product-set-bundle-variations {
							.product-variations {
								.must-select {
									&.swatches {
										li {
											display: none;
											&.selected {
												display: block;
											}
										}
									}
									& > .content {
										display: none !important;
									}
								}
							}
						}
					}
				}
			}
		}
		.product-variations {
			position: relative;
			float: left;
			width: 100%;
			.custom-select-field {
				position: relative;
				margin-top: 0;
				@include respond(small) {
					margin-bottom: 0;
				}
				div.size-chart-link {
					position: absolute;
					top: -1px;
					width: auto;
					right: 22%;
					@include respond(largeUp) {
						right: 7%;
					}
					@include respond(large) {
						right: 7%;
					}
					@include respond(small) {
						right: 0;
					}
				}
			}
			.custom-select-field {
				.container {
					.select-header {
						margin-top: 2px;
						margin-bottom: 5px;
					}
				}
			}
		}
		.price-standard {
			border-right: 1px solid $gray-light;
			padding-right: .5rem;
		}
		.promotion {
			padding: 0 0 1px 0;
			margin: 0;
			display: block;
			border: 0;
			overflow: visible;
			.promotion-title {
				display: none;
			}
			.promotion-callout {
				color: $red;
				font-size: 14px;
				font-family: $titillium;
				line-height: 20px;
				span {
					padding: 0;
					margin: 0 0 5px 0;
					display: block;
					width: 100%;
				}
				a {
					text-decoration: underline;
				}
			}
		}
	}
	.product-name {
		margin-top: 1rem;
		margin-bottom: .3rem;
		font-family: $oswald;
		font-weight: 700;
		letter-spacing: 1.8px;
		color: $gray-dark;
		font-size: 2rem;
		line-height: 112%;
		@include respond(small) {
			font-size: 1.25rem;
			line-height: 1.5rem;
			margin-bottom: 1rem;
			margin-top: 0;
		}
		@include respond(large) {
			margin-top: 0rem;
		}
	}
	.product-number {
		color: $gray-warm;
		font-size: 11px;
		margin-bottom: 3px;
		font-family: $titillium;
		letter-spacing: 1px;
		display: block;
	}
	.product-price {
		font-size: 20px;
		margin-bottom: 3px;
		font-family: $oswald;
		color: $gray-warm;
		font-weight: 400;
		letter-spacing: 2px;
		.price-sales {
			color: $gray-warm;
		}
		.price-standard {
			color: $gray-warm;
			font-weight: 100;
			text-decoration: line-through;
			& + .price-sales {
				color: $red;
			}
		}

		.price-tiered {
			color: $red;
			font-size: .6em;
			line-height: 1.5em;
		}
	}
	.promotion {
		padding-bottom: 1px;
	}
	.product-review {
		@extend %clearfix;
		margin-bottom: .5rem;
	}
	.product-main-attributes {
		.attribute {
			padding: .2em 0;
		}
		.label {
			padding: 0;
		}
	}
}

.rating {
	color: $green;
	.product-info & {
		font-size: 2em;
	}
}

.product-variations {
	ul {
		float: left;
		width: 100%;
		margin-bottom: 0rem;
	}
}

.product-variations,
.product-options {
	width: 100%;
	margin-left: .5em;
	.attribute {
		margin: .175rem 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		h3,
		.label {
			float: left;
			font-weight: bold;
			margin-bottom: .3rem;
			text-transform: uppercase;
			letter-spacing: .5px;
			margin-right: .35rem;
		}
		.value {
			width: 95%;
		}
		ul {
		}
		.selected-value {
			border: 0 none;
			color: #999;
			font-weight: normal;
			text-transform: uppercase;
			position: absolute;
			top: 3px;
			line-height: 120%;
			@include respond(small) {
				//bottom: 118px;
				position: absolute;
			}
			left: 50px;
			font-size: 11px;
			width: 80%;
		}
	}
	.swatches li {
		float: left;
		width: 16%;
		@include respond(small) {
			margin-right: .5rem;
			width: 72px;
			height: 72px;
			margin: 2px;
		}

		&.unselectable {
			a {
				color: $gray-light;
				opacity: .8;
			}
			&:hover {
				a {
					border-color: $gray-light;
				}
			}
		}
		a {
			//	border-radius: 1.25rem;
			//	border: 2px solid $black;
			display: block;
			line-height: 3.5;
			min-height: 2.625rem;
			min-width: 2.625rem;
			margin: 0 .5rem .5rem 0;
			padding: 1px;
			text-align: center;
			white-space: nowrap;
			@include respond(largeUp) {
				line-height: 2.7;
				min-height: 2rem;
				min-width: 2rem;
			}
			@include respond(large) {
				line-height: 2.7;
				min-height: 34px;
				min-width: 34px;
			}
			@include respond(small) {
				line-height: 2.7;
				width: auto;
				height: auto;
				min-height: 2rem;
				min-width: 2rem;
			}
		}
	}
	.color li {
		a {
			display: table-cell;
			vertical-align: middle;
			line-height: 0;
			padding: 0;
			//	color: $white;
			@include respond(small) {
				width: 75px;
				height: 50px;
				&.german,
				&.french {
					width: 100px;
					height: 67px;
				}
			}

			img {
				width: 100%;
				border-radius: 20px;
				margin: 0px;
				display: block;
				@include respond(small) {
					height: 70px;
					width: 70px;
					border-radius: 24px;
				}
			}
		}
	}
	select {
		background-color: $white;
		border: 1px solid $gray-light;
		border-radius: 0;
		height: 42px;
		width: 100%;
		margin-left: 0;
		@include respond(largeUp) {
			height: 2rem;
		}
	}
}

.custom-select-field {
	&.has-error {
		.must-select {
			margin-top: 16px;
			@include respond(small) {
				margin-top: 0px;
			}
		}
	}
	.must-select {
		position: relative;

		&.not-selected {
			margin-top: 16px;

			.required-error {
				display: block;
			}
		}
	}
	.required-error {
		display: none;
		color: $red;
		position: absolute;
		top: -20px;
	}
}

#pdpMain {
	.product-detail {
		#reviewssnippet {
			margin-bottom: 15px;
		}
		.product-variations {
			margin-left: 0;
		}
		.hide-variations {
			.custom-select-field {
				display: none;
			}
		}
		.custom-select-field {
			margin-bottom: 10px;
			@include respond(small) {
				margin-bottom: 0;
			}
			.select-size, .select-sizeW, .select-sizeJ, .select-sizeJG, .select-width, .select-length, .select-hand, .select-waist, .select-gloveHand {
				width: 100%;
				padding: 5px 7px;
				.header {
					margin-left: 6px;
					&:focus {
						color: #666;
					}
				}
			}
		}
		.pdpForm {
			float: left;
			width: 100%;
			.product-add-to-cart {
				padding: 0;

				.inventory-container {
					margin-bottom: 6px;
					@include respond(small) {
						margin-bottom: 0;
						margin-top: 4px;
						padding-top: 0;
					}
					.inventory {
						select {
							padding-left: 15px;
							width: 70px;
						}
					}
					.availability-web {
						margin-left: 20px;
						@include respond(small) {
							float: left;
						}
						label, div {
							font-size: 11px;
							letter-spacing: 0.01em;
						}
					}
				}
				.secondary-actions {
					a {
						margin-top: 21px;
						text-transform: uppercase;
						font-size: 10px;
					}
					@include respond(small) {
						text-align: center;
						padding-top: 14px;
						a, span {
							float: none;
							display: inline;
							margin-right: 5px;
							margin-top: 0;
						}
						span {
							line-height: 90%;
						}
						a {
							line-height: 18px;
						}
					}
				}
				@include respond(large) {
					#add-to-cart {
						padding-left: 20px;
						padding-right: 20px;
						width: 49%
					}
				}
			}
		}
	}
}

.product-set .product-add-to-cart {
	display: block;
}

@include respond (large) {
	.product-add-to-cart {
		padding: 0;
		.add-to-cart {
			padding: 0px 0px;
			width: 8.25rem;
			font-size: .75rem;
		}
	}
}

.pdp-main .product-add-to-cart {
	.secondary-actions {
		float: left;
		width: 100%;
		@include respond(small) {
			margin: auto;
			margin-left: 0;
		}
	}
	margin: 0;
	padding: 0em .5em;
	@include respond (large) {
		padding: 0;
	}
	overflow: hidden;
	.secondary {
		color: black;
		float: left;
		margin-right: .625rem;
		margin-top: 1rem;
	}
	.secondary-spacer {
		float: left;
		margin-top: 1rem;
		margin-right: .625rem;
		@include respond(small) {
			//display: none;
		}
	}
	@include respond(small) {
		padding: 0;
	}
	.inventory-container {
		width: 100%;
		float: left;
		margin: 10px 0;
		@include respond (small) {
			height: 3rem;
		}
		.quantity {
			label {
				margin-right: .5rem;
			}
		}
		.select-pdp {
			border-radius: 1rem;
			border: 2px solid #ccc;
			background-color: #f5f5f5;
			color: $gray-warm;
			padding: 0rem 1rem 0rem 1rem;
			background-image: url("../images/down-arrow.png");
			height: 2.25rem;
			background-repeat: no-repeat;
			background-position: 87% 11px;
			@include respond(small) {
				padding: 0rem 0rem 0rem 1rem;
			}
			&:focus {
				border-color: #999;
			}
		}
		.availability-web {
			@include respond(small) {
				float: right;
				margin-left: 0;
				width: 55%;
			}
		}
		.inventory {
			float: left;
			white-space: nowrap;
			margin-bottom: .3rem;
			label {
				display: inline;
				float: none;
				text-align: right;
			}
			input {
				font-size: 1.5em;
				height: 50px;
				width: 50px;
				margin-right: .4em;
				text-align: center;
				@include respond(largeUp) {
					height: 37px;
					width: 37px;
				}
			}
		}
		.low-inventory-msg {
			clear: both;
			margin-bottom: .5rem;
			color: $red;
		}
	}

	.dw-apple-pay-button {
		padding: 0px 30px!important;
		height: 42px!important;
		float: left!important;
		width: 49%!important;
		border-radius:2em!important;
		border: 2px solid #000 !important;
		margin: 0 !important;
		min-height:10px!important;
		min-width:49%!important;
		background-size:45% 45%!important;
		background-color:$black!important;
		background-image:-webkit-named-image(apple-pay-logo-white)!important;
		background-repeat:no-repeat!important;
		background-position:50% 50%!important;
		@include respond(small) {
			width: 100%!important;
			font-size: 14px!important;
			margin-top: 10px!important;
		}
	}
	.add-to-cart {
		background: $black;
		border: 2px solid $black;
		color: white;
		padding: 0px 30px;
		height: 42px;
		float: left;
		width: 49%;
		margin-right: 2%;
		font-size: 14px;
		@include respond(small) {
			width: 100%;
			font-size: 14px;
			margin-top: 25px;
		}
		@include respond(large) {
			width: 49%;
			margin-right: 2%;
			font-size: 12px;
		}
		&:hover, &:focus {
			color: $black;
			background-color: $white;
			border: 2px solid $black;
		}
	}
	.design-your-own {
		+ .dw-apple-pay-button {
			@include respond(largeUp) {
				margin-top: 3px !important;
			}
		}
	}
}

.pdp-main {
	.product-actions {
		@extend %clearfix;
		//padding: 1em .5em;
		.socialsharing {
			float: none;
			margin-right: 1rem;
			padding-top: 1em;
			@include respond(small) {
				display: none;
			}
		}
		a {
			margin-right: .1rem;
			&.share-icon {
				color: $gray-warm;
				&:hover {
					color: $red;
				}
				i {
					font-size: 3rem;
					@include respond(largeUp) {
						font-size: 2rem;
					}
				}
			}
		}
	}
	.recommendations {
		clear: left;
		padding: 2em 0;
		h2 {
			font-size: 2em;
			font-style: italic;
			font-weight: 400;
			margin-left: 14px;
		}
		#carousel-recommendations {
			margin: auto;
			position: relative;
			overflow: hidden;
			width: 750px;
		}
		ul {
			list-style-type: none;
			position: relative;
			width: 2000em;
		}
		li {
			float: left;
			width: 250px;
		}
		.product-tile {
			padding: 0 2em;
		}
		.product-name {
			font-size: 1em;
			margin: .2em 0;
			width: 100%;
		}
		.product-price {
			font-size: 1em;
		}
		.product-tile:hover .recommendation-tooltip {
			display: block !important;
		}
	}
}

.js .pdp-main .recommendations .product-tile .recommendation-tooltip {
	display: none !important;
}

.pdp-main {
	.recommendations .recommendation-tooltip {
		background: none repeat scroll 0 0 $white;
		border: 1px solid;
		left: 50%;
		position: absolute;
		text-align: left;
		top: 50%;
		width: 300px;
		z-index: 100;
	}
	.promotion {
		border-top: 1px solid #ccc;
		margin: 1em 0;
		overflow: hidden;
		padding: 1.5em 1.5em .7em 1.5em;
		.promotion-callout {
			color: $red-sale;
		}
	}
	.product-set-list {
		border-top: 1px solid $gray-light;
		.product-set-item {
			border-bottom: 2px solid $gray-light;
			clear: both;
			overflow: hidden;
			padding: 1em 0 0;
		}
		.product-set-image {
			float: left;
			width: 22%;
		}
		.product-set-details {
			float: left;
			width: 78%;
		}
		.attribute {
			h3, .label {
				width: 25%;
			}
		}
		.product-name {
			float: none;
			font-size: 1.3em;
			margin: 0;
			width: auto;
		}
		.block-add-to-cart {
			background: none repeat scroll 0 0 $gray-white;
			clear: both;
			overflow: hidden;
			padding: .5em 0;
			.inventory {
				float: left;
				margin-left: 22%;
				width: 20%;
			}
			label {
				padding: .7em 0;
				text-align: left;
				width: 40%;
			}
			.input-text {
				width: 35px;
			}
			.product-price {
				float: left;
				font-size: 1.6em;
				margin: 0;
				padding: 6px 0 0;
				text-align: right;
				width: 35%;
			}
			button {
				float: right;
				font-size: .9em;
				margin: 3px 5px 0 0;
				padding-left: 1em;
				padding-right: 1em;
			}
		}
	}
}

.product-add-to-cart {
	.inventory-container {
		.availability-web {
			label {
				@include respond(small) {
					margin-bottom: 0;
				}
			}
		}
	}
}

.availability-msg {
	@include respond(small) {
		width: auto;
	}
	.not-available-msg {
		color: $red;
	}
}

.js button.add-all-to-cart {
	display: inherit;
}

.top-border-pdp {
	width: 100%;
	height: 2px;
	float: left;
	margin-bottom: 1rem;
	margin-top: 0rem;
}

.swatches {
	.selected-value {
		float: left;
	}
	&.color {
		.selected-value {
			@include respond(small) {
				width: 70%;
				height: inherit;
			}
		}
	}
	&.color.not-selected {
		border: 2px $red solid;
		border-radius: 3em;
		clear: left;
		width: auto;
		padding: 0 1em;
	}
	.selectable .swatchanchor {
		border: 2px solid transparent;
		box-sizing: border-box;
	}
	.selected {
		float: left;
		width: 100%;
		.swatchanchor {
			border: 2px solid $black;
			border-radius: 35px;
			color: $white;
			box-sizing: border-box;
			margin: 0;
			img {
				width: 100%;
				@include respond(small) {
					height: 70px;
					width: 70px;
					border-radius: 35px;
				}
			}
		}
	}
	.selectable {
		float: left;
		width: 100%;
		&.selected {
			pointer-events: none;
		}
	}
}

.description-container {
	width: 100%;
	float: left;
	margin-bottom: 2rem;
	margin-top: 2rem;
	@include respond(large) {
		margin-top: 0;
	}
	@include respond(small) {
		margin-top: 0;
	}
	.pdp-description {
		width: 60%;
		@include respond(large) {
			width: 80%;
			margin-top: 0;
		}
		@include respond(small) {
			width: 100%;
		}

		margin: auto;
		span {
			font-size: 16px;
			color: $gray-warm;
			@include respond(small) {
				font-size: 14px;
			}
		}
		.description-body {
			h3 {
				text-align: center;
				width: 100%;
				float: left;
				font-size: 30px;
				text-transform: uppercase;
				font-family: $oswald;
				font-weight: 700;
				letter-spacing: 2.5px;
				margin-bottom: 25px;
				@include respond(small) {
					font-size: 20px;
					margin-bottom: 1rem;
				}

			}
			p {
				text-align: left;
				font-size: 1rem;
				font-family: $titillium;
				color: $gray-warm;
				letter-spacing: .5px;
				margin-bottom: 3rem;
			}
		}
	}
}

.variant-dropdown {
	width: 50%;
	float: left;
	@include respond(small) {
		width: 100%;
	}
	value {
		width: 94%;
	}
	.variation-select {
		border-radius: 1.25rem;
		border: 2px solid #ccc;
		background: #f5f5f5;
		color: #666;
		text-transform: uppercase;
		height: 2.25rem;
		background-image: url("../images/down-arrow.png");
		background-repeat: no-repeat;
		background-position: 94% 50%;
		display: none;
	}
}

.size-chart-link {
	a {
		color: $gray-dark;
		text-transform: uppercase;
		font-size: 10px;
		border-color: $white !important;
		padding: 0;
	}
}

// IE fix for product thumbs
.productthumbnail {
	width: 55px;
}

.selection-error {
	display: none;
	font-size: 1.2em;
	clear: left;
	@include respond(small) {
		text-align: center;
	}
}

#product-set-list {
	.product-set-bundle-variations {
		.select-length,
		.select-width,
		.select-hand,
		.select-gloveHand,
		.select-size,
		.select-sizeW,
		.select-sizeJ,
		.select-sizeJG {
			border-color: #ccc;
			.header {
				.header-toggle {
					color: #999;
				}
			}
			&.not-selected {
				border-color: $red;
				.header .header-toggle {
					color: $red;
				}
			}
		}
	}
}

// Product Set Customizations //

.product-set-container {
	.product-set {
		.product-name {
			margin-top: 0rem;
			margin-bottom: 0.5rem;
		}
		.product-price {
			font-size: 1.35rem;
			margin-top: -.25rem;
		}
		.product-add-to-cart button {
			background: black;
			color: white;
			margin: auto;
			font-size: .85rem;
			width: 80%;
			margin-top: 1rem;
			margin-left: 10%;
			&:hover {
				border: 2px solid $black;
				color: $black;
				background-color: $white;
			}
		}
		.find-dealer {
			text-align: center;
			margin-top: 1rem;
			text-transform: uppercase;
			a {
				color: black;
				font-size: .65rem;
			}
		}
	}
	.custom-select-field .select-size {
		//    width: 83%;
	}
	.custom-select-field .select-width {
		//    width: 83%;
	}
	.pdp-main {
		.product-name {
			width: 100%;
			margin-top: 0rem;
		}
	}
	.product-set-list {
		.product-set-item {
			border-top: 3px soid #e6e6e6;
		}
		border-top: 3px solid #e6e6e6;
		.block-add-to-cart {
			position: relative;
			top: -2rem;
			margin-bottom: -2rem;

			@include respond(small) {
				top: -1rem;
			}

			button {
				float: left;
				background: black;
				color: white;
				width: 10rem;
				padding: 0px;
				font-size: .85rem;
				margin-left: 39%;
				margin-bottom: 1.5rem;
				&:hover {
					border: 2px solid $black;
					color: $black;
					background-color: $white;
				}
			}
			background: none;
			.availability {
				width: 60%;
				margin-left: 40%;
				.label {
					float: left;
				}
			}
			label {
				width: 20%;
				float: left;
			}
			.input-text {
				width: 55px;
				float: left;
				@include respond(small) {
					margin-top: 7px;
				}
			}
			.inventory {
				float: left;
				width: 100%;
				.quantity {
					float: left;
					width: 50%;
					margin-left: 19%;
				}
			}
		}
		.product-set-details {
			float: right;
			width: 68%;
			margin-left: 10%;
			.product-number {
				margin-top: 0;
				float: left;
				width: 100%;
			}
			.product-set-bundle-promotions {
				display: none;
			}
			.product-price {
				.price-sales {
					font-size: 1rem;
					font-family: $oswald;
					color: $gray-warm;
					margin-bottom: 1rem;
					float: left;
					width: 100%;
					margin-top: -5px;
				}
				.price-standard {
					color: $gray-warm;
					font-size: 1rem;
					float: left;
					& + .price-sales {
						color: $red;
						width: auto;
						margin-top: 0;
						margin-left: 10px;
					}
				}
			}

			.item-name {
				color: $gray-dark;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: bold;
			}
			.product-variations {
				float: left;
			}
		}
	}
}

.product-detail {
	.promotion {
		display: none;
	}
}

// Product Set and Bundle Variation and Promotion Customizations

.product-set-bundle-variations {
	.attribute {
		margin: 0;
	}
}

.product-set-bundle-promotions {
	.promotion {
		display: none;
	}
}

.set-bundle-cart-actions {
	margin: auto;
	width: 58%;
	@include respond (small) {
		width: 75%;
	}
	@include respond (large) {
		width: 65%;
	}
	margin-top: -1rem;
	margin-bottom: 4rem;
	ul {
		width: 100%;
		margin: auto;
		float: left;
		li {
			width: 44%;
			@include respond(large) {

			}
			float: left;
			text-align: center;
			a {
				color: $black;
			}
		}
		.spacer-li {
			width: 2%;
		}
	}
}

.bundle-attribute {
	float: left;
	margin-left: 7px;
	margin-top: -1rem;
	margin-bottom: 1rem;
	.attribute {
		.label {
			color: $black;
			text-transform: uppercase;
			font-weight: bold;
		}
		.value {
			color: $gray-warm;
		}
	}
}

// Power Reviews

.pdp-power-reviews {
	@include respond (small) {
		width: 94%;
		padding: 0;
		margin-left: 2%;
		overflow-x: hidden;
		margin-right: 2%;
	}
	float: left;
	width: 100%;
	.reviews-header {
		font-size: 2rem;
		text-align: center;
		color: #333;
	}
	.answers-container {
		#answers {
		}
	}
}

// PDP Recommendations
.pdp-recommendations {
	float: left;
	width: 100%;
	border-bottom: 2px solid #e6e6e6;
	padding-bottom: 2rem;
	margin-bottom: 4rem;
	@include respond (small) {
		margin-top: 0;
		margin-bottom: 0rem;
		padding-bottom: 1rem;
	}
	.product-info ul {
		font-size: .8rem;
		list-style: square;
		width: 100%;
		padding: 0;
	}
	h3 {
		text-align: center;
		font-size: 30px;
		@include respond(small) {
			font-size: 1.25rem;
		}
		text-transform: uppercase;
	}

	.search-result-items {
		border: none;
	}
	.product-listing-1x4 {
		.search-result-items {
			float: left;
			width: 100%;
			box-sizing: border-box;
			left: 0;
			padding-left: 0;
			padding-right: 0;
			@include respond(large) {
				left: 0;
			}
			.slick-arrow {
				height: 30px;
				width: 19px;
				top: 50%;
				margin-top: -15px;
				@include respond(small) {
					top: 50px;
				}
				&.slick-prev {
					background-image: url('../images/lCarouselLite.svg');
					left: 0;
					@include respond(small) {
						left: 0;
					}
				}

				&.slick-next {
					background-image: url('../images/rCarouselLite.svg');
					right: 0;
					@include respond(small) {
						right: 0;
					}
				}
			}
		}
		.grid-tile {
			width: 25%;
			padding-left: 0;
			margin: 0 10px;
			box-sizing: border-box;
			@include respond(small) {
				padding-right: 0;
			}
			@include respond(large) {
				width: 24%;
				margin: 0 1% 0 0%;
			}
			.product-tile {
				.product-image {
					text-align: center;

					img {
						width: 90%;
						display: inline;
					}
				}
				.name-link {
					font-size: 1rem;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: #333;
				}
				.product-name {
					line-height: 1rem;
					font-size: 16px;
					a {
						font-size: 16px;
						text-transform: none;
						line-height: 120%;
					}
				}
				.promotional-message {
					display: none;
				}
				.product-swatches {
					ul {
						padding: 0;
						margin: 0;
						@include respond (large) {
							width: auto;
						}
					}
				}
			}
		}
	}
}

#QuickViewDialog {
	#pdpMain {
		.product-col-1.product-image-container {
			.slick-prev,
			.slick-next {
				background-color: $white;
				margin-right: 0 !important;
			}
			.play-text {
				width: 61px;
			}
		}
	}
}

.ui-dialog.video {
	padding: 0;
	#dialog-container {
		margin-top: -20px !important;
		width: 760px !important;
		@include respond (large) {
			padding: 1.25em 5px !important;
		}
		@include respond (small) {
			margin-top: -10px !important;
			width: 280px !important;
			padding: 15px !important;
		}
	}
}

@include respond (large) {
	.ui-dialog {
		width: 768px !important;
		box-sizing: border-box;
		#QuickViewDialog {
			#pdpMain {
				width: 100%;
				.product-col-1.product-image-container {
					width: 56%;
					box-sizing: border-box;
					.product-primary-image,
					.product-primary-image-mobile {
						img {

						}
					}

				}
				.product-col-2.product-detail {
					width: 44%;
					.availability-msg {
						width: auto;
					}
					#add-to-cart,
					#design-your-own {
						margin-right: 0;
						height: 36px;
						line-height: 32px;
						width: 100%;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}

@include respond (small) {
	#QuickViewDialog {
		padding: 20px 10px !important;
		#pdpMain {
			width: 100%;
			.product-col-1.product-image-container {
				width: 100%;
				.product-primary-image,
				.product-primary-image-mobile {
					img {
						width: 200px;
					}
				}
			}
			.product-col-2.product-detail {
				box-sizing: border-box;
				padding-left: 0;
				width: 100%;
				h1.product-name {
					margin-top: -13px;
					line-height: 114%;
				}
				.view-full-details {
					display: none;
				}
				.secondary-actions {
					a {
						margin-right: 5px;
					}
				}
				.availability-web {
					float: right;
					width: 50%;
					margin-left: 0;
					@include respond(small) {
						float: left;
					}
				}
				#add-to-cart,
				#design-your-own {
					margin-right: 0;
					height: 36px;
					line-height: 32px;
					width: 100%;
				}
			}
		}
	}
}

#QuickViewDialog {
	width: 100%;
	box-sizing: border-box;
	z-index: 1000;
	position: relative;
	overflow: hidden;

	#pdpMain {
		.product-col-1 {
			width: 58%;
			&.product-image-container {
				position:relative;
				.product-badge-pdp {
				    position: absolute;
				    width: 24%;
				    right:13px;
				    z-index: 1;
				}
			}
		}
		.product-col-2 {
			width: 40%;
			overflow: hidden;

			.product-variations {
				li.variant-dropdown {
					margin: 0;
					padding: 0;
				}
				.hide-variations {
					.custom-select-field {
						display: none;
					}
				}
				.custom-select-field {
					margin-top: 0;
				}
			}
			form.pdpForm {
				float: left;
				width: 100%;
			}
		}
	}
}

#pdpMain {
	.product-col-1 {
		&.product-image-container {
			position:relative;
			.product-badge-pdp {
			    position: absolute;
			    width: 24%;
			    right: 0;
			    z-index: 1;
			}
		}
		#thumbnails {
			.carousel {
				li.product-video {
					.play-text {
						position: relative;
						width: 60px;
						img {
							width: 60px;
						}

						//play button in black circle:
						&:before {
							content: "";
							display: block;
							width: 40%;
							height: 40%;
							background-color: $black;
							position: absolute;
							top: 30%;
							left: 30%;
							border-radius: 50%;
							box-shadow: 0px 0px 10px -2px $white;
						}
						&:after {
							content: "";
							display: block;
							position: absolute;
							width: 0px;
							height: 0px;
							top: calc(50% - 5px);
							left: calc(50% - 2px);
							border-top: solid transparent 5px;
							border-right: none;
							border-bottom: solid transparent 5px;
							border-left: solid $white 8px;
						}
						//end: play button in black circle
					}
				}
			}
		}
		.desktop-hide {
			display: none;
			@include respond(small) {
				display: block;
				.product-video {
					border-top: 2px solid $clp-border;
					border-bottom: 2px solid $clp-border;
					width: 100%;
					padding: 5px 0;
					width: 100%;
					text-align: center;
					margin-bottom: 10px;
					img {
						display: inline;
						height: 16px;
					}
					& > span {
						display: block;
						span {
							font-size: 12px;
							text-transform: uppercase;
							line-height: 16px;
							font-weight: bold;
							padding-left: 20px;
							background: url("../images/play-video-icon.gif") no-repeat left center;

						}
					}
				}
			}
		}
	}
}

.variant-dropdown{
	.label{
		display: none;
	}
}
li#design-your-own-swatch,
li[id^="design-your-blank-swatch"] {
	padding: 4px;
	cursor: pointer;

	img {
		display: block;
		margin:0 auto;
		width:23px;
		height: 23px;
		margin-top: 5px;
	}

	.text-own {
		color: $gray-warm;
		display: block;
		text-transform: uppercase;
		text-align: center;
		font-size: 10px;
		font-weight: 600;
		margin: 2px 0 0 0;
		line-height: 1;
	}
}
.ui-dialog .pdp-main .product-add-to-cart button.dw-apple-pay-button  {
	margin-left:10px!important;
	width: 45%!important;
	min-width: 40%!important;
}

//Added to cart dialog styling
.ui-dialog.just-added-to-cart {
	@include respond(large) {
		max-width: 585px;
	}
	.ui-dialog-title {
		min-height: 24px;
	}
	.ui-dialog-titlebar-close {
		top: 35px;
        @include respond(small) {
            top: 20px;
        }
	}
	.dialog-content {
		box-sizing: content-box;
	}
	.added-to-cart-content {
		width: 545px;
        @include respond(small) {
            width: auto;
        }
		.added-to-cart-header {
			font-family: $oswald;
			font-size: 30px;
            line-height: 32px;
			font-weight: 700;
			text-transform: uppercase;
			color: $gray-dark;
			margin-bottom: 16px;
			&::before {
				content: url("../images/GreenCheck.png");
				display: inline-block;
				width: 32px;
				height: 32px;
				margin-right: 4px;
				vertical-align: bottom;
			}
		}
        .product-info {
            position: relative;
            .cart-totals {
                position: absolute;
                width: 25%;
                bottom: 0;
                right: 0;
                padding-left: 8px;
                text-align: right;
                box-sizing: border-box;
                @include respond(small) {
                    display: none;
                }
            }
        }
		.added-product {
			width: 75%;
            padding-right: 16px;
			border-right: 1px solid $gray-light;
            box-sizing: border-box;
            @include respond(small) {
                width: 100%;
                padding-right: 0;
                border: none;
            }
            .mini-cart-product {
                padding: 0;
            }
            .mini-cart-image {
                width: 175px;
                min-height: 175px;
                height: auto;
                border: 1px solid #ebebeb;
                padding: 5%;
                box-sizing: border-box;
                @include respond(small) {
                    width: 48%;
                }
                img {
                    max-width: none;
                    width: 100%;
                }
            }
            .mini-cart-name,
            .mini-cart-attributes,
            .mini-cart-pricing {
                float: left;
                width: 192px;
                margin-left: 24px;
                @include respond(small) {
                    width: 46%;
                    margin-left: 6%;
                }
            }
            .mini-cart-name-value {
                font-family: $titillium;
                font-weight: 600;
                font-size: 18px !important;
                line-height: 1em !important;
            }
            .mini-cart-attributes {
                font-size: 12px;
            }
            .mini-cart-pricing {
                .mini-cart-price {
                    display: block;
                    float: none;
                    margin-top: 20px;
                }
            }
		}
        .buttons-group {
            clear: both;
            margin-top: 40px;
            @include respond(small) {
                margin-top: 25px;
            }
            .just-added-button {
                display: inline-block;
                width: 48%;
                @include respond(small) {
                    display: block;
                    width: 100%;
                    margin-bottom: 8px;
                }
                &.continue-shopping {
                    margin-right: 2%;
                    @include respond(small) {
                        margin-right: 0;
                    }
                }
            }
        }
	}
	.bonus-item-promo {
		.bonus-item-promo-header {
			font-size: 30px;
			letter-spacing: .6px;
			color: $gray-dark;
			margin: 0;
		}
		.bonus-item-details {
			color: $red;
			font-size: 14px;
			font-weight: 700;
			margin: 8px 0 16px;
		}
		.bonus-item-buttons {
			.button {
				width: 48%;
				@include respond(small) {
					width: 100%;
					display: block;
					margin-bottom: 8px;
				}
				&:first-of-type {
					@include respond(largeUp) {
						margin-right: 2%;
					}
				}
			}
		}
	}
    .just-added-recommendations {
        width: 545px;
        @include respond(small) {
            display: none;
        }
        ul#featured-carousel {
            padding-left: 0;
            li.slick-slide {
                margin: 0 10px;
            }
        }
    }
}
