////////////////////////
// Checkout Billing Step
////////////////////////

#wrapper.pt_checkout{
	#main{
		#primary{
			input[type="password"],input[type="tel"],input[type="email"],input[type="number"],input[type="text"], select{
				height: 34px;
				line-height: 30px;
				padding-top: 0;
				padding-bottom: 0;
			}
			.fieldset.address{
				padding-bottom: 30px;
				display: none;
				&.open{
					display: block;
				}
			}
			.fieldset.billing-address-static{
				color: $gray-warm;
				line-height: 22px;
				font-size: 14px;
				padding-bottom: 25px;
				padding-top: 10px;
			}
			#change-address{
				color: $gray-dark;
				font-size: 12px;
				text-decoration: underline;
				margin-bottom: 20px;
				display: inline-block;
				&:hover{
					text-decoration: none;
				}
			}
			.checkout-billing{
				.fieldset{
					& > .form-row.form-row-button{
						display: none;
					}
					& > .payment-method-options.form-indent{
						margin-left: 0;
						> .form-row {
							margin-left: 18%;
							width: 95%;
							clear: none;
							float: left;

							@include respond(small) {
								margin: 5px 0 12px 0;
							}
							&.month, &.year {
								width: 160px;
							}
							label{
								color: $gray-warm;
								text-transform: none;
								font-weight: normal;
								width: auto;
							}
							label.cc-label {
							    margin-top: 5px;
							}
							span.icon-Visa, 
							span.icon-Amex,
							span.icon-MasterCard,
							span.icon-Discover {
								img {
									height: 26px;
									margin-left: 10px;
									opacity: .3;
								}
							}							
							span.icon{
								background:  url("../images/billing-icon.gif") no-repeat right center;
								width: 230px;
								height: 26px;
								float: left;
								font-size: 0;
								line-height: 0;
								margin: -5px 0 0 10px;
								@include respond(small) {
									width: 180px;
									background-size: 100% auto;
								}
							}
							
							span.icon-PayPal{
								width: 40px;
								height: 28px;
								float: left;
								font-size: 0;
								line-height: 0;
								margin: -5px 0 0 10px;
								@include respond(small) {
									width: 40px;
									background-size: 100% auto;
								}
								img {
									width: 100%;
									height: 100%;
								}
							}
							span.icon-DW_APPLE_PAY{
								height: 28px;
								float: left;
								font-size: 0;
								line-height: 0;
								margin: -5px 0 0 10px;
								@include respond(small) {
									background-size: 100% auto;
								}
								img {
									width: 100%;
									height: 100%;
								}
							}							
						}
						.payment-method {
							margin-bottom: 12px;
						}
					}
					&.payment-methods {
						h2 {
							span {
								background: none;
							}
						}
					}
				}
				@include respond(small) {
					& > .address{
						.form-row{
							margin: 0 0 1px 0;
						}
					}
				}
				& > .coupon-code{
					
					h2.close{
						padding-bottom: 0;
					}
					@include respond(small) {
					 	h2{
					 		margin-bottom: 15px;
					 	}
					 	.billing-coupon-code{
					 		.form-row.label-above{
					 			margin-top: 0;
					 			margin-bottom: 0;
					 			label{
					 				line-height: 100%;
					 				margin-bottom: 7px;	
					 			}
					 			input{
					 				width: 206px;
					 			}
					 		}
					 	}
					}
				}
				& > .form-row.form-row-button{
					margin: 0;
					padding: 20px 0 47px;
					width: 100%;
					border-top: 2px solid $gray-light;
					margin-top: 40px;
					@include respond(small) {
						padding-bottom: 0;
					}
					button{
						float: right;
						height: 44px;
						line-height: 40px;
						background: $black;
						color: $white;
						cursor: pointer;
						font-size: 14px;
						padding: 0 27px;
						&:hover{
							background: $white;
							color: $black;
							border-color: $black;
						}
						@include respond(small) {
							width: 100%;
						}
					}
					button.custom-progress {
						float: right;
						height: 44px;
						line-height: 40px;
						background: url("../images/loading-small.gif") no-repeat;
						background-position:50%;
						color: $clear;
						cursor: pointer;
						font-size: 14px;
						padding: 0 27px;
						@include respond(small) {
							width: 100%;
						}
					}
				}
				
				.dw-apple-pay-button {
					padding: 0px 30px!important;
					height: 42px!important;
					float: right!important;
					width: auto!important;
					border-radius:2em!important;
					border: 2px solid #000 !important;
					margin: 0 !important;
					min-height:10px!important;
					min-width:27%!important;
					background-size:45% 45%!important;
					background-color:$black!important;
					background-image:-webkit-named-image(apple-pay-logo-white)!important;
					background-repeat:no-repeat!important;
					background-position:50% 50%!important;		
					@include respond(small) {
						width: 100%!important;
						font-size: 14px!important;
						margin-top: 10px!important;
					}		
				}				
				
				.payment-method{
					&.payment-method-expanded {
						.form-row.form-row-button {
							display: none;
						}
					}
					@include respond(large) {
						.form-row{
							label{
								line-height: 130%;
							}
							&.label-inline.form-indent{
								label{
									width: 100px;
								}
							}
							.form-field-tooltip{
								width: auto;
								margin-top: 7px;
							}
							
						}
					}
					@include respond(small) {
						.form-row.form-row-button{
							display: none;
						}
						.form-row{
							margin: 0px 0 1px;
							label{
								line-height: 32px; 
							}
						}
					}
					.form-row{
						&.cvn{
							@include respond(small) {
								margin-bottom: 11px;
							}
							input{
								width: 160px;
								@include respond(small) {
									width: 135px;
								}
							}
							.form-field-tooltip{
								left: 320px;
								margin: 7px 0 0;
								@include respond(small) {
									display: none;
								}
							}
						}
					}
				}
				h2{
					padding-bottom: 15px;
					@include respond(small) {
						padding-bottom: 0;
					}
					span{
						background:  url("../images/down-arrow.png") no-repeat scroll right 10px center;
						display: block;
						width: 100%;
						cursor: pointer;
					}
					&.close{
						span{
							background:  url("../images/arrow-right.png") no-repeat scroll right 10px center;
						}
						a{
							color: #000;
						}
					}
					a{
						color: #000;
					}
				}
			}
			.checkout-privacy-policy{
				background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
				color: $gray-warm;
				display: inline-block;
				float: left;
				font-size: 12px;
				font-style: normal;
				line-height: 22px;
				margin: 10px 0;
				padding: 0;
				width: auto;
				text-align: left;
				@include respond(small) {
					margin: 0 0 33px;
				}
				@include respond(large) {
					margin: 0;
					line-height: normal;
				}
				a{
					color: $black;
					text-decoration: underline;
					@include respond(small) {
						display: block;
					}
					@include respond(large) {
						display: block;
					}
					&:hover{
						text-decoration: none;
					}
				}
			}
			////////////
			// Checkout Billing Step Coupon code
			///////////
			.billing-coupon-code{
				margin-left: 0;
				position: relative;
				&.close{
					display: none;
				}
				.content-asset{
					display: none;
				}
				.coupon-error.error{
					margin-left: 18%;
					margin-top: -15px;
				}
				button#add-coupon{
					font-size: 11px;
				    height: 34px;
				    left: 18%;
				    line-height: 30px;
				    margin-left: 350px;
				    padding: 0 20px;
				    position: absolute;
				    top: 0;
				    
				    &:hover{
				    	border-color: $black;
				    }
				    @include respond(small) {
						margin-left: 0;
						left: 216px;
						top: 19px;
						
					}
				}
				.form-row.coupon-error.error,
				.redemption.coupon.form-row{
					margin-bottom: 10px;
				}
				.form-row.label-above{
					width: auto;
					@include respond(large) {
						label{
							line-height: 130%;
						}
					}
					& + .form-row{
						float: left;
						width: auto;
					}
					.field-wrapper{
						width: auto;
					}
				}
			}
			
			/////// END COUPON STYLES ////////////////
			
			.form-row{
				position: relative;
				&.select-address{
					@include respond(large) {
						label{
							line-height: 130%;
						}
					}
				}
				&.label-inline.form-indent{
					margin-left: 18%;
					@include respond(small) {
						margin-left: 0;
					}
					label{
						line-height: 120%;
						font-size: 12px;
						color: $gray-warm;
						text-transform: none;
						font-weight: normal;
						box-sizing: border-box;
					}
					.save-card-label{
						width: 300px;
					}
				}
				.form-caption{
					margin: 7px 0 0 18%;
					color: $gray-warm;
					font-size: 11px;
					@include respond(small) {
						margin: 0;
					}
				}
				.form-row{
					&.year{
						width: 160px;
						margin: 0;
						@include respond(small) {
							width: 135px;
						}
						label, .form-caption{
							display: none;
						}
						select{
							width: 100%;
						}
					}
					&.month{
						width: 160px;
						margin: 0 20px 0 0;
						@include respond(small) {
							width: 135px;
							float: left;
							margin: 0 20px 0 0;
						}
						label, .form-caption{
							display: none;
						}
						select{
							width: 100%;
						}
					}
					.field-wrapper{
						width: 100%;
					}
				}
				.form-row.year + .form-row{
					display: none;
				}
				label{
					width: 18%;
					text-align: left;
					float: left;
					font-weight: bold;
					text-transform: uppercase;
					font-size: 12px;
					line-height: 18px;
					margin-top: 0;
					@include respond(small) {
						width: 100%;
					}
					.required-indicator{
						display: none;
					}
				}
				&.required{
					label{
						span{
							padding-right: 5px;
							&::after{
								content: " *";
								color: $gray-dark;
							}
						}
					}
				}
				.field-wrapper{
					float: left;
					width: 82%;
					padding-left: 0;
					margin-bottom: 5px;
					@include respond(small) {
						width: 100%;
					}
					select,
					input[type="password"],
					input[type="text"],
					input[type="email"],
					input[type="tel"],
					input[type="number"]{
						width: 340px;
						@include respond(small) {
							width: 100%;
						}
					}
					span{
						display: block;
						width: 100%;
					}
					label.radio{
						width: 40px;
						margin-top: 10px;
						@include respond(small) {
							margin-top: 0;
						}
						span{
							color: $gray-warm;
							font-size: 11px;
							text-transform: none;
							font-weight: normal;
							line-height: 150%;
						}
					}
				}
				.form-field-tooltip{
					left: 350px;
				    position: absolute;
				    top: 0;
				    margin: 7px 0 0 18%;
				    padding-top: 0;
				    @include respond(large) {
				    	margin-top: 0
				    }
				    @include respond(small) {
						left: 0;
					    position: relative;
					    top: 0;
					    text-align: right;
					    padding-left: 0;
					    width: 100%;
					    text-align: right;
					    margin: 0;
					}
					a{
						color: $gray-warm;
						font-size: 11px;
					}
				}
			}
			.error-form{
				font-weight: bold;
				font-size: 16px;
				span{
					color: #fff;
					width: 24px;
					height: 24px;
					line-height: 24px;
					font-size: 12px;
					background: #b2595c;
					border-radius: 12px;
					display: inline-block;
					text-align: center;
					margin-right: 6px;
				}
			}
		}
		#secondary{
			.order-component-block{
				@include respond(small) {
					display: inline-block;
				    margin: 22px 0 29px;
				    width: 100%;
				}
			}
			.mini-payment-instrument,
			.mini-billing-address,
			.mini-shipment{
				&.last{
					.section-header{
						.section-header-note{
							display: none;
						}
					}
				}
				h3{
					border-bottom: 0;
				}
				.name{
					font-size: 12px;
					color: $gray-dark;
					text-transform: uppercase;
					font-weight: bold;
					padding-bottom: 5px
				}
				.details{
					background: none;
					padding: 0;
					color: $gray-warm;
					div{
						font-size: 14px;
						color: $gray-warm;
						@include respond(small) {
							line-height: 18px;
						}
						&.address{
							div:last-child{
								text-transform: uppercase;
							}
						}
					}
					.minishipments-method{
						span{
							font-size: 14px;
							color: $gray-warm;
							&:first-child{
								color: $gray-dark;
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}
}
