.tabbed-recommendations {
    .tabbed-layout-heading {
        h2 {
            font-size: 24px;
            font-family: $eurostile;
            text-align: center;
            letter-spacing: 0;
            margin: 0 30px 20px;
            font-weight: 500;
            text-transform: uppercase;
            color: $black;

            @include respond(largeUp) {
                font-size: 36px;
            }
        }
    }

    .nav-tabs {
        li {
            a.nav-link-rec {
               font-size: 15px;
               font-family: $neuzeit;
               font-weight: 400;
               height: 38px;
               line-height: 38px;
               letter-spacing: .05em !important;
               margin: 0;
               padding: 0 20px;
           }
        }
    }

    .fj-recommended-products {
        .fj-rp-slider {
            .slick-arrow {
                position: absolute;
                width: 50px;
                height: 70px;
                bottom: 0;
                top: unset;
                opacity: 1;
                z-index: 10;
                transform: none;
                padding: 0;

                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 12px;
                    height: 12px;
                    top: 50%;
                    left: 50%;
                    border-color: #000;
                    border-style: solid;
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                }

                &:focus,
                &:hover,
                &:active {
                    border: none;
                }

                @include respond(largeUp) {
                    bottom: unset;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.slick-prev,
                &.left {
                    left: 0;
                    border: none;

                    &::before {
                        border-width: 0 0 3px 3px;
                    }
                }

                &.slick-next,
                &.right {
                    right: 0;
                    border: none;

                    &::before {
                        border-width: 3px 3px 0 0;
                    }
                }
            }

            .fj-rp-slide-title {
                color: $black;
                font-family: $neuzeit;
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                padding: 24px 10px 0;
                text-transform: uppercase;

                @include respond(largeUp) {
                    padding: 34px 18px 0;
                }
            }

            .fj-rp-slide-category,
            .fj-rp-slide-price {
                color: $black;
                font-family: $neuzeit;
                font-size: 12px;
                line-height: 20px;
                height: 20px;
            }
        }
    }

    .tabs-toggle {
        display: none;
        &.active {
            display: block;
        }
    }

    .product-listing-1x4 {
        padding: 0 50px;
    }
}
