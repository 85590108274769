footer.foot--footjoy {
	font-family: $neuzeit;
	text-transform: none;

	.innerWidth {
		font-family: $neuzeit;
		box-sizing: border-box;
		width: 100%;
		padding-left: 25px;
		padding-right: 25px;

		@include respond(standard){
			padding-left: 15px;
			padding-right: 15px;
		}

		* {
			font-family: $neuzeit !important;
		}
	}

	.footer-container__top.footer-container__top {
		padding: 15px 0;
        background-color: #253F37;
	}

	.footer-container__middle {
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);

		@include respond(largeUp){
			padding: 35px 0;
		}
	}

	.footer-copy {
		padding: 0;
	}

	.countries-container {

		& > a {
			font-size: 16px;
			line-height: 19px;
		}
	}

	.newsletter-content {
		@include respond(tablet){
			max-width: 270px;
		}
	}

	#footerSignup {
		form#email-alert-signup {
			button {
				position: absolute;
				display: block;
				top: 0;
				right: 0;
				margin: 0;
				padding: 0;
				width: 48px;
				height: 48px;
				border: none;
				background: $dark;
				transform: none;

				&::before {
					content: '';
					position: absolute;
					display: block;
					top: 50%;
					left: calc(50% - 4px);
					width: 13px;
					height: 13px;
					border-top: 4px solid $white;
					border-right: 4px solid $white;
					transform: translateX(-50%) translateY(-50%) rotate(45deg);
					box-sizing: border-box;
				}
			}
		}
	}

	.opt-in {
		label {
			padding-left: 35px;
			margin-bottom: 0;
		}

		input[type=checkbox]+label>.styled-checkbox {
			position: absolute;
			left: 0;
			display: inline-block;
			width: 18px;
			height: 18px;
			margin-right: 15px;
			float: left;
			background: $white;
			border: 1px solid $black;
			vertical-align: top;

			&::after,
			&::before {
				display: none;
			}
		}

		input[type=checkbox]:checked+label>.styled-checkbox {
			background-color: $black;
			box-sizing: border-box;

			&::before,
			&::after {
				content: "";
				position: absolute;
				height: 3px;
				background: #fff;
				display: block;
			}

			&::before {
				width: 8px;
				bottom: 5px;
				transform: rotate(45deg);
			}

			&::after {
				width: 14px;
				right: -1px;
				bottom: 7px;
				transform: rotate(-45deg);
			}
		}
	}

	.position-relative {
		position: relative;
	}

	.countries-container {
		margin-top: 20px;
	}
}

.fp-tableCell footer.homepage-footer-content {
	display: block !important;
}

footer {
	clear: both;
	padding-bottom: 50px;

	@include respond(standard){
		padding-bottom: 70px;
    }

	.innerWidth {
		@include respond(not-standard){
			max-width: initial;//fluid at all scales that aren't standard
		}
		@include respond(large){
			max-width: initial;//fluid at all scales that aren't standard
		}
    }

	.footer-copy {
		padding-top: 0;
		font-size: 16px;
		line-height: 1;
		padding: 25px 0 20px;
		text-align: center;
		text-transform: uppercase;
		letter-spacing:normal;
		box-sizing: border-box;

		@include respond(largeUp){
			padding: 25px 40px 0;
		}
	}

	// Footer Newsletter Signup
	#footerSignup {
		form#email-alert-signup {
			position: relative;

			input[type="text"],
			input[type="email"] {
				background: transparent;
				border: 1px solid $dark;
				border-radius: 3px;
				color: $dark;
				font-family: $neuzeit;
				font-size: 18px;
				line-height: 1;
				padding: 12.5px 55px 12.5px 15px;
				width: 100%;

				&::-ms-clear {
					display: none;
				}

				&::placeholder {
					color: #929292;
				}
			}

			.error {
				display: block;
				font-size: 14px;
				margin-top: 10px;
			}

			button {
				position: absolute;
				display: block;
				top: 0;
				right: 0;
				margin: 0;
				border: transparent;
				border-top: 3px solid $white;
				border-right: 3px solid $white;
				background: transparent;
				display: block;
				width: 20px;
				height: 20px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 17px;
				right: 20px;
				transform: rotate(45deg);
			}
		}
	}

	.footer-container__middle {
		@include respond(largeUp){
			padding: 20px 0;
		}
	}

	.footer-container__middle__inner {
		@include respond(largeUp){
			display: flex;
			justify-content: space-between;
			gap: 30px;
		}
	}

	.footer__logo-search-container {
		margin-bottom:0;

		@include respond(large) {
			margin-bottom:20px;
		}

		@include respond(largeUp){
			display: flex;
			justify-content: space-between;
		}
	}

	// Footer Logo
	.footer__logo {
		padding: 20px;
		text-align: center;
		margin: 0 auto;

		@include respond(tablet){
			margin: 0;
			padding: 0;
			text-align: left;
		}

		img {
			height: 50px;
			max-width: none;
		}
	}

	// Footer Search
	.footer-search {
		padding-right: 20px;
		padding-left: 20px;

		@include respond(largeUp){
			min-width: 365px;
			margin-left: 5%;
			padding: 0;
		}

		form {
			position: relative;
		}

		input[type="text"] {
			background-color: transparent;
			border: 2px solid $blue-dark;
			border-radius: 0;
			font-family: $din-condensed;
			font-size: 18px;
			height: 50px;
			width: 100%;
		}

		[type="submit"] {
			background: transparent;
			border: 0;
			height: 20px;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
			width: 20px;

			&:hover {
				background: transparent;
			}

			&:active {
				opacity: 1;
			}
		}

		.search-submit-icon {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.footer-user-actions {
		display: flex;
		flex-direction: column;

		@include respond(largeUp){
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			margin-right:3%;
		}
		@include respond(not-standard){
			margin-right:0;
		}
	}

	// Footer User Links (Content Assets)
	.footer-user-links {
		background-color: $gray-pale;
		display: flex;
		justify-content: space-between;
		line-height: 25px;
		order: 2;
		padding: 20px;

		@include respond(largeUp){
			background-color: transparent;
			order: 0;
			padding: 0;
			margin-right:10%;
		}
	}

	.footer-user-link {
		@include respond(not-standard){
			min-width:130px;
			margin-left:0;
		}
		@include respond(standard){
			min-width:70px;
			margin-left:50px;
		}
		a, h3 {
			color: #000;
			font-family: $din-condensed;
			font-size: 18px;
			font-weight: 400;
			letter-spacing: normal;
			margin: 0;
			text-transform: uppercase;
		}
		img {
			float: left;
			margin-right: 15px;
		}
		.desktop-only {
			display: none;

			@include respond(largeUp){
				display: block;
			}
		}

		.mobile-only {
			@include respond(largeUp){
				display: none;
			}
		}

		+ .footer-user-link {
			margin-left: 50px;
			min-width: 160px;
		}
	}

	// Footer Social Media
	.footer__social {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		gap: 10px 20px;
		margin-top: 46px;

		@include respond(tablet){
			margin-top: 0;
			justify-content: center;
		}

		@include respond(largeUp){
			margin-top: 35px;
			justify-content: start;
		}

		.footer__social__description {
			font-size: 16px;
			margin: 0;
		}

		.footer__social__links {
			display: flex;
			align-items: center;
			gap: 20px;
		}
	}
}

.newsletter-content {
	img {
		display: block;
		width: 47px;
		margin: 0 auto;
	}
	input[type=email]::placeholder {
   		color: hsla(0,0%,100%,.5);
   	}

}
.footer-container__bottom {
	@include respond(largeUp){
		border: 3px solid #ECECEC;
		border-width: 3px 0;
		margin: 5px 0;
	}
}

// Nav Items
.menu-footer {
	float: none;

	@include respond(largeUp){
		display: flex;
		justify-content: space-between;
	}

	li {
		float: none;

		&:last-of-type {
			display: none;

			@include respond(largeUp){
				display: block;
			}
		}
	}

	a {
		background-color: $gray-pale;
		border-top: 2px solid #d3d3d3;
		color: #000;
		display: block;
		font-size: 18px;
		line-height: 24px;
		padding: 18px 20px 16px;
		position: relative;
		text-transform: uppercase;

		@include respond(largeUp){
			background-color: transparent;
			border-top: none;
			display: inline;
			font-size: 14px;
			line-height: 67px;
			padding: 0;
		}

		@include respond(standard){
			font-size: 18px;
		}

		&:hover,
		&:active {
			background-color: $gray-cirlce;

			@include respond(largeUp){
				background-color: transparent;
			}
		}

		&::after {
			border-top: 2px solid $blue-light;
			border-right: 2px solid $blue-light;
			content: "";
			height: 16px;
			position: absolute;
				top: 50%;
				right: 20px;
			transform: translateY(-50%) rotate(45deg);
			transform-origin: 50% 50%;
			transition: transform .5s;
			width: 16px;

			@include respond(largeUp){
				display: none;
			}
		}

		&:hover::after {
			@include respond(largeUp){
				background-color: #222;
				border: none;
				content: "";
				display: block;
				position: absolute;
				top: auto;
					right: 0;
					bottom: -5px;
					left: 0;
				height: 2px;
				transform: none;
				transition: none;
				width: auto;
			}
		}
	}
}

.intersitial-message-modal {
	img {
		width: 300px;
		margin: 0 auto;
		display: block;
	}
	h1 {
		text-align: center;
	}
	.show-countries {
		display: block;
		text-align: center;
		font-weight: bold;
	}
	.dialog-content {
		padding: 10px !important;
	}
}

.footer__column--logo {
	padding-bottom: 40px;

	@include respond(tablet){
		padding-top: 40px;
		display: flex;
		align-items: center;
	}

	@include respond(largeUp){
		display: block;
		max-width: 240px;
		padding-top: 0;
		padding-bottom: 0;
	}

	@include respond(standard){
		max-width: 310px;
	}
}

.footer__column__links {
	@include respond(largeUp){
		display: flex;
		justify-content: space-around;
		gap: 30px;
	}
}

.footer__column__links__wrapper {
	flex: 1
}

.footer__links__wrapper {
	margin: 0 -25px;
	padding: 0 25px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);

	@include respond(largeUp){
		flex: 1;
		margin: 0;
		padding: 0;
		border: none;
	}

	@include respond(standard){
		flex: initial;
	}

}

.footer__links__wrapper--latest {
	@include respond(largeUp){
		flex: 0 0 200px;
	}

	@include respond(standard){
		flex: initial;
	}
}

.footer__logo__description {
	display: flex;
	gap: 12px;
	flex-direction: column;
	padding-top: 15px;

	@include respond(tablet){
		align-self: start;
		flex-direction: row;
		gap: 32px;
		display: flex;
		flex-wrap: wrap;
		padding-top: 0;
	}

	@include respond(largeUp){
		flex-direction: column;
	}
}

.footer__email-signup .footer__logo__description__text {
	margin: 0;
	font-size: 18px;
	line-height: 27px;
	text-align: center;

	@include respond(tablet){
		text-align: left;
		margin: 0 0 35px 0;
	}

	@include respond(largeUp){
		margin: 30px 0;
		font-size: 16px;
		line-height: 24px;
	}
}

.footer__email-signup {
	@include respond(tablet){
		margin-left: 50px;
	}

	@include respond(largeUp){
		margin-left: 0;
	}
}

footer .footer__links__wrapper .footer__links__title {
	position: relative;
	margin: 0;
	padding: 20px 0;
	font-family: $neuzeit;
	font-weight: 700;
	font-size: 22px;
	line-height: normal;
	letter-spacing: normal;
	color: $dark;
}

.footer-container__middle .footer__links__title__collapse-button {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: none;
	border: none;
	padding: 0 35px 0 0;
	line-height: normal;

	&:hover {
		background: none;
		border: none;
		padding: 0;

		@include respond(small) {
			&::after {
				top: 50%;
			}
		}
	}

	@include respond(small) {
		&::after {
			content: '—';
			display: block;
			position: absolute;
			left: initial;
			bottom: initial;
			right: 0;
			top: 50%;
			background: none;
			transform: translateY(-50%);
			font-size: 40px;
			font-weight: 700;
			line-height: 1;
		}
	}

	&.collapsed {
		@include respond(small) {
			&::after {
				content: '+';
			}
		}
	}
}

footer .footer__links {
	margin: 0;
	padding: 0 0 20px;
	list-style: none;

	@include respond(largeUp){
		padding-bottom: 0;
	}

	&.collapsed {
		display: none;

		@include respond(largeUp){
			display: block;
		}
	}
}

.footer__links__item {
	margin: 5px 0;
	padding: 0;

	@include respond(largeUp){
		margin: 2px 0;
	}
}

.footer__links__item__link {
	font-size: 16px;
	line-height: 30px;
	text-decoration: none;
	color: $dark;

	&:hover {
		text-decoration: underline;
	}
}

footer .footer__email-sign-up {
	margin-top: 20px;

	@include respond(tablet){
		margin-top: 0;
	}
}

.footer__links .footer__help__description,
.footer__links .footer__help__time {
	margin-top: 0;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 22px;
}

.footer__help__number {
	font-weight: 700;
	font-size: 16px;
	text-decoration: none;
	color: $dark;

	&:hover {
		text-decoration: underline;
	}
}

.footer__help__link {
	font-weight: 400;
	font-size: 16px;
	text-decoration: underline;
	color: $dark;

	&:hover {
		text-decoration: none;
	}
}

footer {
	// Countries Container
	.countries-container {
		position: relative;

		& > a {
			background: url("../images/usa_off.gif") left center no-repeat;
			padding-left: 35px;
			color: $black;

			&.ca {
				background: url("../images/canada-icon.gif") left center no-repeat;
			}

			&.kr {
				background: url("../images/korea.png") left center no-repeat;
			}

			&.gb {
				background: url("../images/uk.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.de{
				background: url("../images/germany.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.fr{
				background: url("../images/france.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.es {
				background: url("../images/spain.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.ie{
				background: url("../images/ireland.gif") left center no-repeat;
				background-size: 18px 11px;
			}
			&.be {
				background: url("../images/belgium.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.dk {
				background: url("../images/denmark.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.fi {
				background: url("../images/finland.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.it {
				background: url("../images/italy.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.se {
				background: url("../images/sweeden.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.at {
				background: url("../images/austria.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.lu {
				background: url("../images/luxembourg.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.nl {
				background: url("../images/netherlands.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.sk {
				background: url("../images/slovakia.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.si {
				background: url("../images/slovenia.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.pt {
				background: url("../images/portugal.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.au {
				background: url("../images/australia.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
			&.nz {
				background: url("../images/newzealand.jpg") left center no-repeat;
				background-size: 18px 11px;
			}
		}
		&.ca > a {
			background: url("../images/canada-icon.gif") left center no-repeat;
			padding-left: 35px;
		}
		.country-container{
			position: absolute;
			float: left;
			width: 280px;
			left: 0;
			bottom: 46px;
			background-color: $white;
			border: 2px solid $clp-border;
			border-bottom: 0;
			box-sizing: border-box;
			display: none;
			z-index:53;
			@include respond(small){
				bottom: 34px;
				width: 320px;
			}
			h2{
				float: left;
				width: 100%;
				padding: 10px 10px 10px 20px;
				box-sizing: border-box;
				border-bottom: 2px solid $clp-border;
				color: $gray-dark;
				line-height: 23px;
				margin: 0;
				text-align: left;
				font-size:15px;
				a.close-countries.close-countries {
					background: url("../images/close.svg") left top no-repeat;
					height: 23px;
					width: 24px;
					float: right;
					opacity: 0.8;
					&:hover{
						opacity: 1;
					}
				}
			}
			.country-selector{
				position: relative;
				right: auto;
				top: auto;
				width: 100%;
				@include respond(small){
					border: 0;
				}
				span{
					float: left;
					width: 100%;
					padding: 10px;
					box-sizing: border-box;
					border-bottom: 2px solid $clp-border;
					text-align: left;

					a{
						color: $gray-dark;
						font-size: 14px;
						line-height: 18px;
						text-decoration: none;
						margin: 0;
						height: 18px;
						line-height: 18px;
						display: inline-block;
						background: url("../images/countries-icons.gif") left center no-repeat;
						padding-left: 40px;

						&:hover{
							text-decoration: underline;
						}
						img{
							float: left;
							margin: 5px 20px 0 10px;
						}
					}
					&.austr{
						a{
							background-position: 10px 1px;
						}
					}
					&.canada{
						a{
							background-position: 10px -17px;
						}
					}
					&.china{
						a{
							background-position: 10px -35px;
						}
					}
					&.france{
						a{
							background-position: 10px -53px;
						}
					}
					&.germany{
						a{
							background-position: 10px -71px;
						}
					}
					&.ireland{
						a{
							background: url("../images/ireland.gif") left center no-repeat;
						    background-size: 18px 11px;
    						background-position: 10px 4px;
						}
					}
					&.japan{
						a{
							background-position: 10px -89px;
						}
					}
					&.korea{
						a{
							background-position: 10px -107px;
						}
					}
					&.mexico{
						a{
							background: url("../images/latin.gif") left center no-repeat;
						    background-size: 18px 11px;
    						background-position: 10px 4px;
						}
					}
					&.malas{
						a{
							background-position: 10px -125px;
						}
					}
					&.singapore{
						a{
							background-position: 10px -143px;
						}
					}
					&.spain{
						a{
							background-position: 10px -161px;
						}
					}
					&.sweden{
						a{
							background-position: 10px -179px;
						}
					}
					&.thai{
						a{
							background-position: 10px -197px;
						}
					}
					&.uk{
						a{
							background-position: 10px -215px;
						}
					}
					&.usa{
						a{
							background-position: 10px -233px;
						}
					}
					&.southafrica {
						a{
							background: url("../images/southafrica_off.gif") 10px center no-repeat;
						}
					}
				}
			}
		}
	}
}

.footer-copy__inner {
	display: flex;
	flex-direction: column;
	gap: 34px 40px;
	padding: 25px 0 0;
	text-align: left;

	@include respond(tablet){
		flex-wrap: wrap;
	}

	@include respond(largeUp){
		flex-direction: row;
		justify-content: space-between;
		padding: 35px 0 0;
		flex-wrap: none;
	}
}

.footer__copyright {
	order: 2;
	color: $dark;
	font-size: 15px;
	line-height: 28px;
	text-transform: none;
	text-align: center;
	flex: 1;

	@include respond(largeUp){
		order: initial;
		flex: 0 0 20%;
		text-align: left;
		font-size: 16px;
		line-height: 24px;
	}
}

.footer-copy__links {
	order: 1;

	@include respond(largeUp){
		order: initial;
		flex: 1;
	}
}

footer .footer__bottom__links {
	margin: 0;
	padding: 0;
	list-style: none;

	@include respond(tablet){
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
	}
}

.footer__bottom__links__item {
	flex: 1;
	margin: 13px 0;

	@include respond(tablet){
		flex: 0 0 calc(33% - 15px);
		margin: 0;
	}

	@include respond(standard){
		flex: 0 0 calc(25% - 15px);
	}
}

.footer__bottom__links__item__link {
	font-size: 14px;
	line-height: normal;
	color: #929292;
	text-decoration: none;
	text-transform: none;

	@include respond(largeUp){
		color: #434343;
	}

	&:hover {
		text-decoration: underline;
	}
}

.footer__insider {
	display: flex;
	gap: 25px;
	align-items: start;

	@include respond(largeUp){
		align-items: center;
	}

	@include respond(standard){
		gap: 70px;
	}
}

.footer__insider__logo {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 13px;

	@include respond(tablet){
		gap: 35px;
		flex-wrap: nowrap;
		flex: 1;
		margin-right: 25px;
	}

	@include respond(largeUp){
		flex: initial;
	}
}

.footer__insider__logo__icon {
	width: 150px;

	@include respond(tablet){
		width: 142px;
	}

	@include respond(largeUp){
		width: 127px;
	}
}

footer .footer__insider__links {
	display: none;

	@include respond(largeUp){
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1;
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.footer__insider__logo .footer__insider__description {
	flex: 1;
	font-size: 14px;
	color: $white;
	line-height: 19px;
	text-transform: none;
	margin: 0;

	@include respond(tablet){
		font-size: 16px;
	}

	@include respond(largeUp){
		display: none;
	}
}

.footer__insider__description__link {
	color: $white;
	text-transform: none;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.footer__insider__links__link {
	display: flex;
	align-items: start;
	gap: 10px;
	color: $white;
	font-size: 16px;
	line-height: 19px;
	text-decoration: none;

	@include respond(standard){
		align-items: center;
		gap: 18px;
	}

	&:hover {
		text-decoration: underline;
	}
}

.footer__insider__links__link--more {
	text-decoration: underline;

	&:hover {
		text-decoration: underline;
	}
}

.footer__insider__buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	justify-content: end;
	margin-left: auto;
}

.footer__insider__buttons .footer__insider__buttons__button {
	font-family: $neuzeit;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.0125em;
	border-radius: 2px;
	line-height: 21px;
	min-width: 150px;
	padding: 12px 20px;
	border: 1px solid;
	box-sizing: border-box;
	text-align: center;
	height: auto;

	@include respond(tablet){
		min-width: 120px;
	}
}

.footer__insider__buttons .footer__insider__buttons__button--join {
	color: $dark;
	background: $white;
	border-color: $white;
}

.footer__insider__buttons .footer__insider__buttons__button--sign-in {
	color: $white;
	border-color: $white;
	background: transparent;
}
