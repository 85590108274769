///////////////////////
/// Button Mixins
///////////////////////

@mixin blackButton {
    color: $black;
    border-color: $black;
    &:hover {
		@include respond(largeUp) {
			color: $gray-white;
			background-color: $black;
		}
    }
	&:active {
		color: $gray-white;
		background-color: $black;
	}
}

@mixin blueButton {
    color: $white;
	font-family: $neuzeit;
    border-color: $blue-light2;
	background: $blue-light2;
	height: rem(60px);
	padding: 0 rem(40px);
	font-size: rem(16px);
	font-weight: 700;
    &:hover {
		@include respond(largeUp) {
			color: $white;
			background-color: $blue-dark;
		}
    }
	&:active {
		color: $white;
		background-color: $blue-dark;
	}
}

@mixin whiteButton {
    color: $white;
    border-color: $white;
    &:hover{
		@include respond(largeUp) {
			color: $black;
			background-color: $white;
		}
    }
	&:active{
		color: $black;
		background-color: $white;
	}
}

@mixin whiteButton2 {
    color: $blue-light2;
	font-family: $neuzeit;
    border-color: $white;
	background: $white;
	height: rem(60px);
	line-height: rem(60px);
	padding: 0 rem(40px);
	font-size: rem(16px);
	font-weight: 700;
}

///////////////////////
/// Base Button Styles
///////////////////////
button,
.button,
.buttonstyle,
input[type="button"] {
	background-color: transparent;
	border: 2px solid $black;
	line-height: 40px;
	padding: 0 30px;
	height: 46px;
	border-radius: 0;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	box-sizing: border-box;
	text-align: center;
	font-family:$font-default;
	transition: background-color $quickSpeed;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	outline: none;
	@include blackButton;
	&.black {
		@include blackButton;
	}
	&.white {
		@include whiteButton;
	}
	&.inverted {
		background-color: $black;
		color: $white;
		&:hover{
			background-color: $white;
			color: $black;
		}
	}

	//NOTE: more global button styles can be found in _content_assets_slots.scss

	@include respond(large){
		font-size: 11px;
		padding: 0 20px;
		height: 44px;
		line-height: 30px;
	}
	@include respond(small){
		font-size: 11px;
		padding: 0 20px;
		height: 30px;
		line-height: 30px;
	}
	@include respond(tablet){
		height:44px;
	}

	&.large {
		font-size: 1.35em;
		text-transform: uppercase;
	}
	&[disabled],
	&.disabled {
		background-color: $gray-light;
		border-color: $gray-light;
		color: $gray-dark;
		opacity: 0.5;
	}

	//Content asset helper functions (moved from _content_asset_slots.scss)
	&.mobile-black {
		@include blackButton;
	}
	&.mobile-white {
		@include whiteButton;
	}
	@include respond(largeUp){
		&.desktop-black {
			@include blackButton;
		}
		&.desktop-white {
			@include whiteButton;
		}
	}
	@include respond(large){
		&.tablet-black {
			@include blackButton;
		}
		&.tablet-white {
			@include whiteButton;
		}
	}
}

.button-fancy-large {
	@extend .button.large;
	font-size: 14px;
}

.button-text {
	background-color: transparent;
	border-width: 0;
	color: $red;
	padding: 0;
	&:hover {
		background-color: transparent;
		text-decoration: underline;
	}
}

button#needle_categoryNT.needlepoint {
	border:0!important;
}

.button-full-width {
	padding-left: 0;
	padding-right: 0;
	text-align: center;
	width: 100%;
}
///////////////////////
/// Tab Buttons
///////////////////////
.tab-button-large{
	@include borderbox;
	padding: 20px 40px 20px 40px;
	color: $black;
	background-color: $gray-white;

	@include font-size(18px);
	&.selected{
		background-color: $gray-light;
	}
}
.tab-button-large{
	@include borderbox;
	padding: 15px 60px 15px 60px;
	color: $black;
	background-color: $white;

	@include font-size(12px);
	&.selected{
		background-color: $white;
		color: $white;
	}
}
