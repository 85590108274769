.product-tile {
	@extend %clearfix;
	color: $gray-warm;
	position: relative;
	text-align: center;
	font-family: $titillium;
	@include font-size(16px);
	padding: 0;
	box-sizing: border-box;

	img {
		max-width: 100%;
		&.product-badge-plp {
    		position: absolute;
		    width: 30%;
		    right: 0;
		    z-index: 1;
		}
	}
	@include respond('small') {
		@include font-size(14px);
	}
	.product-image {
		height: auto;
		position: relative;
		min-height: 200px;
		@include respond('large') {
			min-height: 145px;
		}
		@include respond('small') {
			min-height: 132px;
		}
		.quickview {
			@extend .button.black;
			background: $white;
			display: none;
			@include font-size(11px);
			width: 100px;
			top: 75%;
			left: 25%;
			padding: 0;
			position: absolute;
		}
		&:hover {
			.quickview {
				display: block;
			}
		}
		@include respond('small') {
			.quickview {
				display: none;
			}
		}
		.customize-link {
			display: inline-block;
			position: absolute;
			top: 18px;
			right: 25px;
			height: 34px;
			width: 83px;
			@include respond('large') {
				right: 10px;
				width: 50%;
			}
			@include respond('small') {
				height: auto;
				width: 50%;
				right: 10px;
			}
		}
	}
	.product-name {
		color: $black;
		font-weight: bold;
		line-height: 1.25rem;
		margin-bottom: 4px;
		a{
			color: $black;
		}
	}
	.product-category {
		font-family: "Oswald", sans-serif;
		font-size: 0.7rem;
		font-weight: 600;
		text-transform: capitalize;
	}
	.product-pricing {
		font-family: $oswald;
		font-weight: bold;
		@include font-size(13px);
		margin: 0 0 10px 0;
		@include respond('small') {
			@include font-size(12px);
		}
		.product-standard-price {
			text-decoration: line-through;
		}
		.product-sales-price {
			color: $red;
		}
	}
	.product-swatches-all {
		cursor: pointer;
		@include font-size(10px);
	}
	.product-swatches {
		ul {
			display: block;
			list-style: none outside none;
			margin: 0;
			overflow: hidden;
			padding: 0;
			text-align: center;

			@include respond(largeUp) {
				//padding-left: 15px;
			}
		}
		li {
			display: inline-block;
            vertical-align: middle;
			margin: 0 0 4px;
			&.hide{
				display: none;

				&.show-in-desktop {
					@include respond(standard) {
						display: inline-block;
					}
				}
				
				&.show-in-mobile {
					@include respond(small) {
						display: list-item;
					}
				}
			}
			@include respond('large') {
				margin: 0 3px 4px;
				&.hide-mobile{
					display: none;
				}
				&:last-child{
					&.hide-mobile{
						display: block;
					}
				}
			}
			@include respond('small') {
				margin: 0 2px 4px;
				&.hide-mobile{
					display: none;
				}
				&:last-child{
					//margin-right: 0;
					&.hide-mobile{
						display: block;
					}
				}
			}
		}
		.open-close{
			a{
				font-size: 10px;
				color: $gray-dark;
				line-height: 18px;
				text-transform: uppercase;
			}
			span.closetxt {
				display: none;
			}
		}
		&.expanded {
			position: absolute;
			z-index: 1;
			background-color: $white;
			box-shadow: 0 10px 10px 0 $white;
			li.hide, li.hide-mobile{
				display: inline-block;
			}
			span.moretxt {
				display: none;
			}
			span.closetxt {
                display: inline-block;
			}
		}
		.swatch {
			//display: block;
			display: table-cell;
			vertical-align: middle;
			box-sizing: border-box;
			height: 35px;
			width: 35px;
			border-radius: 50%;
			border: 2px solid transparent;
			@include respond(large) {
				height: 32px;
				width: 32px;
			}
			img {
				border-radius: 50%;
				box-sizing: border-box;
				height: 30px;
				width: 30px;
				margin: 0 auto;
				vertical-align: middle;
				@include respond(large) {
					height: 28px;
					width: 28px;
				}
			}
			&.selected{
				border: 2px solid #000;

				img{
					height: 30px;
					width: 30px;
					//border: 1px solid #fff;
					@include respond(large) {
						height: 28px;
						width: 28px;
					}
				}
			}
		}
		.selected img, img:hover {
			border-color: $black;
		}
	}
	.product-promo {
		color: $red;
		@include font-size(14px);
		margin: 0;
		@include respond('small') {
			@include font-size(12px);
		}
	}
	.product-compare {
		float: left;
		line-height: 11px;
		label {
			font-weight: 400;
			line-height: 1.25em;
			width: auto;
		}
		input {
			float: left;
			margin: 0;
		}
	}
	.product-review {
		margin-bottom: 1em;
		.rating-title {
			display: inline-block;
			margin-right: 1em;
		}
		.rating {
			display: inline-block;
		}
	}
	//PowerReview styles
	#reviewstarratings, .reviewstarratings {
		.prSnippetNumberOfRatingsText {
			@include font-size(12px);
			position: relative;
			top: -3px;
		}
	}
}

.capture-product-id {
	left: -999em;
	position: absolute;
}

@include respond (large){
	#quickviewbutton {
		display: none;
	}
}
@include respond ('small'){
	#quickviewbutton {
		display: none;
	}
}

//just in case, allow swatch dropdowns within a slick carousel break the height, so they are visible.
li.slick-slide .product-tile {
	.product-swatches.expanded {
		position: static;
	}
}
