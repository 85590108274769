/////////////////////////
// Checkout Shipping Step
/////////////////////////
#wrapper.pt_checkout{
	#main {
		.checkout-name {
			font-size: 22px;
			line-height: 36px;
			font-weight: 400;
			margin: 25px 0;
			text-transform: none;
		}
        .top-banner .menu-utility-user {
            width: 65px;
            float: right;
        }
	}
    #primary {        
        .form-caption,
        .form-row .form-field-tooltip a {
            color: $darkgrey !important;
            font-size: 12px !important;
        }
        .form-row.label-inline.form-indent label,
        .checkout-shipping .form-row.label-inline label span,
        .checkout-shipping .form-caption {
                color: $darkgrey;
        }
        .checkout-shipping.no-multi-country .selected-country {
            font-size: 12px !important;
        }
    }
}
#wrapper.pt_order-confirmation #main .actions .button {
    line-height: 40px;
    @include respond(largeUp) {
        line-height: 48px;
    }
}

#wrapper.pt_checkout #main {
    #primary .fieldset.billing-address-static,
    #secondary .mini-shipment .details div,
    #secondary .mini-shipment .details .minishipments-method span {
        color: $darkgrey;
    }
} 

.address {
    input[name$="_address1"] {
        & + .melissa-address {
            position: relative;
            height: 0;
            width: 100%;
            @media screen and (min-width: 880px) {
                width: 340px;
            }
            .melissa-address-list {
                list-style: none;
                padding: 0;
                margin: 0;
                position: absolute;
                top: -2px;
                left: 0;
                z-index: 10;
                background-color: $gray-white;
                border: 1px solid $black;
                width: 100%;
                box-sizing: border-box;
                list-style-type: none;
                padding: unset;
                padding-left: 12px;
                line-height: 22px;
                cursor: pointer;
                font-size: 14px;
            }
            .melissa-address-list-item {
                line-height: 20px;
                cursor: pointer;
                &:hover ,
                &:focus {
                    background-color: #3d91ff;
                    color: $white;
                }
            }
        }
    }
}

#wrapper.pt_checkout #main #secondary .checkout-mini-cart .mini-cart-attributes {
    .value,
    .label {
        font-size: 13px;
        line-height: 20px;
    }
}

#wrapper.pt_checkout #main #primary .continue-btn .form-row.form-row-button button {
    line-height: 41px;
}