.flag-icon {
  background-color: red;
  position: relative;
  display: inline-block;
  width: (4 / 3) * 1em;
  line-height: 1em; 
  &:before {
    content: ' ';//Ben: this wass killing the sourcemap build
  }
  &.flag-icon-squared {
    width: 1em;
  }
}