#wrapper {
	&.home-page {
		#main {
			@include respond(small) {
				margin-top: 4.6rem;
			}
		    margin-top: 86px;
		}
	}
}

