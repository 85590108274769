#wrapper.pt_checkout {
    #main {
        #primary {
            .checkout-shipping.no-multi-country {
                .selected-country {
                    font-size: 11px;
                    line-height: 34px;
                }
            }
        }
    }
}