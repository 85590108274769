.bottom-banner-container {
	overflow: hidden;
	.bottom-banner-cell {
		float: left;
		overflow: hidden;
		text-align: center;
	}
	.banner-cell-1 {
		width: 33%;
	}
	.banner-cell-2 {
		width: 34%;
	}
	.banner-cell-3 {
		width: 33%;
	}
}
/* CLP Top Banner */
#wrapper.pt_categorylanding {
	#main{
		@include respond(large){
			box-sizing: border-box;
			padding-left: 15px;
			padding-right: 15px;
		}
	
	/* Secondary Content */
	#secondary {
		&.sidebar-menu {
			/* Menu */
			box-sizing: border-box;
			padding: 16px 38px 0 0;
			@include respond(large){
				padding-right: 0;
				width: 130px;
				padding-top: 25px; 
			}
			@include respond(small){
				padding: 0 15px 20px 15px; 
			}
			h5 {
				color: $gray-dark;
				font-size: 20px;
				font-family: $oswald;
				font-weight: bold;
				margin: 0;
				padding: 0 0 10px; 
				text-transform: uppercase;
				@include respond(small){
					background: $black;
					border-radius: 17px;
					color: $white;
					font-size: 12px;
					line-height: 34px;
					margin: 10px 0 0;
					padding: 0 15px 0 18px;
					margin-bottom: 20px;
					&:after {
						content: ">";
						float: right;
						font-size: 30px;
						font-weight: normal;
						line-height: 26px;
					}
					&.open:after {
						-ms-transform: rotate(90deg); /* IE 9 */
					    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
					    transform: rotate(90deg);	
					    -webkit-transition-duration: 0.5s;
						-moz-transition-duration: 0.5s;
						-o-transition-duration: 0.5s;
						 transition-duration: 0.5s;
						 line-height: 36px;
				    }
				}
			}
			ul {
				border-top: 2px $clp-border solid; 
				font-family: $titillium;
				margin: 0;
				padding: 0;
				text-transform: uppercase;
				
				@include respond(small){
					display: none;
					margin-bottom: 20px;
					&.expanded {
						display: block;
					}
				}
				li {
					border-bottom: 2px $clp-border solid;
					display: block;
					font-size: 14px;
					float: none;
					list-style: none;
					line-height: 30px;
					a {
						color: $gray-warm;
						display: inline;
						&:hover {
							color: $black;
							text-decoration: underline;
						}
					} 
				}
			}
		}
	}
	/* Primary Content */
	.primary-content {
		box-sizing: border-box; 
		padding: 22px 0 0; 
		@include respond(small){
			padding: 0 15px 0;
		}
		/* Common styles */
		p {
			margin: 0;
			padding: 0;
		}
		img {
			display: block;
			max-width: 100%;
			padding: 0;
		}
		/* Top Banner */
		.top-banner {
			border: 0;
			margin: 0 0 20px;
			position: relative;
			@include respond(small){
				margin: 0 0 10px;
			}
			.title {
				color: $white;
				font-size: 30px;
				font-family: $oswald;
				font-weight: bold;
				display: inline-block;
				left: 40px;
				position: absolute;
				top: 45.5%; 
				text-transform: uppercase;
				@include respond(small){
					color: $black;
					font-size: 20px;
					left: 0;
					padding: 10px 0 0;
					position: relative;
					text-align: center;
					top: 0;
				}
			}
		}
		/* Widgets */
		.widgets {
			@extend %clearfix;
			.widget {
				float: left;
				text-align: center;
				width: 33.33%;
				&:first-child {
					text-align: left;
					img {
						margin: 0;
					}
				}
				&:last-child {
					text-align: right;
					img {
						margin: 0;
					}
				}
				@include respond(small){
					float: none;
					width: 100%;
					&:first-child,
					&:last-child {
						text-align: center;
					}
				}
				img {
					display: inline-block;
					max-width: 100%;
					margin: 0 auto;
				}
				.title {
					color: $gray-dark;
					display: block;
					font-size: 14px;
					font-family: $titillium;
					font-weight: bold;
					margin: 0;
					padding: 5px 0;
					text-transform: uppercase;
					text-align: center;
					a {
						color: $gray-dark;
						&:hover {
							color: $black;
							text-decoration: underline;
						} 
					}
					@include respond(small){
						padding: 0 0 10px;
					}
				}
			}
		}
		/* Featured Products */
		.featured-products {
			width: 90%;
			@extend %clearfix;
			border-top: 2px $clp-border solid;
			margin: 30px auto 0;
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				li{
					.product-image{
						float: left;
						width: 100%;
						text-align: center;
						
						a.thumb-link{
							display: inline-block;
							@include respond(large){
								width: 87%;
							}
						}
					}
				}
				.product-tile {
					@include respond(small){
						padding: 0;
					}
				}
				.slick-arrow {
					top: 50%;
					height: 30px;
					width: 19px;
					@include respond(small){
						top: 125px;
					}

					&.slick-prev {
						background: url("../images/featured-prev.png") center center no-repeat;
						left: -4%;
					}
					&.slick-next {
						background: url("../images/featured-next.png") center center no-repeat;
						right: -4%;
					}
				}
			}
			h2 {
				color: $gray-dark;
				font-size: 30px;
				margin: 0;
				padding: 30px 0;
				text-align: center;
				text-transform: uppercase;
				@include respond(small){
					font-size: 20px;
				}
			}
		}
	}
}
}