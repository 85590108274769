///////////////////////////////////
// Global content helper classes //
///////////////////////////////////

.mobile-no-line-breaks {
	br {display: none;}
	@include respond(largeUp){
		br {display: initial;}
	}
}

.desktop-no-line-breaks {
	@include respond(largeUp){
		br {display: none;}
	}
}

.tablet-no-line-breaks {
	@include respond(large){
		br {display: none;}
	}
}

.text-shadow, .text-shadow-dark {
	text-shadow: 0 0 10px rgba($black, 0.5);
}

.text-shadow-light {
	text-shadow: 0 0 10px rgba($white, 0.5);
}

.mobile-text-shadow, .mobile-text-shadow-dark {
	@include respond(small){
		text-shadow: 0 0 10px rgba($black, 0.5);
	}
}

.mobile-text-shadow-light {
	@include respond(small){
		text-shadow: 0 0 10px rgba($white, 0.5);
	}
}

.desktop-text-shadow, .desktop-text-shadow-dark {
	@include respond(largeUp){
		text-shadow: 0 0 10px rgba($black, 0.5);
	}
}

.desktop-text-shadow-light {
	@include respond(largeUp){
		text-shadow: 0 0 10px rgba($white, 0.5);
    }
}

.tablet-text-shadow, .tablet-text-shadow-dark {
	@include respond(large){
		text-shadow: 0 0 10px rgba($black, 0.5);
	}
}

.tablet-text-shadow-light {
	@include respond(large){
		text-shadow: 0 0 10px rgba($white, 0.5);
	}
}

.content-slot img {
	height: auto;
	max-width: 100%;
}

.slot-grid-header p {
	margin: 0;
	padding: 0;
}

.product-listing-1x4 {
	background: none repeat scroll 0 0 $white;
	overflow: hidden;
	padding: .83em 0;

	.reviewstarratings img {
		display: inline-block;
	}
}

.account-nav-asset {
	line-height: 1.5em;
}

.content-asset {
	p {
		line-height: 1.6em;
		margin: 1em 0;
	}
	ul,
	ol {
		line-height: 1.6em;
		margin: 1em 0;
		padding: 0 1.5em;
	}
	address {
		margin: 1em 0;
	}
	ol li {
		list-style: decimal outside none;
	}
	.account-options {
		margin: 0;
		padding: 0;
		li {
			border-bottom: 1px solid $gray-light;
			float: left;
			height: 80px;
			list-style: none outside none !important;
			margin: 0 0 0 4%;
			padding: 1.66em 0 0;
			position: relative;
			width: 46%;
			a {
				display: block;
				font-size: 1.5em;
				line-height: 2.5em;
				padding: 0 0 0 4.58em;
			}
			img {
				left: 0;
				position: absolute;
				top: .83em;
			}
			p {
				margin: 0;
				padding-left: 4.58em;
			}
		}
	}
}

.browser-compatibility-alert {
	background-color: $red;
	color: $white;
	font-size: 1.3em;
	line-height: 2em;
	padding: 0.8em;
}

.folder-slot {
	border-style: solid;
	border-color:  $green;
	border-width: 1px;
	background-color: $white;
	padding: 0.5em 0.5em;

	h2{
		color: 	$green;
		margin: 0.5em 0em;
	}
}

.menu-slot {
	display: none;
	border-top: 1px solid $green;
	margin-top: 0.5em;
	padding-top: 1.5em;
	.social-links {
		padding-left: 0;
		li {
			display: inline-block;
			padding-left: 0;
		}
	}
	@include respond(largeUp) {
		width: 13.5rem;
		display: block;
	}
}
#wrapper.pt_content {
	#primary {
		.content-asset {
			h1 {
				font-size: 30px;
				line-height: 36px;
				padding-bottom: 20px;
				color: $gray-dark;
				font-weight: bold;
				margin: 0;
				text-transform: uppercase;
			}
			h2 {
				font-size: 20px;
				line-height: 24px;
				padding: 0;
				color: $gray-dark;
				margin: 0;
			}
			h3 {
				font-size: 14px;
				line-height: 20px;
				padding: 0;
				color: $gray-dark;
				margin: 0;
				text-transform: uppercase;
				font-family: $titillium;
			}
			p {
				font-size: 14px;
				line-height: 20px;
				color: $gray-warm;
				padding-bottom: 20px;
				margin: 0;

				&.divider {
					border-bottom: 1px solid $clp-border;
					margin-bottom: 27px;
					padding-bottom: 27px;
				}
			}
			ul {
				li {
					color: $gray-warm;
					font-size: 14px;
				}
			}
		}
	}
}

.videoOverlay {
	position: absolute;
}

.youtube-modal-dialog.ui-dialog {
	padding: 0;
	background-color: $black;

	.ui-widget-header {
		background-color: $black;

		.ui-dialog-title {
			display: none;
		}

		.ui-button {
			margin-right: 0.5rem !important; //had to use important tag to override jquery's important tag

			&:before {
				content: "\2715";
				font-size: 1.5em;
				color: white;
			}

			&:hover {
				background-color: transparent;
			}

			.ui-button-icon-primary {
				display: none;
			}
		}
	}

	.ui-dialog-content {
		padding: 0 !important; //had to use important tag to override jquery's important tag
	}
}

.hide-in-mobile {
	/* hide below 768 */
	@include respond(small) {
		display: none;
	}
}

.hide-in-tablet {
	/* hide between 768 and 1999 */
	@include respond(large) {
		display: none;
	}
}

.hide-in-desktop {
	/* hide at 1200 and up */
	@include respond(standard) {
		display: none;
	}
}
