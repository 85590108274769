/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    & img.slick-loading {
        display: none;
    }
	&.slick-loading img {
		display: none;
	}

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow {
    &.slick-hidden {
        display: none;
    }
    &.fj-rp-slider-arrow {
        border: 0 none;
        background-color: transparent;
    }
}

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */
.slick-prev,
.slick-next {
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 38%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
    display: inline-block;
    width: 50px;
    height: 100px;
    text-indent: -999em;
    position: absolute;
    opacity: 0.75;
    z-index: 1;

    &:hover, &:focus {
        opacity: 1;
        background-color: transparent !important;
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    @include respond(small){
        display: none;
    }
    @include respond(large){
        display: none;
    }
}

.slick-prev:before, .slick-next:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    background-image: url('../images/carouselLeft.png');
    left: 0px;
}

.slick-next {
    background-image: url('../images/carouselRight.png');
    right: 0px;
}

/* Dots */

.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
        position: absolute;
        bottom: 0.5vw;
        left: 0px;
        width: 100%;
        text-align: center;
        padding: 0;
        @include respond(small){
            position: initial;
            top: initial;
        }

        li {
            display: inline-block;
            zoom: 1;
            transition: all $quickSpeed;

            button {
                box-sizing: border-box;
                display: block;
                border: 2px solid #ccc;
                background-color: transparent;
                width: 40px;
                height: 8px;
                border-radius: 4px;
                margin: 0 5px;
                opacity: 0.75;
                transition: all $quickSpeed;
                padding: 0;
                margin: 0 5px;
                text-indent: -9999em;
            }
            &.slick-active button {
                opacity: 0.9;
                background-color: #ccc;
            }
        }
 }
