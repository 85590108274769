#pdpMain {
    .product-detail {
        .custom-select-field {
            margin: 0;
        }
    }
    .product-main-info {
        border-bottom: 1px solid $grey-label;
        max-width: 1350px;
        padding: 0 35px 35px;
        width: calc(100% - 70px);

        @include respond(xlUp) {
            padding: 0;
            width: 100%;
        }

        .product-top-data {
            padding: 0;
            margin: 0;
        }
    }

    .product-col-1 {
        float: none;
        padding: 0;

        @include respond(small) {
            margin-top: 0;
        }

        &.product-image-container {
            padding: 0;
            width: 100%;
            max-width: 100%;
            @include respond(largeUp) {
                padding-right: 44px;
            }
        }

        .product-image-badge {
            position: relative;
            display: flex;
        }

        .product-images-thumbnails {

            @include respond(largeUp) {
                display: flex;
                flex-direction: row-reverse;
                justify-content: start;
                gap: 22px;
            }
        }

        .featureIcons {
            display: flex;
            justify-content: space-between;
            margin: 34px 0;
            width: 100%;
            gap: 10px;

            @include respond(largeUp) {
                width: calc(100% - 40px);
            }

            div {
                max-width: 38px;
                width: 100%;
                text-align: center;

                @include respond(largeUp) {
                    max-width: 140px;
                }

                img {
                    max-width: 87px;
                    width: 100%;
                }

                p {
                    display: none;

                    @include respond(largeUp) {
                        display: block;
                        color: black;
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
        }

        .product-image-badge {

            @include respond(largeUp) {
                max-width: 540px;
                width: 100%;

            }
        }

        .product-primary-image {
            max-width: 540px;
            @include respond(small) {
                max-width: 100%;
            }

            .regular.slider {
                background-color: $grey-inactive;
                margin-bottom: 21px;

                @include respond(largeUp) {
                    max-height: 540px;
                    max-width: 540px;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    aspect-ratio: unset                                                                                                                                                                                             ;
                }

                .slick-list,
                .slick-track {
                    height: 100%;
                }

                .primary-image {
                    object-fit: contain;
                    padding: 0;
                    max-width: 100%;
                    @include respond(largeUp) {
                        height: 100%;
                        max-height: 540px;
                    }
                }

                .slick-arrow {
                    border: none;
                    background-size: 20px 20px;
                }

                .slick-prev {
                    left: 0;
                }

                .slick-next {
                    right: 0;
                }
            }
        }
    }

    .product-col-2 {
        padding: 0;
        margin: 10px 0 0;
        width: 100%;

        @include respond(largeUp) {
            max-width: 648px;
            margin: 0;
        }

        .select-header {
            font-size: 15px;
            padding: 0 0 20px 0;
            margin: 0;

            @include respond(largeUp) {
                font-size: 15px;
                padding: 0 0 40px 0;
            }
        }

        .selected-value {
            font-size: 15px;
            font-weight: 700;
            left: 0;
            right: unset;
            top: 15px;
            margin: 0;

            @include respond(largeUp) {
                font-size: 15px;
                top: 20px;
            }
        }

        .swatches {
            display: flex;
            align-items: center;
            gap: 11px;
            flex-wrap: wrap;
            margin: 11px 0 30px 0;

            @include respond(small) {
                width: calc(100% + 10px);
                gap: 0;
                margin: 10px 0 4px 0;
            }

            li {
                @include respond(small) {
                    margin: 0 8px 10px 0;
                }

                &.selected-value {
                    @include respond(small) {
                        margin: 0;
                    }
                }
            }

            .selectable {
                border-radius: 100%;
                height: 54px;
                width: 54px;
                margin: 0;
                padding: 0;
                position: relative;

                @include respond(small) {
                    margin: 0 8px 10px 0;
                }

                &.selected,
                &:hover {
                    border: 1px solid black;
                    height: 52px;
                    width: 52px;
                }

                .swatchanchor {
                    height: 36px !important;
                    width: 36px !important;
                    border: none;
                    top: 50%;
                    left: 50%;
                    padding: 0;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    background-color: $grey-inactive;

                    @include respond(largeUp) {
                        height: 60px;
                        width: 60px;
                    }
                }

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }



        #product-content {
            .product-variations {
                padding: 0;
            }

            .product-top-data {
                padding: 0;
                margin: 0;
            }

            .pdpForm {
                float: none;
                padding: 0;
            }

            .embroidery-wrap {
                padding: 20px 0 0 0;

                label {
                    margin: 0;
                }

                input[type="checkbox"] + label {
                    background: none;
                    padding: 0;
                    color: black;
                    font-size: 14px;
                    font-weight: 400;
                    text-decoration: underline;

                    @include respond(largeUp) {
                        font-size: 18px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .pdp-promo-container {
                color: black;
                font-size: 14px;
                text-align: left;
                padding: 0;
                margin: 0;

                @include respond(largeUp) {
                    font-size: 18px;
                }

                a {
                    text-decoration: underline;
                }

                .pdp-shipping-promo {
                    padding: 8px 0 0 0;
                }

                &.no-promos {
                    display: block;
                }
            }

            .product-add-to-cart {
                padding: 0;

                .add-to-cart {
                    border-radius: 0;
                    background-color: $black;
                    font-size: 16px;
                    @include respond(small) {
                        font-size: 16px;
                        height: 33px;
                        line-height: 33px;
                        padding: 0;
                    }
                }
            }

            .product-info-tabs-accordion {
                margin: 25px auto 0 auto;

                @include respond(largeUp) {
                    margin: 8px auto 20px auto;
                }

                .tabs {
                    display: none;
                    border-bottom: 1px solid $grey-inactive;

                    @include respond(largeUp) {
                        display: flex;
                    }

                    button {
                        align-self: baseline;
                        background: none;
                        border-radius: 0;
                        border: none;
                        color: black;
                        cursor: pointer;
                        margin: 0 15px 0 0;
                        padding: 0;
                        transition: background 0.3s;
                        width: auto;
                        font-size: 14px;
                        @include respond(largeUp) {
                            height: 37px;
                            line-height: 37px;
                        }

                        &.active {
                            border-bottom: 5px solid $grey-label;
                        }
                    }
                }

                .content-wrapper {
                    .content {
                        border-top: none;
                        display: block;
                        opacity: 1;
                        transition: opacity 0.3s ease, transform 0.3s ease;

                        @include respond(largeUp) {
                            display: none;
                            opacity: 0;
                            padding: 15px 0 0;
                            transform: translateY(10px);
                        }

                        &.active {
                            display: block;
                            opacity: 1;
                            transform: translateY(0);

                            .accordion-content {
                                display: block;
                            }
                        }
                    }
                }

                .accordion-header {
                    border-bottom: 1px solid black;
                    border-top: 1px solid black;
                    color: black;
                    cursor: pointer;
                    display: block;
                    font-size: 14px;
                    letter-spacing: 0;
                    margin: -1px 0 0 0;
                    padding: 8px 0;
                    position: relative;
                    text-transform: uppercase;

                    @include respond(small) {
                        font-size: 10px;
                    }

                    &::after {
                        content: '+';
                        display: block;
                        font-size: 18px;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &[aria-expanded="true"]::after {
                        content: '-';
                    }

                    @include respond(largeUp) {
                        display: none;
                    }
                }

                .accordion-content {
                    border-top: none;
                    padding: 10px;
                    color: black;
                    display: none;
                    font-size: 16px;

                    @include respond(largeUp) {
                        padding: 0;
                    }

                    * {
                        font-size: 16px;
                    }

                    h3 {
                        color: black;
                        font-size: 16px;
                        font-weight: 700;
                        letter-spacing: normal;
                        margin: 0 0 10px 0;
                        text-transform: uppercase;

                        @include respond(largeUp) {
                            font-size: 14px;
                        }
                    }

                    li {
                        list-style: disc;
                        margin: 0 0 5px 20px;
                    }

                    p {
                        margin: 0 0 5px 0;
                    }
                }
            }

            .product-compare-link {
                padding-bottom: 10px;
                @include respond(small) {
                    padding-bottom: 0;
                }

                a {
                    font-size: 10px;
                    text-transform: uppercase;
                    position: relative;
                    line-height: 14px;
                    padding-left: 22px;
                    @include respond(largeUp) {
                        font-size: 14px;
                    }

                    @include respond(small) {
                        position: relative;
                        top: 5px;
                    }

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 14px;
                        height: 14px;
                        border: 1px solid $black;

                        @include respond(small) {
                            top: -2px;
                        }
                    }
                }
            }
        }
    }

    .thumbnails-container {
        position: static;
    }

    #thumbnails {
        display: block;
        margin: 0;
        padding: 0;
        float: none;
        width: 100%;
        height: 100%;

        @include respond(largeUp) {
            width: 91px;
            min-height: 540px;
            max-height: 540px;
        }

        .carousel {
            display: flex;
            flex-direction: column;
            height: auto;
            width: 100%;

            @include respond(largeUp) {
                display: block;
                min-height: 560px;
            }

            .slick-list {
                @include respond(largeUp) {
                    min-height: 540px;
                }
            }

            &.slick-vertical {

                .slick-prev {
                    top: -15px;
                    left: 36px;
                    transform: rotate(90deg);
                }

                .slick-next {
                    bottom: -10px;
                    top: unset;
                    left: 36px;
                    transform: rotate(-90deg);
                }

                .slick-slide {
                    margin-bottom: 21px;
                    margin-right: 0;
                }
            }

            .slick-slide {
                margin-right: 8px;
            }

            .slick-arrow {
                top: 32px;

                &:hover,
                &:focus {
                    border: none;
                }
            }

            .is-active {
                .thumbnail-link {
                    &::before {
                        display: block;
                    }
                }
            }
        }

        .thumbnail-link {
            width: 100%;

            &::after {
                background-color: $dustygray;
                height: 7px;
                width: calc(100% - 20px);
                @include respond(small) {
                    width: calc(100% - 15px);
                    height: 5px;
                }
            }

            &::before {
                content: '';
                height: 5px;
                width: 20px;
                position: absolute;
                bottom: 0;
                right: 0;
                border: 1px solid $dustygray;
                background: $white;
                display: none;

                @include respond(small) {
                    width: 15px;
                    height: 3px;
                }
            }

            .productthumbnail {
                background-color: $grey-inactive;
                width: 74px;
                height: auto;
                margin: auto;

                @include respond(largeUp) {
                    width: 91px;
                }
            }
        }
    }

    #product-content {
        width: 100%;
    }

    #reviewssnippet {
        height: auto !important;

        .pr-snippet,
        .pr-snippet-read-and-write {
            display: flex;
            gap: 20px;
            align-items: center;
            margin: 0;

            @include respond(largeUp) {
                gap: 30px;
            }

            .pr-snippet-stars-container {
                margin: 0;
                position: relative;
                top: -3px;
            }

            .pr-snippet-stars {
                margin: 0;

                .pr-rating-stars {
                    white-space: nowrap;
                }

                .pr-star-v4 {
                    height: 9px;
                    width: 9px;

                    @include respond(largeUp) {
                        height: 11px;
                        width: 11px;
                    }
                }
            }

            a {
                font-family: $neuzeit;
                font-size: 10px !important;
                border: none;
                padding: 0;
                margin: 0;
                text-transform: capitalize;
                line-height: normal;
                font-weight: 700;

                @include respond(largeUp) {
                    font-size: 16px !important;
                }
            }
        }
    }

    .product-name {
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 14px 0;
        text-transform: uppercase !important;
    }

    .product-price {
        * {
            font-size: 20px;
        }
    }

    .pdp-feature-icons {
        display: none;
        padding: 30px 0 0 0;
        justify-content: center;

        @include respond(largeUp) {
            padding: 20px 0 48px 111px;
            max-width: 540px;
        }

        &.desktop-show {
            @include respond(largeUp) {
                display: flex;
            }
        }

        &.mobile-show {
            @include respond(small) {
                display: flex;
            }
        }

        div {
            width: 20%;
            text-align: center;

            img {
                max-width: 36px;
                max-height: 36px;
                width: 100%;
                height: 100%;
                object-fit: contain;
                aspect-ratio: 1 / 1;
                display: block;
                margin: 0 auto;

                @include respond(largeUp) {
                    max-width: 48px;
                    max-height: 48px;
                }
            }

            p {
                margin: 0;
                display: none;
                @include respond(largeUp) {
                    padding-top: 20px;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $black;
                }
            }
        }
    }

    .pdp-page-designer-bottom {
        max-width: 1350px;
        margin: 0 auto;
    }

    .cart-buttons-container {
        flex-direction: column;
        .cart-button,
        .apple-pay-button {
            width: 100%;
        }

        .cart-button {
            margin: 0;
        }
    }

    #configurator-cta {
        span {
            text-transform: uppercase;
            font-size: 16px;
        }
    }

    .product-info {
        width: 100%;
        max-width: 1351px;
    }

    .pdp-explore {
        .tlt-asset-grid {
            margin: 0;
            font-size: 22px;
            text-transform: uppercase;
            @include respond(small) {
                text-align: center;
                padding-top: 20px;
                font-size: 16px;
            }
        }
    }

    .apple-pay-button {
        width: 100% !important;
        padding-top: 10px !important;
        display: block !important;
        margin: 0 !important;
    }

    .notify-me-link-wrapper {
        padding: 20px 0 0 0;
    }

    .promotion.promotion-notempty {
        background-color: transparent !important;
    }

    .pdp-expand-wrapper {
        border: 0;
    }
}

.pdp-secondaryContent,
.pdp-page-designer-bottom {
    .richtext {
        @include respond(largeUp) {
            max-width: 90%;
            margin: 0 auto;
        }

        h3,
        h2,
        h4 {
            font-family: $eurostile;
            text-align: left;
            margin: 0;
            font-size: 32px;
            line-height: 32px;
            text-transform: uppercase;
            font-weight: 700;

            @include respond(small) {
                text-align: center;
                padding-top: 20px;
            }
        }
    }
}
