#secondary {
	a {
		color: $gray-warm;
		font-size:14.4px;
		line-height: 24px;
		text-transform: $linkCase;
	}
	.refinement.size a {
		@include font-size(11px);
	}
}
.refinements {

	.color {

		.color-selection{
			float: left;
			margin-bottom: 1rem;
			.selected {
				border: none;
				width: 1.55rem;
				float: left;
				border-radius: 3rem;
				height: 1.55rem;
				margin-right: 11px;
				margin-bottom: 9px;
				a {
					font-size: 0px !important;
					padding: 13px;
					border-radius: 3rem;
					border: 2px solid $gray-dark;
				}
			}
			.color-selection-value {
				border: none;
				width: 1.55rem;
				float: left;
				border-radius: 3rem;
				height: 1.55rem;
				margin-right: 11px;
				margin-bottom: 9px;
				a {
					font-size: 0px !important;
					padding: 13px;
					border-radius: 3rem;
					border: 2px solid $gray-light;
				}
			}
		}
	}
}


#category-level-1 {
	li {
		color: $gray-dark;
		display: block;
		padding-bottom: 0.3125rem;
		a.refinement-link {
			display: block;
			color: $gray-warm;
			font-size:19.2px;
			font-weight:200;
			&.active {
				color: $black !important;
				font-weight: 700;
				text-decoration: underline;
			}
		}
	}
}

#category-level-2 {
	a {
		&.active {
			color: $black !important;
			font-weight: 700;
			text-decoration: underline;
		}
		&.refinement-link {
			margin: 0 0 0 .4em;
			padding: 0;
		}
	}
}
#category-level-2 {
	padding: .5em .1em 0 .7em;
}
#category-level-3 {
	padding: 0;
	a {
		&.refinement-link {
			font-style: normal;
			margin: 0;
			padding: 0;
		}
		&.active {}
	}
}

.refinement {
	position: relative;
}

.refinement-header-wrapper {
	margin: 0 rem(20px);
	position: relative;
	@include respond(largeUp) {
		margin: 0;
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: baseline;
	}
	.refinement-header {
		display: block;
		font-size: 22px;
		text-transform: uppercase;
		font-family: $din-condensed;
		font-weight: normal;
		margin: 0;
		padding-bottom: .5em;
		padding-right: 70px;
		border-bottom: 0.1667rem solid $gray-light;
		letter-spacing: 0;
		@include respond(largeUp) {
			font-size: 30px;
			margin-right: rem(16px);
			margin-left: rem(24px);
			padding-right: 0;
			letter-spacing: .075rem;
		}
		&.mobile-search-header {
			display: none;
			@include respond(small) {
				display: block;
				border: 0;
				margin-bottom: 0.75rem;
				padding: 0 1.25rem;
			}
		}
		&.on-top {
			border-bottom: none;
		}
	}
	.breadcrumb {
		padding: 0;
		margin-bottom: 0;
		@include respond(small) {
			margin-bottom: 2px;
		}
	}
	.results-count {
		position: absolute;
		right: 0;
		bottom: 16px;
		font-size: 13px;
		color: $gray-warm;
		text-transform: uppercase;
	}
}

.search-result-options{
	@include respond('small') {
		.sort-by{
			#grid-sort-header{
				height: 34px;
				float: right;
				width: 70%;
				background-position: right 10px center;
				padding-left: 12px;
			}
		}
	}
}

#refined-by-attributes {
	padding-bottom: 1.438rem;
	border-bottom: 0.1667rem solid $gray-light;
	h3 {
		@include font-size(12px);
		margin: 0.625rem 0;
		text-transform: uppercase;
	}
}
.selected-refinement {
	display: block;
	width: 100%;
	a {
		background: url('../images/interface/remove-refinement.svg') no-repeat;
		display: inline-block;
		float: right;
		margin-top: 0.25rem;
		height: 1rem;
		width:1rem;
	}
}

.selected-refinement-value {
	color: $gray-warm;
	display: inline-block;
	@include font-size(11px);
	width: 100%;

}

.clear-all-refinements a {
	color: $gray-dark !important;
	display: inline-block;
	@include font-size(12px);
	padding-top: 0.5rem;
	text-decoration: underline;
}

.refinement {
	border-bottom: 0.1667rem solid $gray-light;
	float: left;
	width: 100%;
	//padding-bottom: 1.438rem;
	h3 {
		font-size:16px;
 		line-height: 16px;
		margin-top: 0.25re,;
		padding: 0;
		text-transform: uppercase;
		font-family: $titillium;
		letter-spacing: 0;
		&.toggle {
			i {
				background: url('../images/interface/arrow-single.png') no-repeat;
				display: inline-block;
				float: right;
				margin-top: 0.25rem;
				height: 1rem;
				width:1rem;
				transition: all $quickSpeed;
			}
			&.expanded {
				i {
					transform: rotate(90deg);
					transition: all $quickSpeed;
				}
			}
		}

		.ie7 & {
			clear: both;
		}
	}
	&.category-refinement {
		padding-bottom: 1.2rem;
		h3 {
			display:none;
		}
		ul {
			padding: 1.167rem 0 0;
			transition: all 0.5s;
		}
	}
	ul {
		list-style: none outside none;
		margin: 0;
		padding: 0 0 10px 0;
		width: 100%;
		float: left;
		transition: all $quickSpeed;
		li {
			float: left;
			width: 100%;
			i {
				color: $gray-light;
			}
			&.selected {
				i {
					color: $green;
				}
			}
		}

		&.swatches {
			float: none;
		}
		&.swatches li {
			float: left;
			margin: 0 7px 7px 0;
			width: auto;
			font-size: 11.2px;
			@include font-small;
			@include respond(largeUp) {
				margin: 0 6px 6px 0;
			}
			a {
				border: 0.1667rem solid $gray-light;
				border-radius: 50%;
				display: block;
				@include font-size(9.5px);
				height: 25px;
				line-height: 25px;
				text-align: center;
				width: 25px;
				@include respond(largeUp) {
					width: 21px;
					height: 21px;
				}
			}
			&.selected {
				width: initial;
			}
			&.selected a {
				border-color: $black;
				font-weight: bold;
			}
			&.unselectable a {
				background-image: url("../images/interface/icon-color-swatch-unselectable.png");
				background-position: right top;
			}
		}
		&.refinementColor li a {
			padding: 0;
			text-indent: -999em;
		}
	}
	.clear-refinement {
		display: none;
		text-decoration: underline;
		@include font-size(12px);
		padding-top: 1.25rem;
		text-transform: uppercase;
		padding-bottom: 10px;
		a{
			text-decoration: underline;
			color: #999;
			&:hover{
				text-decoration: none;
			}
		}
	}
	.refinement-link {
		border: 0 none;
		.selected-indicator {
			background: url('../images/interface/checkbox-unchecked.svg') no-repeat;
			display: inline-block;
			float: left;
			height: 1rem;
			margin: 5px 5px 0 0 ;
			width: 1rem;
		}
	}
	.selected .refinement-link {
		background-position: left bottom;
		.selected-indicator {
			background: url('../images/interface/checkbox-checked.svg') no-repeat;
		}
	}
	.refinement-top-level {
		padding: 1em;
	}
}

.scrollable {
	height: 10em;
	overflow: auto;
}

.nav {
	ul {
		border: 0 none;
		list-style: none outside none;
		margin: .58em 0;
		padding: 0;
		li {
			list-style: none outside none !important;
		}
	}
	.toggle {
		display: block;
		margin: 0;
		padding: 0;
		@include font-size(14px);
		line-height: 2em;
		text-transform: uppercase;
		background: #fff url(../images/arrow-right-black.png) no-repeat calc(100% - 9px) center;
		@include respond(small){
			padding: .5em .25em;
		}
	}
	.expanded {
		background: #fff url(../images/selectArrowActive.png) no-repeat calc(100% - 9px) center;
	}
}

.price-refinements {
	li {
		float: left;
		width: 100%;
	}
}





