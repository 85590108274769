.js {
	.loader {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	.loader-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $white;
		opacity: 0.7;
	}
	.loader-indicator {
		z-index: 100;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 64px;
		height: 64px;
		background: url("../images/loading-small.gif") no-repeat;
	}
}
