#wrapper.pt_productregistration {

    #main {
        margin-bottom: 104px;
        border: none;
        @include respond(largeUp) {
            margin-top: 144px;
        }
    }

    #primary {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        @include respond(largeUp) {
            width: 84%;
            padding: 0 0 0 40px;
        }
        @include respond(large) {
            width: 74%;
            padding: 0 0 0 40px;
        }


        .section-title {
            margin: 22px 0 26px;
            color: $dark-navy;
            font-size: 30px;
            line-height: 1.75rem;
            letter-spacing: 0;
            @include respond(largeUp) {
                margin: 15px 0 25px;
            }
            + .error-form {
                padding: .8em 0;
            }
        }

        .section-details {
            display: block;
            margin-bottom: 28px;
            font-family: $neuzeit;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            letter-spacing: 0;
            text-transform: none;
            color: $gray-medium2;
            @include respond(largeUp) {
                margin-bottom: 50px;
            }
        }

        #product-register-forms {
            max-width: 520px;
        }

        .btn-box {
            margin-top: 9px;
            max-width: 520px;
            button {
                height: 46px;
                text-align: center;
                font-size: 20px;
                letter-spacing: 0;
                @include respond(largeUp) {
                    margin-top: 27px;
                    margin-left: 33.5%;
                }
            }
        }

        .form-row {
            margin-bottom: 26px;
            @include respond(largeUp) {
                margin-bottom: 10px;
            }

            label {
                margin-bottom: 8px;
                text-transform: uppercase;
                font-weight: normal;
                color: $black;
                @include respond(largeUp) {
                    float: left;
                    width: 33.5%;
                    margin-bottom: .3rem;
                    line-height: 34px;
                }

                &::after {
                    content: '*';
                    margin-left: 8px;
                    font-size: 16px;
                    color: $black;
                }

                span {
                    font-family: $din-condensed;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: normal;
                    color: $black;
                    &.required-indicator {
                        display: none;
                    }
                }
            }

            .field-wrapper {
                @include respond(largeUp) {
                    width: 66.5%;
                    max-width: 340px;
                    float: left;
                }
            }
        }

        .details-row {
            max-width: 730px;
            margin-bottom: 36px;
            font-family: $din-condensed;
            @include respond(small) {
                margin-bottom: 24px;
            }
            &.customer-details {
                ul.details-values {
                    @include respond(small) {
                        margin-bottom: 0;
                    }
                    li {
                        @include respond(small) {
                            margin-bottom: 0;
                        }
                        &:nth-child(even) {
                            @include respond(small) {
                                margin-bottom: 7px;
                            }
                        }
                    }
                }
            }
            ul {
                position: relative;
                float: left;
                list-style: none;
                padding: 0;
                margin: 0;
                font-size: 14px;
                @include respond(largeUp) {
                    float: none;
                    font-size: 16px;
                }
                &.details-labels {
                    width: 40%;
                    text-transform: uppercase;
                    margin-bottom: 3px;
                    @include respond(largeUp) {
                        width: 100%;
                    }
                }
                &.details-values {
                    width: 60%;
                    margin-bottom: 36px;
                    font-family: $neuzeit;
                    font-weight: 300;
                    color: $gray-medium2;
                    @include respond(largeUp) {
                        width: 100%;
                        margin-bottom: 0;
                    }
                    &:last-of-type {
                        @include respond(small) {
                            margin-bottom: 0;
                        }
                    }
                }
                li {
                    min-height: 16px;
                    @include respond(largeUp) {
                        float: left;
                        display: block;
                        width: 25%;
                        margin: 0;
                    }
                    &.customer-email {
                        width: 75%;
                    }
                    &.remove-link {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                    &.mobile-label {
                        font-family: $din-condensed;
                        font-weight: 500;
                        text-transform: uppercase;
                        color: $black;
                    }
                    a {
                        color: $gray-medium2;
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .info-section {
            max-width: 400px;
        }
        .register-more {
            margin-bottom: 24px;
            height: 44px;
            font-size: 20px;
            line-height: 42px;
            @include respond(largeUp) {
                margin-bottom: 36px;
            }
        }
    }

    #secondary {
        margin: 0;
    }
}
