#wrapper.pt_store-locator{
	background-color: $white;	
	#main{
		@include respond(large){
			padding-left: 15px;
			padding-right: 15px;
			box-sizing: border-box;
		}
		.top-border{
			@include respond(small){
				margin-bottom: 0;
			}
		}
		#store-search-form{
			@include respond(small){
				.locator-submit{
					button{
						height: 34px;
						line-height: 30px;
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}
	}
}
.store-locator-search-container {
	width: 27%;
	float: left;
	margin-right: 5%;
	@include respond(small){
		margin-right: 3%;
		margin-left: 0;
	}
	@include respond(small){
		width: 100%;
		padding: 4%;
		margin-right: 0;
		box-sizing: border-box;
	}
	.search-bar{
		select{
			height: 34px;
		}
	}
	button{
		&:hover{
			border-color: $black;
		}
	}
	.store-locator-header {
		//padding-bottom: 40px;
		@include respond(large){
			padding-bottom: 0;
		}
		@include respond(medium){
			padding-bottom: 0;
		}
		@include respond(small){
			padding-bottom: 0;
		}
		h1 {
			font-family: $oswald;
			font-weight: bold;
			font-size: 1.25rem;
			line-height: 58px;
			color: $gray-dark;
			text-transform: uppercase;
			letter-spacing: 2px;
			//display: none;
			margin-top: -1rem;
			margin-bottom: 0rem;
			@include respond(small){
				display: block;
			}
		}
	}
	label {
		font-family: $titillium;
		color: $gray-dark;
		text-transform: uppercase;
		font-size: .75rem;
		letter-spacing: .5px;
	}
}
.store-locator {
	width: 66%;
	float: left;
	@include respond(small){
		width: 100%;
		padding: 4%;
		margin: 0;
		box-sizing: border-box;
	}
	#map-canvas {
		height: 25rem;
		background: none;
		margin-bottom: 3rem;
		transform: translateZ(0px);
		background-color: rgb(229, 227, 223);
  		@include respond(small){
			display: none;
		}
  	}
	.stores-header {
		display: none;
	}
	.stores-container {
		.store {
			width: 100%;
			border-bottom: 1px solid $clp-border;
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
			float: left;
			
			&:last-child{
				border-bottom: 0;
				margin-bottom: 0;
			}
			.store-info-main {
				.storename {
					a {
						font-weight: bold;
						color: $black;
					}
				}
				width: 33%;
				@include respond(small){
					width: 83%;
					font-size: .75rem;
				} 
				@include respond(large) {
					width: 30%;
					font-size: .75rem;
				}
				float: left;
				color: $gray-warm;
				font-size: .875rem;
				letter-spacing: 1px;
			}
			.store-info-main-marker {
				float: left;
				background-image: url('../images/map-marker.png');
				width: 3rem;
				float: left;
				height: 4.25rem;
				background-repeat: no-repeat;	
				@include respond(large) {
					width: 2rem;
				}
			}
			.store-info-container {
				float: left;
				max-width: 77%;
			}
			.store-info-main-image {
				width: 4rem;
				height: 4rem;
				float: left;
				margin-left: 1rem;
				margin-right: 2rem;
				.image {
					margin-top: 1rem;
					border: 0;
					@include respond(small){
						margin-top: 0;
						float: left;
					}
				}
				image {
					border: 0;
				}
				@include respond(large) {
					margin-left: 1rem;
					margin-right: 1rem;
				}
				@include respond(small){
					margin-left: 3rem;
					height: auto;
				}
			}
			.directions {
				a {
					color: $black;
					text-decoration: underline;
					@include respond(small){
						font-size: 12px;
					}
					&:hover{
						text-decoration: none;
					}
				}
			    width: 15%;
				float: left;
				color: black;
				
				font-size: .875rem;
				letter-spacing: 1px;
				@include respond(large){
					width: 21%;
					font-size: .75rem;
					letter-spacing: 1px;
					text-align: right;
				}
				@include respond(small){
					width: 83%;
					margin-left: 3rem;
					margin-top: 1rem;
				}				
			}
			.distance {
				float: right;
				font-size: .875rem;
				letter-spacing: 1px;
				color: $gray-warm;
				@include respond(small){
					float: left;
					width: 83%;
					margin-left: 3rem;
					margin-top: 1rem;
					font-size: .75rem;
				}
			}
			.hours {
				width: 23%;
				float: left;
				font-size: .875rem;
				letter-spacing: 1px;
				color: $gray-warm;
				@include respond(large){
					width: 30%;
					font-size: .75rem;
				}
				@include respond(small){
					width: 83%;
					margin-left: 3rem;
				}
			}
		}
	}
}


.stores-wrapper {
	margin-top: 3rem;
	font-family: $titillium;
	color: #333;
	@include respond(small){
		margin-top: 0;
	}
	.no-stores {
		color: $error-red;
		font-weight: bold;
	}
}
.store-information {
	background: none repeat scroll 0 0 $gray-light;
	border-bottom: 1px solid $white;
}

.store-name {
	font-weight: bold;
	margin: 0 0 10px;
}

.store-map {
	border-bottom: 1px solid $gray-light;
}

.store-locator-details {
	float: left;
	width: 44%;
	img {
		max-width: 100%;
	}
	h1, p {
		margin: 0.25em 0;
	}
}
.store-locator-details-map {
	float: right;
	width: 50%;
	img {
		max-width: 100%;
	}
}

.mapContent {
	font-family: $titillium;
	width: 16rem;
	h1 {
		font-size: .75rem;
		font-weight: bold;
		letter-spacing: .5px;
		margin-bottom: 0;
		color: $gray-dark;
	}
	.contentBody {
		color: #999;
		font-size: .75rem;
		.spacer {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}
	a {
		margin-top: 1.25rem;
		color: $black;
		text-decoration: underline;
		color: $gray-dark;
		&:hover{
			text-decoration: none;
		}
	}
}

.markerLabel {
	display: none !important;
}

.primaryName {
	color: $black;
	font-weight: 700;
}
















