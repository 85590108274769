.item-list {
	margin-bottom: 1em;
	tr {
		font-size: 14.4px;
		border-bottom: 1px solid $gray-light;
		&.last {
			border: 0 none;
		}
		&.headings {
			display: none;
		}
	}
	td {
		padding: 1em;
		vertical-align: top;
	}
}

.section-header {
	padding: 1em;
}

.item-details .product-list-item {
	@include font-small;
	.name {
		font-size: 1.2em;
		font-style: italic;
	}
	.value {
		font-weight: 700;
	}
	.promo {
		color: $red;
	}
	.price-standard {
		color: $gray-warm;
		text-decoration: line-through;
	}
	.item-edit-details {
		@include font-small;
	}
}

.item-user-actions {
	@include font-small;
	.button-text {
		@include font-small;
	}
}
.item-availability ul,
.item-quantity-details ul {
	list-style: none outside none;
	margin: 0;
	padding: 0;
}
.item-dashboard {
	color: $gray-dark;
	width: 16rem;
	label {
		font-weight: normal;
		width: auto;
	}
	.field-wrapper {
		width: auto;
	}
	.option-quantity-desired {
		input {
			width: 3rem;
		}
	}
	.value {
		margin-top: .5rem;
	}
	.form-row {
		margin: 0;
	}
	input,
	.value,
	select
	.option-toggle-public label {
		color: $black;
	}
	.input-checkbox {
		margin: 0 5px;
	}
	.option-add-to-cart {
		margin-top: .5rem;
		width: 18rem; //for IE
		width: max-content;
		label {
			color: $black;
			float: left;
			font-weight: 700;
			margin: .5rem .5rem 0 0;
			text-transform: uppercase;
			width: auto;
		}
		input {
			width: 3rem;
			margin-right: 1em;
		}
		button {
			padding: 0.5em 1.5em;
		}
	}
	.option-quantity-purchased span.exceeded {
		color: $red;
	}
}

#wrapper.pt_cart #main #primary {
	#cart-items-form  {
		#cart-table, 
		#bonus-table {
			tr td {
				&.item-details .item-edit-details {
					@include font-small;
				}
				&.item-price {
					@include font-small;
					letter-spacing:.8px;
					font-size:14.4px;
				}
			}
		}
		.price-total {
			@include font-small;	
		}
		.cart-footer {
			.action-carousel {
				.cart-order-totals {
					@include font-small;
					font-size:14.4px;
				}
				.cart-actions button.button#go-checkout {
					font-size:20px;
				}
			}
		}
	}
	.cart-actions .cart-action-paypal {
		&.button {
			line-height: 44px;
		}
		span {
			font-size:20px;
		}
	}
	.name-container .cart-actions-top button {
		font-size:20px;
	}
}
