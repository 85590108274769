
input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
input[type="email"],
textarea,
select {
	font-family: $neuzeit;
}
