/////////////////////////
// Checkout Summary Panel
/////////////////////////
#wrapper.pt_checkout{
	#main{
		#secondary{
			width: 25%;
			float: right;
			padding-top: 56px;
			@include respond(small) {
				width: 100%;
				padding-top: 36px;
			}
			.section-header{
				font-size: 16px!important;
				padding: 11px 0;
				border-top: 2px solid $gray-light;
				border-bottom: 2px solid $gray-light;
				background: none;
				color: $gray-dark;
				text-transform: uppercase;
				@include respond(small) {
					font-size: 14px!important;
				}
				a{
					float: right;
					color: $gray-dark;
					font-size: 14px;
					text-decoration: underline;
					font-family: $titillium;
					letter-spacing: 1px;
					text-transform: none;
					&:hover{
						text-decoration: none;
					}
					@include respond(small) {
						font-size: 12px;
					}
				}
			}
			.checkout-mini-cart{
				background: $white;
				max-height: 425px;
				@include respond(small) {
					max-height: none;
					overflow: visible;
				}
				.mini-cart-product{
					padding: 10px 0 23px;
				}
				.mini-cart-image{
					width: 40%;
					
					img{
						max-width: 90%!important;
					}
				}
				.mini-cart-name,
				.mini-cart-attributes,
				.mini-cart-pricing{
					width: 60%;
					padding-right: 5%;
					box-sizing: border-box;
				}
				.mini-cart-name{
					a{
						font-size: 14px!important;
						line-height: 150%!important;
						margin-bottom: 8px;
    					margin-top: 3px;
    					text-transform: none;
					}
				}
				.mini-cart-pricing,
				.mini-cart-attributes{
					.label{
						font-size: 11px;
						font-weight: bold;
						color: $gray-dark;
					}
					.value{
						font-size: 11px;
						color: $gray-warm;
					}
				}
				.mini-cart-pricing{
					.mini-cart-price{
						font-size: 11px;
						font-weight: bold;
						color: $gray-dark;
					}
				}
			}
			.checkout-order-totals{
				border-top: 1px solid $gray-light;
				padding: 10px 0;
				background: none;
				@include respond(small) {
					padding-bottom: 5px;
				}
				.order-totals-table{
					tr{
						td{
							text-transform: uppercase;
							color: $gray-dark;
							font-size: 12px;
							padding: 3px 0 2px;;
							@include respond(small) {
								padding: 2px 0;
							}
							&:first-child{
								text-align: left;
								font-weight: bold;
								width: 60%;
							}
						}
						&.order-subtotal {
							td:last-child {
								font-weight: 400;
							}
						}
						&.discount{
							td{
								color: $red;
							}
						}
						&.order-sales-tax{
							td{
								padding-bottom: 13px;
								@include respond(small) {
									padding-bottom: 17px;
								}
							}
						}
						&.order-total-split{
							td{
								padding-top: 13px;
								border-top: 1px solid $gray-light;
								@include respond(small) {
									padding-bottom: 17px;
								}
							}
						}
						&.order-total{
							td{
								padding-top: 13px;
								border-top: 1px solid $gray-light;
								font-size: 14px;
								font-weight: bold;
								@include respond(small) {
									padding-top: 13px;
								}
							}
						}
					}
				}
			}
		}
		#primary .summary{
			.checkout-progress-indicator .inactive a{
				color: $gray-warm;
			}
			.summary-address{
				display: inline-flex;
				width: 100%;
				> div {
					width: 100%;
					display: inline-block;
				}
				.payment-instruments {
					.details {
						padding-bottom: 25px;
					}
					span.icon-PayPal {
						display: block;
						background: url("../images/paypal.jpg") no-repeat right center;
						width: 120px;
						height: 30px;
						background-size: 120px auto;
						padding: 10px 0;
					}
					.label {
						font-size: 13px;
						text-transform: uppercase;
						font-weight: bold;
					}
					.value {
						color: $gray-warm;
					}
				}
			}
			.address-static{
				color: $gray-warm;
				line-height: 22px;
				font-size: 14px;
				padding-bottom: 25px;
				padding-top: 10px;
			}
			#shipping-method-list{
				.form-row.label-inline label{
					width: 100%;
					padding-left: 20px;
				}
				.form-field-tooltip{
					left: 350px;
					position: absolute;
					top: 0;
					margin: 7px 0 0 18%;
					padding-top: 0;
					
					@include respond(large) {
						margin-top: 0;
					}
					@include respond(small) {
						left: 0;
						position: relative;
						top: 0;
						text-align: right;
						padding-left: 0;
						width: 100%;
						text-align: right;
						margin: 0;
					}
					a{
						color: $gray-warm;
						font-size: 11px;
					}
				}
			}
		}
		.order-summary-footer{
			button.button-fancy-large {
				float: right;
				height: 44px;
				line-height: 40px;
				background: $black;
				color: $white;
				cursor: pointer;
				font-size: 14px;
				padding: 0 27px;
				&:hover{
					background: $white;
					color: $black;
					border-color: $black;
				}
				@include respond(small) {
					width: 100%;
				}
			}
		}
	}
}