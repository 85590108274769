///////////////////////
/// Styles for Shopping Cart page
///////////////////////
#wrapper.pt_cart{
	#main{
		#primary{
			float: left;
			width: 100%;
			margin-top: 22px;

			.cart-banner-top{
				display: block;
				width: 100%;
				margin-bottom: 20px;
				img{
					width: 100%;
				}
			}
			.cart-empty{
				#continue-shopping{
					button{
						&:hover{
							color: $red;
						}
					}
				}
			}
			.no-hits-shop-by-category {
				margin-top: 50px;

				&.home-bottom-slots {
					.promoSlotFull#home-full-1 {
						.bannerContent {
							display: none;
						}

						.itemContent {
							border-top: none;

							a {
								border: none;
								width: 20%;

								&:first-child {
									font-size: 20px;
									box-sizing: border-box;
									display: block;
									float: none;
									border: none;
									width: 100%;
									height: 55px;

									.headers-wrapper {
										width: 100%;
										position: static;
										-webkit-transform: none;
										transform: none;
										text-align: center;

										&:after {
											content: '';
											display: block;
											clear: both;
										}

										h2, h3 {
											display: inline-block;
											margin: 0;
											font-size: 30px;

											@include respond(small) {
												font-size: 1.25rem;
											}
										}

										h2 {
											margin-right: 10px;
										}
									}
								}
								&:not(:first-child) {
									@include respond(small) {
										width: 100%;
										height: auto;
										text-align: center;
										border-bottom: 2px solid #ccc;
										vertical-align: middle;

									}
									& > div {
										display: inline-block;
										width: 30%;
										min-width: 100px;
										padding: 5px 0;
										vertical-align: middle;
										text-align: left;
									}
									img {
										@include respond(small) {
											position: static;
											max-width: 25%;
											transform: none;
											-webkit-transform: none;
										}
									}
									h3 {
										@include respond(small) {
											position: static;
											display: inline-block;
											width: 20%;
											padding-left: 2%;
											margin: 0;
											vertical-align: middle;
											text-align: center;
										}
									}
								}
								&:last-child {
									@include respond(small) {
										border-bottom: none;
									}
								}
							}
						}
					}
				}
			}

			.no-hits-recommendations {
				margin-top: 40px;
				border-bottom: none;
				padding-bottom: 0;
				margin-bottom: 0;

				@include respond(small) {
					margin-bottom: 40px;
				}

				h3 {
					margin-bottom: 60px;
				}

				&.pdp-recommendations {
					.product-listing-1x4 {
						.search-result-items {
							margin: 0;
						}
					}
				}
			}
			.name-container{
				float: left;
				width: 100%;
				padding-bottom: 6px;
				border-bottom: 2px solid $gray-light;

				.myjoy-rtns {
					font-family: neuzeit-grotesk,sans-serif,serif;
					font-size: rem(14px);
					font-weight: bold;
					padding-bottom: rem(23px);
				}

				.cart-name{
					float: left;
					width: 20%;
					@include respond(large) {
					    width: 20%;
					}
					@include respond(small) {
						width: 100%;
					}
					h1{
						font-family: $oswald;
						font-weight: bold;
						font-size: 30px;
						color: $gray-dark;
						line-height: 120%;
						text-transform: uppercase;
						@include respond(small) {
							font-size: 20px;
							width: 100%;
							display: block;
						}
					}
					.cart-promo.cart-promo-approaching,
					p.promotion{
						font-family: $titillium;
						font-weight: bold;
						font-size: 16px;
						color: $red;
						line-height: 100%;
						margin: 0;
						background: none;
						padding-left: 0;
						padding-right: 0;
						font-style: normal;
						white-space: nowrap;
						@include respond(small) {
							margin-bottom: 14px;
							line-height: 120%;
							white-space: normal;
						}
					}
				}
				.cart-actions-top{
					float: right;
					width: 80%;
					margin-top: 0;
					@include respond(large) {
					    margin-top: 0;
					    width: 80%;
					}
					@include respond(small) {
						width: 100%;
						margin-top: 0;
						.cart-action-checkout{
							float: left;
							width: 100%;
						}
					}

					button{
						background: $black;
						color: $white;
						padding: 0;
						width: 300px;
						margin-left: 15px;
						height: 44px;
						line-height: 40px;
						float: right;
						@include respond(large) {
							width: 175px;
							font-size: 14px;
						}
						@include respond(small) {
							width: 100%;
							margin-left: 0;
							font-size: 14px;
						}
						&.dw-apple-pay-button {
							padding: 0px 30px!important;
							height: 42px!important;
							float: left!important;
							width: 300px!important;
							border-radius:0 !important;
							border: 2px solid #000 !important;
							margin: 0 15px 0 0 !important;
							min-height:10px!important;
							min-width:220px!important;
							background-size:45% 45%!important;
							background-color:$black!important;
					  		background-image:-webkit-named-image(apple-pay-logo-white)!important;
					  		background-repeat:no-repeat!important;
					  		background-position:50% 50%!important;
							@include respond(large) {
							    width: 175px!important;
							    min-width: 175px!important;
							    padding: 0!important;
							    margin: 0 15px 0 0!important;
							}
							@include respond(small) {
								display:block;
								width: 100%!important;
								font-size: 14px!important;
								margin: 10px 0 0 0!important;
							}
						}
						&:hover{
							background: $white;
							color: $black;
							border-color: $black;
						}
						&.button-text {
							background: none;
							border: none;
							color: #68A1B8;
							text-decoration: underline;
							margin: 0 auto;
							padding-top: rem(8px);
							height: auto;
							width: 100%;
							font-size: 20px;
							@include respond(largeUp) {
								display: none;
							}
							&:hover {
								background: none;
								color: #4D738A;
							}
						}
					}
				}
			}
			.cart-actions {
				.cart-action-paypal {
					width: 100%;
					line-height: 40px;
					float: none;
					margin-left: 0;
					padding: 0 20px;
					&:hover {
					    background: $white;
					    color: $black;
					}
					@include respond(large) {
						width: 100%;
						margin-top: 10px;
						margin-left: 0;
						padding: 0;
						height: 44px;
						float: none;
					}
					span {
                        padding-right: 10px;
                    }
					@include respond(small) {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-left: 0;
                        padding: 0;
                        height: 44px;
                        margin-top: 10px;
                        span {
                        	font-size: 14px;
                        }
                    }
					img {
					    vertical-align: middle;
					    height: 44px;
					    @include respond(large) {
					        height: 30px;
					    }
					}
				}
				&.cart-actions-top {
				    .or-label {
						float: right;
					    margin-left: 20px;
					    display: block;
					    margin-top: 15px;
                        @include respond(large) {
                            display: none;
                        }
					    @include respond(small) {
					    	margin-left: auto;
					    	margin-right: auto;
					    	float: none;
					    	text-align: center;
					    	display: none;
					    }
					}
					.cart-action-paypal {
						width: 300px;
						padding: 0;
						&:hover {
							background: $white;
							color: $black;
						}
						@include respond(large) {
						    height: 44px;
						    width: 175px;
						    margin-top: 0;
						}
						@include respond(small) {
							width: 100%;
							max-width: 100%;
							margin-top: 55px;
							margin-left: 0;
						}
						@include respond(large) {
						    img {
						        height: 30px;
						    }
						}
					}
				}
				button {
					&.dw-apple-pay-button {
						padding: 0px 30px!important;
						height: 42px!important;
						float: left!important;
						width: 100%!important;
						border-radius:0 !important;
						border: 2px solid #000 !important;
						margin: 10px 0 15px 0!important;
						min-height:10px!important;
						min-width:49%!important;
						background-size:45% 45%!important;
						background-color:$black!important;
				  		background-image:-webkit-named-image(apple-pay-logo-white)!important;
				  		background-repeat:no-repeat!important;
				  		background-position:50% 50%!important;
						@include respond(small) {
							width: 100%!important;
							font-size: 14px!important;
							margin-top: 10px!important;
						}
					}
				}
			}
			.bonus-item-promo{
				float: left;
				width: 100%;
				padding: 15px 0;
				@include respond(small) {
					text-align: center;
					border-bottom: 2px solid $gray-light;
				}
				.bonus-item-details{
					float: left;
					font-size: 14px;
					margin-top: 10px;
					@include respond(small) {
						margin-top: 0;
						float: none;
						display: inline-block;
						margin-bottom: 10px;
					}
					a{
						color: $gray-dark;
						font-size: 14px;
						font-style: normal;
						span{
							text-decoration: underline;
							&:hover{
								text-decoration: none;
							}
						}
					}
				}
				.bonus-products{
					float: right;
					@include respond(small) {
						float: none;
					}
					a{
						font-size: 11px;
						line-height: 30px;
						height: 34px;
						margin-top: 5px;
						padding: 0 18px;
					}
				}
			}
			#cart-items-form{
				float: left;
				width: 100%;
				tfoot{
					td.cart-promo{
						padding: 15px 5px;
						span{
							font-size: 14px;

							&.value{
								text-transform: uppercase;
							}
						}
					}
				}
				#bonus-table {
					.cart-promo {
						border-bottom: none;
					}
				}
				#cart-table,
				#bonus-table{
					th{
						background: $gray-white;
						padding: 10px 5px;
						&:first-child{
							padding-left: 20px;
						}
						&:last-child{
							padding-right: 20px;
						}
					}
					tr{
						&.rowbundle{
							&:last-child{
								border-bottom: 1px solid $gray-light;
							}
							td{
								padding-top: 5px;
								padding-bottom: 21px;
								&.item-details{

									.product-list-item{
										@include respond(large) {
											min-width: 250px;
											display: block;
										}
									}
									.item-image{
										width: 135px;
										float: left;
									}
									.name{
										margin-top: 10px;
										display: inline-block!important;

										a{
											line-height: 150%;
										}
									}
									@include respond(small) {
										.item-image{
											float: left;
											width: 100%;
										}
										.name{
											float: left;
											width: 100%;
										}
									}
								}
								@include respond(small) {
									&.item-price,
									&.item-total{
										display: none;
									}
								}
								&.item-quantity{
									color: $gray-warm;
    								font-size: 12px;
    								font-weight: normal;

    								@include respond(small) {
										display: none;
									}
    								.bundleqtyincluded{
    									color: $gray-warm;
    									ont-size: 12px;
    									display: block;
    									margin: 10px 0 18px;
    								}
    								.product-availability-list{
	    								.is-in-stock{
	    									padding: 0;
	    									font-weight: normal;
	   									}
   									}
								}
							}
						}
						td{
							padding: 20px 5px 13px;
							@include respond(small) {
								padding: 17px 5px 0;
							}
							&.item-image{
								padding-left: 0;
								img{
									width: 135px;
									margin-right: 35px;
									max-width: 100%!important;
									@include respond(small) {
										width: 100px;
										margin-right: 20px;
									}
								}
							}
							&.item-details{
								.item-image{
									img{
										width: 135px;
										margin-right: 35px;
										float: left;
										@include respond(small) {
											width: 100px;
											margin-right: 20px;
										}
									}
								}
								.product-list-item{
									div.name,
									a{
										font-style: normal;
										color: $gray-dark;
										font-weight: bold;
										line-height: 100%;
										font-size: 14px;
										display: block;
										@include respond(small) {
											margin-bottom: 3px;
										}
									}
									.label{
										font-size: 11px;
										color: $gray-dark;
										text-transform: uppercase;
										font-weight: bold;
									}
									.attribute{
										display: block;
										width: 100%;
										line-height: 148%;
									}
									.item-links{
										padding-top:5px;
										display: block;
										a{
											font-size: 11px;
											text-transform: uppercase;
										}
									}
									.value{
										font-size: 11px;
										color: $gray-medium;
										text-transform: uppercase;
										font-weight: normal;
									}
									.sku{
										padding-bottom: 10px;
										span{
											font-size: 11px;
											color: $gray-medium;
											text-transform: none;
											font-weight: normal;
										}
									}
									&.myjoy-item{
										.attribute{
											padding-bottom: 10px;
											display: block;

											.label,
											.value{
												color: $gray-medium;
												font-weight: normal;

											}
											&.name{
												padding-bottom: 0;
												span.value{
													font-weight: bold;
													color: $gray-dark;
													padding-bottom: 0;
													display: block;
												}
											}
										}
									}
									&.vokey-item{
										.attribute{
											padding-bottom: 0;
										}
									}
								}
								.item-edit-details{
									padding-top: 10px;
									a, button {
										color: $gray-dark;
										font-size: 12px;
										font-weight: normal;
										height: auto;
										line-height: normal;
										text-transform: capitalize;
										text-decoration: underline;
										&:hover{
											text-decoration: none;
										}
									}
								}
								.bonus-products{
									padding-top: 10px;
									a {
										color: $gray-dark;
										font-size: 12px;
										font-weight: normal;
										height: auto;
										line-height: normal;
										text-transform: capitalize;
										text-decoration: underline;
										&:hover{
											text-decoration: none;
										}
									}
								}
							}
							&.item-quantity{
								@include respond(small) {
									float: right;
									width: 60%;
									clear: none;
									text-align: left;
								}
								.mobile-title{
									display: none;
									@include respond(small) {
										display: block;
										width: 100%;
										font-weight: bold;
										font-size: 11px;
										color: $gray-dark;
										text-transform: uppercase;
										text-align: left;
										margin: -4px 0 5px;
									}
								}
								select{
									width: 73px;
									height: 34px;
									line-height: 30px;
									padding: 0 2.7em 0 0.7em;
									@include respond(small) {
										float: left;
									}
								}
								.item-user-actions{
									@include respond(small) {
										float: left;
										width: 100%;
									}
									a.add-to-wishlist,
									button{
										font-weight: normal;
									}
								}
								.product-availability-list {
									display: block;
									width: 100%;
									font-weight: normal;
									@include respond(small) {
										float: left;
										text-align: left;
									}
									.is-in-stock{
										font-size: 12px;
										color: $gray-medium;
										padding: 11px 0 4px;
										font-weight: normal;
										@include respond(small) {
											padding: 11px 0;
										}
									}
									.pre-order {
										padding: 10px 0 20px;
									}
									.in-stock {
										padding-bottom: 20px;
									}
								}
								.button-text,a.add-to-wishlist{
									color: $gray-dark;
									font-size: 12px;
									text-decoration: underline;
									text-transform: none;
									font-weight: bold;
									line-height: 120%;
									border-radius: 0;
									display: table;
									margin-bottom: 6px;
									border: 0;
									height: auto;
									&:hover{
										text-decoration: none;
									}
									@include respond(small) {
										margin-bottom: 13px;
									}
								}
							}
							&.item-price{
								color: $gray-dark;
								& > span:first-child{
									padding-top: 9px;
									display: block;
									width: 100%;
								}
								.mobile-title{
									@include respond(largeUp) {
										display: none !important;
										& + span {
											padding-top: 9px;
											display: block;
											width: 100%;
										}
									}
									@include respond(small) {
										display: inline-block !important;
										width: initial !important;
										font-weight: bold;
										font-size: 12px;
										font-family: din-condensed, sans-serif;
										color: $gray-dark;
										text-transform: uppercase;
										text-align: left;
										margin: 0;
										padding-top: 9px !important;
										& + span {
											font-size: 12px;
											font-weight: 400;
										}
										& + .price-promotion {
											font-size: 12px;
											font-weight: 400;
										}
									}
								}
								.price-promotion{
									& > span:first-child{
										padding-top: 9px;
										display: block;
										width: 100%;
									}
								}
								@include respond(small) {
									float: right;
									width: 60%;
									padding-top: 0;
									& > span:first-child{
										padding-top: 1px;
									}
									.price-promotion{
										& > span:first-child{
											padding-top: 1px;
										}
									}
								}
								.price-promotion{
									span{
										margin: 0;
										display: block;
									}
									.price-standard{
										text-decoration: line-through;
									}
									.price-sales{
										color: $red;
									}
								}
							}
							&.item-total{
								padding-right: 20px;
								& > span{
									padding-top: 9px;
									display: block;
									width: 100%;
									@include respond(small) {
										font-size:12px;
									}
									&.price-adjusted-total,
									&.price-unadjusted{

										@include respond(small) {
										clear: both;
										padding-top: 3px;
										  span{
										  		font-size:12px;
											}
										}
									}
								}
								div.promo-adjustment{
									width: 100%;
									@include respond(small) {
										font-size: 12px;
									}
								}

								.mobile-title{
									display: none;
									@include respond(small) {
										float: left;
										display: block;
										font-weight: bold;
										font-size: 12px;
										color: $gray-dark;
										text-transform: uppercase;
										text-align: left;
										margin: 0 5px 0 0;
									}
								}
								@include respond(small) {
									float: right;
									width: 60%;
									text-align: left;
									padding-top: 10px;
									padding-bottom: 25px;
								}
								.price-total{
									color: $gray-dark;
									font-weight: normal;
									@include respond(small) {
										float: left;
										font-size: 12px;
										padding-top: 0;
										width: auto;
									}
								}
							}
						}

						.low-inventory-msg {
							margin-top: .3rem;
				   			color: $red;
				   			@include respond(small) {
				   				clear: both;
				   				float: left;
				   			}
				   		}
					}
					.cart-promo{
						background: none;
						font-style: normal;
						td{
							padding: 0;
						}
						@include respond(small) {
							.bonus-item-promo{
								border: 0;
							}
						}
					}

					tfoot{
						tr{
							td.cart-promo{
								padding: 20px 5px;
							}
						}
					}
				}
				.cart-footer{
					float: left;
					width: 100%;
					display: block;
					clear:none;
					margin-top: 0;

					.cart-coupon-code{
						float: left;
						width: 100%;
						padding-bottom: 0;
						margin-top: -11px;
						border-top: 1px solid $gray-light;
						border-bottom: 2px solid $gray-light;
						@include respond(small) {
							padding-bottom: 0;
						}
						input{
							width: 310px;
							height: 34px;
							line-height: 30px;
							@include respond(small) {
								width: 71%;
							}
						}
						.error{
							width: 75%;
						}
						& > button{
							height: 34px;
							line-height: 30px;
							padding: 0 30px;
						}
						.coupons-form{
							float: left;
							width: 70%;
							margin-bottom: 7px;
							@include respond(small) {
								width: 100%;
							}
						}
						.coupons-wrap{
							float: right;
							width: 25%;
							@include respond(small) {
								width: 100%;
							}
							.promotion-title{
								color: $gray-dark;
								text-transform: uppercase;
								width: 100%;
								display: block;
								font-weight: bold;
								margin-bottom: 0;
								margin-top: 2px
							}
							.name{
								float: left;
								color: $gray-warm;
								width: 70%;
							}
							button{
								float: right;
								padding: 0;
								margin: 0;
								border-radius: 0;
								border: 0;
								font-size: 12px;
								text-transform: none;
								color: $gray-dark;
								text-decoration: underline;
								font-weight: normal;
								line-height: 120%;
								cursor: pointer;
								margin-top: 2px;
								height: auto;
								&:hover{
									color: $gray-dark;
									text-decoration: none;
									border: 0;
									background: $white;
								}
							}
						}
						#update-cart{
							display: none;
						}
						#add-coupon{
							height: 34px;
							line-height: 30px;
							@include respond(small) {
								line-height: 2.5em;
								padding: 0 18px;
							}
							&:hover{
								border-color: $black;
							}
						}
						h2{
							margin-bottom: 10px;
							@include respond(small) {
								margin-bottom: 16px;
							}
							span{
								background:  url("../images/down-arrow.png") no-repeat scroll right 10px center;
								display: block;
								width: 100%;
								cursor: pointer;
							}
							&.close{
								span{
									background:  url("../images/arrow-right.png") no-repeat scroll right 10px center;
								}
							}
						}
						.cart-coupon-wrapper.close {
							display: none;
						}
					}
					.action-carousel{
						float: left;
						width: 100%;
						.cart-order-totals{
							float: right;
							width: 25%;
							@include respond(small) {
								width: 100%;
							}
							.or-label {
								margin-left: 47%;
							    margin-top: 10px;
							    display: block;
							    margin-bottom: 10px;
							    @include respond(large) {
                                    display: none;
                                }
								@include respond(small) {
							    	display: none;
								}
							}

							.cart-action-paypal-incontext {
							    margin-left: 20%;
							}
						}
						.cart-actions{
							float: left;
							width: 100%;
							margin: 20px 0 0;
							@include respond(small) {
								margin-top: 16px;
							}
							button.button{
								background: $black;
								color: $white;
								padding: 0;
								width: 100%;
								box-sizing: border-box;
								height: 44px;
								line-height: 40px;
								font-size: 14px;
								&:hover{
									background: $white;
									color: $black;
									border-color: $black;
								}
								&#go-checkout {
								    @include respond(standard) {
									    margin-bottom: 10px;
									}
								}
							}
						}
						.cart-order-totals{
							margin-top: 20px;
							@include respond(small) {
								margin-top: 11px;
							}
							.order-totals-table{
								tr{
									td{
										color: $gray-dark;
										text-transform: uppercase;
										padding: 3px 0 1px;
										&:first-child{
											font-weight: bold;
											text-align: left;
											width: 60%;
										}
									}
									.free-shipping-color {
                                        color: $red;
                                        font-weight: bold;
										&.loggedin {
											color: $green-dark;
										}
                                    }
									&.order-shipping{
										td{
											padding-bottom: 4px;
										}
										span{
											height: 16px;
										    line-height: 16px;
										    margin-right: 5px;
										   	display: inline;
										    float: none;
										}
										a.shipping-icon{
											background: url('../images/cart-shipping-icon.gif') no-repeat left top;
											width: 16px;
											height: 16px;
											display: inline-block;
											margin-bottom: -3px;
										}
									}
									&.order-shipping-discount,
									&.order-discount{
										td{
											color: $red;
										}
									}
									&.order-sales-tax{
										td{
											padding-bottom: 15px;
										}
									}
									&.order-total{
										td{
											font-size: 14px;
											border-top: 1px solid $gray-light;
											padding: 13px 0 0;
										}
									}
								}
							}
						}
					}
				}
			}
			.cart-recommendations{
				float: left;
				width: 70%;
				margin-right: 5%;
				margin-top: 0;
				@include respond(small) {
					width: 100%;
					margin-right: 0;
				}
				h2 {
					text-align: center;
					font-size: 20px;
					color: $gray-dark;
					margin: 24px 0;
					@include respond(small) {
						margin: 30px 0 20px;
					}

                    &.fj-rp-slider-titlemain {
                        text-align: left;
                        color: $black;
                        font-weight: bold;
                        font-size: 16px;
                        margin: 25px 0 15px 0;
                        padding-left: 55px;
                        @include respond(small) {
                            text-align: center;
                            margin: 30px 0;
                            padding-left: 0;
                        }
                    }
				}
				ul{
					li{
						a.thumb-link img{
							display: inline-block;
							width: 80%;
							@include respond(small) {
								width: 100%;
							}
						}
					}
				}
				ul#featured-carousel,
				ul#horizontal-carousel{
					padding: 0;
					margin: 0 auto;
					width: 85%;
					.expanded{
						li.hide{
							display: initial;
						}
					}

					li{
						list-style: none;
						display: inline-block;
						&.hide{
							display: none;
						}
						@include respond('large') {
							margin: 0 3px 4px;
							&.hide-mobile{
								display: none;
							}
							&:last-child{
								&.hide-mobile{
									display: block;
								}
							}
						}
						@include respond('small') {
							margin: 0 2px 4px;
							&.hide-mobile{
								display: none;
							}
							&:last-child{
								&.hide-mobile{
									display: block;
								}
							}
						}
						&.open-close{
							line-height: 18px;
							margin-bottom: 0;
							a{
								font-size: 10px;
								color: $gray-dark;
								line-height: 18px;
								text-transform: uppercase;
							}
						}
						#reviewstarratings, .reviewstarratings{
							img{
								display: inline;
							}
						}
						.product-tile{
							font-family: $titillium;
							text-transform: uppercase;
							font-size: 14px;
							line-height: 1.25em;
							@include respond(large){
								font-size: 12px;
							}
							@include respond(small){
								font-size: 12px;
								padding: 0;
							}
							.product-image{
								opacity: 1;
							}
							&:hover{
								.product-image{
									opacity: 0.9;
								}
							}
							.product-name{
								height: initial;
								margin: 0;
								color: $black;
								overflow: visible;
								text-transform: none;
								min-height: 0;
								line-height: 16px;
								margin-bottom: 5px;

								a{
									font-size: 16px;
									color: $black;
									@include respond(large){
										font-size: 12px;
									}
									@include respond(small){
										font-size: 12px;
									}
								}
							}
							.product-pricing{
								margin: 0;
								color: $gray-warm;
								font-size: 13px;
								font-weight: bold;
								font-family: $oswald;
								margin-bottom: 10px;
							}
							.product-promo{
								display: none;
							}
							.product-swatches{

							}
						}
					}
					.slick-arrow{
						width: 19px;
						height: 30px;
						top: 50%;
						margin-top: -15px;

						&.slick-prev{
							background-image: url('../images/lCarouselLite.svg');
							left: -50px;
							@include respond(small){
								left: -17px;
							}
						}

						&.slick-next{
							background-image: url('../images/rCarouselLite.svg');
							right: -50px;
							@include respond(small){
								right: -17px;
							}
						}
					}
				}
			}
			.footer-mid {
				float: left;
				width: 100%;
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 2px solid $gray-light;

				.cart-promo.cart-promo-approaching,
				p.promotion{
					font-weight: bold;
					font-size: 16px;
					color: $red;
					line-height: 20px;
					margin: 0;
					display: block;
					width: 100%;
					margin-bottom: 10px;
					float: left;
					background: none;
					padding: 0;
					font-style: normal;
				}
				button.button-text{
					background: none;
					border: none;
					color: #68A1B8;
					text-decoration: underline;
					margin: 0 auto;
					height: auto;
					width: 100%;
					font-size: 20px;
					@include respond(largeUp) {
						display: none;
					}
					&:hover {
						background: none;
						color: #4D738A;
					}
				}
			}
		}
		.cart-action-continue-shopping {
			float: none;
		}
		.footer-bottom{
			float: left;
			width: 100%;
			.cart-banner-bottom{
				display: block;
				width: 100%;
				margin-bottom: 43px;
				img{
					width: 100%;
				}
			}
			.banner-security{
				float: left;
				margin: 25px 0 57px;
				width: 100%;
				text-align: center;
				@include respond(small) {
					margin-bottom: 33px;
				}
				@include respond(largeUp) {
					float: none;
					display: flex;
					justify-content: space-between;
					max-width: 634px;
					box-sizing: border-box;
					margin-right: auto;
					margin-left: auto;
					padding-right: 2px;
				}
				& > span {
					font-weight: bold;
					text-transform: uppercase;
					color: $gray-dark;
					width: 17%;
					display: inline-block;
					vertical-align: middle;
					padding-left: 30px;
					line-height: 120%;
					text-align:left;
					background: url('../images/security-icons.gif') no-repeat left 2px;
					&:not(.secure):not(.returns):not(.phone) {
						display: none!important;
					}
					@include respond(small) {
						width: 30%;
						padding:30px 1.5% 0;
						float: left;
						font-size: 11px;
						text-align: center;
					}
					@include respond(largeUp) {
						width: initial;
					}
					&.secure {
						@include respond(small) {
							background: url('../images/secure-tr-icon.gif') no-repeat center top;
						}
					}
					&.returns{
						background-position: left center;
						line-height: 30px;
						@include respond(small) {
							background: url('../images/return-center-icon.gif') no-repeat center top;
							line-height: 120%;
						}
					}
					&.phone{
						background-position: left -55px;
						span{
							font-weight: normal;
							color: $gray-warm;
							text-transform: none;
						}
						@include respond(small) {
							line-height: 120%;
							background-position: center -57px;
							span{
								padding: 0;
								width: 100%;
								float: none;
								font-weight: normal;
								color: $gray-warm;
							}
						}
					}
				}
			}
		}
	}
}
