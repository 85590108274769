///////////////////////
/// Styles for Checkout Header
///////////////////////
#wrapper.pt_order-confirmation,
#wrapper.pt_checkout {
	header {
		.top-banner{
			.menu-utility-user{
				@include respond(small) {
					margin-top:20px;
				}
				li{
					&#mini-cart{
						.mini-cart-content{
							top: 81px;
							&.cart-show {
								@include respond(small) {
									display:none!important;
								}
							}
						}
						a {
							&.mini-cart-link {
								@include respond(small) {
									position:relative;
									padding:0;
									top:0;
								}
								@include respond(largeUp) {
									height: 20px;
									padding-left:0;
								}
							}
						}
					}
				}
			}
		}
	}
	footer {
        background: none;
        .footer-container {
            .footer-bottom {
				@include respond(largeUp) {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
                .left-checkout-footer {
					@include respond(small) {
						padding-left: 27px;
						padding-right: 27px;
						box-sizing: border-box;
					}
					@include respond(largeUp) {
						float: none;
						margin-left: auto;
						margin-right: auto;
						.content-asset ul li {
							padding: 0 18px;
						}
					}
                }
				.banner-security {
					@include respond(small) {
						padding-left: 27px;
						padding-right: 27px;
						box-sizing: border-box;
						& > span {
							width: 33%!important;
						}
					}
					@include respond(largeUp) {
						float: none;
						display: flex;
						justify-content: space-between;
						max-width: 635px;
						width: 100%;
						margin-left: auto;
						margin-right: auto;
						& > span {
							width: initial!important;
						}
					}
				}
            }
        }
    }
	.footer-copy {
		@include respond(largeUp) {
			padding: 25px 65px 60px;
		}
	}
}
