@include respond(print) {
	.pt_order-confirmation #mini-cart, 
	.pt_order-confirmation .menu-utility-user,
	.pt_order-confirmation .menu-utility,
	.pt_order-confirmation .header-banner,
	.pt_order-confirmation #navigation{
		display:none;
	}
	.print-page{
		display:none;
	}
	.top-banner, .footer-container {
		display: none;
	}
	.primary-logo{
		float: none;
	}
	.order-shipment-table{
		td{
			padding-top: 0;
			
			&.line-item-details{
				.item-image{
					float: left;
					width: 100%;
					margin: 0;
					padding: 0;
					clear: left;
					img{
						width: 90px;
						margin-right: 5px;
					}
				}
				.product-list-item{
					float: left;
					width: 100%;
					margin: 0;
					padding: 0;
				}
			}
		}
	}
	footer{
		.left-checkout-footer{
			display: none;	
		}
	}
	
	#wrapper.pt_order-confirmation footer .footer-container .footer-bottom .banner-security{
		float: left;
		width: 100%;
	}
	#wrapper.pt_order-confirmation #main .order-confirmation-details table.order-shipment-table tr td.line-item-details .product-list-item{
		float: left;
		width: 100%;
	}
}
