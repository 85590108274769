#dialog-container #PasswordResetForm {
    button {
        background-color: $black;
        color: $white;
        font-size: 13px;
        height: 46px;
        line-height: 46px;
        padding: 0 18px;
        margin-right: 0 !important;
        margin-top: 10px;
        @include respond(small) {
            font-size: 13px;
        }
    }
    .form-row label {
        @include respond(largeUp) {
            line-height: 46px;
        }
    }
}
#wrapper #main #secondary .account-nav-asset h3 {
    font-family: $neuzeit !important;
}
#wrapper.pt_account {
    #main {
        #primary {            
            h1 {
                @include respond(small) {
                    border-bottom: none;
                }
            }
            span.log-out {
                @include respond(small) {
                    margin-bottom: 10px;
                } 
            }
            #RegistrationForm.registered-form {
                .side-form button {
                    height: 42px;           
                    text-decoration: none;
                    color: #fff;
                    margin-top: 34px;
                    margin-left: 10px !important;
                    @include respond(small) {
                        margin-top: 0;
                    }
                }
                .profile-info-container .my-newsletter input[type="checkbox"] + label {
                    min-height: 27px;
                }
                .profile-info-container .my-newsletter label {
                    padding-left: 46px;
                    line-height: 27px;
                }
            } 
            .main-box .golfer-profile-section .button-edit-profile {
                line-height: 41px !important;
                font-size: 16px;
                &:hover {
                    color: $white !important;
                }
            }
            .col-1 .login-box-content form .reset-pass {
                @include respond(small) {
                    padding-left: 0;
                    a#password-reset {
                        float: none;
                    }
                }
            }
            .content-asset p,
            ul.payment-list li {
                color: $darkgrey;
            }
            h2,h3,h4,h5{
                font-family: $neuzeit !important;
            }
            h1{
                font-family: $eurostileext !important;
            }
        }
        #RegistrationForm .form-row.form-row-button.register {
            margin-left: 0;
        }
    }
} 

#wrapper #main #secondary {
    nav .secondary-navigation ul li a,
    .account-nav-asset p {
            color: $darkgrey;
    }
}

#wrapper.pt_account #primary a.button {
    color: $white !important;
    line-height: 32px !important;
    &:hover {
        color: $white !important;
    }
}
.ui-dialog #dialog-container #CreditCardForm .form-row.form-row-button #applyBtn,
.ui-dialog #dialog-container #edit-address-form .form-row.form-row-button .apply-button {
    background: $darkgrey;
    color: $white;
    &:hover {
        background-color: $black;
        color: $white;
    }
}

.ui-dialog button {
    font-size: 16px;
}

#wrapper.pt_account #primary a.button.add-card,
#wrapper.pt_account #primary a.button.address-create {
    height: 40px;
    line-height: 37px !important;
    font-weight: 400;
    font-size: 16px;
    &:hover {
        color: $white !important;
    }
}