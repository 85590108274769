.pt_product-search-result {
	.product-tile {
		.product-pricing {
			font-weight: 400;
		}
        .product-pricing,
        .product-name a {
            font-size: 15px;
        }
        .product-name a {
            @include respond(largeUp) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                height: 20px;
            }
        }
    }
}


.pt_product-search-result,
.pt_product-details,
.pt_account {
	.product-tile {
		.pr-clp-raing,
		.product-category,
		.members-only-login {
			@include respond(largeUp) {
				position: absolute;
				top: 40px;
				background-color: $white;
				width: 100%;
			}
		}
        .product-tile-details {
			@include respond(largeUp) {
				height: 87px;
				padding-bottom: 5px;
			}
		}
		.pr-clp-raing {
			z-index: 999;
            @include respond(largeUp) {
                top: 23px;
            }
			.pr-category-snippet {
				margin: 0;
			}
		}

		.product-swatches {
            display: block;
			ul {
				text-align: left;
				li {
					display: block;
					float: left;
                    margin: 0 5px 0 0;
					padding: 0;
					width: 34px;
					&.open-close {
						margin-left: 3px;
						line-height: 20px;
						width: auto;
						@include respond(largeUp) {
							margin-left: 8px;
							line-height: 36px;
						}
						@include respond(large) {
							margin-left: 3px;
							line-height: 20px;
						}
						@include respond(extraLarge) {
							margin-left: 3px;
							line-height: 30px;
						}
					}
                    @media only screen and (min-width: 1080px) and (max-width: 1380px) {
                        width: calc((100% / 7) - 12px);
                    }

					@include respond(largeUp) {
						margin: 0 5px 0 0;
						width: 34px;
					}

                    .swatch {
                        border-radius: 50%;
                        padding: 4px;
                        border: 1px solid $white;
                        background: $white;

                        &.selected,
                        &:hover {
                            border: 1px solid $darkgrey;
                        }
                    }

                    .swatch-image {
                        border-radius: 50% !important;
                    }
				}
			}
            .colors-available {
                display: none;
            }
		}
	}
	.search-result-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .grid-tile {
            overflow: hidden;
            flex: 0 0 25%;
            margin-bottom: 0;
			&.wide-tile-1x {
				margin-bottom: 22px;
				.product-tile {
					height: 100%;
				}
				@include respond(small) {
					margin-bottom: 16px;
				}
				.product-image {
					position: relative;
					overflow: hidden;
					height: 100%;

					img {
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						max-width: none;
						object-fit: cover;
					}
				}
			}
            &.wide-tile-2x {
                flex: 0 0 50%;
				margin-bottom: 20px;
            }
            &.wide-tile-3x {
                width: 100%;
                flex-basis: 100%;
                margin-bottom: 20px;
            }
            @include respond(small) {
                flex: 0 0 calc(50% - 8px);
                &.wide-tile-2x {
                    flex: 0 0 100%;
                    margin: 0 0 20px;
                }

            }
        }
    }
}
