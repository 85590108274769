
.pt_product-details {
    .breadcrumb {
        @include respond(largeUp) {
            position: relative;
            padding: 25px 0 20px;
            top: 0;
        }
    }
}

.pdp-main {
    .product-name {
        font-size: rem(22px);
        font-family: $eurostile;
        font-weight: 700;
        @media screen and (max-width: 475px) {
            margin-top: 30px;
        }
    }
    .product-main-info {
        @include respond(largeUp) {
            margin-bottom: 35px;
        }
        @include respond(small) {
            width: 92%;
        }
    }
    .overview-container {
        .pdp-description {
            h2,
            h3,
            .long-description {
                color: $darkgrey;
            }

            h2 {
                font-family: $eurostileext;
                font-weight: 400;
                text-transform: uppercase;
                font-size: rem(36px);
                line-height: 1;

                @include respond(largeUp) {
                    font-size: rem(60px);
                    line-height: 53px;
                }
            }

            h3 {
                text-transform: uppercase;
                margin-top: 20px;
            }

            h3,
            .long-description {
                font-family: $neuzeit;
                font-size: rem(22px);
                font-weight: 700;
            }

            .long-description {
                font-weight: 400;
                margin-top: 25px;
            }

            .tlt-overview {
                font-size: rem(22px);
            }
        }

        .pdp-overview {
            h3 {
                font-family: $neuzeit;
                font-size: rem(20px);
                font-weight: 700;
                text-transform: uppercase;
                color: $black;
                margin-top: 0;
                &:not(:first-child) {
                    margin-top: 15px;
                }
            }
            ul li {
                padding: 0 0 0 15px;
                font-size: rem(16px);
                color: $black;
            }
        }
    }
    .product-variations .attribute .selected-value,
    .product-price .price-sales,
    .product-variations .attribute,
    .product-variations .attribute .label  {
        font-size: 15px;
        color: $black;
        font-weight: 400;
    }
    .product-col-2 {
        &.has-embroidery {
            position: relative;
        }
        #product-content {
            .pdp-promo-container {
                background-color: $white;
                .promotion {
                    background-color: $white;
                    &.promotion-notempty {
                        background-color: $lightestgrey;
                    }
                }
                &.no-promos {
                    display: none;
                }
            }
        }
        .personalize-lnk {
            padding: 0 0 5px;
            clear: both;
            display: flex;
            align-items: center;
            @include respond(largeUp) {
                padding: 17px 0 3px;
            }
            img {
                margin-right: 10px;
            }
        }
    }
    .product-add-to-cart .add-to-cart {
        background-color: $darkgrey;
        border-color: $darkgrey;
        font-weight: 400;
        font-size: 16px;
        &:hover {
            background-color: $black;
            border-color: $black;
            color: $white;
        }
        &[disabled],
        &.disabled {
            background-color: $gray-light;
            border-color: $gray-dark;
            color: $gray-dark;
            opacity: 0.5;
            &:hover {
                background-color: $gray-light;
                border-color: $gray-dark;
                color: $gray-dark;
                opacity: 0.5;
            }
        }
    }
    .add-to-cart {
        line-height: 16px;
    }
}

.embroidery-wrap {
    position: relative;
    clear: both;
    padding: 0 0 12px;
    @include respond(largeUp) {
        padding: 8px 32px 0;
    }
    input[type="checkbox"] + label {
        padding-left: 30px;
        background: url(../images/icons/pdpcheckbox_unchecked.png) left 2px no-repeat;
        line-height: 26px;
    }
    input[type="checkbox"]:checked + label,
    input[type="checkbox"]:checked + span + label {
        padding-left: 30px;
        background:url('../images/icons/pdpcheckbox_checked.png') left 2px no-repeat;
    }
    label {
        text-transform: capitalize;
    }
    #editEmbroidery {
        display: block;
        padding: 6px 0;
    }
}
.ui-modal-config {
    width: auto !important;
    @include respond(small) {
        padding: 0 !important;
    }
    .ui-dialog-title {
        display: none;
    }
    .ui-dialog-titlebar-close {
        @include respond(small) {
            right: 0 !important;
        }
    }
    .ui-widget-header .ui-button .ui-button-icon-primary.ui-icon-closethick {
        margin-top: 10px;
    }
    max-width: 810px;
}
.modal-embroidery,
#swatch-preview {
    display: none;
    .embroidery-fabric {
        position: relative;
        .fab-img {
            width: 100%;
        }
        .emb-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 25px;
            margin-top: -10px;
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }
        .embscript,
        .embscript2 {
            font-family: $brushscript;
        }
        .embarial,
        .embarial2 {
            font-family: $avantgarde;
        }
        .embdiamond {
            margin-top: 0;
            .emb-diamondbg {
                background: url('../images/embroidery/1-Diamond.png') no-repeat;
                width: 275px;
                height: 167px;
                margin: 0 auto;
                text-align: center;
            }
            .diamond-border {
                display: none;
                &.diamond-val {
                    display: block;
                }
            }
        }
    }
    #embroideryFilteringError,
    .emb-error {
        margin-top: 10px;
        color: $red;
        a {
            color: $red;
            text-decoration: underline;
            &:hover {
                color: $red;
            }
        }
    }
    .emb-error {
        display: none;
    }
    h2 {
        text-align: center;
        margin-top: -25px;
        font-size: 26px;
        font-weight: 700;
    }
    .modal-container {
        @include respond(largeUp) {
            display: flex;
        }
    }
    input[type="radio"] + label {
        background-size: 20px 20px;
        padding-left: 30px;
        margin: 13px 0;
        color: $black;
    }
    input[type="radio"]:checked + label {
        background: url("../images/radio_checked.svg") left 0 no-repeat;
        background-size: 20px 20px;
        padding-left: 30px;
    }
    .embroidery-options {
        @include respond(small) {
            padding-top: 15px;
        }
        @include respond(largeUp) {
            padding-left: 30px;
            width: 340px;
        }
    }
    .lbl-diamond {
        background-position: 0 3px;
    }
    .embroidery-remove-button {
        text-decoration: underline;
    }
    #embroiderytext-error,
    .embroidery-remove-button,
    .caption {
        display: block;
    }
    #embroiderytext2 {
        margin-top: 10px;
    }

    .embroidery-save-button {
        margin: 15px 0;
        margin-right: 40px !important;
    }
    .caption {
        font-size: 13px;
        margin-top: 6px;
    }
    .emb-max2 {
        display: none;
    }
    select,
    input[type="text"] {
        max-width: 300px;
    }
    p {
        font-size: 13px;
    }
    .style-rads {
        label {
            display: flex;
            align-items: center;
            .embroidery-img {
                margin-left: 15px;
            }
        }
    }
    .step-label {
        font-size: 20px;
        font-weight: 700;
        margin: 18px 0 8px;
        color: $black;
        &.step-one {
            margin-top: 0;
        }
    }
}

.emb-2lines {
    .embarial,
    .embscript {
        margin-top: -20px !important;
    }
    .embarial2 {
        margin-top: 15px !important;
    }
    .embscript2 {
        margin-top: 15px !important;
    }
}

.embroidery-image {
    p.desktop-only {
        max-width: 420px;
    }
}

#swatch-preview {
    .embroidery-image,
    .embroidery-fabric {
        max-height: 75px;
        overflow: hidden;
        margin-top: 5px;
        line-height: 75px;
        .embscript,
        .embscript2 {
            margin-top: -4px;
        }
        .embarial,
        .embarial2 {
            margin-top: 4px;
        }
        &.emb-2lines {
            max-height: 160px;
            overflow: hidden;
            margin-top: 5px;
            line-height: 160px;
            .embroidery-fabric {
                max-height: 160px;
                line-height: 160px;
            }
        }
    }
    .line2-only {
        .embarial2,
        .embscript2 {
            margin-top: 0 !important;
        }
    }
    &.diamond-wrap {
        max-height: 177px;
        line-height: 177px;
        .embroidery-image,
        .embroidery-fabric {
            max-height: 177px;
        }
    }
}

.p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write a {
    font-family: $neuzeit;
}

.ui-dialog.zoom-image-dialog .zoom-content .zoom-image-wrapper img {
    width: auto;
}

.product-primary-image .primary-image {
    @include respond(1200px) {
        width: 100%;
    }
}

#wrapper.pt_product-details .pdp-spredfast {
    .content-asset {
        display: none;
    }
}

.pdp-power-reviews .reviews-tab-header .review-tlt {
    display: none;
}
#wrapper.pt_product-details .pdp-spredfast {
    .content-asset {
        display: none;
    }
}

.product-add-to-cart-sticky {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	height: 0;
	transition: height 0.5s ease-out;

	.add-to-cart-sticky {
		width: 100%;
		height: 100%;
		border-radius: 0;
		font-size: 18px;
        opacity: 1;
	}

	&.product-add-to-cart-sticky-visible {
		height: 58px;
		@include respond(largeUp) {
			display: none;
		}
	}
}

#pdpMain {
    .primary-image {
        opacity: 0;

        &.lazyloaded {
            opacity: 1;
        }
    }

    .slider {
        opacity: 0;

        &.slick-initialized {
            opacity: 1;
        }
    }

    .carousel {
        opacity: 0;
        &.slick-initialized {
            opacity: 1;
        }
    }
}
