// _customer_service.scss

#wrapper.pt_customer-service{
	#main{
		@include respond(not-large){
			padding: 0 60px;
		}
		@include respond(large){
			box-sizing: border-box;
			padding-left: 15px;
			padding-right: 15px;
		}
		.primary-content {
			border-left: none;
			.dialog-required {
				float: right;
				clear: both;
				width: 100%;
				text-align: right;
				line-height: 100%;
				.required-indicator {
					font-weight: 400;
					font-style: normal;
					color: #999;
					font-size: 11px;
					em {
						font-style: normal;
						text-transform: capitalize;
						letter-spacing: 1px;
					}
				}
    		}
    		.contactus-form{
    			.form-row {
    				margin: 8px 0 12px;
    			}
    		}
		}
		#secondary{
			padding-top: 0;
			.secondary-navigation{
				@include respond(small) {
					border: 0;
					.customer-service-side-nav{
						ul{
							margin-top: 10px;
						}
					}
				}
			}
		}
	}
	#primary{
		width: 38%;
		padding-left: 2%;
		float: left;
		&.long-content{
			width: 80%;
		}
		@include respond (large) {
			width: 56%;
			padding-left: 1%;
		}
		@include respond(small) {
			width: 92%;
			padding-left: 4%;
		}

		.form-horizontal {
			.field-wrapper{
				textarea{
					width: 100%;
				}
			}
			label {
				text-align: left;
				width: auto;
			}
			@include respond(small) {
				.form-row {
					margin: 0rem;
					button {
						margin-left: 0 !important;
						padding-top: 0;
						padding-bottom: 0;
						height: 34px;
						line-height: 30px;
					}
				}
			}
			.form-row {
				button	{
					margin-left: 123px;
					@include respond(large){
						margin-left: 113px;
					}
				}
			}

			.field-wrapper {
				width: 75%;
				float: right;
				@include respond(small){
					width: 100%;
					float: right;
					margin-top: 1%;
				}
				@include respond(large){
					width: 75%;
				}
			}
		}
		h1 {
		    font-family: $oswald;
		    font-weight: bold;
		    font-size: 30px;
		    text-transform: uppercase;
		    letter-spacing: 0.1em;
		    color: #333;
		    line-height: 120%;
		    font-style: normal;
		    @include respond (small){
		    	font-size: 1.5rem;
		    }
		}
		span {
			color: #333;
			font-family: $titillium;
		    text-transform: uppercase;
		    font-weight: bold;
    	}

		h2{
			margin-top: .5em;
			clear: both;
			@include respond(not-large){
				font-size: 1.1em;
			}
		}
		.contactusTopContent{
			margin-bottom: 1em;
		}

		.form-row.noLabel{
			.field-wrapper{
				border: 6px solid #efefef;
				padding-left: 0;
				width: 100%;

				.selector{
					width: 100%;
				}
				select{
					width: 100%;
				}
			}
		}

		.confirmation-message{
			width: 80%;
			font-weight: 400;
			line-height: 1.5em;
			clear: both;
			border-bottom: 0;
			p{
				font-size: 14px;
				color: $gray-warm;
				margin: 0;
				padding: 0 0 20px 0;
			}
			@include respond(not-large){
				width: 80%;
				margin-top: 2em;
			}

			img{
				float: right;
				margin-bottom: 2em;
				@include respond(not-large){
					display: none;
				}
			}
			h1{
				font-size: 5em;
				line-height: 1em;
				display: block;
				text-align: right;
			}
			button{
				margin-top: 2em;
				display: block;
			}
		}

		#bottomContent{
			border-top: 1px solid $gray-white;
			margin-top: 1em;
			padding-top: 1em;

			h2{

				@include font-size(20px);
				margin-top: 1.5em;
				text-transform: capitalize;
			}
		}
	}
	#secondary{

		background: none;
		border: none;
		width: 16%;
		margin-right: 1%;
		@include respond (large) {
			width: 26%;
		}
		nav {
			padding: 0;
		}
		@include respond (small) {
			width: 96%;
			padding: 4% 0% 4% 4%;
		}
		.customer-service-side-nav {
			h3 {
			    font-family: $oswald;
			    font-weight: bold;
			    font-size: 20px;
			    text-transform: uppercase;
			    letter-spacing: 2px;
			    color: #333;
			    padding: 0;
			    margin: 0 0 10px 0;
			}
			ul {
				li {
					@include respond (small) {
						float: left;
					}
					a {
						color: #999;
						font-family: $titillium;
						text-transform: uppercase;
						font-weight: normal;
						letter-spacing: .5px;
						padding-top: .25rem;
						padding-bottom: .25rem;
						width: 100%;
					}
				}
			}
		}
	}
}

//all toggles and the google-map infobubbles
#wrapper #primary .toggle, .storeBubble{
	> h3{
		@include font-size(14px);
		padding: 1em;
		background-color: $gray-white;
		cursor: pointer;
		margin-bottom: 0;
		text-transform: uppercase;

		&:after{
			float: right;
			content: ' ';
			width:12px;
			height: 12px;
			display: block;
		}
	}
	> .toggleContent{
		display: none;
		&:after{
			content: '';
			width: 100%;
			display: block;
			clear: both;
			width: 100%;
		}
	}
	&.expanded{
		> h3{
			background-color: $gray-light;

			&:after{
				content: ' ';
			}
		}
		> .toggleContent{
			padding: 1em 0;
			display: block;
			border: 1px solid $gray-light;
			border-top: 0;

			.content-asset{
				padding: 0 1.5em;
				box-sizing: border-box;
				width: 33%;
				float: left;
				@include respond(medium){
					width: 50%;
					margin-top: 1em;

					&:first-child{
						width: 100%;
					}
				}
				@include respond(small){
					float: none;
					width: 100%;
					margin-top: 1em;
				}

				h4{
					margin-top: 0;
					line-height: 1em;
				}
			}
		}
		&:after{
			content: '';
			width: 100%;
			display: block;
			clear: both;
		}
	}
}
.storeBubble{
	>h3{
		padding: 0;

		&:after{
			display: none;
		}
		svg{
			width: 20px;
			height: 20px;
			background-color: #fff;
			padding: 7px;
			margin: 1px;
		}
	}
	.toggleContent{
		display: block;
		.storedetailitem{
			.address, .openTimes, .locationType, .features{
				width: 50%;
				min-height: 150px;
				padding: 0 33px;
				h3{
					padding: 0 0 1em 0;
					background-color: transparent;
					cursor: initial;
				}
				.button{
					width: 228px;
					bottom: 0px;
					left: 1px;
				}
			}
			.locationType{
				clear: left;
				.button{
					width: 229px;
				}
			}
		}
	}
}

.customer-service-contact {
	width: 20%;
	float: left;
	margin-left: 7%;
    margin-top: 46px;
	font-family: $titillium;
	@include respond(large){
		width: 14%;
		margin-left: 2%;
	}
	@include respond(small){
		width: 80%;
		float: left;
		margin-left: 4%;
		margin-top: 0;
		margin-bottom: 20px;
	}
	h3 {
		font-size: 14px;
		font-family: $titillium;
		text-transform: uppercase;
		margin: 0;
	}
	p {
		color: #999;
		font-size: 14px;
    	line-height: 21px;
		margin: 0;
	}
	.paragraph-black {
		color: $black;
		margin-top: 1rem;
		float: left;
		width: 100%;
	}
}

.top-border {
    width: 100%;
	height: 0;
	float: left;
	margin-bottom: 2.5rem;
	margin-top: 0rem;
}

.customer-service-mobile-drawer {
	display: none;
	@include respond(small){
		display: block;
		float: left;
		width: 100%;
		margin-bottom: 1rem;
		.content {
			display: none;
		}
		.header {
			background: $black;
			padding: .65rem 1rem;
			color: $white;
			border-radius: 1.25rem;
			width: 89%;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: .75rem;
			font-family: $titillium;
			@include respond(small){
				width: 100%;
				box-sizing: border-box;
				&:after {
					content: ">";
					float: right;
					font-size: 30px;
					font-weight: normal;
					line-height: 16px;
				}
				&.open:after {
					-ms-transform: rotate(90deg); /* IE 9 */
				    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
				    transform: rotate(90deg);
				    -webkit-transition-duration: 0.5s;
					-moz-transition-duration: 0.5s;
					-o-transition-duration: 0.5s;
					 transition-duration: 0.5s;
					 line-height: 20px;
			    }
			}
		}
		h3 {
			display: none;
		}
		.customer-service-side-nav {
	 		margin-left: 1rem;
	 		ul {
	 			li {
					width: 100%;
					a {
						float: left;
						margin-top: -2px;
					}
				}
			}
		}
	}
}

// IE fix for form element width issue

#RegistrationForm.contactus-form {
	.form-row {
		.form-caption.error-message {
			margin-left: 27%;
			@include respond(small) {
				margin-left: 0;
			}
		}
	}
	#product-register-forms {
		.form-row {
			.form-caption.error-message {
				margin: 0;
				width: 100%;
			}
		}
	}
}

.contactus-form {
	width: 100%;
	float: left;
	.form-row{
		@include respond(small){
			margin: 8px 0;
			label{
				margin-top: 0;
			}
		}
		select.error{
			background-color: $gray-white;
		}
		.field-wrapper{
			textarea.error{
				background-color: $gray-white;
				border-color: $error-red!important;
			}
		}

	}
}

.desktop-only {
	display: block;
	@include respond(small){
		display: none;
	}
}
#RegistrationForm{
	.form-row{
		input{
			line-height: 160%;
			padding: 0.6em 1.5em;
			height: 34px;
		}
		select{
			height: 34px;
			line-height: 30px;
		}
		textarea{
			border-color: $gray-light;
		}
		.form-caption.error-message{
			margin-left: 20%;
			line-height: 120%;
			@include respond(large){
				margin-left: 27%;
			}
			@include respond(small){
				margin-left: 0;
			}
		}
	}
}

#wrapper.pt_email-sub {
	.newsletter-header {
		max-width: 1200px;
		margin: 0 auto;
		@include respond(large){
			max-width: 768px;
		}
		@include respond(small){
			max-width: 100%;
		}
	}
	#main {
		min-height: 400px;
		.signup-terms {
			margin-left: 123px;
			color: #999;
			a {
				color: #999;
				text-decoration: underline;
			}
			@include respond(small){
				margin: 20px 0 20px 0;
			}
		}
	}
}