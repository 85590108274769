.p-w-r .pr-snippet.pr-category-snippet {
    .pr-category-snippet__item {
        display: table-cell !important;
    }
}

.reviews-container .p-w-r .pr-review-snapshot .pr-review-snapshot-snippets {
    .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars .pr-snippet-read-and-write .pr-snippet-write-review-link {
        @include blackButton;
        font-weight: 400;
    }
}

.reviews-container .p-w-r .pr-review-display {
    .pr-helpful-btn {
        border: none !important;
    }
    .pr-qa-helpful-cta {
        padding-right: 10px;
    }
}

#reviews {
    .p-w-r {
        .pr-ggl {
            display: none;
        }
        .reviews-wrapper {
            width: 100vw;
            max-width: 100vw;
            margin-left: calc((100vw - 100%)/-2);
            background: #f0f0f0;
            position: relative;
        }
        .pr-review-display {
            @include respond(largeUp) {
                max-width: 78rem;
                margin: auto;
                background: transparent;
                position: relative;

                .pr-rd-main-header {
                    width: 100vw;
                    margin-left: calc((100vw - 100%)/-2);
                    text-align: center;
                }
                .pr-rd-main-header-search-sort {
                    display: none;
                }
                .slick-list {
                    max-width: rem(1200px);
                    background-color: transparent;
                    margin: auto;
                }
            }
            @include respond(small) {
                margin: 0 20px;
            }
            .pr-review {
                @include respond(small) {
                    max-width: none !important;
                    margin: 0 20px !important;
                }
                @include respond(largeUp) {
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .slick-arrow {
                &:hover {
                    border: none;
                }
                top: 50% !important;
            }
            .view-all-reviews {
                float: none;
                margin-left: 10px;
            }
        }
    }
}

#modal-reviews .p-w-r .pr-review-display {
    .pr-rd-reviewer-details {
        .pr-rd-author-submission-date {
            min-width: 100px;
        }
    }
}


.reviews-wrapper {
    .slick-prev,
    .slick-next,
    .pr-rd-main-footer {
        display: none !important;
    }

    .pr-rd-main-header {
        margin-bottom: 0 !important;
    }
}
