// footjoy fonts
$titillium: 'Titillium Web', sans-serif; //regular & bold
$oswald: 'Oswald', sans-serif; //light and bold
$arial: Arial, sans-serif; //regular
$din-condensed: din-condensed, sans-serif;
$din2014narrow: din-2014-narrow, sans-serif;
$athelas: athelas, serif;
$neuzeit: neuzeit-grotesk, sans-serif;
// usage: @include font-regular();
// ex: @include font-regular(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-regular(14px, $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs

@mixin font-regular($size:"", $breakpoint:"") {
    font-family: $din-condensed;
    font-weight: 400;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-semibold($size:"", $breakpoint:"") {
    font-family: $oswald;
    font-weight: 700;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-bold($size:"", $breakpoint:"") {
    font-family: $titillium;
    font-weight: 700;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-regular-secondary($size:"", $breakpoint:"") {
    font-family: $din-condensed;
    font-weight: 300;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-small($size:"", $breakpoint:"") {
    font-family: $neuzeit, serif;
    font-weight: 300;
    font-style: normal;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-special($size:"", $breakpoint:"") {
    font-family: $athelas, serif;
    font-weight: 700;
    font-style: normal;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}







