#wrapper.pt_content {

    #primary {

        .gift-card-landing {
            margin-top: 16px;
            @include respond(largeUp) {
                margin-top: 0;
                margin-bottom: 64px;
            }

            .background-container {
                text-align: center;
                background: linear-gradient(0deg, rgba(219,219,219,0) 0%, rgba(219,219,219,1) 100%);

                img {
                    max-width: 100%
                }

            }

            .content-wrapper {
                padding: 0 32px;
                margin-top: -136px;
                margin-bottom: 46px;
                text-align: center;
                @include respond(largeUp) {
                    margin-top: 0;
                }

                .page-title {
                    padding-bottom: 8px;
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: normal;
                    @include respond(largeUp) {
                        font-size: 30px;
                    }
                }

                p {
                    font-family: $neuzeit;
                    letter-spacing: normal;
                    color: $black;
                }

                .page-subtitle {
                    margin-bottom: 20px;
                    font-size: 16px;
                }

                .cta-block {
                    margin-bottom: 34px;
                    @include respond(largeUp) {
                        margin-top: -146px;
                    }

                    .button {
                        display: block;
                        width: 100%;
                        max-width: 305px;
                        height: 50px;
                        margin: 0 auto;
                        font-size: 24px;
                        line-height: 48px;
                        letter-spacing: .06rem;
                    }

                    .or-divider {
                        display: block;
                        text-transform: uppercase;
                        font-size: 24px;
                        margin: 18px 0;
                    }

                }

            }

            @include respond(largeUp) {
                .cards-wrapper {
                    display: flex;
                    justify-content: center;

                    .card-item {
                        width: 40%;
                        margin: 0 12px;
                        .cart-image {
                            background: linear-gradient(0deg, rgba(219,219,219,0) 0%, rgba(219,219,219,1) 100%);
                        }
                    }
                }
            }

        }
    }
}

