////////////////////////
// Checkout Billing Step
////////////////////////
#wrapper.pt_checkout {
	#main {
		#primary {
			.billing-coupon-code {
                .label-above {
                    label {
                        display: none;
                    }
                }
				button#add-coupon {
					font-size: 14px;
                    height: 42px;
                    line-height: 30px;
                    left: 0;
                    font-size: 14px;
                    width: 98px;
					@include respond(small) {
						top: 0;
                        left: 216px;
					}
				}
            }
            .checkout-billing {
                .applepay-tab {
                    display: none;
                }
                .apple-pay-on {
                    display: block;
                }
            }
        }
    }
}
#wrapper.pt_checkout #main #primary {
    .checkout-billing > .form-row.form-row-button button,
    .continue-btn .form-row.form-row-button button {
        background-color: $darkgrey;
        border-color: $darkgrey;
        font-weight: 400;
        &:hover {
            background-color: $black;
            border-color: $black;
            color: $white;             
        }
    }
} 