html {
	overflow-x: hidden; //to prevent excess horizontal scrolling
}

#wrapper {
	background-color: $white-bg;
	border: 0;
	left: 0;
	margin: auto;
	position: relative;
	width: 100%;

	&.pt_storefront {
		width: 100%;
		@include respond(small){
			background-color: $white;
		}
	}
	&.pt_customer-service {
		width: 100%;
	}
	&.pt_categorylanding {
		width: 100%;
		.primary-content {
			padding-bottom: 0;
			@include respond(largeUp) {
				width: 80%;
			}
		}
		.refinements {
			border-right: 0;
			display: none;
			width: 0;
			@include respond(largeUp) {
				display: block;
				width: 20%;
			}
		}
	}

	&.menu-active {
		overflow: hidden;
		position: relative;
		@include respond(small){
			height: 100vh;
		}
		&> div,
		& > footer{
			left: 100%;
			position: relative;
			transition: left $quickSpeed;

			@include respond(largeUp) {
				left: auto;
			}
		}
	}
	@include respond(largeUp) {
		&.menu-active {
			left: auto;

		}
	}
}

.primary-content {
	width: 100%;
	@include respond(largeUp) {
		float: right;
		padding: 0;
		position: relative;
		width: calc(100% - 230px);
	}
	&.full-width-tablet {
		@include respond(large) {
			width: 100%;
		}
	}
	.primary-focus & {
		border: 0 none;
		float: left;
		margin: 0;
		width: 76%;
	}
	.full-width & {
		border: 0 none;
		width: 100%;
	}
	.page-content & {
		padding-left: 3%;
		width: 758px;
	}
}

#secondary {
	border: 0;
	float: none;
	font-size: 1.1em;
	width: 100%;

	@include respond(largeUp) {
		float: left;
		width: 192px;
		position: relative;

		nav {
			padding-left: 10px;
			padding-right: 10px;
			a {
				display: block;
			}
		}
		.primary-focus & {
			background: none;
			border: none;
			box-sizing: border-box;
			float: left;
			padding-left: .4rem;
			width: 24%;
		}
	}
}

.secondary-content {
	clear: both;
}

.ui-dialog {

	&.intersitial-message-modal {
		.dialog-content {
			width: 100% !important;
		}
		.countries-box {
			@include countries-box;
			img {
				height: 45px;
				margin: 20px auto;
			}
		}
		@include respond(small) {
			width: 90% !important;
			padding: 0 5px !important;
		}
	}
}
#main {
	#parcellab-track-and-trace {
		padding: 30px 0;
		text-transform: initial;

		.pl-container {
			padding: 0 28px;

			@include respond(standard) {
				padding: 0 50px;
				max-width: 1242px;
			}

			@include respond(1441px) {
				padding: 0 150px;
				max-width: none;
			}
		}

		h1, h2, h3, h4, h5, .ui-dialog .ui-dialog-title  {
			letter-spacing: .05em;
			color: $black;
		}

		h1, h2, h3, h4, h5,
		div[data-testid="checkpoint-status"],
		.pl-button,
		.pl-vote-label,
		.pl-cal-day,
		.pl-cal-month,
		.pl-box-address-caption,
		.pl-article-item-number,
		.pl-article-item-number span,
		.pl-checkpoint-time,
		.pl-checkpoint-time span {
			color: $black;
			opacity: 1;
		}

		.pl-time-box-caption,
		#pl-courier-fwd-link {
			color: #4c4c4c !important;
		}

		.pl-box-cal-heading {
			padding-bottom: 65px;

			@include respond(small) {
				padding-top: 65px;
			}
		}

		.pl-calendar {
			padding-bottom: 65px;
		}

		.pl-cal-day {
			line-height: 1;
		}

		.pl-time-box-caption.pl-time-box-caption {
			margin-top: 20px;
			margin-bottom: 20px;
			text-align: center;
		}

		.pl-box-heading {
			font-weight: bold;
		}

		.pl-article-item-number {
			text-transform: uppercase;
			font-weight: bold;
		}

		.pl-box-address {
			text-align: center;
		}

		.pl-box-address-caption {
			text-transform: capitalize;
			display: flex;
			justify-content: center;
			gap: 5px;
		}

		h2, .ui-dialog .ui-dialog-title {
			text-transform: initial;
		}

		div.pl-tab.pl-active {
			font-weight: bold;
		}
		.pl-tab-container {
			margin-bottom: 5px;
			display: flex;
			flex-wrap: wrap;
			gap: 10px 16px;
			padding: 0 8px;
		}
		.pl-col {
			padding: 8px;
		}
		.pl-order-sub {
			color: $black;
		}
		div.pl-status {
			top: 5px;
		}
		.pl-tab-container {
			& > div {
				padding: 0;

				& > div {
					margin: 0;
				}
			}

			@include respond(largeUp) {
				.pl-col-1 {
					width: calc(8.33333333% - 16px);
					flex: 0 0 calc(8.33333333% - 16px);
				}

				.pl-col-2 {
					width: calc(16.66666667% - 16px);
					flex: 0 0 calc(16.66666667% - 16px);
				}

				.pl-col-3 {
					width: calc(25% - 11px);
					flex: 0 0 calc(25% - 11px);
				}

				.pl-col-4 {
					width: calc(33.33333333% - 11px);
					flex: 0 0 calc(33.33333333% - 11px);
				}

				.pl-col-5 {
					width: calc(41.66666667% - 16px);
					flex: 0 0 calc(41.66666667% - 16px);
				}

				.pl-col-6 {
					width: calc(50% - 8px);
					flex: 0 0 calc(50% - 8px);
				}

				.pl-col-7 {
					width: calc(58.33333333% - 16px);
					flex: 0 0 calc(58.33333333% - 16px);
				}

				.pl-col-8 {
					width: calc(66.66666667% - 16px);
					flex: 0 0 calc(66.66666667% - 16px);
				}

				.pl-col-9 {
					width: calc(75% - 16px);
					flex: 0 0 calc(75% - 16px);
				}

				.pl-col-10 {
					width: calc(83.33333333% - 16px);
					flex: 0 0 calc(83.33333333% - 16px);
				}

				.pl-col-11 {
					width: calc(91.66666667% - 16px);
					flex: 0 0 calc(91.66666667% - 16px);
				}

				.pl-col-12 {
					width: 100%;
					flex: 0 0 100%;
				}
			}
		}
		.pl-space-bottom,
		.pl-vote {
			margin-bottom: 10px;
		}
		.pl-action-box .pl-box-heading {
			font-weight: bold;
		}
	}
	#pl-reroute-link,
	.pl-box-aside-left .pl-space-bottom.pl-branding {
		display: none;
	}

	#parcellab-track-and-trace-ui-wrapper a {
		border-bottom: none;
	}
}
