.plp-actions {
    .plp-actions-button {
        .sort-by select,
        label {
            text-transform: none !important;
        }
        .plp-actions-toggle-refine {
            text-transform: none;
        }
        .sort-by select {
            font-family: $neuzeit;
        }
    }
}

.plp-actions .plp-actions-button {
    margin: 0;
}

#refined-by-attributes {
    border-left: none;
    border-right: none;
}

.refinement-header-wrapper {
    .breadcrumb {
        margin-bottom: 15px;
        @include respond(largeUp) {
            margin-left: 0;
        }
    }
}

#wrapper.pt_product-search-result {
    #main {
        @include respond(small) {
            margin-top: rem(100px);
        }
    }
}