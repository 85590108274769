.fj_account {
    #wrapper {
        &.pt_wish-list,
        &.pt_account,
        &.pt_order {
            .golfer-profile-wrapper {
                font-family: $neuzeit;
                width: 100%;
                padding-bottom: 2%;
                border-bottom: solid 2px $gray-light3;
                @include respond(small) {
                    margin-top: 135px;
                }
                &.hide-acctMenu {
                    display: none;
                }
                .profile-tabs,
                .golfer-profile-hdr {
                    margin: auto 15px;
                    @include respond(largeUp) {
                        width: 90%;
                        margin: auto 5%;
                    }
                }
                .golfer-profile-hdr {
                    display: flex;
                    align-items: center;
                    @include respond(largeUp) {
                        margin-top: rem(130px);
                    }
                    .profile-icon {
                        min-width: rem(68px);
                        min-height: rem(68px);
                        background: url('../images/icons/icon-account.svg') no-repeat 8px -4px;
                        background-size: 83% 83%;
                        border-radius: 50%;
                        background-color: $gray-lighter;
                        margin: 0 15px auto 0;
                        @include respond(largeUp) {
                            width: rem(80px);
                            height: rem(80px);
                            margin-right: 25px;
                            background-position:  10px -4px;
                        }
                    }
                    .profile-name {
                        @include respond(largeUp) {
                            display: flex;
                            align-items: center;
                        }
                        img {
                            @include respond(small) {
                                height: 20px;
                            }
                        }
                    }
                    h1 {
                        font-size: rem(24px);
                        font-family: $neuzeit;
                        margin: 5px 0;
                        font-weight: 700;
                        text-transform: none;
                        padding: 0;
                        color: $black;
                        letter-spacing: normal;
                        line-height: rem(31px);
                        @include respond(largeUp) {
                            font-size: rem(36px);
                            margin: 0;
                            padding-right: rem(20px);
                        }
                    }
                    .progress-container {
                        margin: 6px 0 8px;
                        color: $black;
                        span {
                            display: block;
                            &.progres-bar-complete {
                                margin-bottom: 6px;
                                font-size: 14px;
                                font-weight: bold;
                                text-transform: uppercase;
                                letter-spacing: .075rem;
                            }
                        }
                        .progress-bg {
                            position: relative;
                            width: rem(200px);
                            height: rem(9px);
                            background: $gray-lighter;
                            @include respond(small) {
                                margin: 10px 0;
                                width: rem(177px);
                            }
                            .progress-bar {
                                position: absolute;
                                height: 100%;
                                width: 39%;
                                background-color: $green-light;
                            }
                        }
                    }
                    .progress-links {
                        .progres-bar-text,
                        .progres-tab-link a {
                            margin-right: 10px;
                            font-size: 12px;
                            letter-spacing: normal;
                        }
                        .progres-bar-text {
                            @include respond(small) {
                                display: block;
                                margin-bottom: 4px;
                            }
                        }
                        a {
                            font-weight: 700;
                            color: $black;
                            text-decoration: underline;
                        }
                    }
                }
                .profile-tabs {
                    display: flex;
                    align-items: center;
                    margin-top: 31px;
                    @include respond(small) {
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
                    .profile-tab {
                        display: flex;
                        align-items: center;
                        border: 1px solid $gray-light3;
                        flex-grow: 1;
                        justify-content: center;
                        margin-right: 20px;
                        height: 50px;
                        border-radius: 3px;
                        &:last-child {
                            margin-right: 0;
                        }
                        img {
                            margin-right: 16px;
                        }
                        span {
                            font-size: rem(14px);
                            font-family: $neuzeit;
                            color: $black;
                            margin-left: 5%;
                            @include respond(large) {
                                margin-left: 20%;
                            }
                        }
                        @include respond(small) {
                            flex: 0 0 calc(50% - 20px);
                            margin: 8px 9px;
                            &.tab-1 {
                                flex: 0 0 calc(100% - 20px);
                            }
                        }
                        &.tabmenu-landing {
                            background: url("../images/icons/icon-insider.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                        }
                        &.tabmenu-preferences {
                            background: url("../images/icons/icon-preferences.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                            @include respond(extra-small) {
                                background-position: 9% center;
                                span {
                                    margin-left: 18%;
                                }
                            }

                        }
                        &.tabmenu-orders {
                            background: url("../images/icons/icon-orders.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                            @include respond(extra-small) {
                                background-position: 16% center;
                            }
                        }
                        &.tabmenu-profile {
                            background: url("../images/icons/icon-my_info.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                            @include respond(extra-small) {
                                background-position: 15% center;
                            }
                        }
                        &.tabmenu-wishlist {
                            background: url("../images/icons/icon-wishlist.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                            @include respond(extra-small) {
                                background-position: 14% center;
                            }
                        }
                        &:hover {
                            background-color: $gray-lighter !important;
                        }
                    }
                }
                span.log-out {
                    position: absolute;
                    right: 17px;
                    top: -17px;
                    a {
                        color: $black;
                        text-decoration: underline;
                    }
                }
            }
			&.pt_account {
				&.tab-orderstatus {
					.golfer-profile-wrapper {
						display: none;
						& ~ #main {
							margin-top: 6.125rem;
						}
					}
				}
			}
			&.pt_account_kr,
			&.pt_order_kr,
			&.pt_wish-list_kr {
				.golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-preferences {
					@include respond(extra-small) {
						background-position: 16% center;
						span {
							margin-left: 16%;
						}
					}
				}
			}
        }
		&.pt_account {
			&.tab-orderstatus {
				.golfer-profile-wrapper {
					display: none;
					& ~ #main {
						margin-top: 6.125rem;
					}
				}
			}
		}
		&.pt_order {
			&.tab-orderhistory.order-unregistered {
				.golfer-profile-wrapper {
					display: none;
					& ~ #main {
						margin-top: 2.125rem;
					}
				}
			}
		}
        //TODO : replace with SVG fill color if available
        &.tab-wishlist .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-wishlist {
            background-image: url("../images/icons/icon-wishlist_on.svg");
        }
        &.tab-accountlanding .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-landing {
            background-image: url("../images/icons/icon-insider_on.svg");
        }
        &.tab-preferences .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-preferences {
            background-image: url("../images/icons/icon-preferences_on.svg");
        }
        &.tab-orderhistory .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-orders {
            background-image: url("../images/icons/icon-orders_on.svg");
        }
        &.tab-myinfo .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-profile {
            background-image: url("../images/icons/icon-my_info_on.svg");
        }

        &.tab-wishlist .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-wishlist,
        &.tab-orderhistory .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-orders,
        &.tab-myinfo .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-profile,
        &.tab-preferences .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-preferences,
        &.tab-accountlanding .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-landing {
            background-color: $gray-lighter;
        }

        &.pt_account {
            #main {
                margin: 0 auto;
                width: 96%;
                @include respond(largeUp) {
                    width: 90%;
                }
                h1, h2, .ui-dialog .ui-dialog-title, h3, h4, h5, .content-asset .account-asset p, .content-asset .account-asset a,
                .account-asset h3, .account-asset p, .account-asset .msg-us, .content-asset .account-asset {
                    font-family: $neuzeit;
                    color: $black;
                    letter-spacing: normal;
                    text-transform: none;
                    line-height: normal;
                }
                .content-asset .account-asset,
                #secondary .account-asset p,
                #secondary .content-asset .account-asset,
                .content-asset .account-asset h3,
                .content-asset .account-asset p {
                    font-size: 14px;
                }
                .content-asset .account-asset p {
                    margin: 0;
                    padding: 0;
                }
                .content-asset .account-asset h3 {
                    padding: 0;
                    margin-bottom: 3px;
                }
                .msg-us {
                    font-weight: 700;
                    text-decoration: underline;
                    font-size: 14px;
                }
                #secondary {
                    padding-top: 48px;
                    display: none;
                    @include respond(largeUp) {
                        display: block;
                        width: 270px;
                        margin-right: 58px;
                    }
                    .content-asset a {
                        color: $black;
                    }
                }

                #primary {
                    padding-top: 33px;
					.successful-password-change {
						float: left;
						width: 100%;
						margin-bottom: 16px;
					}
                    &.account-content {
                        width: auto;
                        float: left;
                        padding-left: 0;
                        @media only screen and (min-width: 880px) and (max-width: 1680px) {
                            width: 55%;
                        }
                        @include respond(largeUp) {
                            max-width: 60%;
                        }
                    }
                    ul.address-list li,
                    ul.address-list li div {
                        font-size: 16px;
                        color: $black;
                        border-bottom: none;
                        .mini-address-title {
                            font-style: normal;
                            margin-bottom: 3px;
                        }
                        .mini-address-location {
                            div {
                                margin: 2px 0;
                                &.city-state {
                                    span {
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                        @include respond(extra-small) {
                            font-size: 14px;
                        }
                    }
                    ul.payment-list li,
                    ul.address-list li {
                        width: 42%;
                        border-bottom: none;
                        @media only screen and (max-width: 1199px) {
                            &:nth-child(odd) {
                                padding-right: 16%;
                            }
                        }
                        @include respond(standard) {
                            width: 22%;
                            padding-right: 3%;
                            min-height: 270px;
                        }
                        a {
                            font-size: 14px;
                            text-transform: uppercase;
                            font-weight: 600;
                            white-space: nowrap;
                            &.address-make-default,
                            &.payment-make-default {
                                border: 1px solid $gray-light3;
                                text-transform: none;
                                text-decoration: none;
                                border-radius: 3px;
                                font-size: 16px;
                                font-weight: bold;
                                padding: 10px;
                                display: inline-block;
                                margin: 6px 0 11px;
                                @include respond(extra-small) {
                                    font-size: 14px;
                                }
                            }
                            &.address-edit {
                                margin-left: 0;
                            }
                        }
                        &.default {
                            width: 42%;
                            float: left;
                            @include respond(standard) {
                                width: 22%;
                            }
                        }
                        .is-default {
                            background-color: $green-light;
                            color: $white;
                            border-radius: 3px;
                            font-size: 16px;
                            font-weight: bold;
                            padding: 10px;
                            display: inline-block;
                            margin: 8px 0 11px;
                            white-space: nowrap;
                            @include respond(extra-small) {
                                font-size: 14px;
                            }
                        }
                    }
                    .default-payments {
                        margin-top: 15px;
                    }
                    #payments {
                        clear: both;
                    }
                    .payment-list {
                        li,
                        .button-text {
                            font-family: $neuzeit;
                            color: $black;
                            letter-spacing: normal;
                        }
                        .button-text {
                            font-size: 14px;
                            font-weight: 600;
                            text-decoration: underline;
                            text-transform: uppercase;
                        }
                        li {
                            font-size: 16px;
                        }
                        .card-tlt {
                            font-weight: 700;
                            padding-bottom: 5px;
                            text-transform: uppercase;
                        }
                    }
                    .registered-form {
                        h1, h2, .ui-dialog .ui-dialog-title, h3, h4, h5, p {
                            font-family: $neuzeit;
                        }
                        h1 {
                            display: inline-block;
                            font-size: rem(24px);
                            text-transform: none;
                            letter-spacing: normal;
                            border-bottom: none;
                        }
                        .section-header-note {
                            display: block;
                            text-transform: uppercase;
                            @include respond(largeUp) {
                                display: inline-block;
                                margin-left: 45px;
                                vertical-align: middle;
                                padding-bottom: 4px;
                            }
                        }
                        .my-information {
                            max-width: rem(335px);
                        }
                        .my-information label {
                            float: none;
                            text-align: left;
                            width: auto;
                        }
                        .side-form {
                            .form-row {
                                margin-right: 6px;
                                width: rem(327px);
                                &.form-row-button {
                                    position: absolute;
                                    left: 304px;
                                    top: 12px;
                                    width: auto;
                                    height: auto;
                                    @include respond(largeUp) {
                                        top: 40px;
                                    }
                                }
                            }
                            @include respond(extra-small) {
                                display: flex;
                                .form-row {
                                    min-width: 75%;
                                    width: auto;
                                    &.form-row-button {
                                        position: relative;
                                        left: auto;
                                        min-width: auto;
                                    }
                                }
                            }
                        }
                        input[type="text"],
                        input[type="password"],
                        input[type="email"],
                        input[type="number"] {
                            max-width: rem(309px);
                        }
                        .error {
                            display: block;
                            margin-top: 4px;
                        }
                    }
                    .form-row label,
                    .my-information label {
                        text-transform: none;
                        font-size: rem(16px);
                    }
                }
            }
        }
		&.pt_account.pt_account_kr {
			#main {
				#primary {
					&.account-content {
						@include respond(standard) {
							width: 65%;
							max-width: 65%;
						}
						@media screen and (min-width: 1300px) {
							width: 55%;
							max-width: 60%;
						}
					}
					ul.address-list {
						display: flex;
						justify-content: flex-start;
						align-items: stretch;
						flex-wrap: wrap;
						li {
							width: 50%;
							padding-right: 0;
							@media screen and (min-width: 375px) {
								min-width: 160px;
							}
							@include respond(standard) {
                                width: 25%;
								padding-right: 0;
								min-height: initial;
                            }
							&.default {
								width: 50%;
								@include respond(standard) {
									width: 25%;
								}
							}
							.address-list-item {
								width: 100%;
								padding-right: 16px;
								box-sizing: border-box;
							}
						}
					}
				}
			}
			& ~ .ui-dialog #dialog-container #CreditCardForm {
				.form-two-cols {
					width: 100%;
					display: flex;
					padding-left: 0;
					box-sizing: border-box;
					@include respond(largeUp) {
						width: 68%;
						padding-left: 2%;
					}
					.month {
						margin-left: 0;
						margin-right: 0;
						padding-right: 2%;
						box-sizing: border-box;
						width: 50%;
						select {
							width: 100%;
						}
					}
					.year {
						margin-left: 0;
						margin-right: 0;
						padding-left: 2%;
						box-sizing: border-box;
						width: 50%;
						select {
							width: 100%;
						}
					}
				}
			}
		}
		&.pt_order.pt_order_kr {
			#main {
				#primary {
					table.order-history-item-header {
						width: 100%;
						table-layout: initial;
						.order-number {
							@include respond(extra-small) {
								width: 33.5%;
								box-sizing: border-box;
								.value,
								.label {
									font-size: 14px;
								}
							}
						}
						.order-date {
							@include respond(extra-small) {
								width: 25.5%;
								box-sizing: border-box;
								.value,
								.label {
									font-size: 14px;
								}
							}
						}
						.order-status-th {
							@include respond(extra-small) {
								width: 41%;
								box-sizing: border-box;
								.value,
								.label {
									font-size: 14px;
								}
							}
						}
					}
					&.account-content {
						.no_orders {
							margin-top: 24px;
						}
					}
				}
			}
		}
		&.pt_wish-list.pt_wish-list_kr {
			#main {
				#secondary {
					@include respond(largeUp) {
						width: 210px;
					}
				}
				#primary {
					@include respond(largeUp) {
						width: calc(90% - 210px);
					}
					.item-list {
						tr {
							.item-dashboard {
								.edit-link {
									top: 4px;
									& ~ .pipe {
										position: relative;
										top: -1px;
									}
								}
							}
						}
					}
				}
			}
		}
    }
    #wrapper #main #secondary nav .secondary-navigation {
        ul li {
            font-size: 16px;
            line-height: 45px;
            border-bottom: none;
            display: flex;
            img {
                margin: -7px 0 0 13px;
            }
        }
        @include respond(small) {
            display: none;
        }
    }

    #wrapper.pt_account #primary #RegistrationForm.registered-form > div .profile-col-title,
    #wrapper.pt_account #primary .registered-form > div .profile-col-title,
    #wrapper #main #secondary nav .secondary-navigation h2 {
        border-bottom: none;
        font-family: $neuzeit;
        font-size: rem(24px);
        font-weight: 700;
        letter-spacing: normal;
        text-transform: none;
        margin-bottom: 6px;
    }

    // placeholder until global button requirements TODO
    #wrapper.pt_account #primary #RegistrationForm .form-row.form-row-button button:not(.button-text),
    #wrapper.pt_account #primary .registered-form .form-row.form-row-button button:not(.button-text),
    .golfer-preferences button {
        height: auto;
        text-decoration: none;
        border-radius: 3px;
        background-color: $black;
        padding: 1px 15px;
        font-size: 14px;
        font-weight: 700;
        font-family: $neuzeit;
        color: $white;
        &:hover {
            color: $white;
        }
    }

	#wrapper.pt_account {
		#main.password-main {
			padding-top: 112px;
			@include respond(largeUp) {
				padding-top: 112px;
			}
		}
	}
}
.pt_account.en_AU .tabmenu-orders,
.pt_account.en_NZ .tabmenu-orders,
.pt_account.en_AU .tabmenu-wishlist,
.pt_account.en_NZ .tabmenu-wishlist {
	display: none !important;
}
