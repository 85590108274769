///////////////////////
/// Styles for Checkout Header
///////////////////////
#wrapper.pt_order-confirmation,
#wrapper.pt_checkout{
	header {
		.top-banner{
            background-color: $black;
			.menu-utility-user {
                width: auto;
				@include respond(small) {
					margin:20px -12px 0 0;
					float: right;					
				}				
			}
		}
	}
}

#wrapper.pt_cart #main .footer-bottom .banner-security > span,
#wrapper.pt_checkout footer .footer-container .footer-bottom .banner-security > span,
#wrapper.pt_order-confirmation footer .footer-container .footer-bottom .banner-security > span {
    height: 30px;
}

#wrapper.pt_checkout .top-banner .menu-utility-user li#mini-cart a.mini-cart-link {
    height: 25px;   
    background: transparent url(../images/icons/cart_white.svg) no-repeat scroll 5px 8px;
    background-size: 25px 19px;
    @include respond(largeUp) {
        color: $white;
        background-position: 5px 5px;
    }
}

#wrapper.pt_checkout header .top-banner .menu-utility-user li#mini-cart {
    @include respond(small) {
		padding-top: 0;
        background-color: transparent;	
        .mini-cart-total {
            height: 36px;
        }
        .minicart-quantity {
            margin-top: -3px;
        }
        a.mini-cart-link {
            height: 36px;
            margin-top: 12px;
        }
    }	
}
