.breadcrumb {
	font-size: 1em;
	line-height: 1em;
	margin-bottom: 2rem;
	font-family: $titillium;
	color: $gray-warm;
	margin-top: 14px;
	@include respond(small){
		margin-bottom: 0rem;
		margin-top: 9px;
		padding: 0 1rem;
	}
	li {
		display: inline;
		list-style: none outside none;
		a {
			color: $gray-warm;
		}
	}
}

.paging-current-page {
	left: -999em;
	position: absolute;
}

.breadcrumb-element {
	color: $gray-warm;
	&:after {
		color: $gray-light;
		content: '/';
		margin-left: .4em;
	}
	&:hover:after {
		text-decoration: none;
	}
	&:last-child {
		font-weight: 400;
		&:after {
			content: '';
		}
	}
}

.breadcrumb-result-text a,
.breadcrumb .searchphraselink {
	background: none;
	color: $gray-warm;
	padding: 0;
}
