
//--------------------------------------------------------------
// Main Slider Styles
//--------------------------------------------------------------
.fj-main-slider {
	position: relative;
	background: $black;
	line-height:1;
	.fj-main-slide {
		position: relative;
		float: left;
		overflow: hidden;
        &.video-slide {
            height: 90vh !important;
            @media only screen and (min-width: 1800px) {
                height: 100vh !important;
            }
            @media only screen and (min-width: 1400px) and (max-width: 1799px) {
                height: 87vh !important;
            }
            @media only screen and (min-width: 1120px) and (max-width: 1399px) {
                height: 74vh !important;
            }
            @media only screen and (min-width: 768px) and (max-width: 1119px) {
                height: 48vh !important;
                .fj-main-slide-content .fj-main-slide-content-buttons {
                    margin-bottom: 25px;
                }
            }
            @media only screen and (min-width: 684px) and (max-width: 767px) {
                height: 42vh !important;
            }
            @media only screen and (min-width: 350px) and (max-width: 683px) {
                height: 66vh !important;
            }
            .fj-main-slide-image {
                &::before {
                    display: none;
                }
            }
            .fp-tableCell {
                height: auto !important;
                background-color: #000;
            }
        }
	}
}

.sub-home {
    @include respond(largeUp) {
        margin-top: 20px;
    }
}

.video-embed-container {
    position: relative;
    @include respond(largeUp) {
        padding-bottom: 176%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        padding-bottom: 40%;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .vid-shim {
            display: none;
        }
    }
    @include respond(small) {
        .vid-shim {
            width: 100%;
            background: white;
            height: 2px;
            margin-top: -3px;
        }
    }
    .vid-desktop {
        display: none;
    }
    .vid-mobile {
        aspect-ratio: 9/16;
        width: 100%;
    }
    @include respond(largeUp) {
        .vid-desktop {
            display: block;
        }
        .vid-mobile {
            display: none;
        }
    }
}

.fj-main-slider .fj-main-slide.main-content {
    padding-top: 25px;
    @include respond(largeUp) {
        padding-top: 40px;
    }
}

#wrapper.home #main {
    @include respond(small) {
        margin-top: 75px;
    }
}

.home {
    text-transform: none;
    h2 {
        margin-block-end: 16.6px;
        margin-block-start: 16.6px;
        font-weight: 700;
    }

    .button {
        background-color: $darkgrey;
        border-color: $darkgrey;
        border-radius: 4px;
        border-width: 2px;
        font-weight: 400;
        font-size: 16px;
        color: $white;
        height: 40px;
        line-height: 37px;
        text-transform: uppercase;
        padding: 0 17px;
        text-decoration: none;
        transition: background-color 0.5s;

        &:hover {
            background-color: $black;
            border-color: $black;
            color: $white;
        }

        &.black {
			border-radius: 0;
		}

		&.blue {
			border-radius: 0;
		}

		&.flat {
			border-radius: 0;
		}
    }
}

.fj-recommended-products .fj-rp-slider-titlemain {
    text-align: center;
    font-family: $eurostile;
    font-weight: 700;
}

.fj-recommended-products .fj-rp-slider-wrapper {
    margin-left: 0;

    .fj-rp-slide {
        .fj-rp-slide-title {
            font-family: $eurostile;
        }
    }
}

.fj-recommended-products {
    .content-wrapper {
        max-width: 1980px;
        @include respond(largeUp) {
            max-width: 90%;
        }
    }
}
