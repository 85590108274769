////////////////////////////////////
// Footjoy content helper classes //
////////////////////////////////////

// Text color by class

@mixin color-by-class() {
	//colors by class
	&.mobile-black {
		color: $black;
	}
	&.mobile-white {
		color: $white;
	}
	&.mobile-gray {
		color: $gray-warm;
	}
	&.mobile-green {
		color: $green;
	}
	button.mobile-black {
		@include blackButton;
	}
	button.mobile-white {
		@include whiteButton;
	}

	@include respond(largeUp){
		&.desktop-black {
			color: $black;
		}
		&.desktop-white {
			color: $white;
		}
		&.desktop-gray {
			color: $gray-warm;
		}
		&.desktop-green {
			color: $green;
		}
		button.desktop-black {
			@include blackButton;
		}
		button.desktop-white {
			@include whiteButton;
		}
	}

	@include respond(large){
		&.tablet-black {
			color: $black;
		}
		&.tablet-white {
			color: $white;
		}
		&.tablet-gray {
			color: $gray-warm;
		}
		&.tablet-green {
			color: $green;
		}
		button.tablet-black {
			@include blackButton;
		}
		button.tablet-white {
			@include whiteButton;
		}
	}
}

%color-by-class {
	@include color-by-class();
}

.content-asset p {
	@extend %color-by-class;
}

#wrapper.pt_content #primary .content-asset{
	h1, h2, h3, p, ul li {
		@extend %color-by-class;
	}
}
#wrapper {
	.header-banner {
		.menu-utility-user {
			li#storeLocator {
				a {
					background: none;
					@include respond(largeUp){
						span.img {
							display: none;
						}
					}
					@include respond(small){
						text-indent: 0;
						margin-left: 5px;
						span:not(.img) {
							display: none;
						}
					}
				}
			}
		}
	}
	.header-bottom-banner {
        font-family: $titillium;
        border-bottom: 1px solid $gray-warm;
        background-color: $gray-light;
        padding: 5px 0;
        min-height: 30px;
		box-sizing: border-box;
        @include respond(small) {
            padding: 4px 8px;
        }
		.innerWidth {
			position: relative;
			.header-bottom-banner-content {
				padding-right: 28px;
			}
			.banner-close-btn {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				display: inline-block;
				text-transform: uppercase;
				font-size: 14px;
                text-indent: 1px;
				width: 18px;
				height: 18px;
				border: 1px solid black;
				text-align: center;
				line-height: 18px;
				border-radius: 50%;
                cursor: pointer;
				box-sizing: content-box;
			}
		}
	}
}

#wrapper {
	&.product-registration {
		#main {
			margin-top: 86px;
			margin-bottom: 0;
		}
		#RegistrationForm {
			.form-row {
				margin-bottom: 12px;
				input,
				select {
					height: 51px;
				}
			}
		}
	}
}

.register-product-container {
	display: flex;
	flex-direction: column;
	@include respond(largeUp) {
		flex-direction: row;
	}

	.product-register-container,
	.product-warrantys-container {
		display: block;
		box-sizing: border-box;
		@include respond(largeUp) {
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			min-height: calc(100vh - 86px);
		}
	}
	.product-register-container {
		padding-top: 45px;
		@include respond(largeUp) {
			padding: 16px 0;
			width: 57.4%;
		}

		.product-registration-form {
			max-width: 548px;
			padding: 0 28px 32px;
			margin: 0 auto;
			@include respond(largeUp) {
				width: 548px;
				padding: 32px;
				margin: 0;
			}
		}
		.warranty-head-title {
			display: block;
			margin: 0 0 10px;
			font-size: 48px;
			line-height: rem(48px);
			font-weight: 400;
			text-transform: none;
			letter-spacing: -.05rem;
			@include respond(largeUp) {
				font-size: 70px;
				line-height: rem(60px);
			}
		}
		.warranty-i {
			display: block;
			padding: 20px 0 36px;
			font-size: 16px;
			font-family: neuzeit-grotesk, sans-serif;
			text-transform: none;
			letter-spacing: -.01rem;
		}
		#warranty-i-container {
			margin-bottom: 24px;
            position: relative;
            padding-left: 30px;
			input[type="checkbox"] {
				&+label {
					font-size: 11px;
                    display: flex;
                    width: 100%;
					flex-direction: row;
					padding: 0;
					cursor: pointer;
					line-height: 18px;
                    text-transform: initial;
                    background: none;
					&>.styled-checkbox {
						position: absolute;
						display: inline-block;
						width: 18px;
                        height: 18px;
                        left: 0;
						background: #4D738A;
						border: solid 1px #25495F;
                        box-sizing: border-box;
					}
				}
				&:checked+label>.styled-checkbox {
					background: #4D738A;
					&::before,
					&::after {
						content: "";
						position: absolute;
						height: 3px;
						background: #fff;
					}
					&::before {
						width: 7px;
						bottom: 5px;
						left: 1px;
						transform: rotate(45deg);
					}
					&::after {
						width: 10px;
						right: 1px;
						bottom: 7px;
						transform: rotate(-45deg);
					}
				}
			}
			.warranty-cont {
				font-size: 16px;
				font-family: neuzeit-grotesk, sans-serif;
				text-transform: none;
				letter-spacing: normal;
				font-weight: normal;
				a {
					text-decoration: underline;
				}
			}
		}
		.btn-box {
            padding: 5px 0;
            display: block;
			@include respond(small) {
                text-align: center;
			}
			.product-register-btn {
				height: 50px;
				font-size: 24px;
                padding: 0 90px;
                @include respond(large) {
                    padding: 0 45px;
                }
				@include respond(small) {
					padding: 0 40px;
					width: 100%;
				}
			}
		}
		.register-data-title {
			display: block;
			padding: 5px 0;
			font-size: 18px;
			letter-spacing: .75px;
            line-height: 1;
			text-transform: uppercase;
            @include respond(small) {
                padding-top: 20px;
                padding-bottom: 10px;
            }
		}
		.product-register-forms-year {
			display: inline-block;
		}
	}

	.product-warrantys-container {
		margin-bottom: -2px;
		padding: 70px 30px;
		box-sizing: border-box;
		text-align: center;
		background-color: #25495F;
		@include respond(largeUp) {
			width: 42.6%;
			padding: 16px 0;
			margin-bottom: 0;
		}

		.product-warranty-content {
			max-width: 364px;
			margin: 0 auto;
			@include respond(largeUp) {
				margin: 0;
			}
			> div {
				&:not(:last-child) {
					margin-bottom: 40px;
				}
			}
			.warranty-header {
				letter-spacing: .05rem;
			}
			.warranty-title {
				display: inline;
				letter-spacing: .012rem;
			}
		}
		img {
			padding-top: 0;
		}
	}

    .new-form {
		float: none;
        box-sizing: border-box;
        @include respond(largeUp) {
			width: calc(100% - 64px);
        }
        .product-register-forms-select {
            position: relative;
            .field-wrapper {
				position: relative;
                border-radius: 0;
                float: left;
                padding-left: 0;
                margin-bottom: 5px;
                input {
                    border-color: black;
                    font-size: 18px;
                    padding: 0 0.5em;
                    background: white;
                    &::placeholder {
                        color: black;
                        font-size: 18px;
                        background: white;
                    }
                }
                select,
				.input-select {
                    height: 45px;
                    border-color: black;
                    line-height: 45px;
                    font-size: 18px;
                    padding: 0 0.5em;
                    background: transparent;
                    position: relative;
                    display: block;
                    &::placeholder {
                        color: black;
                        font-size: 18px;
                        background: white;
                    }
					&.input-select-immitation {
						border: 2px solid #000;
						padding: 0 .5em;
					}
                }
                .register-data-select {
                    background-position: right 5px center;
                    padding: 0 1em 0 0.5em;
                    border-radius: 0;
                }
                &::before {
					content: "";
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					width: 22px;
					height: 12px;
					padding: 19px 16px;
					font-size: 16px;
					background: transparent url(../images/interface/icon-jcarousel-arrow-down.svg) no-repeat center;
					background-size: 22px 12px;
                }
				&.field-wrapper-custom {
					&::before {
						display: none;
					}
				}
            }
        }
        #product-register-forms {
            .form-row {
            	display: block;
                margin: 0;
                .required-indicator {
                    display: none;
                }
            	.field-wrapper {
                    .error-message {
                        width: inherit;
                    }
            		border-radius: 0;
            		float: left;
            		padding-left: 0;
            		margin-bottom: 5px;
            		input {
            			border-color: black;
            			font-size: 18px;
            			padding: 0 0.5em;
            			background: white;
            			&::placeholder {
            				color: black;
            				font-size: 18px;
            				background: white;
            			}
                    }
                }
            }
            .product-register-forms-customer-name {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                @include respond(small) {
                    flex-direction: column;
                }
                .form-row {
                    width: 49%;
                    display: inline-block;
                    @include respond(small) {
                        width: 100%;
                        display: block;
                    }
                    .field-wrapper {
                    	border-radius: 0;
                    	float: left;
                    	padding-left: 0;
                    	margin-bottom: 5px;
                    	input {
                    		border-color: black;
                    		font-size: 18px;
                    		padding: 0 0.5em;
                    		background: white;

                    		&::placeholder {
                    			color: black;
                    			font-size: 18px;
                    			background: white;
                    		}
                        }
                    }
                }
            }
        }
        #register-data-container {
            .product-register-forms-data {
                .form-row {
                    display: inline-block;
					vertical-align: top;
                    margin: 0;
                    width: 80px;
                    padding-right: 5px;
                    .field-wrapper {
						position: relative;
                        border-radius: 0;
                        float: left;
                        width: 80px;
                        padding-left: 0;
                        margin-bottom: 5px;
                        input {
                            height: 45px;
                            border-color: black;
                            font-size: 18px;
                            padding: 0 0.5em;
                            background: white;

                            &::placeholder {
                                color: black;
                                font-size: 18px;
                                background: white;
                            }
                        }
                        select {
                            height: 45px;
                            border-color: black;
                            line-height: 45px;
                            font-size: 18px;
                            padding: 0 0.5em;
                            background: transparent;
                            position: relative;
                            &::placeholder {
                                color: black;
                                font-size: 18px;
                                background: white;
                            }
                        }
						&::before {
							content: "";
							display: block;
							position: absolute;
							right: 0;
							top: 0;
							width: 22px;
							height: 12px;
							padding: 19px 6px;
							font-size: 16px;
							background: transparent url(../images/interface/icon-jcarousel-arrow-down.svg) no-repeat center;
							background-size: 22px 12px;
						}
                        .register-data-select {
                            background-position: right 5px center;
                            padding: 0 1em 0 0.5em;
                            border-radius: 0;
                        }
                    }
                }
                .product-register-forms-select-data {
                    position: relative;
                    display: inline-block;
					.required-indicator {
						display: none;
					}
                }
            }
        }
    }
}

//members only overlay page
html.members-dialog {
	overflow: hidden;
	.membersOnly-dialog {
		width: 100% !important;
		padding: 0 !important;
	}
	.members-only-account .ui-accordion-content {
		overflow: visible;
	}
}

.membersOnly-dialog {
	position: fixed;
	background-color: transparent !important;
	top: 74px;
	height: calc(100% - 74px) !important;
	z-index: 19;
	.ui-dialog-titlebar {
		display: none;
	}
	@include respond(largeUp) {
		top: 86px;
		height: calc(100% - 86px) !important;
	}
}

.pt_membersOnly {
	visibility: hidden;
	min-height: 100vh;
	&.show_mem {
		visibility: visible;
	}
}

#membersOnly {	
	padding: 0 !important;
	width: 100% !important;
	height: 100% !important;
	display: none;
	background-color: rgba(255,255,255,0.9);
	.members-only-modal {
		display: block;
		background-color: transparent;
		width: 100%;
		height: 100%;
		@include respond(largeUp) {
			display: flex;
			justify-content: center;
		}
		.members-only-account {
			float: none;
			width: 100%;
			@include respond(largeUp) {
				width: 30%;
				padding-top: 30px;
			}
			.account-login {
				height: auto !important;
			}
		}
		.members-only-content {
			width: 70%;
			text-align: center;
			margin: 6% auto 0;

			.content-asset {				
				margin: auto;
				.logo {
					margin-bottom: 2%;
				}
				ul li {
					text-align: center;
				}
				@include respond(largeUp) {
					max-width: 40%;
				}
			}
		}
	}
}



