#wrapper {
	&.menu-active {
	    > div {
	        left: auto;
	        @include respond(small) {
	        	&.header-banner {
	        		position: fixed;
	        	}
	        }
	    }
		& > .mobile-access {
			@include respond(small) {
	        	position: fixed;
				right: 0;
				left: auto;
	        }
		}

	    #navigation {
	        left: 0;
	    }
	    .header-main {
	    	position:fixed;
	    }
	}
	&.home {
		#main {
			@include respond(small) {
				margin-top: 7rem;
			}
		    margin-top: 86px;
            #parcellab-track-and-trace {
                padding: 30px;
            }
		}
	}
	&.pt_checkout {
		margin-top:0;
		#main {
			margin-top: 2rem;
		}
	}
	#main {
		@include respond(small) {
			margin-top: 8rem;
		}
		margin-top: 7rem;
	}
	&.pt_customer-service.pt_email-sub {
		.newsletter-header {
			@include respond(small) {
				margin-top: 8rem;
			}
			margin-top: 7rem;
		}
	}
	.capitalize {
		text-transform: capitalize;
	}
}

.primary-content {
	@include respond(largeUp) {
		width: calc(100% - 240px);
	}
	.hide-refinements & {
		width: 100%;
	}
}

