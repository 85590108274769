
#wrapper.pt_customer-service #primary {
    h1 {
        text-transform: none;
        font-size: 22px;
        font-family: $eurostileext;
    }
    h2, h3 {
        font-family: $neuzeit;
        font-size: 22px;
        font-weight: 400;
    }
    span {
        font-family: $neuzeit !important;
        text-transform: none;
        font-size: 16px;
    }
}
