html {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-weight: 400;

	&.freeze-scroll {
        overflow: hidden !important;
    }
}

  /**
   * Firefox specific rule
   */

  @-moz-document url-prefix() {
	body {
	  font-weight: lighter !important;
	}
  }

h1,h2,h3,h4,h5{
  font-family: $font-default;
  letter-spacing: 0.1em;
}

/* Pixel 10/2018 Non-Homepage Main content pushed down for new fixed header */

.pt_storefront {
  &.home {
	#main {
	  margin-bottom:0;
	}
  }
}

/* Fix for Checkout Header */
@include respond(small) {
	#wrapper.pt_order-confirmation header .top-banner .menu-utility-user,
	#wrapper.pt_checkout header .top-banner .menu-utility-user {
		width:75%;
		margin-top:10px;
		li {
			&#mini-cart {
				padding-top:15px;
				background-color:$white;
				a {
					&.mini-cart-link {
						height:20px;
						margin-top:5px;
					}
				}
				.minicart-quantity {
					background-color:$white;
				}
			}
		}
	}
}

#wrapper.pt_cart #main #primary #cart-items-form {
	#cart-table tr td.item-quantity select,
	#bonus-table tr td.item-quantity select {
		border-radius:0;
	}
}
.sort-by select,
.items-per-page select {
	border-radius:0;
}
#secondary a{
	border-radius:0;
}

footer .innerWidth {
	max-width: 1440px;
}

.widthFix {
	width:33.3333%!important;
}

#navigation {
	.innerWidth{

		@include respond(small) {
			max-width: inherit;
		}
	}
}
.ui-tooltip-content {
	font-family: $neuzeit;
}

.fullwidth {
	@include respond(largeUp) {
		padding: 0 26px;
	}
}

.display-mobile-only {
	@include respond(largeUp) {
		display: none !important;
	}
}

.display-desktop-only {
	@include respond(small) {
		display: none !important;
	}
}

.overlay-layer {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	background-color: $white;
	&.show {
		display: block;
	}
}
