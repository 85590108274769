.js {
	.ui-dialog-content {
		width: 66rem;
		.product-col-1 {
			padding: 13px 1%;
			width: 59%;
			.product-image {
				max-width: 100%;
			}
		}
		.product-col-2 {
			width: 38%;
		}
		.pdp-main {
			width: 66rem;
			.product-set-container {
				.product-col-1 {
					padding: 13px 1%;
					width: 55%;
				}
				.product-col-2 {
					width: 36%;
					.product-set-details {
						width: 69%;
						margin: 0;
					}
				}
			}
			.product-actions {
				display: none;
			}
			.product-add-to-cart {
			    padding: 0em;
			    overflow: visible;
				.bundle {
				    width: 80%;
				    margin-top: 2rem;
				    margin-bottom: 2rem;
				}
				#design-your-own,
				#add-to-cart{
					margin-right: 0!important;
				}
				#design-your-own{
					border-color: $black;
				}
			}
			.product-variations .attribute .value, .product-options .attribute .value {
			    width: 95%;
			}
			margin-top: -1rem;
			.product-set-list {
				border-top: 2px solid $gray-light;
				height: 366px;
				overflow: auto;
				border-left: 1px solid $white;
				.product-set-item {
					margin: 1em 0;
				}
				.product-set-image {
					width: 28%;
				}
				.product-set-details {
					width: 68%;
				}
				.product-name {
					margin: 0;
					letter-spacing: 2px;
					font-size: 1.75rem;
				}
				.block-add-to-cart .price {
					font-size: 1.5em;
					padding: 0.4em 0 0;
					width: 130px;
				}
			}
		}
	}
	.zoomPad {
		position: relative;
		float: left;
		z-index: 99;
		cursor: crosshair;
		width: 100%;
	}
	.zoomPreload {
		-moz-opacity: 0.8;
		opacity: 0.8;
		filter: alpha(opacity = 80);
		color: $black;
		font-size: 12px;
		font-family: $arial;
		text-decoration: none;
		border: 1px solid $gray-light;
		background-color: $white;
		padding: 8px;
		text-align: center;
		background-image: url(../images/zoomloader.gif);
		background-repeat: no-repeat;
		background-position: 43px 30px;
		z-index: 110;
		width: 90px;
		height: 43px;
		position: absolute;
		top: 0px;
		left: 0px;
		*width: 100px;
		*height: 49px;
	}
	.zoomPup {
		overflow: hidden;
		background-color: $white;
		-moz-opacity: 0.6;
		opacity: 0.6;
		filter: alpha(opacity = 60);
		z-index: 120;
		position: absolute;
		border: 1px solid $gray-light;
		z-index: 101;
		cursor: crosshair;
	}
	.zoomOverlay {
		position: absolute;
		left: 0px;
		top: 0px;
		background: $white;
		z-index: 5000;
		width: 100%;
		height: 100%;
		display: none;
		z-index: 101;
	}
	.zoomWindow {
		position: absolute;
		left: 110%;
		top: 40px;
		background: $white;
		z-index: 6000;
		height: auto;
		z-index: 10000;
		z-index: 110;
	}
	.zoomWrapper {
		position: relative;
		border: 1px solid $gray-warm;
		z-index: 110;
	}
	.zoomWrapperTitle {
		display: block;
		background: $gray-warm;
		color: $white;
		height: 18px;
		line-height: 18px;
		width: 100%;
		overflow: hidden;
		text-align: center;
		font-size: 10px;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 120;
		-moz-opacity: 0.6;
		opacity: 0.6;
		filter: alpha(opacity = 60);
	}
	.zoomWrapperImage {
		display: block;
		position: relative;
		overflow: hidden;
		z-index: 110;
		img {
			border: 0px;
			display: block;
			position: absolute;
			z-index: 101;
		}
	}
	.zoomIframe {
		z-index: -1;
		filter: alpha(opacity = 0);
		-moz-opacity: 0.80;
		opacity: 0.80;
		position: absolute;
		display: block;
	}
}

.quickview-nav {
	.quickview-next {
		float: right;
	}
}

.product-name {
	font-style: normal;
}

.quickview-prev, .quickview-next {
	display: none;
}

.view-full-details {
	text-transform: uppercase;
	font-family: $titillium;
	font-weight: 700;
	color: $black;
	text-decoration: underline;
	margin-top: .5rem;
	margin-bottom: 1rem;
	float: left;
	width: 100%;	
}
