.ui-widget-overlay {
	background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% $black;
	opacity: 0.95;
}

.dialog-container {
	float: left;
}

.ui-dialog {
	background-color: $white;
	padding: 0;
	.ui-widget-header {
		background-color: $white;
		border: 0 none;
		border-radius: 0 0 0 0;
		height: 23px;
		text-transform: uppercase;
	}
	.ui-dialog-buttonpane
		.ui-dialog-buttonset {
			float: left !important;
			margin-left: 2.5rem;
			margin-bottom: 1rem;
		}
	button {
		background: $black;
		color: $white;
		font-size: .75rem;
		margin-right: .7rem !important;
		&:hover{
			color: $black;
			background-color: $white;
			border-color: $black;
		}
		.ui-button-text-only .ui-button-text {
			padding: 6px 10px;
			background: black;
			color: white;
			border-radius: 1.5rem;
			border: 2px solid black;
			&:hover{
				color: $black;
				background-color: $white;
				border-color: $black;
			}
		}
	}
	.mobile-a2c-intecept {
		.mobile-a2c-header {
			margin-top: 20px;
			border-bottom: 2px solid $gray-warm;
			.ui-dialog-title {
				font-size: 18px;
				display: inline-block;
				margin-left: 0!important;
				float: left;
				width: 50%;
				text-transform: uppercase;
				vertical-align: bottom;
				font-weight: bold;
			}
			.mobile-a2c-items{
				color: $gray-warm;
				float: right;
				text-align: right;
				font-size: 14px;
				text-transform: none;
				line-height: 30px;
			}
		}
		.mobile-a2c-sub-header {
			font-weight: bold;
			text-align: center;
			font-size: 1.3em;
			padding: 10px 0;
			& + div {
				font-weight: bold;
			}
		}
		.mobile-a2c-intercept-continue {
			background: $white;
			color: $black;
			border: 0;
			padding: 0;
			margin-left: 4px;
			margin-right: 17px!important;
		}
		button {
			&.mobile-a2c-intercept-cart {
				height: 36px;
				line-height: 20px;
				margin-right: 0!important;
				margin-left: 17px;
			}
		}
	}
	.ui-dialog-title {
		color: $black;
		@extend h2;
		margin-top: 13px !important;
		margin-left: 2rem !important;
	}
	.ui-icon-closethick {
		background-image: url("../images/interface/black-close-btn.png");
		background-position: left top;
		height: 32px;
		width: 32px;
		left: 0;
		top: 0;
		margin-left: 0;
		margin-top: 0;
	}
	.ui-dialog-titlebar-close {
		background-color: transparent;
		border: 0 none;
		border-radius: 0 0 0 0;
		height: 32px;
		margin: 0;
		opacity: .5;
		padding: 0;
		right: 0;
		top: 0;
		width: 32px;
		&::before {
			content: none; //to undo the button slant bakcground in Vokey
		}
		&:hover {
			opacity: 1;
		}
		span {
			margin: 0;
			padding: 0;
			outline: none;
		}
	}
	h1,
	h2 {
	}
	.dialog-required {
		font-weight: 400;
		position: relative;
		text-transform: none;
	}
	&.cart-intercept {
		.ui-widget-header .ui-button .ui-button-icon-primary {
			margin-top: 0;
		}
		@include respond(small) {
			width: 310px!important;
			padding: 0!important;
			box-sizing: border-box;
		}
		.ui-dialog-titlebar {
			padding: 0;
			.ui-dialog-titlebar-close {
				opacity: 1;
				top: 5px;
				.ui-button-icon-primary {
					width: 14px;
					height: 14px;
					background-size: contain;
				}
				.ui-button-text {
					display: none;
				}
			}
			.ui-dialog-title {
				display: none;
			}
		}
	}
}

.ui-button-icon-only .ui-icon {
    left: 0% !important;
    margin-left: -18px !important;
}

.ui-dialog .ui-dialog-titlebar-close {
    outline: none !important;
}

.ui-dialog .ui-dialog-content {
    padding: 1.25rem !important;
    float: left;
}

.no-close .ui-dialog-titlebar-close {
	display: none;
}

.ui-accordion .ui-accordion-content {
	padding: 0;
}

.ui-tabs {
	.ui-tabs-nav {
		height: 2.45em;
		padding: 0;
		li {
			border-color: $white;
			border-style: solid;
			border-width: 0 0 3px 0;
			&.ui-state-active {
				border-bottom-color: $red;
				a {
					color: $black;
					font-weight: 700;
				}
			}
			a {
				color: $gray-warm;
			}
		}
	}
	.ui-tabs-panel {
		background-color: $white;
		border: 1px solid $white;
		line-height: 1.5em;
		margin: 0;
		padding: 20px 10px;
		h3 {
			@include respond(largeUp) {
			display: none;
			}
		}
	}
}

#vertical-carousel {
	height: 19rem;
	position: relative;
	overflow: hidden;
	ul {
		height: 10000em;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		li {
			float: left;
			height: 19rem;
			width: 100%;
			.capture-product-id {
				display: none;
			}
		}
	}
}

#dialog-container #PasswordResetForm .form-row .field-wrapper {
    width: 80%;
    float: left;
    @include respond(small) {
		width: 100%;
	}
}

.ui-dialog .ui-dialog-titlebar-close {
    position: absolute !important;
    right: 0.3em;
    top: 50%;
    width: 20px;
    margin: -10px 0 0 0;
    padding: 1px;
    height: 20px;
    @include respond(small) {
		right: -10px!important;
		margin-top: 0!important;

		.ui-button-icon-primary{
			margin: -10px 0 0;
		}
	}
}

.dialog-content {
    width: 93% !important;
    min-height: 56px;
    max-height: none;
    margin-top: -40px;
}

