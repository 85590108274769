////////////////////////
// Checkout Billing Step
////////////////////////
#wrapper.pt_checkout{
	#main{
		#primary{
			.billing-coupon-code {
				button#add-coupon {
					@include respond(small) {
						top: 24px;
					}
				}
				.form-row.coupon-error.error {
					@include respond(small) {
						clear: both;
						margin-left: 0;
					}
				}
				.form-row {
					.form-field-tooltip {
						a {
							@include font-small;
						}
					}
				}
			}
			.checkout-billing {
				> .giftcards,
				> .coupon-code {
					h2 {
						padding: 20px 0;
						margin: 0;
						&.close {
							padding-bottom: 20px;
						}
					}
				}
				.giftcards {
					.giftcert-container {
						margin: -13px 0 24px;
						@include respond(largeUp) {
							margin: -20px 0 18px;
						}
						.gc-messaging {
							margin-top: 24px;
							letter-spacing: normal;
							@include respond(largeUp) {
								margin-left: 12px;
							}
						}
						.pin {
							.close-tooltip {
								display: none;
							}
						}
					}
				}
				.gift-cert-used {
					display: none;
				}
				h2 {
					span.required-indicator {
						background: none;
					}
				}
				.email-fiels{
					margin-bottom: 23px;
					@include respond(small) {
						margin-bottom: 15px;
					}
					.form-row{
						margin-bottom: 0;

					}
					.field-wrapper{
						margin-bottom: 0;
					}
				}
				.form-caption {
					margin: 7px 0 0 18%;
					color: #999;
					font-size: 11px;
					font-family: neuzeit-grotesk,sans-serif,serif;
					font-weight: 300;
					font-style: normal;
					@include respond(small) {
						margin: 0;
					}
					a {
						color: #333;
						text-decoration: underline;
						&.privacy-policy {
							display: inline-block;
							font-size: 12px;
							margin-left: 23px;
							margin-top: -10px;
							@include respond(small) {
								margin-top: 0;
							}
						}
					}
				}
				.form-row.label-inline{
					margin-left: 18%;
					margin-top: 0;
					margin-bottom: 0;
					@include respond(small) {
						margin: 13px 0 0 0;
					}
					.field-wrapper{
						width: auto;
						margin-bottom: 0;
					}
					label{
						width: 100%;
						padding-left: 20px;
						span{
							font-size: 12px;
							color: $gray-warm;
							text-transform: none;
							font-weight: normal;
							float: left;
							line-height: 120%;
							margin-left: 0;
							@include font-small;
						}
					}
				}
			}

			.gc-content {
				&.close {
					display: none;
				}
				margin: 0;
				position: relative;
				.form-row {
					display: inline-block;
					margin-right: 13px;
					&.pin {
						input[type='tel'] {
							width: 60px;
						}
					}
					&.balance {
						margin: 0;
						display: block;
					}
					&.giftcert-error {
						margin: 0;
						span {
							display: inline-block;
							&.message-text {
								width: 167px;
								@include respond(largeUp) {
									width: 193px;
								}
							}
							&.gc-number {
								width: 118px;
								@include respond(largeUp) {
									width: 144px;
								}
							}
						}
					}
					&.redemption {
						display: block;
						margin: 0;
						.gc-message {
							margin-bottom: 4px;
							font-family: $neuzeit;
							font-size: 12px;
							color: $black;
							span {
								display: inline-block;
								&.value {
									width: 50px;
									@include respond(largeUp) {
										width: 55px;
									}
								}
								&.message-text {
									width: 110px;
									@include respond(largeUp) {
										width: 130px;
									}
								}
								&.gc-number {
									width: 125px;
									@include respond(largeUp) {
										width: 140px;
									}
								}
							}
							a {
								font-family: $din-condensed;
								color: $black;
								text-decoration: underline;
								&:hover {
									@include respond(largeUp) {
										text-decoration: none;
									}
								}
							}
						}
						.remaining-order-total {
							display: none;
						}
					}
					label {
						display: block;
						float: none;
						width: auto;
					}
					.field-wrapper {
						input[type='tel'] {
							padding: 0 16px;
							background-color: transparent;
						}
						.gift-card-number {
							width: 150px;
						}
					}
					.form-field-tooltip {
						left: 0;
						top: 60px;
						width: 110%;
						padding: 0;
						margin-left: 0;
						font-size: 12px;
						letter-spacing: normal;
						@include respond(small) {
							position: absolute;
							top: 6px;
							left: 27px;
						}
						a {
							font-size: 12px;
						}
					}

				}
				.giftcert-apply {
					display: inline-block;
					position: relative;
					@include respond(largeUp) {
						top: 37px;
					}
					button {
						font-size: 20px;
						height: 44px;
						line-height: 42px;
					}
				}
			}
			input[type="password"],input[type="tel"],input[type="email"],input[type="number"],input[type="text"], select{
				background-color:#f7f7f7;
				border-radius:0;
				@include font-small;
			}
			.fieldset {
				&.billing-address-static {
					@include font-small;
				}
			}
			.checkout-billing {
				&.address {
					&.required-error {
						.payment-method {
							.adyen-checkout__button {
								background: #b5b5b5;
								border: 2px solid #b5b5b5;
								pointer-events: none;
								&:hover {
									background: #b5b5b5;
									border: 2px solid #b5b5b5;
									color: white;
								}
							}
							.adyen-checkout__paypal__button {
								opacity: 0.5;
								pointer-events: none;
							}
						}
					}
				}
				.dw-apple-pay-button {
					float: none !important;
				}
				.fieldset > .payment-method-options.payment-method-options-kr {
				    @include respond(small) {
				        display: flex;
				    }
					& ~ .payment-method {
						@media screen and (max-width: 879px) {
							padding: 40px 20px;
							button.adyen-checkout__button {
								font-size: 14px;
							}
						}
						@media screen and (max-width: 374px) {
							padding: 40px 16px;
							button.adyen-checkout__button {
								font-size: 12px;
							}
						}
					}
				}
				.fieldset > .payment-method-options.form-indent {
					&.emea-options {
						> .form-row {
							@include respond(small) {
								width: auto;
							}
						}
					}
					> .form-row {
						width: auto;
						margin: 0 -1px 0 0;
						@include respond(small) {
							width: calc((100% / 3) + .5px);
						}
						label {
							@include font-small;
							.label-text {
								float: left;
							}
						}
						span.icon-PayPal {
							margin-top: -4px;
						}
					}
				}
				.payment-methods {
					position: relative;
					background-color: $gray-light2;
					padding: 8px;
					@include respond(small) {
						margin: 0 -14px;
					}
					> h2 {
						margin: 0 0 20px;
						padding: 0;
						border: none;
						&::after {
							content: url('../images/secure-tr-icon.gif');
							display: inline-block;
							margin-left: 4px;
							vertical-align: middle;
							opacity: .75;
						}
					}
					.dialog-required {
						position: absolute;
						right: 15px;
						top: 108px;
					}
					.payment-method-options {
						& ~ .form-row {
							display: none;
						}
					}
					.payment-method-tab {
						border: 1px solid $gray-warm;
						border-bottom: none;
						padding: 10px 30px;
						height: 45px;
						box-sizing: border-box;
						position: relative;
						cursor: pointer;
						margin: 0;
						@include respond(small) {
							padding: 10px 15px;
						}
						&.selected {
							background-color: $white;
							&::after {
								content: '';
								display: block;
								position: absolute;
								bottom: -2px;
								left: .5px;
								height: 4px;
								width: calc(100% - .5px);
								background-color: $white;
							}
						}
						&:not([data-method=CREDIT_CARD]) {
							.label-text {
								@include respond(small) {
									display: none;
								}
							}
						}
						img {
							height: 18px;
						}
					}
					.payments-kr {
					    @include respond(small) {
                            height: auto;
                            &:last-child {
                                border: none;
                                position: relative;
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: 1px;
                                    display: inline-block;
                                    width: 1px;
                                    height: 100%;
                                    background-color: #999;
                                }
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: inline-block;
                                    width: calc(100% - 1px);
                                    height: 1px;
                                    background-color: #999;
                                }
                            }
                        }
						.logo {
							height: 20px;
							margin: 3px 0 0 4px;
							@include respond(small) {
							    margin: 3px auto 0 auto;
                                display: block;
                                width: 100%;
                                max-width: 31px;
							}
						}
					}
					.payment-method-name {
						float: left;
						width: 18%;
						font-size: 18px;
						letter-spacing: 0;
						text-transform: capitalize;
						border: none;
						margin-top: 0;
						@include respond(small) {
							width: auto;
						}
						@include respond(large) {
							width: 30%;
						}
						&::after {
							content: '';
							display: inline-block;
							margin-left: 13px;
							opacity: .75;
						}
					}
					.card-icons {
						float: left;
						margin-top: 8px;
						height: 26px;
						@include respond(small) {
							margin-top: 13px;
						}
						span {
							margin-right: 15px;
							@include respond(small) {
								margin-right: 8px;
							}
							&.icon-Visa,
							&.icon-Amex,
							&.icon-MasterCard,
							&.icon-Master,
							&.icon-Discover {
								img {
									height: 100%;
									opacity: 1;
									&.grayscale {
										display: none;
									}
									@include respond(iphone) {
										height: 24px;
									}
								}
								img:not(.grayscale) + img {
									display: none;
								}
							}
						}
					}
				}
				.payment-method {
					margin-bottom: 0;
					min-height: 345px;
					.payment-method-text {
						@include font-small(12px);
						line-height: 1rem;
						margin-bottom: 32px;
					}
					&.payment-method-expanded {
						background-color: $white;
						border: 1px solid $gray-warm;
						padding: 24px 16px 16px;
						&:not([data-method="CREDIT_CARD"]) {
							padding: 40px;
						}
					}
					&.payment-method-kr {
						min-height: auto;
						.content-asset {
							padding: 5px 0 20px;
						}
					}
					.form-row.cvn {
						.form-field-tooltip {
							@include font-small;
						}
					}
					button {
						&.adyen-checkout__button,
						&.submit-billing {
							font-size: 20px;
							font-weight: 200;
							background: #000;
							color: #fff;
							cursor: pointer;
							height: 44px;
							width: initial;
							border-radius: 0;
							box-shadow: none;
							border: 2px solid #000;
							@include respond(small) {
								display: block;
								margin: 0 auto;
							}
							&:hover {
								background: #fff;
								color: #000;
								border-color: #000;
								box-shadow: none;
							}
						}
					}
					.adyen-checkout__paypal__button {
						max-width: 305px;
						margin: 0 auto;
						@include respond(largeUp) {
							margin-left: 0;
						}
					}
				}
				label {
					margin-top: 5px;
				}
				.dw-apple-pay-button {
					border-radius:0!important;
				}
			}
			.checkout-billing > .form-row.form-row-button {
				border-top: none;
				padding-top: 0;
				button {
					font-size:20px;
					font-weight:200;
				}
			}
			.checkout-billing .kcp-method {
				display: none;
			}
			.checkout-privacy-policy {
				@include font-small;
				a {
					@include font-small;
				}
			}
			.form-row {
				.form-caption {
					@include font-small;
				}
				.field-wrapper{
					select,
					input[type="password"],
					input[type="text"],
					input[type="email"],
					input[type="tel"],
					input[type="number"]{
						background-color:#f7f7f7;
						border-radius:0;
						@include font-small;
					}
				}
				#daum-postcodes-drowdown {
					div[id^="__daum__layer"] {
						@media screen and (max-width: 479px) {
							overflow: visible !important;
							width: 120% !important;
							transform: scale(0.8);
							iframe {
								left: -12.5% !important;
								width: 104% !important;
							}
						}
						@media screen and (min-width: 480px) {
							width: 100% !important;
						}
						@include respond(largeUp) {
							width: 446px !important;
						}
						@include respond(standard) {
							width: 484px !important;
						}
					}
				}
			}
		}
		#secondary  {
			.mini-payment-instrument .details div,
			.mini-billing-address .details div,
			.mini-shipment .details div {
				@include font-small;
			}
		}
	}
	&.egc-checkout {
		#main {
			#primary {
				.fieldset {
					&.address {
						display: block;
					}
				}
			}
			#secondary {
				.order-totals-table {
					tr {
						&.order-subtotal,
						&.order-sales-tax,
						&.order-total {
							td {
								&:first-child {
									font-size: 14px;
								}
								&:last-child {
									font-weight: normal;
									font-size: 16px;
								}
							}
						}
						&.order-total {
							td {
								&:first-child {
									font-size: 16px;
								}
							}
						}
					}
					.order-shipping {
						display: none;
					}
				}
				.checkout-order-totals {
					border-top: none;
				}
				.checkout-mini-cart {
					.mini-cart-image {
						width: 30%;
					}
					.mini-cart-name,
					.mini-cart-id,
					.mini-cart-pricing {
						width: 70%;
						padding-right: 0;
						float: right;
						font-family: $neuzeit;
						font-size: 14px;
						letter-spacing: normal;
					}
					.mini-cart-id {
						color: $gray-medium;
					}
					.mini-cart-name {
						font-weight: bold;
						.mini-cart-name-value {
							color: $black;
							margin-bottom: 0;
						}
					}
					.mini-cart-pricing {
						margin-top: 34px;
						.mini-cart-price {
							font-size: 16px;
						}
					}
				}
				.gc-mini-attributes {
					padding: 14px 0 40px;
					font-family: $neuzeit;
					font-size: 16px;
					letter-spacing: normal;
					> div {
						margin: 6px 0;
						&.gc-inline {
							display: inline-block;
							margin-bottom: 0;
							margin-right: 48px;
						}
						&.gc-to-email {
							display: inline-block;
							margin-top: 0;
							margin-bottom: 6px;
							.label {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
