.notify-me-link-wrapper {
    font-size: rem(12px);
	padding: 0 0 rem(25px);
	margin-top: -10px;
    a {
        font-weight: 400;
		color: $primary-orange;
		text-align: right;
		text-decoration: underline;
		@include respond(largeUp) {
			margin-right: rem(35px);
		}		
    }
}

.ui-dialog.notifyme-dialog {
    font-family: $suisseintregular;
	max-width: 690px;
	background-color: #f3f4f2;
	.ui-widget-header {
		background-color: #f3f4f2;
	}
	
	.ui-icon-closethick {
		background-image: url(../images/interface/black-close-btn2.png);
	}

	.notifyme-header-wrapper {
		.notifyme-title {
			@include font-size(29px);
			@include line-height(40px);
			color: $primary-orange;
			font-weight: 400;
			margin-bottom: 18px;
		}
		.notifyme-subtitle {
			@include font-size(18px);
			@include line-height(20px);
			color: $darkgrey;
			font-weight: 400;
			padding: 0 0 rem(25px);
			border-bottom: solid 2px #c8caca;
		}
	}

	.radio-buttons-group-wrapper {
		.label-container {
			color: $gray-medium2;
			padding: 0 7px 15px;
		}
		.label {
			@include font-size(18px);
			font-weight: 400;
			color: $darkgrey;
		}
		.radio-button {
			
			.radio-button-label {
				@include font-size(18px);
				border: 1px solid #a4a4a4;
				color: #a4a4a4;
				font-weight: 400;
				background-color: #e1e2e0;
				padding: 9px 18px;
			}
			input[type=radio] {
				font-size: 16px;
				&:checked ~ .radio-button-label {
					color: $black;
					border-color: $black;
					background-color: $white;
					font-weight: 400;
				}
			}
			span {
				&.error {
					@include font-size(12px);
					font-weight: 400;
				}
			}
			&.swatchanchor {
				background: $gray-white;
				border-width: 1px;

				&.selected,
				&:hover {
					border-color: $black;
				}
			}
		}
	}
	input:not([type=checkbox]) {
		border: 1px solid #8b8c8a;
		color: #8b8c8a;
		background-color: $white;
		@include font-small;
		font-size: 16px;
		font-weight: 400;
	}
	.input-checkbox {

		& + label {
			background: url(../images/interface/unchecked.png) no-repeat 0 3px;
			span {
				@include font-size(12px);
				color: #4C4C4C;
			}
		}
		&:checked {
			& + label {
				background: url(../images/interface/checked.png) no-repeat 0 3px;
			}
		}
	}
	.notifyme-submit {
		@extend .button;
		padding: 0 rem(30px);
		text-transform: uppercase;
		line-height: normal;
		height: 44px;
	}
	&.notifyme-success {	
		border-top: 0;	
		.ui-widget-header {
			background-color: $gray-light-bk;
		}
		.ui-icon-closethick {
			background-image: url(../images/interface/black-close-btn2.png);
			margin-top: 0 !important;
		}

		.notifyme-confirmation-reco {
			background-color: $white;
			min-height: 370px;
			.product-listing-1x4 .progress {
				display: none;
			}
			.slick-slider {
				margin-bottom: 5px;
			}
			.product-tile {
				max-height: 330px;
				@media only screen and (min-width: 567px) and (max-width: 770px) {
					max-height: 380px;
				}
			}
		}
		
		.notifyme-confirmation-header {
			background-color: $gray-light-bk;
			text-align: center;
			padding: 15px 24px 0;
			@include respond(largeUp) {
				text-align: left;
			}
			.notifyme-contact {
				margin: 35px 0 25px;
				font-size: 14px;
				color: $black;
				a {
					color: $black;
					text-decoration: underline;
				}			
			}
		}
		.notifyme-confirmation-title {
			@include font-size(36px);
			@include line-height(36px);
			font-weight: 400;
			color: $darkgrey;
			margin-bottom: 35px;
		}
		.notifyme-confirmation-subtitle {
			@include font-size(16px);
			@include line-height(18px);
			color: $black;
			font-weight: 400;
			span {
				&::after {
					content: '\0027';
				}
			}
		}
	}
	.pdp-recommendations h3 {
		font-size: rem(18px);
		text-align: center;
		@include respond(largeUp) {
			padding-top: 20px;
		}
		@include respond(small) {
			margin-top: 20px;
		}
	}
}