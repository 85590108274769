.js .simple-submit {
	display: none;
}

legend {
	background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top $gray-white;
	border-bottom: 1em solid $white;
	font-size: 1.1em;
	font-weight: 700;
	margin: 0;
	padding: 1.16em 2% .83em;
	position: relative;
	text-transform: uppercase;
	width: 96%;
	.ie7 & {
		width: 94%;
	}
	span {
		font-size: 12px;
		font-weight: 400;
		position: relative;
		text-transform: none;
		padding: 0 .5em;
		em {
			color: $black;
		}
	}
}
.input-textarea {
	border-radius: 1rem;
	border: 2px solid #ccc;
	background: #f5f5f5;
	width: 98%;
	font-family: $titillium;
}

.dialog-required {
	display: inline;
	font-size: 12px;
	font-weight: 400;
	position: relative;
	padding: .16em 0;
	text-transform: none
	em {
		color: $black;
	}
}

label {
	display: block;
	font-size: 1.1em;
	font-weight: 700;
	margin-bottom: .3rem;
	.form-horizontal & {
		float: left;
		margin-top: .5rem;
		margin-bottom: 0;
		width: 100%;
		@include respond(largeUp) {
			text-align: right;
			width: 25%;
		}
	}
	.label-inline & {
		display: inline;
		float: none;
		font-size: 1em;
		font-weight: 400;
		margin-top: 0;
		text-align: left;
		width: auto;
	}
	.label-above & {
		margin-bottom: .3rem;
		width: auto;
	}
}

.form-label-text {
	float: left;
	font-size: 1.1em;
	font-weight: 700;
	padding: 0.75em 0 0;
	text-align: right;
	width: 25%;
}

.form-row {
	@extend %clearfix;
	margin: .5rem 0 1rem;
}

.form-row-button {
	clear: left;
	.form-horizontal & {
		@include respond(largeUp) {
			margin-left: 27%;
			width: 62%;
		}
	}
}

.field-wrapper {
	box-sizing: border-box;
	margin-bottom: .5rem;
	width: 100%;
	.form-horizontal & {
		float: left;
		width: 76%;
		@include respond(largeUp) {
			padding-left: 2%;
			width: 50%;
		}
	}
	.label-inline & {
		float: left;
		padding-left: 0;
		width: auto;
	}
	.label-above & {
		float: none;
		padding-left: 0;
		width: auto;
	}
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
input[type="email"],
textarea,
select {
	font-family: $titillium;
	border-radius: 2em;
	line-height: 2.5em;
	min-height: 2.5em;
	padding: 0 1.5em;
	border: 2px solid $gray-light;
	background-color: $gray-white;
	color: $black;
	width: 100%;
	box-sizing: border-box;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	&:focus{
		outline: none;
	}
	&::-ms-clear {
		display: none;
	}
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

select {
	background-image: url('../images/selectArrow.svg');
	background-repeat: no-repeat;
	background-position: right 17px center; //might crash IE9??? caniuse.com
	padding: 0 4em 0 1.5em;
	-webkit-appearance:	none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance:	none;

}

// IE fix for dropdown select default
select::-ms-expand {
	display: none;
}

input[type="checkbox"],
input[type="radio"] {
	margin: 1em 1% 1em 2%;
	width: 1px;
    height: 1px;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
	.label-inline & {
		margin: 0 .3rem 0 0;
	}
}
//custom checkbox and radio
input[type="checkbox"] + label {
display: block;
background:url('../images/checkbox_unchecked.png') left 2px no-repeat;
cursor:pointer;
padding-left: 25px;
margin-left: 0;
}
input[type="checkbox"]:checked + label {
background:url('../images/checkbox_checked.png') left 2px no-repeat;
}
input[type="radio"] + label {
	min-height: 16px;
	line-height: 16px;
	display: block;
	background:url('../images/radio_unchecked.svg') left 0 no-repeat;
	background-size: 14px 14px;
	cursor:pointer;
	padding-left: 20px;
	margin-left: 0;
}
input[type="radio"]:checked + label {
	background:url('../images/radio_checked.svg') left 0 no-repeat;
	background-size: 14px 14px;
}
//checkbox and radio end

.form-caption {
	clear: left;
	width: auto;
	.form-horizontal & {
		@include respond(largeUp) {
			margin-left: 27%;
		}
	}
	.label-above & {
		margin-left: 0;
	}
	.form-indent & {
		margin-left: 0;
	}
}

.form-indent {
	@include respond(largeUp) {
		margin-left: 27%;
	}
}

.error-message {
	padding: 0;
	text-indent: 0;
	width: 49.5966%;
}

.error {
	background-color: $green;
	border-color: $error-red;
	color: $error-red;
}

span.error,
div.error,
div.error-message {
	background: none;
	color: $error-red !important;
	text-transform: none !important;
	font-weight: 400 !important;
	border: none;
}

.error-form {
	background-color: $white;
	border: 1px solid $error-red;
		border: 0;
		float: left;
		width: 100%;
	border-radius: .5em;
	color: $error-red;
	margin: 0.5em 0;
	padding: 0.8em;
}

.success-form {
  color: $green;
  padding: 8px 0 16px
}

.form-inline {
	form {
		padding: 0 0 2em;
		width: 98%;
	}
	label {
		padding: 0 0 0.75em;
		text-transform: uppercase;
		width: auto;
	}
	input[type="text"], select {
		margin-left: 0;
		width: 95%;
	}
	button {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}

.row-inline {
	display: inline;
	label {
		width: auto;
	}
}

.require-dialog {
	font-weight: 400;
	position: absolute;
	right: 1em;
	text-transform: none;
	top: 1em;
}

.required-indicator {
	color: $red;
}

.form-field-tooltip {
	float: left;
	margin-left: 3%;
	padding-top: .75em;
	width: 20%;
}

.contactus-form {
	.required-indicator {
		color: $black;
		float: right;
		margin-left: 2px;
	}
}

.input-text {
	border-radius: 1.25rem;
}

.field-wrapper {
	input.error {
		border: 2px solid $error-red !important;
	}
}

.form-row.error {
	input {
		border: 2px solid $error-red;
	}
}

.input-code86 {
	display: none;
}
