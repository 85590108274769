.js {
	#suggestions {
		background-color: $gray-light;
		color: $black;
		cursor: pointer;
		padding: 0;
		display: none;
		position: absolute;
		z-index: 8184;
		margin-left: 10px;
		div {
			clear: both;
			height: 33px;
			padding: 5px 5px;
			overflow: hidden;
			white-space: nowrap;
			&:hover {
				background-color: $white;
				color: $gray-dark;
			}
			.suggestionterm {
				float: left;
				overflow: hidden;
				width: 110px;
			}
			.hits {
				float: right;
				line-height: 1.8em;
				text-align: right;
				margin-left: 3px;
			}
		}
	}
}

.top-border-search {
	width: 100%;
	height: 2px;
	float: left;
	margin-bottom: .5rem;
	margin-top: 0rem;
	background-color: $gray-light;
}

.pt_product-search-noresult {
	.no-hits-shop-by-category {
		margin-top: 50px;

		&.home-bottom-slots {
			.promoSlotFull#home-full-1 {
				.bannerContent {
					display: none;
				}

				.itemContent {
					border-top: none;

					a {
						border: none;
						width: 20%;

						&:first-child {
							font-size: 20px;
							box-sizing: border-box;
							display: block;
							float: none;
							border: none;
							width: 100%;
							height: 55px;

							.headers-wrapper {
								width: 100%;
								position: static;
								-webkit-transform: none;
								transform: none;
								text-align: center;

								&:after {
									content: '';
									display: block;
									clear: both;
								}

								h2, h3 {
									display: inline-block;
									margin: 0;
									font-size: 30px;

									@include respond(small) {
										font-size: 1.25rem;
									}
								}

								h2 {
									margin-right: 10px;
								}
							}
						}
						&:not(:first-child) {
							@include respond(small) {
								width: 100%;
								height: auto;
								text-align: center;
								border-bottom: 2px solid #ccc;
								vertical-align: middle;

							}
							& > div {
								display: inline-block;
								width: 30%;
								min-width: 100px;
								padding: 5px 0;
								vertical-align: middle;
								text-align: left;
							}
							img {
								@include respond(small) {
									position: static;
									max-width: 25%;
									transform: none;
									-webkit-transform: none;
								}
							}
							h3 {
								@include respond(small) {
									position: static;
									display: inline-block;
									width: 20%;
									padding-left: 2%;
									margin: 0;
									vertical-align: middle;
									text-align: center;
								}
							}
						}
						&:last-child {
							@include respond(small) {
								border-bottom: none;
							}
						}
					}
				}
			}
		}
	}

	.no-hits-recommendations {
		margin-top: 40px;
		border-bottom: none;
		padding-bottom: 0;
		margin-bottom: 0;

		@include respond(small) {
			margin-bottom: 40px;
		}

		h3 {
			margin-bottom: 60px;
		}

		&.pdp-recommendations {
			.product-listing-1x4 {
				.search-result-items {
					margin: 0;
				}
			}
		}
	}

	.no-hits-banner {
		width: 100%;
		@include respond(small) {
			padding-top: 10px;
		}
		img {
			width: 100%;
		}
		.no-results-banner {
			@include respond(small) {
				height: auto;
				padding-top: 10px;
				margin-bottom: 10px;
				min-height: 50px;
			}
		}
	}
	.primary-content {
		width: 100%;
		padding: 0;
		border: 0;
		@include respond(small) {
			width: 93%;
			border: 0;
			margin: auto;
		}
		.section-header {
			font-family: $titillium;
			font-weight: 700;
			color: $gray-warm;
			margin: 0;
			padding: 1rem;
			text-transform: none;
			text-align: center;
			background: none;
			border: none;
			font-weight: 400;
			font-size: 1.25rem !important;
			letter-spacing: .5px;
			margin-top: -2.5rem;
			.no-hits-search-term, .no-hits-search-term-suggest {
				color: $black;
				font-style: normal;
			}
			@include respond(small) {
				font-size: 1rem !important;
			}
			p {
				@include respond(small) {
					line-height: 18px;

					span.search-term-suggest {
						padding-top: 10px;
						display: block;
					}
				}
			}
			a {
				color: $black;
			}
		}
		.no-hits-search {
			width: 40%;
			margin: auto;
			height: 7rem;
			@include respond(small) {
				width: 80%;
				height: auto;
			}
			.no-hits-label {
				text-align: center;
				font-family: $oswald;
				font-weight: bold;
				font-size: 16px;
				letter-spacing: 1px;
				margin-bottom: 1rem;
				text-transform: uppercase;
				color: #333;
			}
			button[type="submit"] { //search button
				background-image: url('../images/searchIcon.svg');
				background-repeat: no-repeat;
				border: none;
				color: $gray-dark;
				margin-top: -1.625rem;
				margin-right: .75rem;
				float: right;
				opacity: 0.7;
				width: 17px;
				height: 17px;
				padding: 0;
				border-radius: 0;
				&::before {
					content: none;
				}
				&:hover {
					opacity: 1.0;
					position: relative;
					z-index: 1;
				}
			}
		}
		.no-hits-help {
			width: 60%;
			margin: auto;
			margin-bottom: 2rem;
			@include respond(small) {
				width: 100%;
			}
			p {
				text-align: center;
				color: $gray-dark;
				font-family: $titillium;
				font-weight: 700;
				text-transform: uppercase;
				font-size: 1rem;
				@include respond(small) {
					font-size: 12px;
				}
			}
			li {
				font-family: $titillium;
				color: $gray-warm;
				font-size: 14px;
				@include respond(small) {
					font-size: 12px;
				}
			}

		}
		.search-noresults-header {
			margin-top: 2rem;
			margin-bottom: .5rem;
			text-align: center;
			h1 {
				font-family: $oswald;
				font-weight: bold;
				font-size: 2rem;
				color: $gray-dark;
				text-transform: uppercase;
				letter-spacing: 2px;
				@include respond(small) {
					font-size: 20px;
					line-height: 36px;
				}
			}
		}
		.breadcrumb {
			padding: 0;
			margin-bottom: 2rem;
			color: $gray-warm;
			font-family: $titillium;
			font-weight: 400;
			a {
				color: $gray-warm;
			}
			.breadcrumb-element:last-child {
				font-weight: 400;
			}
		}
	}
}

.no-hits-contactus {
	width: 100%;
	float: left;
	margin-bottom: 50px;
	@include respond(small) {
		margin-bottom: 30px;
	}
	h3 {
		font-family: $oswald;
		font-weight: bold;
		font-size: 1.25rem;
		color: $gray-dark;
		text-transform: uppercase;
		letter-spacing: 2px;
		text-align: center;
		@include respond(small) {
			font-size: 16px;
		}
	}
	ul {
		width: 100%;
		float: left;
		text-align: center;

		@include respond(small) {
			width: 100%;
			margin: 0;
			padding: 0;
		}
		margin: auto;
		li {
			width: auto;
			display: inline-block;
			list-style: none;
			text-align: center;
			padding: 0 20px;
			box-sizing: border-box;
			@include respond(small) {
				width: 100%;
				margin-bottom: 2rem;
			}

			.contactus-black {
				color: $black;
				font-family: $titillium;
				text-transform: uppercase;
				font-size: .875rem;
				margin-top: .5rem;
				width: 100%;
				text-align: center;
				font-weight: 700;
				float: left;
				@include respond(small) {
					color: $gray-dark;
					font-size: 12px;
				}
			}
			.contactus-gray {
				color: $gray-warm;
				font-family: $titillium;
				font-size: .875rem;
				width: 100%;
				text-align: center;
				font-weight: 400;
				float: left;
				@include respond(small) {
					font-size: 12px;
				}
			}
		}
	}
}


