// clubglove header overrides
.primary-logo {
    display: block;
    a img {
        width: 79px;
        max-width: 100%;
        @include respond(largeUp) {
            width: 111px;
        }
    }
}
.header-search {
    background-color: $lightestgrey;
    @include respond(small) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        &.hdr-open {
            display: block;
            top: 48px;
        }
    }
    input[type="text"] {
        background-color: $lightestgrey;
        color: $extradarkgrey;
        text-transform: none;
        padding: 17px 13px 10px;
        @include respond(largeUp) {
			padding: 24px 50px 12px 20px;
		}
    }
    button[type="submit"] {
        background-image: url('../images/icons/icon-search-gray.svg');
    }
    @media only screen and (min-width: 880px) and (max-width: 1102px) {
        width: 135px;
    }
}

.header-main__inner {
    @include respond(small) {
        height: 49px;
    }
}

// Header Promo
.header-banner {
    font-family: $din-condensed;
    @include respond(small) {
        display: block;
    }
    .header-promotion {
        font-size: rem(16px);
        font-family: $bebas-semi;
        font-weight: 700;
        color: $black;
    }
}

.header-main {
    box-shadow: 0 0 20px 0 rgba(0,0,0,.5);
    font-family: $din-condensed;
    &__inner {
        box-shadow: none;
    }
    @include respond(small){
        border-bottom: solid 1px $lightestgrey;
    }
}

#mobileSearchShowBtn {
    background-color: $lightestgrey;
    background-image: url('../images/icons/icon-search-gray.svg');
}

.header-search input[type="text"] {
    letter-spacing: normal;
    letter-spacing: 1.64px;
    font-size: 18px;
    text-transform: uppercase;
    @include respond(small) {
        padding: 15px 13px;
    }
}

.checkout-enable-accessibility {
	height: 26px;
	line-height: 26px;
	font-family: $din-condensed;
	font-size: 14px;
}
