@each $type in $swatch-types {
	.swatch-#{nth($type, 1)} a{
		background-color: nth($type, 2) !important;
	}
}

.swatch-miscellaneous a {
	background: url("../images/interface/icon-color-swatch-misc.png") no-repeat scroll left top transparent;
}

.unselectable.swatch-miscellaneous a {
	background: url("../images/interface/icon-color-swatch-misc-unselectable.png") no-repeat scroll left top transparent !important;
}

.swatch-camo a {
    background: url("../images/interface/camo-30x30.jpg") no-repeat scroll left top transparent;
}
