.bonus-product-list {
	.custom-select-field .container {
		width: 98%;
		float: left;
		margin-right: 2%;
		.select-size {
    		margin-bottom: 1rem;
    	}
		.select-width {
    		margin-bottom: 1rem;
    	}
    	.select-length {
    		margin-bottom: 1rem;
    	}
		.select-waist {
    		margin-bottom: 1rem;
    	}
    	.select-hand {
    		margin-bottom: 1rem;
    	}
	}
	.promotion {
		color: $red;
		@include font-size(14px);
		width: 100%;
		border: 0 none;
		box-sizing: border-box;
		margin: 0;
		padding: 10px 20px;
	}
	.bonus-product-item {
		border-top: 2px solid #eee;
		padding-top: 16px;
		margin-bottom: 16px;
		.product-col-1 {
			width: 30% !important;
			padding: 0 !important;
			margin: 0;
			.product-primary-image {
				display: block;
				width: 100%;
				max-width: 100%;
			}
		}
		.product-col-2 {
			width: 64% !important;
			float: right;
			.product-name {
				float: none;
				margin-top: 0rem;
				font-size: 14px;
				line-height: 1;
				margin-bottom: 0;
			}
			.product-variations,
			.product-options {
				.swatches {
					li {
						width: 16%;
						height: auto;
						&.selected-value {
							width: 80%;
							margin: 1px;
						}
						a {
							width: auto;
							height: auto;
							img {
								width: 100%;
								height: auto;
							}
						}
					}
				}
			}
		}
		@extend %clearfix;
	}
	.product-add-to-cart {
		padding-bottom: 0;
		position: relative;
		clear: both;
		.inventory {
			width: 6rem;
			float: left;
			label {
				float: left;
				margin-top: 6px;
			}
			input {
				width: 55px;
				float: left;
				margin-bottom: 10px;
				margin-left: 8px;
				height: 34px;
				&.error {
					border-color: $red;
				}
			}
		}
	}
	.quantity-error {
		color: $red;
		text-transform: inherit;
		width: 100%;
		position: absolute;
		bottom: -14px;
		left: 0;
		line-height: 1;
	}
	.bonus-product-list-footer {
		@extend %clearfix;
		padding-top: 1em;
		.add-to-cart-bonus {
			float: right;
		}
	}
	.selected-bonus-item {
		margin: 1em 0 1em 1em;
		position: relative;
		.item-name {
			font-weight: bold;
		}
		.remove-link {
			cursor: pointer;
			color: $red;
			position: absolute;
			&:hover {
				color: $gray-dark;
			}
		}
	}
}

.bonus-discount-container {
	.ui-dialog-title {
		margin-left: 2.5rem;
		margin-top: .5rem;
		font-family: $oswald;
		font-size: 1.25rem;
		letter-spacing: 1px;
		color: $gray-dark;
	}
	.bonus-product-promo{
		color: $red;
		@include font-size(14px);
		margin-top: 1rem;
		margin-left: 15px;
	}
	.bonus-product-alert {
		@include font-bold(14px);
		margin-left: 15px;
		margin-top: 5px;
	}
	.promo-details {
		display: none;
		&.visible {
			display: block;
		}
	}
}

.bonusDiscountContainerHtml {
	color: $red;
	button {
		color: $red;
	}
}

.bonus-product-summary {
	h2 {
		font-size: .875rem;
		font-family: $titillium;
		font-weight: bold;
		text-transform: uppercase;
	}
	.selected-bonus-items {
		margin-left: -.75rem;
		.selected-bonus-item {
			margin-top: -1rem;
		}
		.item-name {
			color: $black;
			text-transform: uppercase;
			text-transform: uppercase;
			clear: both;
		}
		.item-attributes {
			color: $gray-warm;
			text-transform: uppercase;
		}
		.remove-link {
			width: 1rem;
			height: 1rem;
			color: white;
			border-radius: 8px;
			background-image: url("../images/close.png");
			background-repeat: no-repeat;
			background-color: #aaa;
			background-position: 3px;
			position: absolute;
			float: left;
			right: 0;
			top: .15rem;
		}
	}
}

.bonus-product-list {
	.bonus-product-list-footer {
		.add-to-cart-bonus {
			width: 100%;
			float: left;
		}
	}
}

.select-bonus-item {
	border: 2px solid black;
	background: white !important;
	color: black !important;
	float: left;
	margin: 0;
	width: 5rem;
	padding: 0;
	line-height: 22px;
	height: 33px;
	&:hover {
		color: $white !important;
		background: $black !important;
	}
	&.enabled{
		border-color: $black;
		opacity: 1;
	}
}
#bonus-product-dialog {
	#bonus-product-list {
		margin: 0;
		max-width: 100%;
		position: static;
	}
}

.ui-dialog {
	&.bonus-dialog {
		box-sizing: border-box;
		@include respond(small) {
			width: 385px!important;
			max-width: calc(100% - 10px);
		}
		@include respond(large) {
			max-width: 385px;
		}
		.ui-dialog-title {
			@include font-bold(14px);
			margin: 5px 0 25px 20px!important;
		}
		.dialog-content {
			box-sizing: border-box;
			width: 100%!important;
			padding: 15px!important;
		}
		.ui-dialog-buttonpane  {
			.ui-dialog-buttonset {
				margin-left: 25px;
			}
		}
	}
}
