///////////////////////
/// Styles for Shopping Cart page
///////////////////////
#wrapper.pt_cart {
    #main {
        #primary {
            .cart-actions {
                a.cart-action-paypal {
                    span {
                        line-height: 46px;
                    }
                }
                .cart-action-paypal.button {
                    background-color: $white;
                    color: $black;
                    border: solid 1px $black;
                    .cart-action-paypal {
                        background-color: $white;
                        color: $black;
                        border: solid 1px $black;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .cg-attribute,
            .line-item-attributes {
                line-height: 21px;
            }
            .cg-attribute {
                .value,
                .label {
                    font-size: 16px;
                    color: #767676 !important;
                }
            }
            .line-item-attributes {
                .value,
                .label {
                    font-size: 14px !important;
                    color: $black !important;
                }
            }
            #cart-items-form {
                #cart-table {
                    tr td {
                        &.item-quantity .button-text,
                        &.item-details .item-edit-details a,
                        &.item-quantity a.add-to-wishlist,
                        &.item-details .product-list-item .label,
                        &.item-details .product-list-item .value {
                            font-size: 14px;
                        }
                        &.item-details .product-list-item a {
                            font-size: 16px;
                        }
                    }
                }
                .cart-footer .cart-coupon-code {
                    border-top: none;
                }
            }
        }
    }
    .cart-recommendations-mobile {
        display: none;

        @include respond(small) {
            display: block;
            width: calc(100% + 2.3em - 10px) !important;
            margin-left: -1.15em;
        }

        @media screen and (max-width: 767px) {
            width: calc(100% + 2.3em) !important;
        }

        .fj-recommended-products {
            .fj-rp-slider {
                padding: 0;
            }
        }

        .fj-rp-slider-wrapper {
            overflow: visible;
        }

        .slick-list {
            @media screen and (max-width: 767px) {
                margin-left: -10px;
            }
        }
    }
    .cart-recommendations-desktop {
        display: block;
        @include respond(small) {
            display: none;
        }
    }
    .cart-recommendations {
        .fj-recommended-products .fj-rp-slider .fj-rp-slide {
            padding: 0 5px;

            @include respond(small) {
                padding: 0 0 0 10px;
            }
        }

        .fj-rp-slide-image-background {
            background-color: #ebebeb;
        }

        .fj-rp-slide-title {
            float: left;
            width: 80%;
            padding: 17px 0 0 0;
            box-sizing: border-box;
            text-align: left;
            font-size: 16px;
            color: #333;
            font-weight: 700;
            font-family: $neuzeit;
            @media screen and (max-width: 767px) {
                padding: 17px 10px 0 10px;
            }
        }

        .fj-rp-slide-price {
            float: right;
            width: 20%;
            padding: 20px 0 0 0;
            color: $black;
            font-weight: bold;
            font-family: $font-small;
            font-size: 14px;
            box-sizing: border-box;
            text-align: right;
            @media screen and (max-width: 767px) {
                padding: 20px 10px 0 0;
            }

        }

        .slick-arrow {
            height: 30px;
            width: 19px;
            top: 50%;
            margin-top: 0;
            padding: rem(20px);
            box-shadow: none;
            transform: translateY(calc(-100% - 9px));

            @media screen and (min-width: 481px) {
                transform: translateY(calc(-50% - 6px));
            }
            @media screen and (min-width: 768px) {
                box-shadow: 0 .125rem .25rem rgba($black, 0.3);
                transform: translateY(calc(-50% - 15px));
            }

            &.left {
                background: $white url(../images/carouselBlackArrowLeft.svg) center / 9px 13px no-repeat;
                left: 46px;
                @include respond(small) {
                    left: 0;
                }
                @media screen and (max-width: 767px) {
                    background: transparent url(../images/carouselBlackArrowLeft.svg) center / 9px 13px no-repeat;
                }

            }

            &.right {
                background: $white url("../images/carouselBlackArrowRight.svg") center/9px 13px no-repeat;
                right: 46px;
                @include respond(small) {
                    right: -10px;
                }
                @media screen and (max-width: 767px) {
                    background: transparent url("../images/carouselBlackArrowRight.svg") center/9px 13px no-repeat;
                    right: 0;
                }
            }

            &.slick-disabled {
                opacity: 0;
            }

            &:hover {
                border: solid 2px $black;
            }

            &::before {
                display: none;
            }
        }
    }
}

#wrapper.pt_cart #main #primary .name-container .cart-name {
	h1 {
		font-weight: 400;
		text-transform: none;
        font-size: 22px;
	}
}

#wrapper.pt_cart #main #primary {
    #cart-items-form .cart-footer .cart-coupon-code {
        #add-coupon {
            height: 42.5px;
            font-size: 14px;
        }
        input {
            height: 42px;
            line-height: 42px;
            border-radius: 0;
            width: 340px;
            margin-right: -3px;
            @include respond(small) {
                width: 308px;
            }
        }
    }
}

#wrapper.pt_cart #main #primary {
    #cart-items-form .cart-footer .action-carousel .cart-actions button.button#go-checkout,
    .name-container .cart-actions-top button.go-checkout {
        background-color: $darkgrey;
        border-color: $darkgrey;
        font-weight: 400;
        &:hover {
            background-color: $black;
            border-color: $black;
            color: $white;
        }
    }
}

#wrapper.pt_cart #main #primary .name-container .cart-actions-top button.go-checkout {
    height: 48px;
    line-height: 43px;
}
