.fj_account #wrapper.pt_account.tab-accountlanding #main {
	#secondary {
        display: none;
    }
   #primary.account-content {
       width: 100%;
       padding-top: 0;

       @media only screen and (min-width: 880px) and (max-width: 1680px) {
            width: 100%;
        }
        @include respond(largeUp) {
            max-width: 100%;
        }

        @include respond(small) {
            footer {
                padding-top: 50px;
            }
        }


       //--------------------------------------------------------------
        // Dashboard recommendations
        //--------------------------------------------------------------

        .fj-recommended-products {

            .fj-rp-slider-wrapper {
                margin-left: 0;
                &.pdp-recommendations {
                    max-width: none !important;
                }

                .fj-rp-slider-arrow {
                    bottom: 50%;
                    box-shadow: rgba(50,50,93,.25) 0px 6px 12px -2px, rgba(0,0,0,.3) 0px 3px 7px -3;
                    &.left::before {
                        left: 58%;
                    }
                    &.right::before {
                        left: 45%;
                    }
                }

                .fj-rp-slider {
                    padding: 0;

                    .fj-rp-slide-title {
                        text-align: left;
                        font-size: 1rem;
                        font-weight: bold;
                        line-height: 1.5;
                        letter-spacing: -0.32px;
                        color: $almost-black;
                        padding: 0;

                        span.product-name {
                            width: 140px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                    .fj-rp-slide-category {
                        text-align: left;
                        letter-spacing: -0.07px;
                        color: $gray-warm2;
                        font-size: 14px;
                        margin: 0;
                        padding: 0;
                    }
                    .fj-rp-slide-price {
                        text-align: left;
                        line-height: 1.71;
                        letter-spacing: -0.07px;
                        color: $almost-black;
                        font-size: 14px;
                        font-weight: bold;
                        float: right;
                        padding: 0;
                    }
                }
            }

            a {
                text-decoration: none;
            }

            .fj-rp-slider-title {
                left: auto;
                width: 100%;
                margin: 60px 0 15px;
                font-family: $neuzeit;
                letter-spacing: -0.48px;
                font-size: 24px;
                color: $almost-black;
                text-transform: capitalize;
                font-weight: bold;
                position: relative;


                p {
                    display: inline;
                    font-size: 14px;
                    color: $black;
                    font-weight: normal;
                    margin-left: 25px;

                    @include respond(small) {
                        display: block;
                        color: $black;
                        margin-left: 0;
                    }

                    a {
                        text-decoration: underline;
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }
            }
        }

        //--------------------------------------------------------------
        // Dashboard grid Styles
        //--------------------------------------------------------------


        .fj-account-dashboard-cards {
            padding: 0;
            font-family: $neuzeit;

            h2 {
                margin: 50px 0 38px;
                font-family: $neuzeit;
                letter-spacing: -0.48px;
                font-size: 24px;
                color: #1a1a1a;
                text-transform: capitalize;
                font-weight: bold;

                @include respond(small) {
                    margin: 23px 0 2px;
                }
            }

            .content-wrapper {
                display: flex;
                flex-direction: row;
                padding: 0;
                margin-left: -10px;
                margin-right: -10px;


                @include respond(small) {
                    flex-direction: column;
                    margin-left: 0;
                    margin-right: 0;
                }

                &.insider {
                    margin-bottom: 50px;

                    @include respond(small) {
                        display: inline;

                        .fj-account-dashboard-card {
                            width: 46%;
                            float: left;
                            min-height: 300px;
                            margin-bottom: 30px;
                            &:nth-child(even) {
                                padding-left: 20px;
                            }
                            &:nth-child(odd) {
                                clear: both;
                            }
                        }

                        .fj-account-dashboard-card-text {
                            width: 90%;
                        }

                    }

                    .fj-account-dashboard-card-image {
                        min-height: auto;
                        margin: 0 0 0 -13px;
                        padding-bottom: 15px;
                        img {
                            height: 82px;
                        }
                    }

                    h4 {
                        font-family: $neuzeit;
                        font-weight: 700;
                        color: $black;
                        font-size: 20px;
                        text-transform: none;
                        letter-spacing: normal;
                        line-height: normal;
                        width: 95%;
                        padding-bottom: 10px;

                    }

                    .fj-account-dashboard-card-text {
                        font-size: 14px;
                        line-height: 1.43;
                        color: #000;
                        padding-bottom: 25px;
                    }

                    .fj-account-dashboard-card-link {
                        line-height: 1.5;
                        font-size: 1rem;
                        text-decoration: underline;
                        display: block;
                    }
                }
            }

            .dash-whatsnew {
                @include respond(small) {
                    margin-right: -30px;
                }
            }

            .fj-account-dashboard-card-link {
                line-height: 1.5;
                letter-spacing: -0.32px;
                text-align: left;
                color: #1a1a1a;
                font-weight: 700;
                font-size: 1rem;
                text-decoration: none;
                padding: 0;
                width: 100%;
                display: block;
                margin-bottom: 12px;
            }

            .fj-account-dashboard-card {
                position: relative;
                width: auto;
                height: auto;
                padding: 0;
                margin-top: 20px;

                @include breakpoint( $small-breakpoint ) {
                    width: 33%;
                    height: auto;
                    padding: 0 10px;
                    margin-top: 0;
                }

                .fj-account-dashboard-card-image {
                    position: relative;
                    min-height: 300px;

                    .badge {
                        position: absolute;
                        background: $black;
                        color: $white;
                        top: 20px;
                        left: 20px;
                        padding: 5px 10px;
                        text-transform: uppercase;
                        letter-spacing: 0.53px;
                        font-size: 10.5px;
                        font-weight: bold;
                    }
                }

                .fj-account-dashboard-card-text {
                    margin: 13px 0 13px;
                    line-height: 1.71;
                    letter-spacing: -0.07px;
                    color: $gray-warm2;
                    padding: 0;
                    @include respond(small) {
                        margin: 21px 0 5px;
                        font-size: 14px;
                    }

                }
            }
        }
        .fj-account-dashboard-card img {
            width: 100%;
        }
        .dash-whatsnew {
            @include respond(small) {
                .fj-account-dashboard-card {
                    margin-right: 20px;
                    img {
                        width: 100%;
                    }
                }
            }
            .slick-list {
                padding:0 20px 0 0;
            }
            .slick-prev {
                background: $white url('../images/carouselBlackArrowLeft.svg') center/9px 13px no-repeat;
                left: -10px;
            }
            .slick-next {
                background: $white url('../images/carouselBlackArrowRight.svg') center/9px 13px no-repeat;
                right: 20px;
            }
            .slick-next,
            .slick-prev {
                &:hover,
                &:focus {
                    background-color: #fff !important;
                }
                top: 53%;
                transform: translateY(calc(-100% - 9px));
                box-shadow: -1px 1px 5px 0px rgba(0,0,0, 0.35);
                height: 52px;
                width: 52px;
                opacity: 1;
            }
            .slick-disabled {
                display: none !important;
            }
        }
        .fj-account-dashboard-cards .progressDash {
            width: 100%;
            position: relative;
            height: 4px;
            background-image: linear-gradient(to right, #000, #000);
            @include respond(largeUp) {
                display: none;
            }
            .slider_label {
                position: absolute;
                height: 4px;
                left: 0;
                width: 20%;
                background-color: $black;
                border-radius: 7px;
            }
        }
   }
}
