/*
Styles for badges on Grid Tiles and PDP.
*/
.pt_product-search-result .product-tile .product-image,
.product-col-1.product-image-container {
	.product-badge {
		white-space: nowrap;
		&.black-white {
			background-color: #000;
			color: #fff;
		}
		&.red-white {
			background-color: #d32300;
			color: #fff;
		}
		&.white-black {
			background-color: #fff;
			color: #000;
		}
		&.white-gradient {
			background-color: #fff;
			span {
				white-space: nowrap;
				background-image: -webkit-linear-gradient(to bottom, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* For Chrome and Safari */
				background-image:    -moz-linear-gradient(to bottom, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* For old Fx (3.6 to 15) */
				background-image:     -ms-linear-gradient(to bottom, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* For pre-releases of IE 10*/
				background-image:      -o-linear-gradient(to bottom, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* For old Opera (11.1 to 12.0) */
				background-image:         linear-gradient(to bottom, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* Standard syntax; must be last */
				color: transparent;
				-webkit-background-clip: text;
				-moz-background-clip: text;
				background-clip: text;
				padding: 15px 10px;
				@include respond(small) {
					background-image: -webkit-linear-gradient(to right, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* For Chrome and Safari */
					background-image:    -moz-linear-gradient(to right, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* For old Fx (3.6 to 15) */
					background-image:     -ms-linear-gradient(to right, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* For pre-releases of IE 10*/
					background-image:      -o-linear-gradient(to right, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* For old Opera (11.1 to 12.0) */
					background-image:         linear-gradient(to right, #69a3d5 0%,#69a3d5 10%,#72beb7 20%,#b4c03b 30%,#f2cc43 40%,#e4a239 50%,#d75f29 60%,#bd3b3b 70%,#d56574 80%,#9263d4 90%,#9263d4 100%); /* Standard syntax; must be last */
				}
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					/* IE10+ CSS styles go here */
					background-image: none;
					color: #274A60;
				}
			}

		}

        &.silver-gradient {
            background: linear-gradient(90deg, rgba(123, 123, 123, 1) 0%, rgba(224, 224, 224, 1) 50%, rgba(123, 123, 123, 1) 100%);
            transform: rotate(0) !important;
            writing-mode: initial !important;
            direction: initial !important;
            min-width: 98px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            text-transform: capitalize !important;
            font-family: $eurostile !important;
            top: 5px;
            left: 5px !important;
            padding: 6px 12px;
            font-size: 13px;
            color: $black;
            text-align: center;
        }
	}
}

.product-col-1.product-image-container {
    .pdp-badges-container {
        top: 0 !important;
        right: auto !important;
        left: var(--imagePositionLeft, 0px);
    }
}
