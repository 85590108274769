///////////////////////
/////Order Confirmation
///////////////////////
#wrapper.pt_order,
#wrapper.pt_order-confirmation{
	#main{
		h1{
			font-size: 30px;
			color: $gray-dark;
			line-height: 120%;
			margin-bottom: 10px;
			margin-top: 25px;
			font-weight: bold;
			text-transform: uppercase;
			@include respond(small) {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 15px;
			}
		}
		.confirmation-message{
			font-size: 14px;
			color: $gray-warm;
			padding: 0;
			margin: 0 0 20px 0;
			border: 0;
			@include respond(small) {
				font-size: 12px;
				line-height: 16px;
			}
		}
		button.print-page{
			font-size: 12px;
			color: $gray-dark;
			line-height: 120%;
			text-decoration: underline;
			padding: 0;
			margin: 0;
			border: 0;
			background: none;
			text-transform: none;
			&:hover{
				text-decoration: none;
			}
			@include respond(small) {
				display: none;
			}
			@include respond(print) {
				display: none;
			}
		}
		.order-information{
			div{
				display: inline-block;
				width: 100%;
				margin-bottom: 0;
				span{
					font-size: 12px;
					text-transform: uppercase;
					@include respond(small) {
						font-size: 12px;
					}
					&.label{
						color: $gray-dark;
						font-weight: bold;
					}
					&.value{
						color: $gray-warm;
					}
				}
			}
		}
		.order-information,
		.confirmationregister{
			display: inline-block;
			border-bottom: 2px solid $gray-light;
			padding-bottom: 34px;
			width: 100%;
			@include respond(small) {
				padding-bottom: 27px;
			}
			h2{
				margin: 34px 0 12px !important;
				@include respond(small) {
					line-height: 18px;
   					margin: 15px 0 18px !important;
				}
			}
			.login-box-content{
				.hidden{
					visibility: hidden;
					height: 0px;
					.form-row{
						padding: 0;
						margin: 0;
						height: 0px;
						display: inline-block;
					}
				}
				.confirm-login-btn{
					margin-left: 12%;
					width: 340px;
					@include respond(small) {
						width: 100%;
						margin-left: 0;
					}
					button{
						height: 34px;
						line-height: 30px;
						font-size: 11px;
						padding: 0 11px;
						letter-spacing: 0;
						float: right;
						&:hover{
							border-color: $black;
						}
						@include respond(small) {
							float: left;
						}
					}
				}
				.form-row{
					position: relative;
					
					label{
						width: 12%;
						text-align: left;
						float: left;
						font-weight: bold;
						text-transform: uppercase;
						font-size: 12px;
						line-height: 34px;
						margin-top: 0;
						@include respond(small) {
							width: 100%;
							line-height: 120%;
						}
						.required-indicator{
							display: none;
						}
					}
					&.email-line{
						margin-bottom: 0;
						.field-wrapper{
							margin-bottom: 1px;
						}
						.email{
							margin-top: 8px;
							@include respond(small) {
								margin-top: 0;
							}
						}
						label{
							margin-bottom: 2px;
						}
					}
					&.required{
						label{
							span{
								padding-right: 5px;
								&::after{
									content: " *";
									color: $gray-dark;
								}
							}
						}
					}
					.form-caption{
						margin: 5px 0 0 12%;
						font-size: 11px;
						color: $gray-warm;
						@include respond(small) {
							margin-left: 0;
							display: none;
						}
					}
					
					.field-wrapper{
						float: left;
						width: 82%;
						padding-left: 0;
						margin-bottom: 5px;
						@include respond(small) {
							width: 100%;
						}
						input[type="password"],
						input[type="text"]{
							width: 340px;
							@include respond(small) {
								width: 100%;
							}
						}
						span{
							display: block;
							width: 100%;
						}
						.email{
							color: $gray-warm;
							text-transform: uppercase;
						}
					}
				}
			}
		}
		.content-header{
			font-size: 16px;
			margin: 40px 0 20px 0!important;
			font-style: normal;
			font-weight: bold;
			text-transform: uppercase;
			font-family: $oswald;
			font-weight: bold;
			background: none;
			padding: 0;
			@include respond(small) {
				font-size: 14px;
				margin: 20px 0!important;
			}
		}
		.confirmationregister{
			padding: 20px 0;
			font-size: 14px;
			@include respond(small) {
				padding-bottom: 30px;
			}
		}
		.order-confirmation-details{
			h2{
				margin-top: 36px!important;
				@include respond(small) {
					margin: 15px 0 14px!important;
				}
			}
			div.content-header{
				@include respond(small) {
					margin: 16px 0 0 !important;
				}
			}
			table.item-list{
				border-bottom: 2px solid $gray-light;
				
				tr{
					border: 0;
					.section-header{
						padding: 10px 0;
						font-size: 14px;
						color: $gray-dark; 
						background: none;
						@include respond(small) {
							display: none;
						}
					}
					td{
						font-size: 14px;
						color: $gray-warm;
						font-style: normal;
						line-height: 22px;
						padding: 0 10px 0 0;
						
						&.order-payment-instruments{
							.payment-amount{
								display: none;
							}
						}
						.mobile-visible{
							display: none;
							@include respond(small) {
								display: block;
								padding: 0;
								font-size: 12px!important;
								line-height: 100%;
							}
						}	
						@include respond(small) {
							width: 100% !important;
							float: left;
						}
						&:last-child{
							padding: 0 50px 0 0;
							width: 30%;
						}
						address{
							font-style: normal;
						}
						.payment-type {
							font-weight: bold;
						}
						.payment-type-border {
							font-weight: bold;
							padding-top: 13px;
							margin-top: 13px;
							border-top: 1px solid $gray-light;	
							width: 50%;						
						}
						&.order-information{
							display: none;
						}
						&.order-payment-summary{
							@include respond(small) {
								padding-bottom: 10px;
								.section-header.mobile-visible{
									padding-bottom: 15px;
								}
								
							}
							.order-detail-summary{
								.order-totals-table{
									
									tr{
										border: 0;
										td{
											
											color: $gray-dark;
											text-transform: uppercase;
											padding: 0 0 11px 0;
											font-size: 12px;
											line-height: 100%;
											&:first-child{
												font-weight: bold;
												text-align: left;
											}
											@include respond(small) {
												width: auto !important;
												float: none;
											}
										}
										&.order-shipping-discount,
										&.order-discount{
											td{
												color: $red;
											}
										}
										&.order-sales-tax{
											td{
												padding-bottom: 19px;
											}
										}
										&.order-total-split{
											td{
												padding-top: 13px;
												border-top: 1px solid $gray-light;
												@include respond(small) {
													padding-bottom: 17px;
												}
											}
										}
										&.order-total{
											td{
												font-size: 14px;
												border-top: 1px solid $gray-light;
												padding: 16px 0 20px 0;
											}
										}
									}
								}
							}
						}
					}
					&:last-child{
						td{
							padding-bottom: 20px;
							@include respond(small) {
								padding-right: 0;
							}
						}
					}
				}
			}
			table.order-shipment-table{
				&:last-child{
					tr:last-child{
						td{
							border-bottom: 0;
						}
					}
				}
				&.table-2,
				&.table-3{
					tr{
						&.desktop-visible{
							display: none;
						}
						td{
							border-bottom: 0;
						}
					}
				}
				tr{
					&.desktop-visible{
						@include respond(small) {
							display: none;
						}
					}
					th{
						padding: 10px 20px;
						background: $gray-white;
						&:last-child{
							padding: 0;
							background: none;
							vertical-align: top;
						}
						&:nth-child(2){
							text-align: center;
						}
						&:nth-child(3){
							text-align: left;
							padding: 14px 30px;
						}
						&:nth-child(4){
							text-align: right;
							padding-left: 0
						}
						@include respond(small) {
							display: none;
						}
					}
					td{
						padding: 14px 0 23px;
						color: $gray-dark;
						border-bottom: 1px solid $gray-light;
						@include respond(small) {
							width: 58%;
							float: right;
							border-bottom: 0;
							text-align: left;
							padding: 0;
						}
						.mobile-visible{
							display: none;
							@include respond(small) {
								display:inline-block;
								font-size: 11px;
								font-weight: bold;
								color: $gray-dark;
								text-transform: uppercase;
							}
						}
						&.order-shipment-gift-message-header{
							padding: 30px 0 0 0;
							font-size: 12px;
							text-transform: uppercase;
							border-bottom: 0;
							@include respond(small) {
								width: 100%
							}
						}
						&.order-shipment-gift-message{
							font-size: 12px;
							color: $gray-warm;
							padding: 0 0 30px 0;
							border-bottom: 0;
							@include respond(small) {
								width: 100%
							}
						}
						&.line-item-details{
							width: 37%;	
							@include respond(small) {
								width: 100%;
								padding: 10px 0 0 0;
							}
							.product-list-item{
								float: right;
								width: 52%;
								&.myjoy-item{
									.attribute{
										display: block;
    									padding-bottom: 5px;
										span.label{
											color: $gray-warm;
											font-weight: normal;
										}	
										&.name{
											padding-bottom: 0;
											span{
												color: $gray-dark;
											}
										}
									}
								}
								@include respond(large) {
									float: left;
									width: 43%;
								}
								@include respond(small) {
									float: right;
									width: 58%;
								}
								@include respond(print) {
									float: left;
									width: 100%;
								}
								div.name,
								a{
									font-size: 14px;
									color: $gray-dark;
									font-weight: bold;
									text-transform: none;
								}
								.sku{
									padding-bottom: 10px;
									span{
										font-size: 11px;
										color: $gray-warm;
									}
								}
								.attribute{
									span{
										font-size: 11px;
										text-transform: uppercase;
										color: $gray-warm;
										&.label{
											color: $gray-dark;
											font-weight: bold;
										}
									}
								}
								&.myjoy-item{
									.attribute{
										padding-bottom: 10px;	
										display: block;
										
										.label,
										.value{
											color: $gray-warm;
											font-weight: normal;
											
										}
										&.name{
											padding-bottom: 0;
											span.value{
												font-weight: bold;
												color: $gray-dark;
												padding-bottom: 0;	
												display: block;
											}
										}
									}
								}
							}
						}
						&:nth-child(2){
							text-align: center;
							@include respond(small) {
								text-align: left;
								color: $gray-warm;
								padding-top: 10px;
							}
						}
						&:nth-child(3){
							text-align: left;
							padding: 14px 30px;
							@include respond(small) {
								padding: 10px 0 0 0;
							}
						}
						&:nth-child(4){
							text-align: right;
							padding-right: 20px;
							@include respond(small) {
								text-align: left;
								padding-right: 0;
								padding-top: 10px;
							}
						}
						
						&.line-item-details{
							.item-image{
								@include respond(small) {
									width: 100px;
									margin-right: 20px;
									float: left;
									position: absolute;
								}
								img{
									margin-right: 35px;
    								width: 135px;
    								float: left;
    								@include respond(large) {
										width: 90px;
										margin-right: 10px;
									}
    								@include respond(small) {
										width: 100%;
										margin-right: 0;
									}
								}
							}
						}
						&.order-shipment-details{
							width: 30%;
							border-right: 0;
							border-bottom: 0;
							padding-top: 0;
							
							.order-shipment-address{
								margin-top: -14px;
								position: relative;
								@include respond(small) {
									margin-top: 0;
								}
							}
							&.last{
								display: none;
							}
							@include respond(small) {
								&.first{
									display: none;
								}
								&.last{
									display: block;
									width: 100%;
									box-sizing: border-box;
									border-bottom: 2px solid $gray-light;
									padding-bottom: 20px;
									
									.mobile-visible{
										font-size: 12px;
										font-weight: bold;
										text-transform: uppercase;
										margin: 15px 0 5px 0;
										
										&.gift-mobile{
											display:block;
											.order-shipment-gift-message-header{
												font-size: 12px;
   											}
											.order-shipment-gift-message{
												color: $gray-warm;
    											font-size: 12px;
    											text-transform: none;
    											font-weight: normal;
    											border-bottom: 1px solid #e6e6e6;
    											padding-bottom: 20px;
											}
										}
									}
									
								}
							}
							.order-shipment-address{
								.name{
									font-size: 14px;
									color: $gray-warm;
									line-height: 22px;
									text-transform: none;
									.phone{
										display: none;
									}
								}
							}
							.shipping-status,
							.shipping-method,
							.trackingnumber {
								.value,
								.label{
									font-size: 14px;
									color: $gray-dark;
									line-height: 22px;
								}
								.value{
									color: $gray-warm;
								}
								a {
									font-size: 14px;
									color: $gray-warm;
								}
								a:hover {
									text-decoration: underline;
								}
							}
							.trackingnumber {
								a {
									color: $red;
								}
								a:hover {
									text-decoration: none;
								}
							}
						}
					}
					td,th{
						&.header-divider{
							width: 25px;
							background: none;
							border: 0;
							@include respond(small) {
								display: none;
							}
						}
					}
					td.header-divider{
						@include respond(small) {
							border-bottom: 1px solid $gray-light;
						    display: block;
						    float: left;
						    width: 100%;
						}
					}
				}
			}
		}
		.actions{
			padding: 20px 0;
			border-top: 2px solid $gray-light;
			width: 100%;
			display: inline-block;
			@include respond(small) {
				border-top: 0;
				padding: 0 0 20px;
			}
			.button{
				float: right;
				padding: 0 27px;
				@include respond(small) {
					float: left;
					width: 100%;
					height: 44px;
					line-height: 40px;
					font-size: 14px;
				}
				@include respond(large) {
					height: 44px;
					line-height: 40px;
					font-size: 14px;
				}
			}
		}
	}
}