// Import the styles generated from the SVG icons
@import "../compiled/svg-icons";

.icon {
	display: inline-block;
	vertical-align: middle;
	fill: $gray-dark;
	
	&.logo {
		display: inline-block;
		width: 150px;
		height: 50px;
		fill: $white;
		@include respond(small){
			display: none;
		}
	}
	
	&.logo-mobile {
		display: none;
		height: 30px;
		width: 115px;
		fill: $white;
		@include respond(small){
			display: inline-block;
		}
	}

	&.menu-icon{
		height: 32px;
		width: 22px;
	}

	&.arrow-right{
		height: 11px;
		width: 9px;
	}
	&.arrow-down{
		height: 10px;
		width: 11px;
	}

}
