.breadcrumb {
    font-family: $eurostile;
    font-size: 13px;
    @include respond(largeUp) {
        margin: 0 0 15px -40px
    }
    .breadcrumb-element {
        &::after {
            content: '|';
        }
        &:last-child {
            &::after {
                content: '';
            }        
        }
    }
}

.refinement-header-wrapper .breadcrumb {
    margin-right: 0;
}
