
footer {
	clear: both;
	padding-bottom: 50px;
    color: $black;

	@include respond(standard){
		padding-bottom: 70px;
    }

	.innerWidth{
		@include respond(not-standard){
			max-width: initial;//fluid at all scales that aren't standard
		}
		@include respond(large){
			max-width: initial;//fluid at all scales that aren't standard
		}
		@include respond(largeUp){
			padding-right: 20px;
			padding-left: 20px;
		}
    }

	// Footer Top Section
	.footer-container__top {
        background-color: $black !important;
		clear: both;
		padding: 40px 20px 70px;

		&::after{
			content: ' ';
			clear: both;
        }

        .cg-footer-top {            
            @include respond(largeUp){
                display: flex;
            }
            .cg-footer-box {
                color: $white;
                text-align: center;
                max-width: 180px;
                margin: 65px auto 70px;
                .cg-footer-img {
                    display: flex;
                    height: 59px;
                    align-items: center;
                    justify-content: center;
                }
                h2 {
                    font-size: 24px;
                    color: $white;
                    font-family: $eurostile !important;  
                    margin: auto;
                    height: 58px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 23px;
                    max-width: 132px;
                }
                p {
                    font-family: $eurostile;
                    font-size: 12px;
                }
                a {
                    display: block;
                    margin: 3px auto;
                    border-radius: 4px;
                    background-color: $white;
                    padding: 5px 10px;
                    font-size: 11px; 
                    color: $black;
                    text-transform: uppercase;
                    max-width: 90px;
                    font-family: $eurostile;
                }
            }
        }
	}

	// Footer Newsletter Signup
	#footerSignup {
		margin: 0 auto;
		max-width: 500px;

		h3 {
			color: #FFF;
			font-family: $suisseintmedium;
			font-size: 20px;
			font-weight: 400;
			letter-spacing: 1px;
			margin: 30px 0;
			text-align: center;
			text-transform: none;
		}

		form#email-alert-signup {
			position: relative;

			input[type="text"],
			input[type="email"] {
				background: transparent;
				border: 1px solid #000;
				border-radius: 0;
				font-family: $suisseintmedium;
				font-size: 14px;
				line-height: 1;
				padding: 15px 45px 15px 15px;
				width: 100%;

				&::-ms-clear {
					display: none;
				}

				&::-ms-input-placeholder {
					color: #fff;
				}
				&::placeholder {
					color: #fff;
				}
			}

			.error {
				display: block;
				font-size: 14px;
				margin-top: 10px;
			}

			button {
				border: transparent;
				border-top: 3px solid $white;
				border-right: 3px solid $white;
				background: transparent;
				display: block;
				width: 20px;
				height: 20px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 17px;
				right: 20px;
				transform: rotate(45deg);
				border-radius: 0;
			}
		}
	}

	.footer-container__middle {
		@include respond(largeUp){
			padding: 20px 0;
		}

		@include respond(standard){
			display: flex;
			justify-content: space-between;
		}
	}


	.footer__logo-search-container {
		margin-bottom:0;

		@include respond(large) {
			margin-bottom:20px;
		}

		@include respond(largeUp){
			display: flex;
			justify-content: space-between;
		}
	}

	// Footer Logo
	.footer__logo {
		padding: 20px;
		text-align: center;

		@include respond(largeUp){
			padding: 0;
			min-width:250px;
			text-align:left;
		}

		img {
			height: 50px;
		}
	}

	// Footer Search
	.footer-search {
		padding-right: 20px;
		padding-left: 20px;

		@include respond(largeUp){
			min-width: 365px;
			margin-left: 5%;
			padding: 0;
		}

		form {
			position: relative;
		}

		input[type="text"] {
			background-color: transparent;
			border: 2px solid $blue-dark;
			border-radius: 0;
			font-family: $suisseintmedium;
			font-size: 14px;
			height: 50px;
			width: 100%;
		}

		[type="submit"] {
			background: transparent;
			border: 0;
			height: 20px;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
			width: 20px;

			&:hover {
				background: transparent;
			}

			&:active {
				opacity: 1;
			}
		}

		.search-submit-icon {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.footer-user-actions {
		display: flex;
		flex-direction: column;

		@include respond(largeUp){
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			margin-right:3%;
		}
		@include respond(not-standard){
			margin-right:0;
		}
	}

	// Footer User Links (Content Assets)
	.footer-user-links {
		background-color: $gray-pale;
		display: flex;
		justify-content: space-between;
		line-height: 25px;
		order: 2;
		padding: 20px;

		@include respond(largeUp){
			background-color: transparent;
			order: 0;
			padding: 0;
			margin-right:10%;
		}
	}

	.footer-user-link {


		@include respond(not-standard){
			min-width:130px;
			margin-left:0;
		}
		@include respond(standard){
			min-width:70px;
			margin-left:50px;
		}
		a, h3 {
			color: #000;
			font-family: $suisseintmedium;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: normal;
			margin: 0;
			text-transform: uppercase;
		}
		img {
			float: left;
			margin-right: 15px;
		}
		.desktop-only {
			display: none;

			@include respond(largeUp){
				display: block;
			}
		}

		.mobile-only {
			@include respond(largeUp){
				display: none;
			}
		}

		+ .footer-user-link {
			margin-left: 50px;
			min-width: 160px;
		}
	}

	// Footer Social Media
	#footerSocial {
		order: 1;
		margin: 21px auto;
		width: auto;
		padding: 0;
		@include respond(small){
			padding: 25px 20px 30px;
		}


		p {
			display: flex;
			justify-content: space-between;
			margin: 0;

			a + a {
				@include respond(largeUp){
					margin: auto 0;
				}

			}
			.social {
				width: 25px;
				height: 25px;
				background-repeat: no-repeat;
			}

			.facebook {
				background-image: url('../images/icon-social-fb.svg');
			}

			.twitter {
				background-image: url('../images/icon-social-tw.svg');
				height: 21px;
				margin-top: 3px;
			}

			.youtube {
				background-image: url('../images/icon-social-yt.svg');
				height: 18px;
			}

			.instagram {
				background-image: url('../images/icon-social-ig.svg');
			}
		}
	}
}

.newsletter-content {
	img {
		display: block;
		width: 47px;
		margin: 0 auto;
	}
	input[type=email]::placeholder {
   		color: hsla(0,0%,100%,.5);
   	}

}
.footer-container__bottom {
	@include respond(largeUp){
		border: 3px solid #ECECEC;
		border-width: 3px 0;
		margin: 5px 0;
	}
}

// Nav Items
.menu-footer {
	float: none;
    font-family: $neuzeit;

	@include respond(largeUp){
		display: flex;
		justify-content: space-between;
	}

	li {
		float: none;

		&:last-of-type {
			display: none;

			@include respond(largeUp){
				display: block;
			}
		}
	}

	a {
		background-color: $gray-pale;
		border-top: 2px solid #d3d3d3;
		color: #000;
		display: block;
		font-size: 16px;
		line-height: 18px;
		padding: 18px 20px 16px;
		position: relative;
		text-transform: none;

		@include respond(largeUp){
			background-color: transparent;
			border-top: none;
			display: inline;
			font-size: 14px;
			line-height: 67px;
			padding: 0;
		}

		@include respond(standard){
			font-size: 14px;
		}

		&:hover,
		&:active {
			background-color: $gray-cirlce;

			@include respond(largeUp){
				background-color: transparent;
			}
		}

		&::after {
			border-top: 2px solid $primary-orange;
			border-right: 2px solid $primary-orange;
			content: "";
			height: 16px;
			position: absolute;
				top: 50%;
				right: 20px;
			transform: translateY(-50%) rotate(45deg);
			transform-origin: 50% 50%;
			transition: transform .5s;
			width: 16px;

			@include respond(largeUp){
				display: none;
			}
		}

		&:hover::after {
			@include respond(largeUp){
				background-color: #222;
				border: none;
				content: "";
				display: block;
				position: absolute;
				top: auto;
					right: 0;
					bottom: -5px;
					left: 0;
				height: 2px;
				transform: none;
				transition: none;
				width: auto;
			}
		}
	}
}

.footer__column__links {
    @include respond(largeUp){
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
        margin-left: 10%;
    }
}

.footer-copy {
	color: $black;
	font-size: 16px;
	line-height: 1;
	padding: 25px 0 20px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing:normal;
	@include respond(largeUp){
		display: flex;
		justify-content: space-between;
		padding: 25px 40px 0;
		.content-asset {
			margin-left: auto;
		}
	}

	a {
		color: $black;
	}

	// Countries Container
	.countries-container {
		position: relative;

		& > a {
			background: url("../images/usa_off.gif") left center no-repeat;
			padding-left: 35px;
			&.ca {
				background: url("../images/canada-icon.gif") left center no-repeat;
				padding-left: 35px;
			}
		}
		&.ca > a {
			background: url("../images/canada-icon.gif") left center no-repeat;
			padding-left: 35px;
		}
		.country-container{
			position: absolute;
			float: left;
			width: 280px;
			left: -46px;
			bottom: 29px;
			background-color: $white;
			border: 2px solid $clp-border;
			box-sizing: border-box;
			display: none;
			z-index:53;
			@include respond(small){
				width: 70%;
				margin: auto;
				left: 0;
				right: 0;
			}
			h2{
				float: left;
				width: 100%;
				padding: 10px 10px 10px 20px;
				box-sizing: border-box;
				border-bottom: 2px solid $clp-border;
				color: $gray-dark;
				line-height: 23px;
				margin: 0;
				text-align: left;
				font-size:16px;
				a.close-countries{
					background: url("../images/interface/close.jpg") left top no-repeat;
					height: 23px;
					width: 24px;
					float: right;
					opacity: 0.8;
					&:hover{
						opacity: 1;
					}
				}
			}
			.select-country-buttons {
				padding: 22px 0 15px;
				.button {
					text-transform: none;
					color: $white;
					line-height: 44px;
					height: 44px;
					width: 82%;
					font-size: 16px;
				}
			}
			
	
		}
	}
	#footer-4 {
		@include respond(small){
			margin-top: 25px;
		}
	}
	.copyright{
		padding: 25px 0 0;

		@include respond(largeUp){
			padding: 0;
		}
	}
}

// Geo Popup
.geo-modal-message-modal #dialog-container {

	.ui-dialog-titlebar {
		height: 0;
		padding: 0;
	}
	.ui-dialog .ui-widget-header .ui-button .ui-button-icon-primary.ui-icon-closethick {
		margin-top: 15px;
	}

	.select-country-container,
	.select-language-container {
		.select-geo-dropdown {
			background-color: white;
			border-radius: 0;
			line-height: 47px;
			margin-bottom: 20px;
			width: 100%;
			font-size: 15px;
			border-color: $lightgrey;
		}
	}

	.select-country-buttons {
		text-align: center;
		margin-top: 20px;
		a {
			background: $darkgrey;
			color: $white;
			font-weight: bold;
			font-size: 15px;
			padding: 0 20px;
			width: 80px;
			display: inline-block;
			height: 45px;
			line-height: 45px;
			text-transform: uppercase;
			background-repeat: no-repeat;
			background-size: 15px;
			margin: 0 1.78%;
			text-align: left;
			@include respond(largeUp){
				margin: 0 15px;
			}

			&.switch {
				background-image: url("../images/checkmark-24.png");
				background-position: 95px center;
				background-color: $primary-orange;
			}

			&.stay {
				background-image: url("../images/x-mark-24.png");
				background-position: 90px center;
			}
		}
	}
}

.geo-modal-message-modal .ui-dialog-titlebar-close {
	display: none;
}

.footer-container__middle .footer__links__title__collapse-button {
    &::after {
        color: $black;
    }
}
