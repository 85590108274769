#wrapper.pt_checkout #main #primary {
    .shipping-login {
        .login-box {
            h2 {
                margin-bottom: 0;
            }
        }
        form {
            padding-bottom: 10px;
        }
        h2 {
            text-align: center;
            display: block;
            padding-bottom: 20px;
            padding-top: 20px;
            margin-bottom: 0;
        }
        .shipping-or-border {
            border-bottom: 1px solid $gray-light;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .shipping-or {
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
            background: white;
            padding-left: 5px;
            padding-right: 5px;
            font-size: 12px;
        }
        .guest-checkout {
            position: relative;
            margin-top: 0;
            padding-bottom: 6px;
            border-top: none;
        }
    }
    .checkout-billing {
        .fieldset {
            & > .payment-method-options.form-indent {
                .payment-method {
                    .payment-method-name {
                        font-size: 18px;
                        letter-spacing: 0;
                        text-transform: capitalize;
                        border: none;
                        margin-top: 0;
                        @include respond(small) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &.payment-methods {
                h2 {
                    span {
                        background: none;
                    }
                }
            }
        }
    }
    .checkout-progress-indicator {
        &.multiship {
            > div {
                @include respond(small) {
                    width: 25%;
                }
                @include respond(large) {
                    width: 25%;
                }
                .name {
                    @include respond(small) {
                        font-size: 10px;
                        letter-spacing: -.025rem;
                    }
                    @include respond(iphone) {
                        font-size: 10px;
                        letter-spacing: -.025rem;
                    }
                }
            }
        }
        > div {
            @include respond(small) {
                width: 33%;
            }
            @include respond(large) {
                display: block;
                float: left;
                text-align: center;
                padding: 0 .3em;
                width: 33%;
                box-sizing: border-box;
            }
            span {
                @include respond(large) {
                    margin-right: 0;
                    margin-bottom: 4px;
                }
            }
            .name {
                @include respond(small) {
                    font-size: 10px;
                    line-height: 1.25em;
                }
                @include respond(large) {
                    display: block;
                    line-height: 1rem;
                    padding: 0;
                }
            }
        }
    }
}
