.club-glove-parcel-lab {
    .header {
        padding-top: 16px;
    }

    .primary-logo {
        img {
            margin: 0 auto;
        }
    }

    .footer__bottom__links {
        justify-content: center;
        display: flex;
        gap: 15px;
        list-style: none;
        flex-wrap: wrap;
        @include respond(small) {
            padding: 0 18px !important;
        }
    }
}
#pl-returns-plugin {
    padding: 0 18px;

     * {
         color: $black !important;
     }

    input {
        font-family: $neuzeit !important;
        font-size: 15px !important;
        letter-spacing: normal !important;
        border-radius: 0 !important;
        height: 42px !important;
        background-color: transparent !important;
        border: 1px solid #e6e6e6 !important;
        color: $black !important;
        padding: 10px 12px !important;
    }

    h1 {
        color: #333 !important;
    }

    p {
        color: $darkgrey !important;
    }

    label {
        font-size: 12px !important;
        text-transform: uppercase !important;
        color: $darkgrey !important;
        font-family: $neuzeit !important;
    }

    button:not(.pl-dropdown-border),
    .pl-button {
        background-color: $darkgrey !important;
        justify-content: center !important;
        align-items: center !important;
        height: 48px !important;
        border-radius: $border-radius !important;
        cursor: pointer !important;
        display: inline-block !important;
        font-size: 16px !important;
        box-sizing: border-box !important;
        font-family: $suisseintregular !important;
        text-transform: uppercase !important;
        text-align: center !important;
        transition: background-color $quickSpeed !important;
        font-weight: normal !important;
        letter-spacing: $globalLetterSpacing !important;
        outline: none !important;
        color: $white !important;
        border: solid 2px $darkgrey !important;
        opacity: 1 !important;
        line-height: 16px !important;

        &:hover {
            color: $white !important;
            background-color: $black !important;
            border: solid 2px $black !important;

            span {
                color: $white !important;
            }
        }

        span {
            color: $white !important;
        }

        &.pl-shopfinder-button {
            background: $white !important;
            color: $black !important;
            border-color: $black !important;

            span {
                color: $black !important;
            }

            &:hover {
                background: $white !important;
                color: $black !important;
                border-color: $black !important;

                span {
                    color: $black !important;
                }
            }
        }
    }

    .pl-input-wrapper--error {
        input {
            border: 2px solid #b2595c !important;
        }
        label {
            color: #b2595c !important;
        }

        .pl-input-message--error {
            color: #b2595c !important;
        }
    }

    .pl-icon-clear {
        display: none !important;
    }

    .pl-error-message {
        border-color: #b2595c !important;
    }

    .pl-dropdown {
        background-image: url(../images/selectArrow.svg) !important;
        background-repeat: no-repeat !important;
        background-position: right 17px center !important;
        border-color: #e6e6e6 !important;

        .current-selected {
            position: relative !important;
            top: -8px !important;
            text-transform: none !important;
            width: calc(100% - 31px) !important;
        }

        .text-ellipsis {
            background: $white !important;
        }
    }

    .pl-dropdown-border--error {
        border-radius: 0 !important;
        background-color: $white !important;
        .pl-dropdown-border {
            border-color: #b2595c !important;
        }
    }

    .pl-text--error {
        color: #b2595c !important;
    }

    .pl-card {
        box-shadow: none !important;
        border-color: #e6e6e6 !important;
    }

    .pl-register-button,
    .pl-register-another {
        padding: 0 32px 32px 32px !important;

        .pl-missing-fields-error {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .pl-checkbox input[type=checkbox]:checked +.pl-checkbox-icon,
    .pl-radio input[type=radio]:checked ~.pl-checkbox-icon {
        box-shadow: 0 0 0 15px #000 inset !important;
        border: 1px solid #000 !important;
        position: relative !important;

        &::after {
            width: 14px !important;
            right: 1px !important;
            bottom: 9px !important;
            transform: rotate(-45deg) !important;
            content: "" !important;
            position: absolute !important;
            height: 3px !important;
            background: #fff !important;
            display: block !important;
        }

        &::before {
            content: "" !important;
            position: absolute !important;
            height: 3px !important;
            background: #fff !important;
            display: block !important;
            width: 8px !important;
            bottom: 7px !important;
            left: 4px !important;
            transform: rotate(45deg) !important;
        }
    }

    .pl-style-article-checkbox .pl-article-list .pl-card-article .pl-article-select-toggle-wrapper .pl-checkbox .pl-checkbox-icon {
        border-radius: 0 !important;
        border: 1px solid #000 !important;
        transition: none !important;

        &::after {
            transition: none !important;
        }
    }
    .pl-shopfinder-column {
        .pl-button-content {
            @media only screen and (max-width: 475px) {
                position: relative !important;
                top: -9px !important;
            }
        }
    }

    .pl-article-list-notreturnable .pl-card-article .pl-article-details {
        opacity: 0.7 !important;
    }

    .pl-anchor {
        text-decoration: underline !important;

        &:hover {
            text-decoration: none !important;
        }
    }

    .pl-style-article-checkbox {
        .pl-article-options {
            .eight {
                margin-top: 27px !important;

                .pl-select-label {
                    display: none !important;
                }
            }

            .four {

                .pl-select-label {
                    color: $black !important;
                }
            }

            > .pl-space-bottom--half {
                position: relative !important;
                width: 50% !important;
                top: 27px !important;

            }

        }
    }
}
