#cart-table {
	thead {
		display: none;
		@include respond(largeUp) {
			display: table-header-group;
		}
	}
	td {
		box-sizing: border-box;
		padding: 5px;
	}
	.header-total-price {
		text-align: right;
	}
	.item-image {
		float: left;
		text-align: center;
		width: 40%;
		@include respond(largeUp) {
			float: none;
			width: 170px;
		}
	}
	.item-details {
		float: left;
		width: 60%;
		@include respond(largeUp) {
			float: none;
			width: auto;
		}
		.name {
			font-size: 1rem;
		}
	}
	.item-quantity {
		clear: left;
		float: left;
		text-align: center;
		width: 40%;
		@include respond(largeUp) {
			clear: none;
			float: none;
			text-align: left;
			width: auto;
		}
		input {
			width: 2.5rem;
		}
	}
	.item-quantity-details {
		clear: left;
		float: left;
		text-align: center;
		width: 40%;
		@include respond(largeUp) {
			clear: none;
			float: none;
			text-align: left;
			width: auto;
		}
		.item-user-actions {
			a,
			button {
				display: block;
				width: 100%;
				margin: .3rem 0;
				@include respond(largeUp) {
					width: auto;
				}
			}
		}
	}
	.item-price {
		float: left;
		width: 60%;
		@include respond(largeUp) {
			float: none;
			width: auto;
		}
	}
	.price-promotion {
		.price-sales {
			color: $red;
		}
		.price-standard {
			margin: 0 1em 0 0;
			text-decoration: line-through;
		}
	}
	.item-total {
		float: left;
		font-size: 1rem;
		font-weight: 700;
		text-align: right;
		width: 100%;
		word-wrap: break-word;
		@include respond(largeUp) {
			float: none;
			font-size: 1em;
			width: auto;
		}
		.promo-adjustment {
			@include respond(largeUp) {
				width: 7.5rem;
			}
		}
	}
	.price-unadjusted {
		color: $gray-dark;
		display: block;
		margin: 0 0 .42em;
		span {
			text-decoration: line-through;
		}
	}
	.price-adjusted-total {
		color: $gray-dark;
		display: block;
		margin: .42em 0 0;
		span {
			color: $red;
		}
	}
	.gift-cert-delivery {
		color: $red;
	}
}

.cart-promo {
	background: url("../images/interface/ui-promo-bg-gray-diag.png") repeat scroll 0 0 transparent;
	color: $black;
	font-style: italic;
	margin: 0.3em 0;
	padding: 1em;
	.bonus-item-actions {
		font-size: 1em !important;
		text-align: right;
		vertical-align: middle;
	}
}

.cart-footer {
	@extend %clearfix;
	margin: .83em 0;
}

.cart-coupon-code {
	@include respond(largeUp) {
		float: left;
		width: 60%;
	}
	input {
		margin-bottom: .5rem;
		width: 35%;
		@include respond(largeUp) {
			width: 45%;
		}
	}
	.error {
		padding-top: 0.4em;
	}
	button {
		margin-left: .6em;
	}
}

.cart-order-totals {
	margin: 1rem 0;
	@include respond(largeUp) {
		float: right;
		margin-top: 0;
		width: 40%;
	}
}

.order-totals-table {
	td {
		padding: .3rem 0;
		text-align: right;

	}
	.order-totals-item {
		width: 60%;
	}

	.order-totals-value {
		width: 40%;
	}

	.order-subtotal {
		font-weight: 700;
	}
	.order-total {
		font-size: 1rem;
		font-weight: 700;
	}
}


.cart-actions {
	@extend %clearfix;
}

.cart-action-checkout {
	float: right;
}

.cart-action-continue-shopping {
	float: right;
	button.button-text {
		line-height: 3.25em;
		margin-right: 1.0em;
	}
}

.cart-recommendations {
	margin-top: 1rem;
	.product-listing {
		@extend %clearfix;
		background-color: $gray-white;
		padding: 1rem 0;
		h2 {
			margin-left: 1.3rem;
		}
		.search-result-items {
			margin-top: 1em;
		}
	}
}

.cart-actions-top {
	margin-top: .5rem;
	padding-bottom: 1em;
	.cart-action-continue-shopping {
		float: none;
	}
}

.cart-empty {
	@extend %clearfix;
	margin-top: 4rem;
	h1 {
		text-align: center;
	}
	.cart-action-continue-shopping {
		float: none;
		text-align: center;
		margin-bottom: 4rem;
	}
	.product-listing {
		@extend %clearfix;
		background-color: $gray-white;
		padding: 1rem 0;
		h2 {
			margin-left: 1.3rem;
		}
		.search-result-items {
			margin-top: 1em;
		}
	}
}

.promo-adjustment {
	color: $red;
}

ul.product-availability-list {
	margin: 0;
	padding: 0;
	li {
		list-style: none outside none;
	}
	.on-order, .not-available {
		color: $red;
		font-size: 0.9em;
	}
}

.account-welcome {
	.welcome-message {
		font-size: 1.2em;
		font-style: italic;
	}
	.not-user {
		margin: .83em 0;
	}
}

#password-reset{
	white-space: nowrap;
}
