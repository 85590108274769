%search {
    input {
        border: none;
        border-radius: 25px;
        background-color: $gray-light;
        font-family: $titillium;
        font-size: 12px;
        line-height: 24px;
        padding: 0 16px;
        color: $black;
        &::placeholder {
            color: $gray-warm;
        }
    }
    button {
        border: none;
        background: none;
        .pr-search-icon {
            svg {
                transform: none;
                path {
                    fill: $black;
                }
            }
        }
    }
}

%select {
    label {
        font-size: 20px;
        text-transform: none;
        letter-spacing: 0.6px;
    }
    select {
        width: auto;
        height: 2.25rem;
        background-color: $gray-white;
        border: 2px solid $gray-light;
        border-radius: 2em;
        background-image: url("../images/selectArrow.svg");
        background-position: right 17px center;
        background-repeat: no-repeat;
        padding: 0 4em 0 1.5em;
        color: #666;
        line-height: 1.5;
        @include respond(small) {
            width: 90px;
            background-position: right 4px center;
            padding: 0 20px 0 10px;
        }
    }
}

.p-w-r {
    margin: 0 !important;
    letter-spacing: .6px;
    .pr-review-snippet-container {
        &.pr-no-reviews {
            .pr-snippet-review-count {
                display: none;
            }
        }
        .pr-snippet-stars-reco-reco {
            display: none;
        }
    }
    .pr-star-v4 {
        &.pr-star-v4-0-filled {
            width: 12px;
            height: 12px;
            background-image: url(../images/interface/stars/star-v4-0-filled.svg);
        }
        &.pr-star-v4-25-filled {
            width: 12px;
            height: 12px;
            background-image: url(../images/interface/stars/star-v4-25-filled.svg);
        }
        &.pr-star-v4-50-filled {
            width: 12px;
            height: 12px;
            background-image: url(../images/interface/stars/star-v4-50-filled.svg);
        }
        &.pr-star-v4-75-filled {
            width: 12px;
            height: 12px;
            background-image: url(../images/interface/stars/star-v4-75-filled.svg);
        }
        &.pr-star-v4-100-filled {
            width: 12px;
            height: 12px;
            background-image: url(../images/interface/stars/star-v4-100-filled.svg);
        }
    }
    .pr-snippet {
        &.pr-category-snippet {
            .pr-category-snippet__item {
                @include respond(small) {
                    display: block;
                }
                @include respond(large) {
                    display: block;
                }
                &.pr-category-snippet__total {
                    font-family: $titillium;
                    font-size: 12px;
                    color: $gray-warm;
                    @include respond(small) {
                        padding-left: 0;
                    }
                    @include respond(large) {
                        padding-left: 0;
                    }
                }
            }
            .pr-snippet-stars {
                .pr-snippet-rating-decimal {
                    border: 0;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                    background-color: $black;
                    color: $white;
                }
            }
        }
        .pr-snippet-stars-png {
            .pr-star-v4 {
                &.pr-star-v4-0-filled {
                    width: 12px;
                    height: 12px;
                    background-image: url(../images/interface/stars/star-v4-0-filled.svg);
                }
                &.pr-star-v4-25-filled {
                    width: 12px;
                    height: 12px;
                    background-image: url(../images/interface/stars/star-v4-25-filled.svg);
                }
                &.pr-star-v4-50-filled {
                    width: 12px;
                    height: 12px;
                    background-image: url(../images/interface/stars/star-v4-50-filled.svg);
                }
                &.pr-star-v4-75-filled {
                    width: 12px;
                    height: 12px;
                    background-image: url(../images/interface/stars/star-v4-75-filled.svg);
                }
                &.pr-star-v4-100-filled {
                    width: 12px;
                    height: 12px;
                    background-image: url(../images/interface/stars/star-v4-100-filled.svg);
                }
            }
        }
    }
    .pr-snippet-stars-reco-inline {
        &.pr-snippet-compact,
        &.pr-snippet-minimal {
            .pr-snippet-stars-container {
                margin-top: 12px;
            }
            .pr-snippet-read-and-write {
                a {
                    font-family: $titillium;
                    font-size: 10px !important;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: $gray-dark;
                    float: left;
                    &.pr-snippet-review-count {
                        padding-right: 10px;
                        border-right: 1px solid $clp-border;
                    }
                    &.pr-snippet-write-review-link {

                    }
                }
            }
        }
    }
}

.pr-clp-raing {
    .p-w-r {
        .pr-no-reviews {
            display: none;
        }
    }
}

.reviews-container {
    border-bottom: 2px solid $clp-border;
    > .clear {
        display: none;
    }
    .p-w-r {
        .pr-rid {
            display: none;
        }
        .pr-filter-btn--button-separated {
            &:hover,
            &.pr-filter-btn--active {
                .pr-filter-btn__child {
                    background: $gray-dark;
                }
            }
        }
        .pr-accordion {
            &.pr-accordion-exapanded {
                .pr-accordion-content {
                    .pr-rd-tags.pr-rd-content-block {
                        .pr-rd-review-tag {
                            float: none;
                            width: 100%;
                            dd {
                                padding: 10px;
                            }
                        }
                    }
                }
            }
            .pr-accordion-btn {
                color: $gray-warm;
                > span {
                    font-weight: normal;
                    text-transform: none;
                    font-style: italic;
                    letter-spacing: 0;
                    font-size: 12px;
                    color: $gray-warm;
                    svg {
                        width: 14px;
                        height: 14px;
                    }
                    .pr-caret-icon__line {
                        stroke: $gray-warm;
                    }
                }
            }
        }


        .pr-review-snapshot {
            dl {
                overflow: visible;
            }
            .pr-review-snapshot-header-intro {
                display: none;
            }
            .pr-review-snapshot-block-container {
                font-family: $titillium;
                letter-spacing: .6px;
                display: flex;
                justify-content: center;
                .pr-review-snapshot-block {
                    .pr-review-snapshot-block-headline {
                        letter-spacing: .6px;
                    }
                    .pr-snapshot-tag-def {
                        float: none;
                        &.pr-snapshot-tag-def-filtering {
                            height: auto;
                            .pr-filter-btn {
                                padding-left: 40px;
                                text-indent: -37px;
                            }
                        }
                        &:hover {
                            .pr-filter-btn {
                                .pr-filter-btn__voteCount {
                                    background-color: $gray-dark;
                                }
                            }
                        }
                    }
                    &.pr-review-snapshot-block-cons {
                        display: none;
                    }
                    &.pr-review-snapshot-block-histogram {
                        .pr-ratings-histogram {
                            .pr-histogram-stars {
                                .pr-histogram-label,
                                .pr-histogram-count {
                                    color: $gray-dark;
                                    font-size: 12px;
                                }
                                .pr-ratings-histogram-bar-container {
                                    .pr-ratings-histogram-bar {
                                        .pr-ratings-histogram-barValue {
                                            background: $black;
                                        }
                                    }
                                }
                                &:hover {
                                    .pr-ratings-histogram-barValue {
                                        background: $black;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .pr-review-snapshot-msq-container {
                &.pr-review-snapshot-subratings {
                    .pr-rd-def-list {
                        .pr-snippet-stars {
                            .pr-snippet-rating-decimal {
                                display: block;
                            }
                        }
                    }
                }
                &.pr-review-snapshot-msqs {
                    display: none;
                }
                &.pr-review-snapshot-subratings {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    @include respond(small) {
                        display: table;
                        width: 100%;
                        text-align: center;
                        dl {
                            width: 100%;
                            padding-right: 0;
                            dt, dd {
                                width: 100%;
                                padding-right: 0;
                                button {
                                    margin: 0 auto;
                                }
                            }
                            dd > div {
                                display: inline-block;
                            }
                        }
                    }
                }
                &.pr-review-snapshot-subratings {
                    @include respond(small) {
                        margin-bottom: 15px;
                    }
                }
                .pr-msq-list {
                    dd {
                        @include respond(small) {
                            display: block;
                            float: none;
                            &:first-of-type {
                                padding-top: 30px;
                            }
                        }
                    }
                }
            }

            .pr-review-snapshot-faceoff {
                display: none;
                font-family: $titillium;
                letter-spacing: .6px;
                .pr-faceoff-label {
                    font-size: 14px;
                }
                .pr-snippet-stars {
                    .pr-snippet-rating-decimal {
                        border: none;
                        background-color: $black;
                    }
                }
                .pr-faceoff-title {
                    font-size: 16px;
                }
                .pr-faceoff-review {
                    font-size: 12px;
                    color: $gray-warm;
                }
                button {
                    position: static;
                    @include buttonstyle;
                }
            }

            .pr-review-snapshot-snippets {
                .pr-snippet-stars-reco-inline {
                    .pr-snippet-stars-reco-stars {
                        float: none;
                        text-align: center;
                        .pr-snippet-stars-container {
                            display: block;
                            margin-bottom: 12px;
                            .pr-snippet-stars {
                                .pr-star-v4 {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            .pr-snippet-rating-decimal {
                                font-size: 20px;
                                padding: 2px 4px;
                                border: none;
                                background-color: $black;
                            }
                        }
                        .pr-snippet-read-and-write {
                            .pr-snippet-review-count {
                                display: block;
                                font-family: $titillium;
                                font-size: 14px;
                                margin-bottom: 8px;
                            }
                            .pr-snippet-write-review-link {
                                @include buttonstyle;

                            }
                        }
                    }
                    .pr-snippet-stars-reco-reco {
                        float: none;
                        text-align: center;
                        .pr-snippet-reco-to-friend {
                            margin: 0 auto;
                            font-family: $titillium;
                            max-width: none;
                            .pr-checkbox-icon {
                                display: none !important;
                            }
                            .pr-reco {
                                .pr-reco-value {
                                    font-size: 14px;
                                    font-weight: 700;
                                    color: $gray-dark;
                                }
                            }
                            .pr-reco-to-friend-message {
                                font-size: 12px;
                                line-height: 1.75rem;
                                max-width: none;
                            }
                        }
                    }
                }
            }
            .pr-review-snapshot-histogram-msq-container {
                .pr-review-snapshot-msq-histogram {
                    .pr-histogram-msqs {
                        &:hover {
                            .pr-ratings-histogram-barValue {
                                background: $black;
                            }
                        }
                        .pr-ratings-histogram-bar {
                            .pr-ratings-histogram-barValue {
                                background: $black;
                            }
                        }
                    }
                }
            }

            .pr-review-snapshot-block {
                dl.pr-review-snapshot-tags {
                    dt {
                        margin-top: 0;
                        float: none;
                    }
                }
            }
        }

        //Review Display section
        .pr-review-display {
            font-family: $titillium;
            .pr-rd-main-footer {
                .pr-rd-content-block {
                    width: 100%;
                }
                a {
                    color: $gray-dark;
                }
                .pr-rd-pagination {
                    @include respond(small) {
                        border-bottom: none;
                    }
                    .pr-rd-pagination-btn {
                        color: $gray-dark;
                    }
                }
            }
            .pr-rd-no-reviews {
                margin-bottom: 20px;
                .pr-snippet-write-review-link {
                    @include buttonstyle;
                }
            }
            .pr-review {
                .pr-rd-description {
                    .pr-rd-side-content-block {
                        .pr-rd-reviewer-details {
                            .pr-rd-details {
                                font-size: 14px;
                                margin-bottom: 4px;
                                color: $gray-dark;
                                .pr-rd-bold {
                                    color: $gray-dark;
                                }
                            }
                            .pr-verified_buyer {
                                .pr-badging-icon {
                                    svg #Group-3 circle {
                                        fill: $black !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .pr-rd-main-header {
                .pr-rd-review-header-contents {
                    background: none;
                    float: left;
                    width: 80%;
                    padding-left: 0;
                    padding-right: 0;
                    @include respond(small) {
                        float: none;
                        width: 100%;
                    }
                    .pr-rd-search-container {
                        @extend %search;
                    }
                }
                .pr-rd-review-header-sorts {
                    padding-left: 0;
                    padding-right: 0;
                    float: right;
                    width: 20%;
                    @include respond(small) {
                        float: none;
                        width: 100%;
                    }
                    select {
                        height: 2.25rem;
                        @include respond(small) {
                            width: 100%;
                        }
                    }
                }
            }
            .pr-rd-sort-group {
                @extend %select;
                label[for="pr-rd-filter-variant-by"],
                #pr-rd-filter-variant-by {
                    display: none;
                }
            }
            &.pr-rd-display-desktop {
                article.pr-review {
                    margin-bottom: 20px;
                    border-bottom: 1px solid $clp-border;
                }
                .pr-rd-main-header {
                    padding: 15px 0 20px;
                    background: none;
                    border-bottom: 1px solid $clp-border;
                    .pr-rd-review-header-contents {
                        .pr-rd-search-container {
                            .pr-rd-search-reviews-input {
                                width: 300px;
                                float: right;
                                input {
                                    background-color: $gray-light;
                                }
                            }
                        }
                    }
                    h1 {
                        font-size: 20px;
                        letter-spacing: 0.6px;
                    }
                }
                .pr-rd-main-footer {
                    border-bottom: none;
                }
            }
            .pr-snippet-stars {
                .pr-snippet-rating-decimal {
                    border: none;
                    background-color: $black;
                }
            }
            .pr-rd-def-list {
                dt {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
            .pr-rd-star-rating {
                .pr-snippet-rating-decimal {
                    font-size: 10px;
                    line-height: 15px;
                }
            }
            h2.pr-rd-review-headline {
                font-size: 14px;
                letter-spacing: .6px;
                text-transform: none;
            }
            p.pr-rd-description-text {
                color: $gray-warm;
                font-size: 12px;
                line-height: 18px;
            }
            .pr-rd-footer {
                a {
                    font-size: 12px;
                    text-decoration: underline;
                    color: $gray-dark;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .pr-rd-bottomline {
                    span {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                .pr-rd-helpful-action {
                    .pr-rd-helpful-text {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }

        .pr-snippet-stars {
            .pr-snippet-rating-decimal {
                border: none;
                background-color: $black;
                color: $white;
            }
        }
    }
}

.answers-container {
    .p-w-r {
        .pr-qa-display {
            &.pr-qa-display-desktop {
                padding: 0;
                .pr-qa-display-headline {
                    margin-left: 0;
                }
                .pr-qa-sort-group {
                    label {
                        display: block;
                    }
                }
                .pr-qa-display-question {
                    float: none;
                    width: 100%;
                    .pr-qa-display-questionlist {
                        padding-left: 33px;
                    }
                }
            }
            padding: 0;
            .pr-qa-display-headline {
                margin-left: 0;
                > h1 {
                    display: none;
                }
                .pr-qa-display-ask-question {
                    display: block;
                    float: none;
                    padding: 0;
                    text-align: center;
                    button.pr-btn-default {
                        float: none;
                        @include buttonstyle;
                    }
                }
                .pr-qa-display-search {
                    background: none;
                    position: absolute;
                    @include respond(small) {
                        padding: 0 20px;
                        position: static;
                    }
                    @include respond(large) {
                        padding: 0 20px;
                    }
                    label {
                        display: block;
                        width: auto !important;
                        text-align: center;
                        margin: 0 auto 8px;
                        font-size: 12px;
                        text-transform: none;
                    }
                    .pr-qa-display-searchbar {
                        display: block;
                        max-width: 275px;
                        margin: 0 auto;
                        input {
                            border: none;
                            border-radius: 25px;
                            background: $gray-light;
                            font-family: $titillium;
                            font-size: 12px;
                            line-height: 24px;
                            padding: 0 16px;
                        }
                        button {
                            border: none;
                            background: none;
                            .pr-search-icon {
                                svg {
                                    path {
                                        fill: $black;
                                    }
                                }
                            }
                        }
                    }
                }
                .pr-qa-display-sort {
                    margin-top: 8px;
                    padding-bottom: 35px;
                    @include respond(small) {
                        margin-top: 16px;
                        padding-bottom: 16px;
                    }
                    .pr-qa-sort-headline {
                        font-size: 20px;
                        letter-spacing: .6px;
                        text-transform: none;
                        margin-top: 21px;
                        margin-bottom: 0;
                        @include respond(small) {
                            font-size: 14px;
                            display: inline-block;
                            width: 65%;
                            margin: 0;
                        }
                    }
                }
            }
            .pr-qa-sort-group {
                label {
                    font-size:  12px;
                    text-transform: none;
                    letter-spacing: 0.6px;
                }
                select{
                    width: auto;
                    height: 2.25rem;
                    background-color: $gray-white;
                    border: 2px solid $gray-light;
                    border-radius: 2em;
                    background-image: url("../images/selectArrow.svg");
                    background-position: right 17px center;
                    background-repeat: no-repeat;
                    padding: 0 4em 0 1.5em;
                    color: #666;
                    line-height: 1.5;
                    @include respond(small) {
                        width: 90px;
                        background-position: right 4px center;
                        padding: 0 20px 0 10px;
                    }
                }
                &::after {
                    display: none;
                }
            }
            .pr-qa-display-question {
                font-family: $titillium;
                .pr-qa-display-questionlist {
                    overflow: visible;
                    padding: 0 0 8px 33px;
                    margin-bottom: 32px;
                    border: none;
                    border-bottom: 1px solid $gray-light;
                    @include respond(small) {
                        padding: 0 0 32px 0;
                    }
                    @include respond(large) {
                        padding: 0 0 32px 33px;
                    }
                    .pr-qa-display-item {
                        float: none;
                        margin-bottom: 8px;
                        .pr-qa-display-item-icon {
                            background-color: $black;
                            font-size: 14px;
                            top: -4px;
                            @include respond(small) {
                                left: 0;
                                top: -3px;
                            }
                        }
                        .pr-qa-display-item-text {
                            font-size: 16px;
                            font-weight: 700;
                            letter-spacing: .1px;
                            @include respond(small) {
                                text-indent: 33px;
                            }
                        }
                    }
                    .pr-qa-display-item-details {
                        position: static;
                        letter-spacing: .1px;
                        margin-top: 0;
                        @include respond(small) {
                            float: none;
                            margin-bottom: 16px;
                        }
                        .pr-subscript {
                            font-size: 11px;
                        }
                        time {
                            font-size: 11px;
                            vertical-align: sub;
                        }
                    }
                    .pr-qa-display-answer-link-container {
                        margin-top: 2px;
                        @include respond(small) {
                            float: none;
                            padding-left: 25px;
                        }
                        .pr-btn-answer {
                            border-radius: 0;
                            span {
                                color: $gray-dark;
                                vertical-align: baseline;
                                &.pr-cross-icon {
                                    @include respond(small) {
                                        left: -25px;
                                    }
                                    svg {
                                        .pr-cross-icon__circle,
                                        .pr-cross-icon__line {
                                            stroke: $gray-dark;
                                        }
                                    }
                                }
                                &.pr-qa-display-text {
                                    z-index: 11;
                                    @include respond(small) {
                                        left: 4px;
                                    }
                                }
                            }
                        }
                    }
                    .pr-qa-display-answer {
                        float: none;
                        clear: both;
                        @include respond(small) {
                            padding: 0;
                        }
                        .pr-qa-display-answer-by-container {
                            border-left: 1px solid $black;
                            @include respond(small) {
                                padding-left: 16px;
                            }
                            @include respond(large) {
                                padding-left: 16px;
                            }
                            .pr-qa-display-answer-details {
                                .pr-qa-display-answer-icon {
                                    @include respond(small) {
                                        position: static;
                                        display: inline-block;
                                        margin-right: 4px;
                                    }
                                    @include respond(large) {
                                        position: static;
                                        display: inline-block;
                                        margin-right: 4px;
                                    }
                                    .pr-badging-icon {
                                        svg {
                                            g#Group-618 {
                                                fill: $black;
                                            }
                                        }
                                    }
                                }
                                .pr-answer-by-expert {
                                    @include respond(small) {
                                        display: inline-block;
                                        vertical-align: text-top;
                                        span {
                                            font-size: 12px;
                                            vertical-align: top;
                                        }
                                    }
                                    @include respond(large) {
                                        display: inline-block;
                                        vertical-align: top;
                                    }
                                    span {
                                        color: $black;
                                    }
                                }
                                .pr-qa-display-answer-text {
                                    font-size: 16px;
                                    letter-spacing: .1px;
                                }
                            }
                        }
                    }
                }
            }
            .pr-qa-display-ask-question {
                text-align: center;
                .pr-btn-ask-question {
                    @include respond(small) {
                        float: none;
                        margin-left: 0;
                        @include buttonstyle;
                        .pr-cross-icon {
                            display: none;
                        }
                        .pr-qa-display-text {
                            font-size: 11px;
                            color: $gray-dark;
                        }
                    }
                    @include respond(large) {
                        float: none;
                        margin-left: 0;
                        @include buttonstyle;
                        .pr-cross-icon {
                            display: none;
                        }
                        .pr-qa-display-text {
                            font-size: 11px;
                            color: $gray-dark;
                        }
                    }

                    &:focus {
                        .pr-qa-display-text {
                            text-shadow: none;
                            box-shadow: none;
                            color: $white;
                        }
                    }
                    &:hover {
                        .pr-qa-display-text {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}



.pdp-power-reviews {
    .p-w-r {
        :focus,
        a:focus {
            outline: none;
            box-shadow: none;
            text-decoration: none;
        }
        .pr-helpful-btn {
            border: none;
            &:hover {
                background: $white;
                span {
                    color: $black;
                }
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
        }
    }
    h2.reviews-header {
        margin-bottom: 0;
        @include respond(small) {
            font-size: 20px;
        }
    }
}
