.pt_product-search-noresult {
    .primary-content {
        .no-hits-search button[type="submit"] {
            margin-top: rem(-31px);
            background-color: transparent;
        }
    }
}

#suggestions {
	.selected {
		background: $lightestgrey;
	}
}

.phrase-suggestions {
	.selected {
		width: 100%;
		background: $white;
		text-decoration: underline;
	}
}
.product-suggestions {
	.header-suggestion {
		&.selected {
            background-color: $white;
            color: $gray-dark;
        }
	}
}
