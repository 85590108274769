.notify-me-link-wrapper {
    font-size: rem(14px);
    text-align: right;
    padding: 0;
	@media screen and (min-width: 768px) {
		padding: 0 rem(32px) rem(7px);
	}
    a {
        color: $black;
        text-decoration: underline;
        margin: 0 rem(3px) 0 0;
        font-weight: 700;
        display: block;
    }
}

.ui-dialog.notifyme-dialog {
    font-family: $neuzeit;
	max-width: 594px;
	width: 100% !important;
	transform: translate(-50%, -50%);
	overflow-y: auto;
	.ui-dialog-title {
		float: none;
	}
	.ui-dialog-titlebar-close {
		opacity: 1;
		top: 20px;
		right: 6px !important;
		@media screen and (min-width: 768px) {
			right: 20px;
			top: 38px;
		}
		&:hover {
			background-color: transparent;
		}
	}
	.ui-icon-closethick {
		background-image: url(../images/interface/black-close-btn2.png);
	}
	.dialog-content {		
		float: none;
		margin-top: -1px;
		width: 92% !important;
		overflow-x: hidden;
	}
	.notifyme-header-wrapper {
		margin-bottom: 27px;
		.notifyme-title {
			@include font-size(36px);
			@include line-height(40px);
			color: $blue-light2;
			font-weight: 700;
			margin-bottom: 18px;
		}
		.notifyme-subtitle {
			@include font-size(16px);
			@include line-height(18px);
			color: $black;
			font-weight: 400;
		}
		.attr-lables:not(:last-child) {
			&::after {
				content: '/';				
			}
		}
	}
	.notify-me-form {
		.notify-me-form-container {
			width: 100%;
		}
	}
	.radio-buttons-group-wrapper {
		display: inline-block;
		margin: 0 -5px;
		position: relative;
		vertical-align: middle;
		width: 100%;
		&.orientation {
			padding: 0;
			.label-container {
				padding: 0 12px 12px;
			}
			.radio-button {
				width: calc(50% - 22px);
				margin: 0 10px 10px 5px;
			}
		}
		.radio-buttons-container {
			position: relative;
		}
		.label-container {
			padding: 0 7px 4px;
			color: $gray-medium2;
		}
		.label {
			display: block;
			margin-bottom: 0;
			@include font-size(14px);
			font-weight: 700;
			color: $gray-medium2;
		}
		#selectedColor {
			position: absolute;
			top: -3px;
			right: 0;
			font-weight: 700;
			font-size: 14px;
		}
		.size {
			.radio-button {
				.radio-button-label {
					width: rem(52.5px);
					@include respond(small) {
						width: rem(51.5px);
					}
				}
			}
		}
		.width {
			.radio-button {
				.radio-button-label {
					min-width: 5.1125rem;		
					width: auto;
					@include respond(largeUp) {
						min-width: 5.1875rem;
					}
				}
			}
		}
		.radio-button {
			float: none;
			position: static;
			text-transform: none;
    		margin: 0 2.5px 10px;
			float: none;
			display: inline-block;
			.radio-button-label {
				height: rem(42px);
				line-height: rem(42px);
				padding: 0;
				@include font-size(14px);
				border: 2px solid $gray-light3;
				color: #292929;
				display: block;
				text-align: center;
				transition: box-shadow .2s;
				font-weight: 700;
			}
			input[type=radio] {
				position: absolute;
				left: -999em;
				font-size: 16px;
				&:checked ~ .radio-button-label {
					color: $white;
					box-shadow: none;
					border-color: $black;
					background-color: $black;
					font-weight: 700;
				}
			}
			&:hover {
				input[type=radio]:not(:checked) ~ .radio-button-label {
					box-shadow: inset 0 0 0 1px $black;
				}
			}
			span {
				&.error {
					position: absolute;
    				bottom: -7px;
					@include font-size(12px);
					font-weight: 400;
				}
			}
			&.swatchanchor {
				cursor: pointer;
				padding: 3px;
				border: 2px solid $trans;
				border-radius: 2px;
				background: $gray-white;

				&.selected,
				&:hover {
					border-color: $black;
				}

				&.not-selectable {
					opacity: 0.5;

					&:hover {
						border-color: $trans;
					}
				}
			}
		}
		& + .radio-buttons-group-wrapper {
			margin-top: 12px;
		}
		&.error-notify {
			.label-container span,
			.radio-buttons-container .radio-button {
				color: $red;
			}
			.radio-buttons-container {
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: calc(100% - 5px);
					height: calc(100% - 10px);
					top: -2px;
					left: 50%;
					transform: translateX(-50%);
					border: 2px solid $red;
					z-index: -1;
				}
				.radio-button-label {				
					color: $red;
				}
			} 
		}
	}
	input:not([type=checkbox]) {
		border: 1px solid $gray-warm;
		color: $gray-warm;
		background-color: $white;
		border-radius: 0;
		@include font-small;
		font-size: 16px;
		font-weight: 400;
		padding: 4px 18px;
		text-transform: none;

		+ input,
		+ span:empty + input,
		+ button {
			margin-top: 15px;
		}
	}
	.wrapper-optin {
		position: relative;
	}
	.input-checkbox {
		display: inline;
		float: left;
		margin-left: 0;
		& + label,
		& + .error + label {
			background: url(../images/interface/unchecked.png) no-repeat 0 3px;
			width: auto;
			float: none;
			overflow: hidden;
			padding-left: rem(35px);
			display: block;
			@media only screen and (min-width: 550px) {
				min-height: 21px;
				.content-asset {
					line-height: 21px;
				}
			}
			span {
				@include font-size(12px);
				color: #4C4C4C;
				text-transform: none;
				font-weight: normal;
				float: left;
				line-height: 120%;
				margin-left: 0;
			}
		}
		&:checked {
			& + label,
			& + .error + label {
				background: url(../images/interface/checked.png) no-repeat 0 3px;
			}
		}
	}
	.notifyme-email-confirm {
		background: none;
		width: auto;
		float: none;
		overflow: hidden;
		padding-left: 0.375rem;
		display: block;
	}
	.notifyme-submit {
		@include blueButton;
		@include respond(small) {
			width: 100%;
		}

	}
	&.notifyme-success {
		padding: 0 !important;
		.ui-widget-header {
			background-color: $blue-light2;
		}
		.ui-icon-closethick {
			background-image: url(../images/interface/white-close-btn.png);
		}
		.ui-dialog-title {
			float: none;
		}
		#dialog-container {
			float: none;
			padding: 0 !important;
			width: 100% !important;
		}
		.notifyme-confirmation-header {
			background-color: $blue-light2;
			padding: 15px 24px 48px;
			@include respond(small) {
				text-align: center;
			}
		}
		.notifyme-confirmation-reco {
			min-height: 400px;
			padding-bottom: 0;
		}
		.notifyme-confirmation-title {
			@include font-size(36px);
			@include line-height(36px);
			font-weight: 700;
			margin-bottom: 18px;
			color: $white;
		}
		.notifyme-confirmation-subtitle {
			@include font-size(16px);
			@include line-height(18px);
			color: $white;
			font-weight: 400;
			margin-bottom: 40px;
			span.bolden {
				font-weight: 700;
			}
		}
		.notifyme-confirmation-continue-shopping {
			.continue-shopping {
				padding: 0 1.875rem;
				text-transform: uppercase;
				line-height: 40px;
				height: 44px;

				@include respond(small) {
					width: 100%;
				}
			}
		}
		.club-pdp-recommendations {
			margin: 0 auto 0 auto;
			padding: 24px 0;
			@media screen and (min-width: 768px) {
				padding: 24px 12px;
			}
			.product-content-headers {
				margin-bottom: 28px;
			}
			.product-tile {
				max-height: 374px;
			}
			.tiles-container {
				padding: 0;
				margin-bottom: 0;
				.slick-list {
					padding: 0 !important;
					margin: 0;
					.slick-track {
						margin: 0 auto;
					}
				}
				.slick-arrow {
					height: 30px;
					width: 30px;
					&:hover,
					&:focus {
						background-color: $white !important;
					}
					&.slick-prev {
						background: $white url(../images/icons/arrow-left-grey.svg) center no-repeat;
						background-size: 10px 10px;
						left: 0px;

					}
					&.slick-next {
						background: $white url(../images/icons/arrow-right-grey.svg) center no-repeat;
						background-size: 10px 10px;
						right: -10px;
						@media screen and (min-width: 768px) {
							right: -15px;
						}
					}
				}
				.grid-tile {
					margin: 0 10px;
					@media only screen and (min-width: 1200px) {
						margin: 0 11px;
					}
				}
			}
		}
	}
	
	.pdp-recommendations .product-listing-1x4 .grid-tile .product-tile {
		.product-swatches ul li {
			width: 20px;
		}
		.plp-text-badge {
			display: none;
		}
	}

	.pdp-recommendations .product-listing-1x4 .grid-tile .product-tile .product-swatches ul li.open-close {
		line-height: 20px;		
	}
	.pdp-recommendations h3 {
		font-size: rem(16px);
		margin-top: rem(30px);
	}
}