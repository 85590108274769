// Level 1's
.level-1 > li {	
    &:hover {
        > a {
            color: $black;
        }
    }
	> a {
        font-size: rem(18px) !important;
        text-decoration: none;
        @include respond(largeUp) {
            color: $white;
        }
        &:hover {
            color: $black;
            @include respond(small) {
                background-color: $lightestgrey !important;
            }
        }
		@include respond(largeUp) {
			text-transform: none;
		}
		@media only screen and (min-width: 880px) and (max-width: 1320px) {
			font-size: rem(16px) !important;
		}
	}
    .menu-item-toggle {
        font-family: $eurostile;
    }
}

.link-group-link,
.link-group-head {
    font-family: $neuzeit !important;
}

.link-group-link {
    font-weight: 400 !important;
}

.link-group-head {
    font-weight: 700;
    font-size: 20px !important;
}

.menu-footer a {
    &::after {
        display: none !important;
    }
}

.megamenu-content {
    font-family: $neuzeit;
    text-transform: none;
}

// Mobile Menu Toggle
.menu-toggle, .mobile-hamburger-toggle {
    @include respond(small) {
        height: 52px;
        span {
            &:nth-child(1) {
                top: 19px;
                width: 28px;
            }
            &:nth-child(2) {
                top: 27px;
                width: 20px;
            }
            &:nth-child(3) {
                top: 35px;
                width: 28px;
            }
        }
    }
}

nav[role="navigation"] {
	@extend .clearfix;
	color: $black;
	margin: 0;
	width: 100%;
	height: 100vh;
	top: 52px; //due to being relative from the top black banner
	float: none;

	@include respond(small){
    	left: -100%;
		position: fixed;
		top:74px;
    	height: 100vh;
    	overflow-y: auto;
    	background: $white-smoke;
    	padding-bottom: 20px;
		display: block;
		z-index: 99;        
		.menu-active & {
			display: block;
		}
	}

	@include respond(largeUp) {
		color: $black;
		float: left;
		left: initial;
		top: initial;
		position: initial;
		height: auto;
		width: auto;
		border: 0;
		min-width:475px;
		overflow:hidden;
		margin: auto;
	}
	.primary-logo {
		margin: 0;
		padding: 15px 20px;
		height: 66px;
		background: $white;
		width: 100%;
		float: left;
		clear: both;
		&.mobile-only {
			@include respond(largeUp) {
				display: none;
			}
		}
	}
	.menu-category {

        .home-button {
            @include respond(small) {
                &:hover {
                    background-color: $lightestgrey;
                }
            }
        }

		@include respond(small){
			z-index: 100;
			clear: both;
			background: $gray-pale;
		}
		@include respond(large){
			z-index: 100;
			clear: both;
			background: $gray-pale;
		}
		@include respond(largeUp) {
			display: flex;
			justify-content: space-between;
			margin-left: 8.5%;
		}
		.level-2{
			padding-top:0px;
			z-index: -1;
            ul > li a {
                @include respond(small) {
                    font-size: 16px;
                    font-family: $eurostile !important;
                    padding: 19px 18px 17px 47px;
                }
            }
			.catSubnav{
				ul{
					margin-right: 10px;
					ul{
						margin-right: 0;
					}
					@include respond(small) {
						margin:0;
					}
				}
				.subNavBanners {
					.fly-out-banner {
						float:left;
						margin:0 10px;
						max-width: 250px;
						margin-bottom: 40px;
					}
				}

				@include respond(large) {
					& > ul{
						margin-right: 0;
						width: 40%;
						&#cat-nav-myjoys{
							width: 46%;
							margin-right: 45px;
							& + .subNavBanners{
								width: 47%;
							}
						}
					}
					.two-columns {
						float: left;
						width: 50%;
						& > ul {
							margin-right: 0;
							width: 50%;
						}
					}
					.subNavBanners {
						float: right;
						width: 50%;
						margin-left: 0;
						.fly-out-banner {
							float:left;
							width:50%;
							margin:0 20px;
							max-width:243px;
						}
						a {
							width: auto;
						}
					}
				}
			}
		}
	}
}

// Mobile Menu Toggle
.menu-toggle, .mobile-hamburger-toggle {
    border-right: 1px solid $gray-light;
	display: block;
    cursor: pointer;
    width: 48px;
	height: 48px;
	position: relative;
	margin: 0;
	transform: rotate(0deg);
	transition: $quickSpeed;
    outline: none;
    top: 0;

	@include respond(largeUp) {
		display: none;
	}

	span {
		background: $black;
		height: 2px;
		position: absolute;
		left: 10px;
		transition: all 0.3s;
		transform-origin: 0 50%;

		&:nth-child(1) {
            top: 15px;
            width: 28px;
		}
		&:nth-child(2) {
            top: 23px;
            width: 20px;
		}
		&:nth-child(3) {
            top: 31px;
            width: 28px;
		}
	}

	&.open, &.active {
		span {
			left: 14px;

			&:nth-child(1) {
				top: 14px;
				transform: rotate( 45deg );
			}

			&:nth-child(2) {
				width: 0;
				top: 24px;
			}

			&:nth-child(3) {
				top: 34px;
				transform: rotate( -45deg );
			}
		}

	}
}

.menu-category, .playersSlot ul {
	@extend %clearfix;
	list-style: none;
	margin: 0;
	padding: 0;
    text-transform: none;

	@include respond(small) {
		background: $white;
	}

	@include respond(largeUp) {
		margin: 0;
		width: 100%;
    }

	@include respond(large){
		padding-right: 0;
		display:none;
	}

	@include respond(small) {
		.home-button {
			position: absolute;
			z-index: 1;
			height: 60px;
			display:none;
			border-top: 2px solid $gray-darker;
			padding: 18px 20px 5px;
			background: transparent;
			line-height: 24px;
			letter-spacing: normal;
			font-family: $suisseintregular;
			text-transform: uppercase;
			font-size: 14px;
			padding-left: 30px;
			cursor:pointer;

			&::after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				top: 50%;
				left: 18px;
				right: auto;
				border-bottom: 2px solid $black;
				border-left: 2px solid $black;
				transform: translateY(-50%) rotate(45deg);
				transform-origin: 50% 50%;
				transition: transform .5s;
			}
			&.hideme {
				display:block;
                background-color: transparent;
			}            
		}
	}
	li {
		list-style: none;

		@include respond(small) {
			background: $white;

			&.active {
				display:block;
                &:hover {
                    background: $white;
                    color: $black;
                }
			}
			&.hideme {
				display:none;
			}
		}
		@include respond(largeUp) {
			border: none;
			float: left;
			border: 0;
			letter-spacing:1.64px;
			height:60px;
			&:last-child{
				border: 0;
			}
			&:hover > a {
				background-color: transparent;
			}
		}
	}
	a {
		color: #000;
		font-family: $suisseintregular;
		text-transform: none;
		font-weight: 400;
		font-size: 14px;
    	padding: 0.9rem 0;
		display: block;
		width: 100%;
		@include respond(large){
			font-size: .75rem;
		}
	}
}

// Level 1's
.level-1 > li {

	&:first-child {
		border-top: none;
	}

	&:hover {
		background-color: $lightestgrey;

		@include respond(small) {
			background: $lightestgrey;
		}
	}

	&.active {
		i.menu-item-toggle-nav{
			@include respond(small) {
				//open
				transform: rotate(45deg);
			}
		}

		> a.menu-item-toggle {
			@include respond(small) {
				padding-left: 18px;

				&::after {
					display: none;
				}
			}
		}
	}

	a {
		line-height: 2rem;
		@include respond(small) {
			font-size: 18px;
			line-height:24px;
			letter-spacing:normal;
		}
		transition: background-color $quickSpeed, color $quickSpeed;
		&:hover {
            @include respond(small) {
			    background-color: $lightestgrey;
            }
			border-bottom: none;
		}
		@include respond(largeUp) {
			&:hover {
				background-color: transparent;
			}
		}
		img {
			&.help-icon {
				margin-top:-5px;
				margin-left: 5px;
				vertical-align:middle;
			}
		}
		&.special-1857 {
			font-size:25px;
			letter-spacing:normal;
			img {

				@include respond(largeUp) {
					padding-bottom: 7px;
				}
			}
		}
	}
	> a {
		border-top: 1px solid $gray-darker;
		display: block;
		padding: 18px;
		position: relative;
		@include respond(small) {
			font-size: 18px;
			line-height:24px;
			letter-spacing:normal;
		}
		@include respond(largeUp) {
			border-top: none;
			float: none;
			padding: 5px 1vw 0px;
			margin: 0 1rem;
			width: auto;
			box-sizing: border-box;
			border-bottom: 5px solid transparent;
			line-height: 52px;
			&.has-sub-menu {
				width: auto;
			}
			&:hover {
				background-color: transparent;
				color: $black;
				border-color: $black;
				border-top: none;
			}
		}

		&:hover {
			background-color: $white;

			@include respond(largeUp) {
				background-color: transparent;
			}
		}

		&::after {
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			top: 50%;
			right: 20px;
			border-top: 2px solid $black;
			border-right: 2px solid $black;
			transform: translateY(-50%) rotate(45deg);
			transform-origin: 50% 50%;
			transition: transform .5s;

			@include respond(largeUp) {
				display: none;
			}
		}

		&.has-sub-menu {
			margin: 0;
			font-size: 14px;
			text-transform: none;

			@include respond(small) {
				font-size: 18px;
			}

			&.active {
				padding-left: 55px;

				&::after {
					display: none;
				}
			}
		}
	}
}

.level-2 {
	box-shadow: none;
	clear: left;
	display: none;
	z-index: 1;

	.level-1 > .active & {
		display: block;
		@include respond(largeUp){
			//if somethings open in mobile and then the browser gets wide...
			display: none; //ignore the .active class possibly set by javascript
		}
	}

	ul {
		@extend .clearfix;
		padding: 0;
		background-color: $white-smoke;
		border-top: 1px solid $white-smoke;
		@include respond(small) {
			background: $gray-pale;
            border-top: none;            
		}
		> li {
			border: 0;
			&:last-child {
                @include respond(small) {
				    border-bottom: $darkgrey;
                }
			}
			h3 {
				font-size: 14px;
			}
			@include respond(small) {
				a {
					font-size: 15px;
					padding: 18px 18px 18px 47px;
					border-top: 1px solid $gray-darker;
					position: relative;

					&.active {
						&::after {
							content: "—";
						}
					}

					&::after {
						content: "+";
						position: absolute;
						display: block;
						top: 0;
						right: 23px;
						text-align: center;
						font-size: 20px;
						line-height: 61px;
						text-align: center;
						border: none;
						transform: none;
						width: 11px;
						height: 11px;
					}
					&.without-submenu {
						&::after {
							display: none;
						}
					}
				}
			}
		}
		a{
			border: 0;
			text-align: left;
			padding: 1rem 1.333rem;
			margin: 0;
		}
	}
	.subNavBanners{
		display: none;
		/*margin-top: 1.25rem;*/
		margin-left: 2.5rem;
		float: right;
		padding-top:8px;
		margin-bottom:20px;
		margin-left:0;
		width:50%;
		@include respond(largeUp) {
			display:flex;
		}
		a{
			padding: 0;
			margin: 0;
			text-decoration:none;
		}
		img{
			display: block;
			margin-bottom: 1.25rem;
		}
		.fly-out-banner {
			margin:10px;
			max-width:160px
		}
	}

	@include respond(small){
		.catSubnav{
			display: none;
		}
		.mobileSubnav{
			display: block;
		}
	}

	@include respond(largeUp) {

		width: 100%;
		position: absolute;
		left: 0px;
		top: 60px;
		background-color: $lightestgrey;
		box-shadow: 0 3px 3px 2px rgba($gray-dark, 0.3);
		color: $black;
		padding: 0;
		z-index: 2;
		clear: none;

		.innerWidth{
			padding:50px 20px;
			@include respond(standard){
				max-width: 1400px;
			}
			@include respond(largeUp){
				max-height: 400px;
			}
		}

		ul {
			float: left;
			padding-left: 0;
			background-color: transparent;
			border-top: 0;
			margin-right: 2.5rem;

			> li {
				float: left;
				clear: left;
				min-width: 12rem;
				border: 0;
				background-color: transparent;

				&.bottomBorder{
					padding-bottom: 1em;
					border-bottom: 1px solid $gray-light;
				}

				> a {
					padding: 1rem 0 0 0;
					text-transform: none;
					background-color: transparent;

					&.withArrow{
						font-size: 14px;

						&:after{
							content: '>>';
						}
					}
				}
			}
			&#cat-nav-shoes, &#cat-nav-apparel {
				h3 {
					margin-bottom: 0;
				}
				.cat-nav-link {
					padding-top: .75rem;
				}
			}
			&#cat-nav-gloves, &#cat-nav-gear, &#cat-nav-socks {
				h3 {
					margin-bottom: .5rem;
				}
			}
		}
		.catSubnav{
			display: flex;
            justify-content: center;
            .subNavImg {
                max-width: 368px;
                width: initial;
                @media only screen and (min-width: 880px) and (max-width: 1199px) {
                    max-width: 278px;
                }
                .desktop-promo-link {
                    font-size: 18px !important;
                    display: block !important;
                    margin: 10px 0;
                    clear: both;
                }
            }
		}
		.mobileSubnav{
			display: none;
		}
	}
}


/*custom my joys level-2 styles */
#cat-nav-myjoys {
	margin-right: 0;
	li {
		float: left;
		min-width: initial;
		width: 100%;
		h3 {
			margin-bottom: 1rem;
		}
		ul {
			li {
				clear: none;
				float: left;
				min-width: initial;
				width: auto;
			}
			&.level-3 li a {
				padding: 0 1rem 5px;
				&:first-of-type {
					padding-right: 1.25rem;
					padding-left: 0;
				}
			}
		}
		button {
			padding: 0 2em;
		}
	}
}
.level-2 {
	.level-3 {
		&.active {
			display: block;
		}
	}
}

.home li > ul.level-3 {
	@include respond(small) {
		margin-left: 0;
	}
}

ul.level-3 {
	float: none;
	color: $darkgrey; //#999
	padding-left: 0;
	@include respond(small) {
		display: none; //no show in mobile menu!(?)
		padding: 0;
		background-color: $white;
		border-top: 1px solid $darkgrey;
		margin-top: 0;
	}
	& > li{
		float: none;

		& > a {
			padding-top: .33rem;
			padding-bottom: .33rem;
			padding-left: 66px;
			font-weight: 400;
			color: $darkgrey;
			@include respond(small) {
				border-top: none;
				font-size: 15px;
				line-height: none;
				color: $black;
				padding: 15px 15px 15px 66px;

				&::after {
					display: none;
				}
			}
		}
	}
}

.nav-mobile-only {

	@include respond(largeUp){
		display: none;
		&.home-button {
			display: none!important;
		}
	}
	li {
		border-top: 1px solid $white-smoke;
	}
	a {
		/*border-top: solid 2px darken( $gray-light, 10% );*/
		display: block;
		padding: 18px 20px;
		position: relative;
		border-top: 1px solid $gray-darker;
		&::after {
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			top: 50%;
			right: 20px;
			border-top: 2px solid $black;
			border-right: 2px solid $black;
			transform: translateY(-50%) rotate(45deg);
			transform-origin: 50% 50%;
			transition: transform .5s;
		}

		&.block-promo {
			background: $gray-lighter;
			padding: 20px;
			&::after {
				display:none;
			}
			.mobile-menu-promo-title {
				padding: 10px 0;
			}
			.mobile-menu-promo-text {
				font-size: 15px;
				color: $gray-medium;
				font-family: $font-small;
				text-transform: none;
			}
		}
	}
}

.nav-mobile-first {
	display: none;
	a {
		font-weight: normal;
		height: 30px !important;
		border: none;
	}
	@include respond(small){
		display: block;
	}
}
.playersSlot{
	margin-top: 1rem;
	float: left;
	box-sizing: border-box;
	padding-left: 1rem;
	margin-left: 23px;
    &::before {
        content: '';
        display: block;
        width: 2px;
        background: $gray-light;
        height: 26px;
        position: absolute;
        top: 27px;
    }
	ul{
		margin: 0;
		padding: 0;
		li a{
			padding: 1rem 20px 3rem 10px;
			margin: 0 0 0 32px;
		}
	}
	@include respond(large){
		padding-left: 0;
		margin-left: 0;
		ul li a{
            padding: 1rem 5px 3rem 0;
            margin: 0 0.3rem 0 1rem;
		}
	}
	@include respond(small){
		display: none;
	}
}

/* Updated Navigation Styles 10/2018 */
.desktop-menu-link-groups {
	overflow: hidden;
	float:left;
	width:40%;

	.link-group {
		float: left;
		padding-right: 25px;
		margin-bottom: 35px;
		max-width:185px;
		&:first-child {
			margin-bottom: 40px;
		}

		* {
			display: block;
			/*line-height: 25px;*/
			line-height: 1;
			max-width:185px;
		}
		a {
			&:hover {
				text-decoration:underline;
			}
		}
		p {
			&.link-text {
				padding-top:14.4px;
			}
		}
		.link-group-head {
			font-size: 14px;
			padding: 5px 0 20px;
			padding-top:0;
			font-family: $suisseintmedium;
		}

		.link-group-head,
		.link-group-link {
			letter-spacing: normal !important;
		}

		.link-group-link {
			font-size: 11px;
			letter-spacing: .8px;
			line-height:25px;
			padding:0;

			&:hover {
				text-decoration: underline;
			}
		}

		.link-group-divider {
			width: 80px;
			margin: 8px 0 15px 0;
			border-top: solid 2px rgba( $black, 0.3 );
		}

		.link-group-spacer {
			height: 25px;
			@include respond(large) {
				height: 27px;
			}
		}
	}
}

.desktop-menu-promos {
	display: flex;
	float: left;
	overflow: hidden;
	max-width:60%;
	@include breakpoint( $standard-breakpoint ) {
		max-width: 60%
	}

	.desktop-menu-promo {
		display: block;
		float: left;
		max-width: 250px;

		&:hover > .desktop-menu-promo-title {
			text-decoration: underline;
		}
		&:hover > .desktop-menu-promo-text {
			text-decoration: none;
		}

		img {
			width: 100%;
		}

		.desktop-menu-promo-title {
			font-size: 16px;
			padding: 5px 0;
			letter-spacing:normal;
			line-height:normal;
		}

		.desktop-menu-promo-text {
			font-size: 12px;
			color: $gray-strong;
			font-family: $font-small;
			text-transform: none;
			letter-spacing:normal;
			line-height:normal;
			font-weight:100;
			text-decoration:none;
		}
	}
}
.desktop-menu-promo {
	display: block;
	float: left;
	max-width: 275px;

	&:hover > .desktop-menu-promo-title {
		text-decoration: underline;
	}
	&:hover > .desktop-menu-promo-text {
		text-decoration: none;
	}
	& + .desktop-menu-promo {
		margin-left: 25px;
	}

	img {
		width: 100%;
	}

	.desktop-menu-promo-title {
		font-size: 20px;

		padding: 5px 0;
	}

	.desktop-menu-promo-text {
		font-size: 16px;
		color: $gray-strong;
		font-family: $font-small;
		text-transform: initial;
		line-height:1;
	}
}

#wrapper {
	&.menu-active {
		#navigation {
			height:90%;
		}
	}
}

#wrapper header.header .header-main #navigation ul.menu-category.level-1,
.header-main__inner {
    background-color: $black;
}

.menu-toggle,
.mobile-hamburger-toggle {
    border-right: 1px solid $black;
    top: 4px;
    span {
        background-color: $white;
    }
}

#mobileSearchShowBtn {
    height: 49px;
}

@include respond(largeUp) {
	#wrapper {
		header {
			&.header {
				.header-main {
					box-shadow: none;
					border-bottom: none;
					#navigation {
						ul {
							&.menu-category {
								@include respond(large){
									padding-right:inherit;
									font-size:14px;
									display: flex;
									justify-content: space-around;
								}

								&.level-1 {
									display: flex !important;
                                    @include respond(largeUp){
                                        justify-content: unset;
                                        margin-left: 0;
                                    }
                                    @include respond(xlUp) {
                                        justify-content: space-evenly;
                                    }
									li {
										@include respond(largeUp){
											padding: 0 20px 0;
										}
                                        @media only screen and (min-width: 880px) and (max-width: 1102px) {
                                            padding: 0 10px 0;
                                        }
										a {
											padding:0;
											font-size: 13px;
											line-height:64px;
											height: 30px;
											padding-bottom: 12px;
											white-space:nowrap;
											overflow:visible;
											@include respond(largeUp){
												height: inherit;
												letter-spacing: normal;
											}
											@include respond(small){
												&:hover {
                                                    @include respond(small) {
                                                        background-color: $lightestgrey;
                                                    }
                                                }
											}
                                            @media only screen and (min-width: 880px) and (max-width: 926px) {
                                                font-size: 14px !important;
                                            }
											&.desktop-menu-promo {
												white-space:normal;
											}
										}
										.level-2 {
											.link-group {
												width:initial;
												padding:0;
												margin-right: 4%;
                                                max-width: 196px;
											}
											.desktop-menu-link-groups {
												width:50%;
												padding-left: 4%;

												.link-group {
													min-width:100px;
													@include respond(large){
														&:first-child {
															padding-left: 0;
														}
													}												}
											}
											.innerWidth {
												padding: 35px 0 0;
											}
											a {
												line-height:1;
												padding:1px 0;
												max-width: none;
												&.link-group-head {
													font-size:18px;
													padding-bottom:15px;
												}
												&.link-group-link {
													font-size:14px;
                                                    line-height: 16px;
                                                    margin-bottom: 13px;
                                                    white-space: inherit;
												}
												.desktop-menu-promo-text {
													font-size:14px;
													line-height:16px;
												}
											}
											.subNavBanners {
												width:50%;
												margin-bottom:20px;
												margin-left:0;
												display:flex;
												@include respond(large){
													max-width:53%;
												}
												.fly-out-banner {
													max-width: 30%;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.js #suggestions {
    background-color: $white;
}