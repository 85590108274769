
// Help Menu

.menu-utility-user {
	background-color: $lightgrey;
	li {
		&.user-info {
			background-color: $lightgrey !important;
        }
        .account-success-modal {
            background-color: #F2F2F0;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 99;
            display: none;  
        }
        .user-panel {
            border-top-color: $black;
            .user-links a {
                &::after {
                    border-color: $black;
                }
            }
            .account-toggle-wrapper button {
                background-color: $darkgrey;
                border-color: $darkgrey;
                border-radius: 4px;
                font-weight: 400;
                font-size: 16px;
                &:hover {
                    background-color: $black;
                    border-color: $black;
                    color: $white;             
                }
            }
        }
    }
}

#mini-cart {
    background-color: $darkgrey;
}

.menu-help {
    background-color: $white;
}
